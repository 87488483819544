import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Translatable } from 'src/app/shared/model/translatable.model';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { Show } from '../../show/model/show.model';
import { getClosestDay } from '@app/shared/utils/time-utils';

export class Promotion extends Translatable {
    id?: number;
    statusId: number;
    organizationId: number;
    title: string;
    deadline: Date;
    showId: number;
    show: Show;
    dateFrom: string;
    monthsNumber: number;
    isPaid: number;
    createdAt: string;

    private promotionForm: UntypedFormGroup;

    constructor(data: any) {
        super();
        this.id = data[ 'id' ];
        this.statusId = data[ 'statusId' ];
        this.organizationId = data[ 'organizationId' ];
        this.title = data[ 'title' ];
        this.deadline = data[ 'deadline' ];
        this.showId = data[ 'showId' ];
        this.dateFrom = data[ 'dateFrom' ] ? getClosestDay(data[ 'dateFrom' ]).toString() : "";
        this.monthsNumber = data[ 'monthsNumber' ];
        this.isPaid = data[ 'isPaid' ];
        this.createdAt = data[ 'createdAt' ];
        this.trans = data[ 'trans' ];
        this.show = data[ 'show' ] ? new Show(data[ 'show' ]) : null;
    }

    toListItem(lang: string): ListItem {
        const item = new ListItem();

        item.itemId = this.id;
        item.itemDate = this.dateFrom;
        item.itemTitle = this.getTitle(lang);
        item.itemImgSrc = this.show ? this.show.image : '';
        item.itemStatus = this.statusId;
        item.itemInfo1 = this.monthsNumber + '';

        return item;
    }

    getPromotionFormGroup(fb: UntypedFormBuilder, lang: string): UntypedFormGroup {
        if (!this.promotionForm) {
            this.promotionForm = fb.group({
                title: [ this.getTitle(lang) ],
                selectedShow: [ this.show, [ Validators.required ] ],
                dateFrom: [ this.dateFrom, [ Validators.required ] ],
                monthsNumber: [
                    this.monthsNumber ? this.monthsNumber.toString() : '1',
                    [ Validators.required ],
                ],
            });
        }
        return this.promotionForm;
    }

    getTitle(lang: string) {
        return this.trans
            ? this.getTranslatedProperty(lang, 'title')
            : this.show
                ? new Show(this.show).getTranslatedProperty(lang, 'title')
                : '';
    }
}
