import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SPMenuItemComponent } from './menu-item.component';
import { RouterModule } from '@angular/router';


@NgModule({
    imports: [ CommonModule, RouterModule, ],
    exports: [ SPMenuItemComponent ],
    declarations: [ SPMenuItemComponent ]
})
export class SPMenuItemModule { }
