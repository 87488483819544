import { NgModule } from '@angular/core';
import { SPButtonModule } from './button/button.module';
import { SPMenuModule } from './menu/menu.module';
import { CommonModule } from '@angular/common';
import { SPMenuItemModule } from './menu/menu-item/menu-item.module';
import { SPRadioModule } from './radio/radio.module';
import { SPDividerModule } from './divider/divider.module';
import { SPCheckboxModule } from './checkbox/checkbox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SPCheckboxGroupModule } from './checkbox-group/checkbox-group.module';
import { SPSelectModule } from './sp-selesct/select.module';
import { ActionButtonModule } from './action-button/action-button.module';

const modules = [
    CommonModule,
    SPButtonModule,
    SPMenuModule,
    SPMenuItemModule,
    SPRadioModule,
    SPDividerModule,
    SPCheckboxModule,
    SPCheckboxGroupModule,
    FormsModule,
    ReactiveFormsModule,
    SPSelectModule,
    SPRadioModule,
    ActionButtonModule
];

@NgModule({
    imports: [...modules],
    exports: [...modules]
})
export class ComponentsModule {}
