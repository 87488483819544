import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'sp-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: RadioGroupComponent,
          multi: true,
        },
      ],
})
export class RadioGroupComponent implements  ControlValueAccessor {
    onChange = (value: any) => {};
    onTouched = () => {};

    @Input() options: { label: string; value: boolean }[] = [];
    @Input() formControl: UntypedFormControl;
    @Output() selectionChange = new EventEmitter<any>();

    constructor() {}

    writeValue(value: any): void {
        if (this.formControl) {
            this.formControl.setValue(value);
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    emitSelection(value: any) {
        this.selectionChange.emit(value);
        this.onChange(value);
        this.onTouched();
    }

}
