import { Component, Input, OnChanges, HostBinding, SimpleChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

let unique = 0;

@Component({
    selector: 'sp-radio-button',
    template: `
        <nz-form-item>
            <div class="row w-100">
                <div class="col-12 mb-1">
                    <label [attr.for]="id">
                        <ng-content></ng-content>
                    </label>
                </div>
                <div class="col-12">
                    <nz-form-control>
                        <nz-radio-group class="d-flex" [attr.id]="id" [(ngModel)]="selected" (ngModelChange)="ngModelChange()">
                            <label nz-radio *ngFor="let key of keys" [nzValue]="key" [nzDisabled]="disabled">{{ translateKeyPrefix + Options[key] | translate }}</label>
                        </nz-radio-group>
                    </nz-form-control>
                </div>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent<T> implements OnChanges, ControlValueAccessor, OnInit {
    private onChange: (value: T) => void;
    private onTouched: () => void;
    keys: (string | number)[];
    @Input() Options: { [key: string]: string } | { [key: number]: string };
    @Input() disabled: boolean;
    @Input() validateStatus = '';
    @Input() labelProperty: string;
    @Input() valueProperty = 'id';
    @Input() translateKeyPrefix = '';
    @Input() valueSelected: any;
    @Input() filterOutKeys: (string | number)[] = [];
    @Output() setValueOnchange: EventEmitter<boolean> = new EventEmitter();
    @HostBinding('class.warning') get classWarning(): boolean {
        return this.validateStatus === 'warning';
    }
    @HostBinding('class.error') get classError(): boolean {
        return this.validateStatus === 'error';
    }
    @HostBinding('class.disabled') get classDisabled(): boolean {
        return this.disabled;
    }
    id = `RadioComponent-${unique++}`;
    selected: any = false;
    constructor(readonly translateService: TranslateService, private readonly ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }
    ngOnInit(): void {
        this.selected = this.valueSelected;
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.Options) {
            this.keys = Object.keys(this.Options)
                .filter((key) => !this.filterOutKeys.includes(isNaN(Number(key)) ? key : Number(key)))
                .map((key) => (isNaN(Number(key)) ? key : Number(key)));
        }
    }
    ngModelChange(): void {
        this.onChange(this.selected);
        this.onTouched();
        this.setValueOnchange.emit(this.selected);
    }
    writeValue(propertyValue: any): void {
        this.selected = propertyValue;
    }
    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
