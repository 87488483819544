import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Tour} from 'src/app/concepts/tours/model/tour.model';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {PxUser} from 'src/app/concepts/account/model/project-x-user';
import {ToursService} from "../../../concepts/tours/services/tours.service";
import {take, tap} from "rxjs/operators";
import {AccountService} from "../../../concepts/account/services/account.service";
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';

interface RouteData {
  user: PxUser,
  tour: Tour,
}

@Component({
  selector: 'app-tour-access-manager',
  templateUrl: './tour-access-manager.component.html',
  styleUrls: ['./tour-access-manager.component.scss'],
})
export class TourAccessManagerComponent implements OnInit, OnDestroy {
  tourId: number;
  subscription = new Subscription();
  routeData: RouteData;
  tourAdmin: boolean;


  constructor(
    readonly translateService: TranslateService,
    readonly activatedRoute: ActivatedRoute,
    private readonly toursService: ToursService,
    private readonly accountService: AccountService,
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly router: Router
  ) {
  }

  ngOnInit() {
    this.subscription.add(combineLatest([
      this.activatedRoute.paramMap,
      this.activatedRoute.data as Observable<RouteData>
    ]).subscribe(([paramMap, routeData]) => {
      this.routeData = routeData;
      if (paramMap.get('tourId')) {
        this.tourId = +paramMap.get('tourId');
        this.tourAdmin = this.routeData.tour.admins.some(admin => admin.user.id === this.routeData.user.id);

      }
    }));
  }

  openTour = () => {
    return this.toursService.accesDiffuseurs(
      this.routeData.tour.id,
      this.accountService.getCurrentCtxOrganizationId(),
      true
    ).pipe(
      tap(() => {
        this.routeData.tour.isDepositOpen = true;
        this.setTourStorage();
      }),
      take(1)
    ).subscribe();
  }

  closeTour = () => {
    return this.toursService.accesDiffuseurs(
      this.routeData.tour.id,
      this.accountService.getCurrentCtxOrganizationId(),
      false
    ).pipe(
      tap(() => {
        this.routeData.tour.isDepositOpen = false;
        this.setTourStorage();
      }),
      take(1)
    ).subscribe();
  }

  inviteDiffusers = () => {
    this.router.navigate([
      this.localizeRouterService.translateRoute(
        `/tour/${ this.tourId }/modification/diffuseurs`
      ),
    ]);
  }

  private setTourStorage() {
    const tourList:Tour[] = [];
    const storedTours = sessionStorage.getItem('rideau.notifications.tours');
    if(storedTours) {
    const parsedData = JSON.parse(storedTours) as Tour[];
    parsedData.forEach((tourItem) => {
      if(tourItem.id === this.routeData.tour.id) {
        tourItem.isDepositOpen = this.routeData.tour.isDepositOpen;
      }
      tourList.push(tourItem);
    })
    } else {
      tourList.push(this.routeData.tour);
    }
    sessionStorage.setItem('rideau.notifications.tours', JSON.stringify([ ...tourList ]));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
