import { Component, OnInit, Inject } from '@angular/core';
import {
    ListItem,
    SelectedFilter,
    Filter,
    Pagination,
    FilterValue,
} from 'src/app/shared/model/list-item.model';
import { Publication } from '../../model/publication.model';
import { TranslateService } from '@ngx-translate/core';
import { PublicationService } from '../../services/publication.service';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ObjectStatus } from 'src/app/shared/enums/object-status.enum';
import { Globals } from '@app/_configs/globals';

@Component({
    selector: 'app-publication-list',
    templateUrl: './publication-list.component.html',
})
export class PublicationListComponent implements OnInit {
    public isLoading = false;
    public leftSideFilters: Filter[] = [];
    public pagination: Pagination = new Pagination();
    public pubItemArray: ListItem[] = [];

    private lang: string;
    private publications: Publication[];
    private selectedFilters: SelectedFilter[] = [];

    constructor(
        private globals: Globals,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private publicationService: PublicationService,
        private notification: RideauNotificationService
    ) { }

    public deletePublication(itemId) {
        this.publicationService.deletePublication(itemId).subscribe(() => {
            this.notification.success(
                this.translate.instant('PUBLICATION-SUPPRIMEE')
            );
            this.getPublications();
        });
    }

    public getPublications() {
        this.isLoading = true;
        const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
        this.publicationService
            .getPublications([ ...this.selectedFilters ], this.pagination)
            .subscribe((res) => {
                this.publications = res;
                this.pubItemArray = this.publications.map((pub) => {
                    const item = pub.toListItem(this.lang);
                    //  Dates dans descriptions
                    // item.itemStatus = null;
                    item.itemInfo1 = this.translate.instant('SOUMIS-LE') + ' ';
                    item.itemInfo1 += new Date(pub.createdAt).toLocaleDateString(
                        this.globals.locales.frFR,
                        options
                    );
                    item.itemInfo2 = this.translate.instant('PUBLICATION-LE') + ' ';
                    item.itemInfo2 += new Date(pub.dateFrom).toLocaleDateString(
                        this.globals.locales.frFR,
                        options
                    );
                    return item;
                });

                this.isLoading = false;
            });
    }

    public initFilter() {
        // Tous les élements
        const allItemsFilter = new Filter();
        allItemsFilter.filterField = '';
        allItemsFilter.filterValues = [
            {
                filterValueName: this.translate.instant('TOUS'),
                selected: true,
            } as FilterValue,
        ];

        const statusFilter = new Filter();
        statusFilter.filterField = 'statusId';
        statusFilter.filterName = this.translate.instant('SHOW-STATUS');
        statusFilter.filterValues = Object.keys(ObjectStatus)
            .filter((val) => isNaN(Number(val)))
            .map((key) => ({
                filterValueName: this.translate.instant('STATUS.' + key),
                filterValue: ObjectStatus[ key ],
            } as FilterValue));

        this.leftSideFilters = [ allItemsFilter, statusFilter ];
    }

    public initPagination() {
        this.pagination.limit = 12;
        this.pagination.offset = 0;
        this.pagination.total = 0;
    }

    public modifyPublication(itemId) {
        const route = this.localizeRouter.translateRoute('modify');
        this.router.navigate([ `./${itemId}/${route}` ], { relativeTo: this.route });
    }

    public ngOnInit(): void {
        this.lang = this.translate.currentLang;
        this.initFilter();
        this.initPagination();
        this.getPublications();
    }

    public onFilterChange(event: SelectedFilter) {
        this.selectedFilters = [ event ];
        this.getPublications();
    }

    public onPageChange(event: Pagination) {
        this.pagination.offset = event.offset;
        this.getPublications();
    }
}
