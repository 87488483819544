import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subscription, Observable } from 'rxjs';
import { ProgramRouteData } from 'src/app/concepts/program/program-route-resolvers';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';
import { PaidTourShowsResolver } from 'src/app/concepts/tours/paid-tour-shows.resolver';
import { ShowStatus } from '@app/concepts/show/enums/show-status.enum';

@Component({
    selector: 'app-selected-tour-manager',
    templateUrl: './selected-tour-manager.component.html',
    styleUrls: ['./selected-tour-manager.component.scss']
})
export class SelectedTourManagerComponent implements OnInit, OnDestroy {
    subscription = new Subscription();
    routeData: ProgramRouteData;
    selected: number;
    options: Array<{ id: number; label: string; tourShow: TourShow }>;

    constructor(
        readonly translateService: TranslateService,
        readonly activatedRoute: ActivatedRoute,
        private readonly localizeRouterService: LocalizeRouterService,
        private readonly router: Router,
        private readonly paidTourShowsResolver: PaidTourShowsResolver
    ) {}

    ngOnInit() {
        this.subscription.add(
            (this.activatedRoute.data as Observable<ProgramRouteData>).subscribe((routeData) => {
                this.routeData = routeData;
                this.update();
            })
        );
        this.activatedRoute.snapshot.data.showStatusId = ShowStatus.APPROUVE;
        this.subscription.add(
            this.paidTourShowsResolver.resolve(this.activatedRoute.snapshot).subscribe((tourShows) => {
                this.routeData.tourShows = tourShows.filter((tour) => tour.show.statusId == ShowStatus.APPROUVE && tour.show.isActive);
                this.update();
            })
        );
    }

    update() {
        this.selected = this.routeData.tourShow ? this.routeData.tourShow.id : null;
        this.options = this.routeData.tourShows.map((tourShow) => {
            const count = tourShow.programCount;
            const countText = this.translateService.instant(count ? 'DATE-COUNT' : 'AUCUNE-DATE', { count });
            return {
                count,
                id: tourShow.id,
                label: `${tourShow.show.getTranslatedProperty(this.translateService.currentLang, 'title')} (${countText})`,
                tourShow
            };
        });
    }

    ngModelChange() {
        let queryParams: any = { ...this.activatedRoute.snapshot.queryParams };

        if (this.selected) {
            queryParams = { ...queryParams, showId: this.routeData.tourShows.find((tourShow) => tourShow.id === this.selected).showId };

            this.router.navigate([this.localizeRouterService.translateRoute(`/programmations/tour/${this.routeData.tour.id}/tour-show/${this.selected}`)], {
                queryParams: queryParams
            });
        } else {
            this.router.navigate([this.localizeRouterService.translateRoute(`/programmations/tour/${this.routeData.tour.id}`)], { queryParams: queryParams });
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
