import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Permission } from 'src/app/shared/enums/roles.enum';
import { OrganizationTypes } from '../../organization/enums/organization-types.enum';
import { AccountService } from './account.service';
@Injectable({
    providedIn: 'root'
})
export class PermissionGuard  {
    private _appPermission: Permission;
    private _objectOrganizationId: number;
    private _organizationType: OrganizationTypes;
    private _shouldBeApproved = true;

    constructor(private accountService: AccountService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // get permissions needed from data
        this._appPermission = route.children[0] ? route.children[0].data['appPermission'] : route.data['appPermission'];
        this._organizationType = route.children[0] ? route.children[0].data['organizationType'] : route.data['organizationType'];
        this._shouldBeApproved =
            route.children[0] && route.children[0].data['shouldBeApproved'] !== undefined ? route.children[0].data['shouldBeApproved'] : this._shouldBeApproved;
        // get organizationId if available
        this._objectOrganizationId = route.params['organizationId'] ? Number(route.params['organizationId']) : null;
        return this.accountService.populateUserIdAsync().flatMap(() => {
            return this.accountService.checkAccess(this._appPermission, this._objectOrganizationId, this._organizationType, this._shouldBeApproved);
        });
    }

    canActivateChild = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => this.canActivate(route, state);
}
