import { Component, AfterViewInit, Inject } from '@angular/core';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { forkJoin, of, Observable } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { LocationService } from 'src/app/concepts/location/services/location.service';
import { countInvalidControls } from 'src/app/shared/utils/other-utils';
import { SiteConcepts } from '../../../../shared/enums/site-concepts.enum';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { isBlankValidator } from '../../../../shared/validators/not-blank.validator';
import { AccountService } from '../../../account/services/account.service';
import { AuthService } from '../../../account/services/auth/auth.service';
import { OrganisationStatus } from '../../enums/organization-status.enum';
import { OrganizationService } from '../../services/organization.service';
import { Globals } from './../../../../_configs/globals';
import { IOrganizationTypes, Organization } from './../../model/organization.model';
import { OrganizationManagerService } from '../../services/organizations-manager.service';
import { ObjectUtils } from '@app/shared/utils/object.utils';

@Component({
    selector: 'app-organization-modify',
    templateUrl: './organization-modify.component.html',
    styleUrls: [ './organization-modify.component.scss' ]
})
export class OrganizationModifyComponent implements AfterViewInit {
    public SCENE_PRO_ID = this.globals.SCENE_PRO_ORGID;
    public breadcrumbItem: BreadcrumbItem;
    public coordonneesErrorCount = 0;
    public currentCtxOrg: number;
    //displaying errors
    public displayErrors = false;
    public isLoading: boolean;
    public isOrgMember: any;
    public isProducteur = false;
    public isReady = false;
    public isRideauMember = false;
    public lang = this.translate.currentLang;
    public openedTab: string;
    public organization: Organization;
    public organizationId: number;
    public profileErrorCount = 0;
    public typeErrorCount = 0;
    public user = parseInt(this.authService.User.id);

    private cleanupAndModifyOrganizationHandler$ = (): Observable<any> => {
        ObjectUtils.cleanUpNullValues<Organization>(this.organization);
        return this.organizationService.modifyOrganization(this.organization)
    }

    constructor(
        public translate: TranslateService,
        public locationService: LocationService,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private organizationService: OrganizationService,
        private organizationManager: OrganizationManagerService,
        private authService: AuthService,
        private accountService: AccountService,
        private notification: RideauNotificationService,
        private modalService: NzModalService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private globals: Globals,
        private fb: UntypedFormBuilder,
        private router: Router
    ) { }

    public approve(): void {
        this.changeStatus(OrganisationStatus.APPROUVE).subscribe(() => {
            this.notification.success(this.translate.instant('STATUS.APPROUVEE'));
        });
    }

    public deleteOrganization(id: number): void {
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: acceptDelete,
            nzCancelText: rejectDelete,
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.organizationService
                    .deleteOrganization(id)
                    .pipe(
                        switchMap(() => {
                            const userOrganizations = this.organizationManager.getLocalUserOrganizations();
                            if (userOrganizations) {
                                return of(userOrganizations);
                            }
                            return this.accountService.getUserOrganizations();
                        }),
                        map((organizations: Organization[]) => {
                            if (!organizations.length) {
                                return this.router.navigate([ '/dashboard' ]);
                            }
                            // set 1st organization as current
                            this.organizationManager.deleteUserOrganization(id);
                            this.accountService.setCurrentCtxOrganizationId(organizations[ 0 ].id);
                        }),
                        tap(() => this.notification.success(this.translate.instant('ORGANIZATION-SUPPRIMEE'))),
                        tap(() => this.organizationManager.setUserOrganizationStatus('deleted')),
                        finalize(() => {
                            // redirect to list of organizations in user profile
                            const route: any[] = this.localizeRouter.translateRoute([ '/profile/', 'organizations' ]) as any[];
                            this.router.navigate([ ...route ]);
                        })
                    )
                    .subscribe();
            }
        });
    }

    public getSingleOrganization(): void {
        this.organizationService.getOrganization(this.organizationId).subscribe(
            (data: Organization) => {
                this.organization = new Organization(data);

                this.isProducteur = this.organization.types.includes(OrganizationTypes.IS_PRODUCTEUR);

                // Set BreadcrumbItem
                const organisationName = this.organization.getTranslatedProperty(this.lang, 'name');
                const organisationURL = '/organization/' + this.organization.id;

                this.breadcrumbItem.set(organisationName, organisationURL, true);
                this.isOrgMember = this.accountService.isUserMemberOfOrganisation(this.organization.id);

                this.setBreadcrumb();

                if (this.organization.statusId !== OrganisationStatus.APPROUVE) {
                    this.updateErrorCounts();
                }
                this.getSocialMedias().subscribe((x) => (this.isReady = true));
                this.isRideauMember = this.organization.networks.includes(this.globals.RIDEAU_ORGID) && this.organization.id !== this.globals.SCENE_PRO_ORGID;
            },
            (error) => {
                this.organizationManager.deleteUserOrganization(this.route.snapshot.params[ 'organizationId' ]);
                const transTab: any[] = this.localizeRouter.translateRoute([ '/profile/', 'organizations' ]) as any[];
                this.router.navigate(transTab);
            }
        );
    }

    public getSocialMedias(): Observable<any> {
        return this.organizationService.getOrganizationSocialMedia(this.organization.id).do((data) => (this.organization.socialMediaAccounts = data));
    }

    public ngAfterViewInit(): void {
        this.breadcrumbItem = new BreadcrumbItem();
        this.organizationId = this.route.snapshot.params[ 'organizationId' ];
        this.currentCtxOrg = this.accountService.getCurrentCtxOrganizationId();
        this.handleRouteEvents();
        this.getSingleOrganization();
    }

    public reject(): void {
        this.changeStatus(OrganisationStatus.REJETE).subscribe(() => {
            this.notification.success(this.translate.instant('STATUS.REJETEE'));
        });
    }

    public saveCoord(event: unknown): void {
        this.isLoading = true;
        forkJoin([
            this.locationService.modifyAdress(this.organization.address),
            this.cleanupAndModifyOrganizationHandler$()
        ])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
                this.updateErrorCounts();
                /**
                 * Si l'organisationn'est pas approuvée, et si on n'a pas de
                 * parametre d'evenement, on sauve puis on passe à l'onglet
                 * suivant.
                 * Sinon, on sauve juste
                 */
                if (this.organization.statusId !== OrganisationStatus.APPROUVE && event === undefined) {
                    const transTab = this.localizeRouter.translateRoute('type');
                    this.router.navigate([ `../${transTab}` ], { relativeTo: this.route });
                }
            });
    }

    public saveProfile(event: unknown): void {
        this.isLoading = true;

        forkJoin([
            this.organizationService.addSocialMedias(this.organization.id, this.organization.socialMediaAccounts),
            this.cleanupAndModifyOrganizationHandler$()
        ])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.updateErrorCounts();
                /**
                 * Si l'organisationn'est pas approuvée, et si on n'a pas de
                 * parametre d'evenement, on sauve puis on Soumet pour Approbation
                 * Sinon, on sauve juste
                 */
                if (this.organization.statusId !== OrganisationStatus.APPROUVE && event === undefined) {
                    if (this.totalErrorCount() > 0) {
                        // afficher message d'erreur et toggle open le displayError
                        this.notification.error(this.translate.instant('MESSAGES.ERREUR-SOUMISSION'));
                        this.displayErrors = true;
                    } else {
                        this.submitForApproval();
                    }
                }
            });
    }

    public saveTypes(event: unknown): void {
        this.isLoading = true;
        const body = {
            organizationId: this.organization.id,
            types: this.organization.types
        } as IOrganizationTypes;

        forkJoin([
            this.organizationService.modifyOrganizationTypes(body),
            this.cleanupAndModifyOrganizationHandler$()
        ])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.updateErrorCounts();
                /**
                 * Si l'organisationn'est pas approuvée, et si on n'a pas de
                 * parametre d'evenement, on sauve puis on passe à l'onglet
                 * suivant.
                 * Sinon, on sauve juste
                 */
                if (this.organization.statusId !== OrganisationStatus.APPROUVE && event === undefined) {
                    const transTab = this.localizeRouter.translateRoute('profile');
                    this.router.navigate([ `../${transTab}` ], { relativeTo: this.route });
                }
            });
    }

    public setBreadcrumb(): void {
        this.currentCtxOrg = this.accountService.getCurrentCtxOrganizationId();
        this.breadcrumbService.addBreadcrumbCascade([ this.breadcrumbItem ], true);
        // If i'm a member of the organization except Scene-pro members
        if (this.organization && this.currentCtxOrg == this.organization.id) {
            this.breadcrumbService.setBreadcrumbConcept(SiteConcepts.ACCOUNT);
        }
    }

    public submitForApproval(): void {
        this.changeStatus(OrganisationStatus.SOUMIS).subscribe(() => {
            this.notification.success(this.translate.instant('MESSAGES.SOUMIS-POUR-APPROBATION'));
        });
    }

    public toggleDisplayErrors(): void {
        this.displayErrors = !this.displayErrors;
        if (this.displayErrors) {
            this.updateErrorCounts();
        }
    }

    public totalErrorCount(): number {
        return this.coordonneesErrorCount + this.typeErrorCount + this.profileErrorCount;
    }

    private changeStatus(status: OrganisationStatus): Observable<any> {
        const approvalState = {
            organizationId: this.organizationId,
            statusId: status
        };
        this.isLoading = true;
        return this.organizationService.modifyOrganizationApproval(approvalState).do(() => {
            this.organization.statusId = status;
            this.isLoading = false;
        });
    }

    /**
     * L'onglet courrant est determiné par le parametre :page de l'URL. Comme celui-ci
     * est traduit, il y a un travail de revers traduction à faire pour trouver l'onglet
     * correspondant.
     */
    private handleRouteEvents(): void {
        const tabs = [
            'coordonnees',
            'type',
            'profile',
            'promotion',
            'team',
            'contacts',
            'broadcaster',
            'venues',
            'artists',
            'billing',
            'members',
            'network-infos',
            'user-depot'
        ];

        this.route.params.subscribe((params) => {
            if (params[ 'organizationId' ] !== this.organizationId) {
                this.organizationId = params[ 'organizationId' ];
                this.getSingleOrganization();
            }
            //default value
            this.openedTab = tabs[ 0 ];
            if (params.page) {
                // traduire tous les onglets jusqu'à trouver celui passé en URL
                for (const tab of tabs) {
                    const locTab = this.localizeRouter.translateRoute(tab);
                    if (params.page === locTab) {
                        this.openedTab = tab;
                        break;
                    }
                }
                this.setBreadcrumb();
            } else {
                //redirect to coordonees si pas de parametre.
                const transTab = this.localizeRouter.translateRoute(this.openedTab);
                this.router.navigate([ `./${transTab}` ], { relativeTo: this.route });
            }
        });
    }

    private updateErrorCounts(): void {
        const coordForm = this.organization.getCoordFormGroup(this.translate.currentLang);
        this.organization.coordFormValueChangedListener();
        this.coordonneesErrorCount = countInvalidControls(coordForm);

        const typeForm = this.organization.getTypeFormGroup(this.fb);
        typeForm.updateValueAndValidity();
        this.typeErrorCount = typeForm.status === 'INVALID' ? 1 : 0;

        const profileForm = this.organization.getProfileFormGroup(this.fb, this.translate.currentLang);
        this.profileErrorCount = countInvalidControls(profileForm);

        // Custom error count for profile description box (not part of the form group)
        const descriptionInput = this.organization.getTranslatedProperty(this.translate.currentLang, 'description');
        if (isBlankValidator(descriptionInput)) this.profileErrorCount++;
    }
}
