import { Injectable } from '@angular/core';
import { ResolveEnd, ResolveStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResolverListnerService {
    private isResolvingSubject$ = new Subject<boolean>();
    public isResolvingObservable$ = this.isResolvingSubject$.asObservable();

    constructor(private router: Router) {}

    public initListner() {
        this.router.events.pipe(filter((routerEvents) => routerEvents instanceof ResolveStart)).subscribe((_) => {
            this.isResolvingSubject$.next(true);
        });

        this.router.events.pipe(filter((RouterEvents) => RouterEvents instanceof ResolveEnd)).subscribe((_) => {
            this.isResolvingSubject$.next(false);
        });
    }
}
