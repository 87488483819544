<form nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row nzGutter="16">
        <div nz-col nzMd="12">
            <!-- <nz-form-item>
                <nz-form-control>
                    <label for="name">{{ 'FORM.NOM-SALLE' | translate}} *</label>
                    <input nz-input id="name" name="name" formControlName="name" required>
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('name').errors">
                        {{'CHAMP-REQUIS'|translate}}
                    </span>
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'name'" [control]="formGroup.get('name')" inputType="text">
                <div class="input-custom-label">
                    <label for="name">{{ 'FORM.NOM-SALLE' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('name').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
    </div>
    <div nz-row nzGutter="16">
        <div nz-col nzMd="12">
            <!-- <nz-form-item>
                <nz-form-control>
                    <label for="address1">{{ 'FORM.ADRESSE' | translate}} *</label>
                    <input id="address1" name="address1" formControlName="address1">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('address1').errors">
                        {{'CHAMP-REQUIS'|translate}}
                    </span>
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'address1'" [control]="formGroup.get('address1')" inputType="text">
                <div class="input-custom-label">
                    <label for="address1">{{ 'FORM.ADRESSE' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('address1').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="12">
            <!-- <nz-form-item>
                <nz-form-control>
                    <label for="city">{{ 'FORM.VILLE' | translate}} *</label>
                    <input nz-input id="city" name="city" formControlName="city">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('city').errors">
                        {{'CHAMP-REQUIS'|translate}}
                    </span>
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'city'" [control]="formGroup.get('city')" inputType="text">
                <div class="input-custom-label">
                    <label for="city">{{ 'FORM.VILLE' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('city').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
    </div>
    <div nz-row nzGutter="16">
        <div nz-col nzMd="8">
            <!-- <nz-form-item>
                <nz-form-control>
                    <label for="zipcode">{{ 'FORM.CODE-POSTAL' | translate}} *</label>
                    <input nz-input id="zipcode" name="zipcode" formControlName="zipcode">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('zipcode').errors">
                        {{'CHAMP-REQUIS'|translate}}
                    </span>
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'zipcode'" [control]="formGroup.get('zipcode')" inputType="text">
                <div class="input-custom-label">
                    <label for="zipcode">{{ 'FORM.CODE-POSTAL' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('zipcode').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <nz-form-item>
                <nz-form-control>
                    <label for="country">{{ 'FORM.PAYS' | translate }} *</label>
                    <select formControlName="country">
                        <option *ngFor="let country of countries" [ngValue]="country.id">
                            {{ country.name }}
                        </option>
                    </select>
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('country').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <nz-form-item *ngIf="states$ | async as states">
                <ng-container *ngIf="canadaSelected; else otherCountries">
                    <nz-form-control>
                        <label for="province">{{ 'FORM.PROVINCE' | translate }} *</label>
                        <select class="" name="province" id="province" formControlName="province">
                            <option value="null" disabled>{{ 'FORM.CHOISIR-PROVINCE' | translate }}</option>
                            <option *ngFor="let state of states" [value]="state.getTranslatedProperty(lang, 'name')">
                                {{ state.getTranslatedProperty(lang, 'name') }}
                            </option>
                        </select>
                        <span class="has-error" *ngIf="displayErrors && formGroup.get('province').errors">
                            {{ 'CHAMP-REQUIS' | translate }}
                        </span>
                    </nz-form-control>
                </ng-container>
                <ng-template #otherCountries>
                    <nz-form-control>
                        <label for="province">{{ 'FORM.PROVINCE' | translate }} *</label>
                        <input id="province" name="province" formControlName="province" />
                        <span class="has-error" *ngIf="displayErrors && formGroup.get('province').errors">
                            {{ 'CHAMP-REQUIS' | translate }}
                        </span>
                    </nz-form-control>
                </ng-template>
            </nz-form-item>
        </div>
    </div>
    <div nz-row class="d-flex mb-3" nzGutter="16">
        <div nz-col nzMd="8">
            <!-- <nz-form-item>
                <nz-form-control>
                    <label for="phone">{{ 'FORM.TELEPHONE' | translate}} *</label>
                    <input nz-input id="phone" formControlName="phone">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('phone').errors">
                        {{'CHAMP-REQUIS'|translate}}
                    </span>
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'phone'" [control]="formGroup.get('phone')" inputType="text">
                <div class="input-custom-label">
                    <label for="phone">{{ 'FORM.TELEPHONE' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('phone').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <!-- <nz-form-item>
                <nz-form-control>
                    <label for="phonePostNumber">{{ 'FORM.POSTE' | translate}}</label>
                    <input nz-input id="phonePostNumber" name="phonePostNumber" formControlName="phonePostNumber">
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'phonePostNumber'" [control]="formGroup.get('phonePostNumber')" inputType="text">
                <div class="input-custom-label">
                    <label for="phonePostNumber">{{ 'FORM.POSTE' | translate }}</label>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <!-- <nz-form-item>
                <nz-form-control>
                    <label for="courriel">{{ 'FORM.COURRIEL' | translate}} *</label>
                    <input type="email" nz-input id="email" name="email" formControlName="email">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('email').errors">
                        <span
                        *ngIf="formGroup.get('email').errors['required']; else incorrectField">{{ 'CHAMP-REQUIS' | translate }}</span>
                          <ng-template #incorrectField>{{'ERRORS.EMAIL-FORMAT' | translate}}</ng-template>
                    </span>
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'email'" [control]="formGroup.get('email')" inputType="email">
                <div class="input-custom-label">
                    <label for="courriel">{{ 'FORM.COURRIEL' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('email').errors">
                        <span *ngIf="formGroup.get('email').errors['required']; else incorrectField">{{ 'CHAMP-REQUIS' | translate }}</span>
                        <ng-template #incorrectField>{{ 'ERRORS.EMAIL-FORMAT' | translate }}</ng-template>
                    </span>
                </div>
            </app-nz-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <!-- <nz-form-item>
                <nz-form-control>
                    <label for="website">{{ 'FORM.SITE-WEB' | translate }}</label>
                    <input id="website" nz-input name="website" formControlName="website">
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'website'" [control]="formGroup.get('website')" inputType="text">
                <div class="input-custom-label">
                    <label for="website">{{ 'FORM.SITE-WEB' | translate }}</label>
                </div>
            </app-nz-form-item>
        </div>
    </div>
    <!-- wikidata & artsdata -->
    <div class="row">
        <div class="col-md-6">
            <!-- <nz-form-item>
                <nz-form-label for="wikidataId" class="label-only label-with-icon">
                    <span>{{ 'VENUE-DATA.IDENTIFIER' | translate }} {{ 'VENUE-DATA.WIKIDATA_TITLE' | translate
                        }}</span><a class="ml-3 d-inline-flex" [routerLink]="'/help' | localize"
                        [fragment]="'AIDE-MENU-ID.BOTTIN' | translate"><span
                            class="help-icon help-icon--blue help-icon--small" nz-tooltip
                            nzTooltipTitle="{{'VENUE-DATA.TOOLTIP_LINK' | translate }}" nzTooltipPlacement="right">
                        </span></a></nz-form-label>
                <nz-form-control [ngClass]="{'u-cursor-not-allowed': !isSceneProAdmin}">
                    <input nz-input id="wikidataId" [disabled]="!isSceneProAdmin" name="wikidataId"
                        formControlName="wikidataId">
                    <span class="has-error" *ngIf="shouldDisplayFormControlError('wikidataId', 'regex')">
                        {{'VENUE-DATA.ERROR-FORMAT' | translate:{'format':'Q111857243'} }}
                    </span>
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'wikidataId'" [control]="formGroup.get('wikidataId')" inputType="text">
                <div class="input-custom-label">
                    <label for="wikidataId" class="label-only label-with-icon">
                        <span>{{ 'VENUE-DATA.IDENTIFIER' | translate }} {{ 'VENUE-DATA.WIKIDATA_TITLE' | translate }}</span>
                        <a class="ml-3 d-inline-flex" [routerLink]="'/help' | localize" [fragment]="'AIDE-MENU-ID.BOTTIN' | translate">
                            <span
                                class="help-icon help-icon--blue help-icon--small"
                                nz-tooltip
                                nzTooltipTitle="{{ 'VENUE-DATA.TOOLTIP_LINK' | translate }}"
                                nzTooltipPlacement="right"
                            ></span>
                        </a>
                    </label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="shouldDisplayFormControlError('wikidataId', 'regex')">
                        {{ 'VENUE-DATA.ERROR-FORMAT' | translate : { format: 'Q111857243' } }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div class="col-md-6">
            <!-- <nz-form-item>
                <nz-form-label for="artsdataId" class="label-only label-with-icon">
                    <span>{{ 'VENUE-DATA.IDENTIFIER' | translate }} {{ 'VENUE-DATA.ARTSDATA_TITLE' | translate }}</span>
                    <a class="ml-3 d-inline-flex" [routerLink]="'/help' | localize" [fragment]="'AIDE-MENU-ID.BOTTIN' | translate">
                        <span class="help-icon help-icon--blue help-icon--small" nz-tooltip nzTooltipTitle="{{'VENUE-DATA.TOOLTIP_LINK' | translate }}" nzTooltipPlacement="right"></span>
                    </a>
                </nz-form-label>
                <nz-form-control [ngClass]="{'u-cursor-not-allowed': !isSceneProAdmin}">
                    <input nz-input id="artsdataId" [disabled]="!isSceneProAdmin" name="artsdataId" formControlName="artsdataId">
                    <span class="has-error" *ngIf="shouldDisplayFormControlError('artsdataId', 'regex')">
                        {{'VENUE-DATA.ERROR-FORMAT' | translate:{'format':'abcd1234'} }}
                    </span>
                </nz-form-control>
            </nz-form-item> -->

            <app-nz-form-item inputId="'artsdataId'" [control]="formGroup.get('artsdataId')" inputType="text">
                <div class="input-custom-label">
                    <label for="artsdataId" class="label-only label-with-icon">
                        <span>{{ 'VENUE-DATA.IDENTIFIER' | translate }} {{ 'VENUE-DATA.ARTSDATA_TITLE' | translate }}</span>
                        <a class="ml-3 d-inline-flex" [routerLink]="'/help' | localize" [fragment]="'AIDE-MENU-ID.BOTTIN' | translate">
                            <span
                                class="help-icon help-icon--blue help-icon--small"
                                nz-tooltip
                                nzTooltipTitle="{{ 'VENUE-DATA.TOOLTIP_LINK' | translate }}"
                                nzTooltipPlacement="right"
                            ></span>
                        </a>
                    </label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="shouldDisplayFormControlError('artsdataId', 'regex')">
                        {{ 'VENUE-DATA.ERROR-FORMAT' | translate : { format: 'abcd1234' } }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
    </div>
</form>
