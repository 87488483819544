<form [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row nzGutter="16">
        <div nz-col nzMd="12">
            <app-nz-form-item [label]="'FORM.TITRE' | translate" [control]="formGroup.get('title')" inputType="text"></app-nz-form-item>
        </div>
    </div>

    <div nz-row nzGutter="16">
        <div nz-col nzMd="12">
            <sp-simple-select [label]="'FORM.CATEGORIE'" [options]="categoryList" [control]="formGroup.get('categoryId')"></sp-simple-select>
        </div>
    </div>

    <div nz-row nzGutter="16">
        <div nz-col nzMd="16">
            <nz-form-item>
                <nz-form-control>
                  <label for="description">{{ 'FORM.DESCRIPTION' | translate }} </label>
                  <div class="quill-editor-edit d-inline-block w-100"
                       [class.ql-disabled]="formGroup.disabled"
                       tabindex="formGroupe.disabled ? null : 0">
                    <quill-editor id="description"
                                  class="w-100"
                                  [(ngModel)]="descriptionModel"
                                  [disabled]="formGroup.disabled"
                                  [sanitize]="true"
                                  [modules]="descriptionModules"
                                  (onContentChanged)="limitDescriptionLength($event)"
                                  [placeholder]="'FORM.DESCRIPTION-PLACEHOLDER' | translate"
                                  [ngModelOptions]="{ standalone: true }">
                    </quill-editor>
                  </div>
                </nz-form-control>
              </nz-form-item>
        </div>
    </div>

    <div nz-row nzGutter="16" class="ml-1 mb-1">
        <label for="date">{{ 'FORM.DATE-ET-HEURE' | translate }}</label>
    </div>
    <div nz-row nzGutter="16">
        <div nz-col nzMd="6">
            <nz-form-item>
                <nz-form-control>
                    <sp-nz-date-picker [control]="formGroup.get('date')"></sp-nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="1" class="pt-3">
            {{ 'DE' | translate }}
        </div>
        <div nz-col nzMd="4">
            <nz-form-item>
                <nz-form-control>
                    <nz-time-picker formControlName="hourFrom" nzFormat="HH:mm"></nz-time-picker>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="1" class="pt-3">
            {{ 'A-CCENT' | translate }}
        </div>
        <div nz-col nzMd="4">
            <nz-form-item>
                <nz-form-control>
                    <nz-time-picker formControlName="hourTo" nzFormat="HH:mm"></nz-time-picker>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <div nz-row nzGutter="16">
        <div nz-col nzMd="8">
            <nz-form-item>
                <nz-form-control>
                    <label for="place">{{ 'FORM.LIEU' | translate }}</label>
                    <input id="place" name="place" formControlName="place" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <nz-form-item>
                <nz-form-control>
                    <label for="room">{{ 'FORM.SALLE' | translate }}</label>
                    <input id="room" name="room" formControlName="room" />
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row nzGutter="16">
        <div nz-col nzMd="16">
            <sp-checkbox [classList]="['mb-2']" formControlName="isAvailable">
                <strong>{{ 'FORM.ACTIVITY-DISPO' | translate }}</strong>
            </sp-checkbox>
        </div>
    </div>

    <button nz-button [nzLoading]="isLoading" class="btn btn--default">
        {{ 'ENREGISTER' | translate }}
    </button>
</form>
