<form nz-form
      [formGroup]="formGroup"
      (ngSubmit)="doSubmit()"
      class="mb-4">
  <div nz-row
       nzGutter="16">
    <div nz-col
         nzMd="10">
      <nz-form-item>
        <nz-form-control>
          <label for="orgAccountingNumber">{{ 'FORM.NUMERO-DE-PLAN-COMPTABLE' | translate }}
            <input id="orgAccountingNumber"
                   formControlName="orgAccountingNumber"
                   required>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <label for="adminOrgProjectNumber">{{ 'FORM.NUMERO-DE-PROJET' | translate }}
            <input id="adminOrgProjectNumber"
                   formControlName="adminOrgProjectNumber">
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <label for="uniqueCode">{{ 'FORM.CODE-UNIQUE-ATTRIBUE' | translate }}
            <input id="uniqueCode"
                   formControlName="uniqueCode">
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <label for="adminOrgAccountingNumber">{{ 'FORM.NUMERO-DE-PLAN-COMPTABLE-ORGANISATION' | translate }}
            <input id="adminOrgAccountingNumber"
                   formControlName="adminOrgAccountingNumber">
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <label for="orgProjectNumber">{{ 'FORM.NUMERO-DE-PROJET-ORGANISATION' | translate }}
            <input id="orgProjectNumber"
                   formControlName="orgProjectNumber">
          </label>
        </nz-form-control>
      </nz-form-item>
      <sp-checkbox *ngIf="!isMembershipProduct" [(ngModel)]="checkboxTaxable" formControlName="taxable" [classList]="['mb-2', 'py-2']">
        <b>{{ 'FORM.TAXABLE' | translate }}</b>
    </sp-checkbox>

    </div>
  </div>

  <ng-container *ngIf="simpleButton;else twoButton">
    <button *appPermission="1;"
            nz-button
            [nzLoading]="isLoading"
            class="btn btn--default">
      {{'ENREGISTER' | translate}}
    </button>
  </ng-container>
  <ng-template #twoButton>
    <button *appPermission="1;"
            nz-button
            [nzLoading]="isLoading"
            class="btn btn--default">
      {{'ENREGISTER-CONTINUER' | translate}}
    </button>
    <p *appPermission="1;"
       class="d-inline-block ml-2">{{ 'OU' | translate }}
      <a *ngIf="!isLoading"
         (click)="doSubmit('simple')">{{'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
      <span *ngIf="isLoading">{{'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
    </p>
  </ng-template>
</form>
