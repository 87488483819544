<form class="w-100" nz-form [formGroup]="contactForm" (ngSubmit)="addNewContact()">
    <div nz-row nzGutter="24" class="form-filter__container p-3">
        <div nz-col nzMd="8" class="pr-0 pl-3">
            <!-- 
          <nz-form-item>
              <nz-form-control>
                  <label for="lastName" class="repertory-filter__label d-block">{{'FORM.NOM' | translate}} *</label>
                  <input nz-input id="lastName" formControlName="lastName" type="lastName" maxlength="75" required>
                  <span class="has-error" *ngIf="contactForm.invalid && contactForm.get('lastName').errors && (contactForm.get('lastName').dirty || contactForm.get('lastName').touched)">
                      {{ "CHAMP-REQUIS" | translate }}
                  </span>
              </nz-form-control>
          </nz-form-item>
          -->

            <app-nz-form-item inputId="'lastName'" [control]="contactForm.get('lastName')" inputType="text">
                <div class="input-custom-label">
                    <label for="lastName" class="repertory-filter__label d-block">{{ 'FORM.NOM' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span
                        class="has-error"
                        *ngIf="contactForm.invalid && contactForm.get('lastName').errors && (contactForm.get('lastName').dirty || contactForm.get('lastName').touched)"
                    >
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="8" class="pr-0 pl-0">
            <!--
          <nz-form-item>
              <nz-form-control>
                  <label for="firstName" class="repertory-filter__label d-block">{{'FORM.PRENOM' | translate}} *</label>
                  <input nz-input id="firstName" formControlName="firstName" type="firstName" maxlength="75" required>
                  <span class="has-error" *ngIf="contactForm.invalid && contactForm.get('firstName').errors  && (contactForm.get('firstName').dirty || contactForm.get('firstName').touched)">
                      {{ "CHAMP-REQUIS" | translate }}
                  </span>
              </nz-form-control>
          </nz-form-item>
          -->

            <app-nz-form-item inputId="'firstName'" [control]="contactForm.get('firstName')" inputType="text">
                <div class="input-custom-label">
                    <label for="firstName" class="repertory-filter__label d-block">{{ 'FORM.PRENOM' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span
                        class="has-error"
                        *ngIf="contactForm.invalid && contactForm.get('firstName').errors && (contactForm.get('firstName').dirty || contactForm.get('firstName').touched)"
                    >
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="8" class="pr-0 pl-0">
            <!--
          <nz-form-item>
              <nz-form-control>
                  <label for="title" class="repertory-filter__label d-block">{{'FORM.TITRE' | translate}} *</label>
                  <input nz-input id="title" formControlName="title" type="title" maxlength="75" required>
                  <span class="has-error" *ngIf="contactForm.invalid && contactForm.get('title').errors  && (contactForm.get('title').dirty || contactForm.get('title').touched)">
                      {{ "CHAMP-REQUIS" | translate }}
                  </span>
              </nz-form-control>
          </nz-form-item>
          -->

            <app-nz-form-item inputId="'title'" [control]="contactForm.get('title')" inputType="text">
                <div class="input-custom-label">
                    <label for="title" class="repertory-filter__label d-block">{{ 'FORM.TITRE' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="contactForm.invalid && contactForm.get('title').errors && (contactForm.get('title').dirty || contactForm.get('title').touched)">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="12" class="pr-0 pl-3">
            <!--
          <nz-form-item>
              <nz-form-control>
                  <label for="email" class="repertory-filter__label d-block">{{'FORM.COURRIEL' | translate}} *</label>
                  <input nz-input id="email" formControlName="email" type="email" required>
                  <span class="has-error" *ngIf="contactForm.invalid && contactForm.get('email').errors  && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                      <span *ngIf="contactForm.get('email').errors['required']; else incorrectField">{{ "CHAMP-REQUIS" | translate }}</span>
                      <ng-template #incorrectField>{{'ENTRER-VALEUR'|translate}}</ng-template>
                  </span>
              </nz-form-control>
          </nz-form-item>
          -->

            <app-nz-form-item inputId="'email'" [control]="contactForm.get('email')" inputType="email">
                <div class="input-custom-label">
                    <label for="email" class="repertory-filter__label d-block">{{ 'FORM.COURRIEL' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="contactForm.invalid && contactForm.get('email').errors && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                        <span *ngIf="contactForm.get('email').errors['required']; else incorrectField">{{ 'CHAMP-REQUIS' | translate }}</span>
                        <ng-template #incorrectField>{{ 'ENTRER-VALEUR' | translate }}</ng-template>
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="8" class="pr-0 pl-0">
            <!--
          <nz-form-item>
              <nz-form-control>
                  <label for="phone" class="repertory-filter__label d-block">{{'FORM.TELEPHONE' | translate}} *</label>
                  <input nz-input id="phone" formControlName="phone" type="phone" required>
                  <span class="has-error" *ngIf="contactForm.invalid && contactForm.get('phone').errors && (contactForm.get('phone').dirty || contactForm.get('phone').touched)">
                      {{ "CHAMP-REQUIS" | translate }}
                  </span>
              </nz-form-control>
          </nz-form-item>
          -->

            <app-nz-form-item inputId="'phone'" [control]="contactForm.get('phone')" inputType="text">
                <div class="input-custom-label">
                    <label for="phone" class="repertory-filter__label d-block">{{ 'FORM.TELEPHONE' | translate }} *</label>
                </div>
                <div class="input-custom-error-msg">
                    <span class="has-error" *ngIf="contactForm.invalid && contactForm.get('phone').errors && (contactForm.get('phone').dirty || contactForm.get('phone').touched)">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </div>
            </app-nz-form-item>
        </div>
        <div nz-col nzMd="4" class="pr-0 pl-0">
            <!--
          <nz-form-item>
              <nz-form-control>
                  <label for="phonePostNumber" class="repertory-filter__label d-block">{{'FORM.POSTE' | translate}}</label>
                  <input nz-input id="phonePostNumber" formControlName="phonePostNumber" type="phonePostNumber">
              </nz-form-control>
          </nz-form-item>
          -->

            <app-nz-form-item inputId="'phonePostNumber'" [control]="contactForm.get('phonePostNumber')" inputType="text">
                <div class="input-custom-label">
                    <label for="phonePostNumber" class="repertory-filter__label d-block">{{ 'FORM.POSTE' | translate }}</label>
                </div>
            </app-nz-form-item>
        </div>
    </div>
    <div class="form-filter__footer text-md-right p-3">
        <button class="btn-rect px-3 d-inline-block mr-md-3" (click)="dismiss()">{{ 'ANNULER' | translate }}</button>
        <button class="btn d-inline-block" [disabled]="!contactForm.valid" style="font-size: 14px !important; padding: 3px 14px 1px 14px">{{ 'AJOUTER' | translate }}</button>
    </div>
</form>
