import { Network } from './../../model/organization.model';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Address } from 'src/app/concepts/location/models/location.model';
import { ListItem, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { LocationService } from 'src/app/concepts/location/services/location.service';
import { Organization } from '../../model/organization.model';
import { NetworkService } from '../../services/network.service';
import { OrganizationService } from '../../services/organization.service';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { Show } from 'src/app/concepts/show/model/show.model';
import { OrganizationTypes } from '../../enums/organization-types.enum';
import { ShowService } from 'src/app/concepts/show/services/show.service';
import { OrganisationStatus } from '../../enums/organization-status.enum';
import { tagOrganisationView } from 'src/app/data-layer';
import { HttpClient } from '@angular/common/http';
import { WikiArtsDataValidation } from '@app/shared/services/wikiArtsData.validation';

@Component({
    selector: 'app-organization-single',
    templateUrl: './organization-single.component.html',
    styleUrls: ['./organization-single.component.scss']
})
export class OrganizationSingleComponent implements OnInit {
    /**
     * The organization.
     * @type {Organization}
     */
    organization: Organization;

    /**
     * A list of organization team members.
     * @type {Array}
     */
    organizationTeamList = [];

    /**
     * The number of organizations.
     * @type {number}
     */
    organizationsNumber: number;

    /**
     * The ID of the organization.
     * @type {number}
     */
    organizationId = 0;

    /**
     * The state of the organization.
     * @type {string}
     */
    organizationState: string;

    /**
     * The types of organizations.
     * @type {OrganizationTypes}
     */
    organizationTypes = OrganizationTypes;

    /**
     * A list of producer shows.
     * @type {ListItem[]}
     */
    producerShows: ListItem[] = [];

    /**
     * The description model.
     * @type {string}
     */
    descriptionModel: string;

    /**
     * A list of networks.
     * @type {Network[]}
     */
    networks: Network[] = [];

    /**
     * A list of network members.
     * @type {ListItem[]}
     */
    networkMembers: ListItem[] = [];

    /**
     * The active tab.
     * @type {string}
     */
    activeTab: string;

    /**
     * A boolean indicating if the component is ready.
     * @type {boolean}
     */
    isReady = false;

    /**
     * An array of social media accounts.
     * @type {Array}
     */
    socialMediasArray = [];

    /**
     * The current language.
     * @type {string}
     */
    lang: string;

    /**
     * The page title.
     * @type {string}
     */
    pageTitle: string;

    /**
     * The page URL.
     * @type {string}
     */
    pageUrl: string;

    /**
     * The edit link.
     * @type {string}
     */
    editLink = '';
    /**
     * The base URL for Wikidata.
     * @type {string}
     */
    public readonly wikidataUrlBase: string = WikiArtsDataValidation.wikidataUrlBase;
    /**
     * The base URL for Artsdata.
     * @type {string}
     */
    public readonly artsdataUrlBase: string = WikiArtsDataValidation.artsdataUrlBase;

    constructor(
        private organizationService: OrganizationService,
        private networkService: NetworkService,
        private showService: ShowService,
        private translate: TranslateService,
        private location: LocationService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private route: ActivatedRoute,
        private httpClient: HttpClient
    ) {}

    ngOnInit(): void {
        this.lang = this.translate.currentLang;
        this.pageUrl = this.router.url;

        this.organizationId = this.route.snapshot.params['organizationId'];
        this.getSingleOrganization();
        this.getSingleOrganisationMembers();
        this.activeTab = 'TAB_TEAM';

        this.route.params.subscribe((params) => {
            if (params['organizationId'] !== this.organizationId) {
                this.networks = [];
                this.socialMediasArray = [];
                this.networkMembers = [];
                this.organizationId = params['organizationId'];
                this.getSingleOrganization();
                this.getSingleOrganisationMembers();
            }
        });
    }

    getSingleOrganization(): void {
        this.organizationService.getOrganization(this.organizationId).subscribe((data: Organization) => {
            this.organization = new Organization(data);
            this.pageTitle = data.getTranslatedProperty(this.lang, 'name');

            // Set BreadcrumbItem
            const breadcrumbItem = new BreadcrumbItem();
            breadcrumbItem.set(this.pageTitle);
            this.breadcrumbService.addBreadcrumbCascade([breadcrumbItem]);
            this.getSocialMedias().subscribe((x) => (this.isReady = true));
            // Informations d'adresse
            if (this.organization.addressId !== null) {
                this.location.getAddress(this.organization.addressId).subscribe((locationData: any) => {
                    const adr = new Address(locationData[0]);
                    this.organization.address.country = this.translate.instant('COUNTRIES.' + this.organization.address.countryId);
                    this.organizationState = adr.getTranslatedState(this.lang) || this.organization.address.otherState;
                });
            }
            // Si l'organisation est membre d'un ou plusieurs réseaux, on va chercher les infos.
            if (this.organization.networks.length > 0) {
                this.getNetworksInfos();
            }
            // si l'organisation EST un réseau, on affiche la liste de ses membres.
            if (this.organization.isNetworkHead) {
                this.networkService.getNetworkDetail(this.organization.id).subscribe((organizations) => {
                    this.networkMembers = organizations.map((organization) => {
                        const item = organization.toListItem(this.lang);
                        item.itemUrl = `/organization/${organization.id}`;
                        return item;
                    });
                });
            }

            this.isTypeProducer();

            this.descriptionModel = this.organization.getTranslatedProperty(this.translate.currentLang, 'description');

            this.editLink = `/organization/${this.organization.id}/modify/coordonnees`;
            this.isReady = true;

            tagOrganisationView(
                {
                    organisation: this.organization.getTranslatedProperty('fr', 'name'),
                    types: this.organization.types.map((type) => OrganizationTypes[type])
                },
                this.httpClient
            );
        });
    }

    getSocialMedias(): Observable<any> {
        return this.organizationService.getOrganizationSocialMedia(this.organizationId).do((data) => {
            this.organization.socialMediaAccounts = data;
            this.organization.socialMediaAccounts.forEach((x) => {
                const theUrl = x.getTranslatedProperty(this.lang, 'url');
                const socialMedias = {
                    url: theUrl,
                    urlDomain: this.getDomainName(theUrl)
                };
                this.socialMediasArray.push(socialMedias);
            });
        });
    }

    /**
     * Récuperation des réseaux
     */
    getNetworksInfos(): void {
        this.networkService.getAllNetworks().subscribe((networks: Network[]) => {
            this.networks = this.organization.networks.map((ntwkId) => networks.find((ntwk) => ntwk.id === ntwkId));
        });
    }

    getDomainName(url) {
        if (url.includes('.')) {
            const urlPattern = /^(?:https?:\/\/)?(?:w{3}\.)?([\w\d\.-]+)\.(?:[\w\.]{2,10})(?:[/\w\.-]*)*/;
            const domainPattern = url.match(urlPattern);
            return domainPattern ? domainPattern[1] : url;
        } else {
            // fallback if wrong url format
            return url;
        }
    }

    getSingleOrganisationMembers(): void {
        this.organizationService.getOrganizationContacts(this.organizationId).subscribe((data: any) => {
            this.organizationTeamList = data.contacts;

            // email: "smartin@lechabada.com"
            // firstName: "Stéphane"
            // id: 4137
            // lastName: "MARTIN"
            // phone: "+33 2 41 96 13 40"
            // phonePostNumber: null
            // title: "Programmateur"
        });
    }

    toggleOrganizationContent(tab) {
        this.activeTab = tab;
    }

    isTypeProducer(): void {
        if (this.organization.types.includes(OrganizationTypes.IS_PRODUCTEUR)) {
            this.getProducerShows();
        } else {
            this.activeTab = 'TAB_TEAM';
            this.toggleOrganizationContent(this.activeTab);
        }
    }

    private getProducerShows(): void {
        this.showService
            .getShows([
                {
                    field: 'organizationId',
                    value: this.organizationId
                } as SelectedFilter,
                {
                    field: 'statusId',
                    value: OrganisationStatus.APPROUVE
                } as SelectedFilter,
                {
                    field: 'isAvailable',
                    value: 1
                } as SelectedFilter,
                {
                    field: 'withOrg',
                    value: 1
                } as SelectedFilter
            ])
            .subscribe((shows) => {
                this.producerShows = shows.map((show: Show) => {
                    const itemCard: ListItem = show.toListItem(this.lang);
                    itemCard.itemSubtitle = this.showService.extractArtistList(show);
                    itemCard.itemUrl = `/shows-offers/${show.organizationId}/${show.id}`;
                    return itemCard;
                });
            });
    }
}
