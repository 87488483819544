<form nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row nzGutter="16" class="residency-section" *ngIf="venue.isResidency">
        <app-alert prefix class="mb-3 success">
            <i nz-icon nzType="check" nzTheme="outline"></i>
            <span [innerHTML]="'RESIDENCY-FORM-ACTIVE' | translate"></span>
            <button appButton (click)="switchResidencyStatus(false)">
                {{ 'RESIDENCY-INACTIVE' | translate }}
            </button>
        </app-alert>
    </div>

    <div nz-row nzGutter="16" class="residency-section" *ngIf="!venue.isResidency">
        <app-alert prefix class="mb-3 danger">
            <i nz-icon nzType="close" nzTheme="outline"></i>
            <span [innerHTML]="'RESIDENCY-FORM-INACTIVE' | translate"></span>
            <button appButton (click)="switchResidencyStatus(true)">
                {{ 'RESIDENCY-ACTIVE' | translate }}
            </button>
        </app-alert>
    </div>

    <div *ngIf="venue.isResidency">
        <!-- TYPE -->
        <section formGroupName="type">
            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <h4>
                        {{ 'RESIDENCY-FORM.RESIDENCY-TYPE-TITLE' | translate }}
                        <span class="error">*</span>
                    </h4>
                </div>
            </div>

            <span class="has-error" *ngIf="isFormGroupInvalid('type')">
                {{ 'CHAMP-REQUIS' | translate }}
            </span>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <nz-divider></nz-divider>
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isTypeCreation">
                        <div class="d-inline-block float-right">
                            <span class="d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-1' | translate }}</span>
                            <span class="checkbox-sub-title d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-1-SUB' | translate }}</span>
                        </div>
                    </sp-checkbox>
                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isTypeTechnical">
                        <div class="d-inline-block float-right">
                            <span class="d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-2' | translate }}</span>
                            <span class="checkbox-sub-title d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-2-SUB' | translate }}</span>
                        </div>
                    </sp-checkbox>

                    <nz-divider></nz-divider>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isTypeDried">
                        <div class="d-inline-block float-right">
                            <span class="d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-3' | translate }}</span>
                            <span class="checkbox-sub-title d-block">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-FIELD-3-SUB' | translate }}</span>
                        </div>
                    </sp-checkbox>

                    <nz-divider></nz-divider>
                </div>
            </div>
        </section>
        <!-- END TYPE-->
        <section formGroupName="availability">
            <div class="mb-2" nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <h4>
                        {{ 'RESIDENCY-FORM.DISPONIBILITY-TITLE' | translate }}
                        <span class="error">*</span>
                    </h4>
                </div>
            </div>

            <span class="has-error" *ngIf="isFormGroupInvalid('availability')">
                {{ 'CHAMP-REQUIS' | translate }}
            </span>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isDisponibilityFall">
                        <div class="d-inline-block float-right">
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.DISPONIBILITY-FIELD-1' | translate }}</span>
                        </div>
                    </sp-checkbox>
                </div>
                <div nz-col nzMd="12">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isDisponibilitySpring">
                        <div class="d-inline-block float-right">
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.DISPONIBILITY-FIELD-3' | translate }}</span>
                        </div>
                    </sp-checkbox>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isDisponibilityWinter">
                        <div class="d-inline-block float-right">
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.DISPONIBILITY-FIELD-4' | translate }}</span>
                        </div>
                    </sp-checkbox>
                </div>
                <div nz-col nzMd="12">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isDisponibilityWinter">
                        <div class="d-inline-block float-right">
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.DISPONIBILITY-FIELD-2' | translate }}</span>
                        </div>
                    </sp-checkbox>
                </div>
            </div>
        </section>
        <section formGroupName="duration">
            <div class="mb-2" nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="12">
                    <h4>
                        {{ 'RESIDENCY-FORM.RESIDENCY-DURATION-TITLE' | translate }}
                        <span class="error">*</span>
                    </h4>
                </div>
            </div>

            <span class="has-error" *ngIf="isFormGroupInvalid('duration')">
                {{ 'CHAMP-REQUIS' | translate }}
            </span>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isDurationDay">
                        <div class="d-inline-block float-right">
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-1' | translate }}</span>
                        </div>
                    </sp-checkbox>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isDurationWeek">
                        <div class="d-inline-block float-right">
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-2' | translate }}</span>
                        </div>
                    </sp-checkbox>
                </div>
            </div>

            <div nz-row nzGutter="16" class="residency-section">
                <div nz-col nzMd="16">
                    <sp-checkbox [disabled]="formGroup.disabled" formControlName="isDurationMonth">
                        <div class="d-inline-block float-right">
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.RESIDENCY-DURATION-FIELD-3' | translate }}</span>
                        </div>
                    </sp-checkbox>
                </div>
            </div>
        </section>
        <div class="mb-2" nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <h4>
                    {{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-TITLE' | translate }}
                </h4>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasReceptionStaff">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-1' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasAssemblyStaff">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-3' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasTechnicalDirector">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-5' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasSoundEngineer">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-7' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasLightingDesigner">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-2' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasStageTechnician">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-4' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasRigger">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-PERSON-DISPONIBILITY-FIELD-6' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div class="mb-2" nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <h4>
                    {{ 'RESIDENCY-FORM.STAGE-SIZE-TITLE' | translate }}
                </h4>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">
                                {{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-1' | translate }}
                                <span class="error">*</span>
                            </span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" formControlName="totalStageWidth"></app-input-meter>
                            <form-error *ngIf="formGroup.get('totalStageWidth').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                            <form-error *ngIf="formGroup.get('totalStageWidth').errors?.required" [value]="'CHAMP-REQUIS' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider-top"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">
                                {{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-2' | translate }}
                                <span class="error">*</span>
                            </span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" formControlName="totalSceneDepth"></app-input-meter>
                            <form-error *ngIf="formGroup.get('totalSceneDepth').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                            <form-error *ngIf="formGroup.get('totalSceneDepth').errors?.required" [value]="'CHAMP-REQUIS' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider-top"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-3' | translate }}</span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" formControlName="clearHeightLamps"></app-input-meter>
                            <form-error *ngIf="formGroup.get('clearHeightLamps').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider-top"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-4' | translate }}</span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" formControlName="widthPlayground"></app-input-meter>
                            <form-error *ngIf="formGroup.get('widthPlayground').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="20">
                <nz-divider class="residency-divider-top"></nz-divider>
                <div nz-col nzMd="14">
                    <nz-form-item>
                        <nz-form-control>
                            <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.STAGE-SIZE-FIELD-5' | translate }}</span>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzMd="10" class="float-right">
                    <nz-form-item>
                        <nz-form-control>
                            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" formControlName="playgroundDepth"></app-input-meter>
                            <form-error *ngIf="formGroup.get('playgroundDepth').errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <nz-divider class="residency-divider"></nz-divider>
            </div>
        </div>

        <div class="mb-2" nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <h4>
                    {{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-TITLE' | translate }}
                </h4>
            </div>
        </div>
        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasAttachmentPoints">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-1' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasDanceFloor">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-3' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasVideoProjector">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-5' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasSoundEquipment">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-7' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasLightEquipment">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-2' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasInternet">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-4' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasTelepresenceEquipment">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-6' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div class="mb-2" nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <h4>
                    {{ 'RESIDENCY-FORM.HOME-TITLE' | translate }}
                </h4>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasLodge">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-1' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasStorageSpace">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-3' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasKitchen">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-5' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasLaundryRoom">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-2' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>

        <div nz-row nzGutter="16" class="residency-section">
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasHostingPartnership">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-4' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
            <div nz-col nzMd="12">
                <sp-checkbox [disabled]="formGroup.disabled" formControlName="hasLandingStage">
                    <div class="d-inline-block float-right">
                        <span class="checkbox-sub-title d-block pt-1">{{ 'RESIDENCY-FORM.HOME-FIELD-6' | translate }}</span>
                    </div>
                </sp-checkbox>
            </div>
        </div>
    </div>
    <div nz-row nzGutter="16" class="residency-section mt-4">
        <ng-container *ngIf="venue.isNotSubmitted || venue.isRejected">
            <button *appPermission="1; objectOrganizationId: venue.organizationId; shouldBeApproved: false" nz-button [nzLoading]="isLoading" class="btn btn--default">
                {{ 'ENREGISTER-CONTINUER' | translate }}
            </button>
            <p *appPermission="1; objectOrganizationId: venue.organizationId; shouldBeApproved: false" class="d-inline-block ml-2">
                {{ 'OU' | translate }}
                <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
                <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
            </p>
        </ng-container>
        <ng-container *ngIf="(venue.isSubmitted || venue.isApproved) && !formGroup.disabled">
            <button
                [disabled]="formGroup.invalid"
                *appPermission="1; objectOrganizationId: venue.organizationId; shouldBeApproved: false"
                nz-button
                [nzLoading]="isLoading"
                class="btn btn--default"
            >
                {{ 'ENREGISTER' | translate }}
            </button>
        </ng-container>
    </div>
</form>
