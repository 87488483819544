import { Component, Input, HostBinding, ViewChild, Directive, ContentChild, TemplateRef, AfterContentInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/*@Directive({
  selector: 'app-select-item'
})
export class SelectItemDirective {
}*/

let unique = 0;

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styles: [
        `
            :host.warning /deep/ .ant-select-selection {
                border: 2px solid #faad14 !important;
            }

            :host.warning /deep/ .ant-select-selection:hover {
                border: 2px solid #faad14 !important;
            }

            :host.error /deep/ .ant-select-selection {
                border: 2px solid #f5222d !important;
                border-radius: 4px !important;
            }

            :host.error /deep/ .ant-select-selection:hover {
                border: 2px solid #f5222d !important;
            }

            :host /deep/ nz-select {
                width: 100%;
            }

            :host /deep/ .ant-select-selection__placeholder {
                color: #1c1c1b;
            }

            :host.with-prefix /deep/ nz-select {
                display: inline-block;
                margin-left: 1em;
                margin-right: 1em;
                width: auto;
                min-width: 60px;
            }
        `
    ]
})
export class SelectComponent<T, U> implements ControlValueAccessor, AfterContentInit {
    private onChange: (value: U) => void;
    private onTouched: () => void;
    @ContentChild(TemplateRef, { static: true }) optionTemplate!: TemplateRef<any>;
    @Input() options: Array<T> | Record<string, Array<T>>;
    @Input() disabled: boolean;
    @Input() validateStatus = '';
    @Input() labelProperty = 'label';
    @Input() valueProperty = 'id';
    @Input() placeholder: string;
    @Input() withClear = true;
    @Input() withSearch = true;
    @Input() customLabel: (option: T) => string;
    @HostBinding('class.warning') get classWarning(): boolean {
        return this.validateStatus === 'warning';
    }
    @HostBinding('class.error') get classError(): boolean {
        return this.validateStatus === 'error';
    }
    @HostBinding('class.disabled') get classDisabled(): boolean {
        return this.disabled;
    }
    id = `SelectComponent-${unique++}`;
    selected: U;
    @ViewChild('prefix', { static: true }) prefix: unknown;
    constructor(readonly translateService: TranslateService, private readonly ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }
    ngAfterContentInit() {
        return;
    }
    ngModelChange(): void {
        this.onChange(this.selected);
        this.onTouched();
    }
    writeValue(propertyValue: U): void {
        this.selected = propertyValue;
    }
    registerOnChange(fn: (value: U) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    hasGroups(): boolean {
        return !(this.options instanceof Array);
    }
}
