import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-custom-form',
    templateUrl: './custom-form.component.html',
    styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent {
    @Input() validateForm: UntypedFormGroup;
    @Output() formSubmit: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    onSubmit(): void {
        this.formSubmit.emit();
    }
}
