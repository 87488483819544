<ng-container *ngIf="show && isReady" class="content">
    <!-- TOUR SHOW -->
    <ng-container *ngIf="tourShow; else notTourShow">
        <app-page-title
            title="{{ show.getTranslatedProperty(lang, 'title') }}"
            subtitle="{{ artistList.join(', ') }}"
            [organizationId]="show.organizationId"
            [isShowPage]="true"
            [userNetworkIds]="userNetworkIds"
        ></app-page-title>
    </ng-container>

    <!-- NOT TOUR SHOW -->
    <ng-template #notTourShow>
        <app-page-title
            *ngIf="editShowLink$ | async as editShowLink"
            title="{{ show.getTranslatedProperty(lang, 'title') }}"
            subtitle="{{ artistList.join(', ') }}"
            buttonText="{{ 'FORM.MODIFIER' | translate }}"
            [buttonLink]="editShowLink"
            [permission]="1"
            [organizationId]="show.organizationId"
            [isShowPage]="true"
            [user]="user"
            [useFavorites]="displayFavorites && !disableFavorites"
            [userNetworks]="userNetworks"
            [(favorites)]="showFavorites"
            (onSaveFavorites)="onSaveFavorites($event)"
            [organizationId]="show.organizationId"
            [userNetworkIds]="userNetworkIds"
        ></app-page-title>
    </ng-template>

    <div class="container">
        <div nz-row class="mb-5 rate" *ngIf="tourShow">
            <div nz-col nzMd="24" class="rate-container">
                <!-- ACTIONS -->
                <div class="rate-container-left">
                    <div *ngIf="isAdmin; else rateContainer">
                        <nz-switch [(ngModel)]="isKeep" (ngModelChange)="isShowKeeped()" ngClass="rate-container-switch mr-3"></nz-switch>
                        <span class="rate-container-switch-label">{{ 'KEEP_OFFER' | translate }} {{ organisation.getTranslatedProperty(lang, 'name') }}</span>
                    </div>
                    <ng-template #rateContainer>
                        <img [src]="organisation.logo ? organisation.logo : '/assets/images/notfound-square.png'" alt="" />
                        <button (click)="isliked()" [class]="switchStyle === true ? 'isLiked' : ''">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 20 20">
                                <path
                                    d="M8.84 3.248c.66-1.695 1.556-2.41 3.218-2.211l.226.04c.97.22 1.63.936 1.934 1.962l.053.206c.21.903.196 1.84-.017 3.012l-.038.201-.031.129.72-.07.444-.036c.917-.051 1.634.058 2.28.397 1.177.617 1.483 1.466 1.325 3.015-.025.247-.055.493-.095.781l-.09.59-.174 1.027c-.06.275-.144.561-.253.867-.07.199-.148.4-.247.634l-.198.455-.386.84c-1.053 2.134-2.71 3.378-4.864 3.51-.415.027-.78-.005-1.344-.104l-.284-.05c-.245-.042-.35-.055-.471-.06l-.34-.018c-.221-.014-.481-.035-.787-.062l-.906-.09-2.09-.231c-.81-.09-1.425-.775-1.425-1.59v-6.363c0-.25.059-.497.171-.721.098-.193.221-.382.373-.578.106-.137.225-.277.366-.428l.125-.13c.066-.068.142-.144.213-.213l.237-.224.27-.28c.348-.366.553-.606.711-.837l.128-.199c.011-.02.036-.069.068-.14l.118-.268.311-.77zM3 9c.513 0 .936.386.993.883L4 10v7c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L2 17v-7c0-.552.448-1 1-1zm8.77-5.985c-.51-.06-.67.012-.945.658l-.118.29-.752 2.004-.327.809c-.13.309-.231.525-.316.667-.269.45-.565.82-1.113 1.397l-.342.347c-.024.021-.139.131-.253.244l-.235.239c-.095.102-.176.197-.244.284l-.07.095-.056.083v5.9l1.724.192.887.088.39.033.467.032.293.015c.17.012.341.036.603.08l.287.052c.42.073.638.092.872.077 1.393-.086 2.442-.873 3.184-2.374l.365-.797.185-.424c.08-.19.144-.355.201-.516.082-.231.143-.438.175-.58l.162-.962.086-.563c.035-.252.062-.471.084-.692l.015-.154c.065-.675.03-.728-.28-.89-.28-.147-.651-.204-1.221-.172l-.26.019-.883.086-.86.077-1.854.13.356-1.589.283-1.155.072-.413c.122-.79.12-1.386-.002-1.907l-.038-.147c-.103-.35-.247-.504-.399-.54z"
                                />
                            </svg>
                            <span>Intérêt</span>
                        </button>
                        <button
                            nz-dropdown
                            nzTrigger="click"
                            id="dislikeBtn"
                            [nzDropdownMenu]="menu"
                            [class]="switchStyle === false ? 'isDisliked' : ''"
                            (nzVisibleChange)="isRotate = !isRotate"
                            [nzClickHide]="false"
                            (click)="switchStyle = false"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 20 20">
                                <path
                                    d="M12.647 1.407c2.154.133 3.81 1.376 4.864 3.511l.386.84.198.455c.099.234.177.435.247.634.109.305.192.591.253.867l.174 1.027.09.59c.027.191.05.365.068.532l.027.248c.158 1.55-.148 2.399-1.325 3.015-.646.34-1.363.449-2.28.397l-.444-.035-.72-.07.031.128.038.201c.213 1.173.228 2.11.017 3.013l-.053.205c-.304 1.027-.964 1.743-1.934 1.963l-.226.04c-1.662.199-2.558-.517-3.218-2.211l-.749-1.994-.31-.77-.119-.268-.053-.11-.015-.03c-.181-.303-.392-.565-.839-1.036l-.27-.28-.237-.223-.213-.213-.125-.13c-.14-.152-.26-.291-.366-.428-.152-.196-.275-.385-.373-.579-.112-.223-.171-.47-.171-.72V3.613c0-.816.614-1.501 1.426-1.59l2.089-.232.906-.09c.306-.027.566-.048.787-.062l.34-.017c.12-.005.226-.018.47-.06l.285-.05c.565-.1.929-.131 1.344-.105zm-.997 2.074l-.287.05c-.262.046-.433.07-.603.082l-.588.033c-.164.012-.351.028-.562.047l-.887.087L7 3.972v5.9l.056.083.07.096c.068.087.149.181.244.284l.235.239c.114.112.229.222.253.243l.342.348c.548.577.844.946 1.113 1.396.085.143.185.359.316.668l.327.808.752 2.005.118.29c.275.646.435.718.945.657l.123-.022c.152-.036.296-.191.399-.54l.038-.148c.121-.52.124-1.117.002-1.906l-.072-.413-.283-1.156-.356-1.588 1.853.13.861.076.883.087.26.018c.57.032.94-.024 1.222-.172l.116-.065c.2-.124.219-.246.163-.824l-.015-.155c-.022-.22-.049-.44-.084-.691l-.086-.563-.162-.963c-.032-.141-.093-.348-.175-.579-.057-.162-.122-.327-.2-.516l-.186-.425-.365-.796c-.742-1.501-1.79-2.289-3.184-2.375-.234-.015-.451.004-.872.078zM3 2c.552 0 1 .448 1 1v7l-.007.117c-.057.497-.48.883-.993.883-.552 0-1-.448-1-1V3l.007-.117C2.064 2.386 2.487 2 3 2z"
                                />
                            </svg>
                            <span>Hésitation</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" [ngClass]="isRotate ? '_svgRotate' : ''">
                                <path
                                    fill-rule="nonzero"
                                    d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z"
                                />
                            </svg>
                        </button>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <sp-menu [classList]="['dropdown-menu']">
                                <sp-menu-item [classList]="['dropdown-menu-item', 'dropdown-menu-item__first']">Spécifiez une ou plusieurs raisons :</sp-menu-item>
                                <sp-menu-item [classList]="['dropdown-menu-item']">
                                    <nz-checkbox-group [(ngModel)]="dislikeReasons"></nz-checkbox-group>
                                </sp-menu-item>
                                <sp-menu-item [classList]="['dropdown-menu-item', 'controls']">
                                    <button class="btn btn--larger mr-2" nzType="default" nzTrigger="click" (click)="isdisliked()">Sauvegarder</button>
                                    <button class="btn-rect btn--larger" nzType="default" nzTrigger="click" (click)="canceldislike()">Annuler l'hésitation</button>
                                </sp-menu-item>
                            </sp-menu>
                        </nz-dropdown-menu>
                    </ng-template>
                </div>
                <!-- COMPTEURS -->
                <div class="rate-container-right">
                    <span class="rate-counter">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 20 20">
                            <path
                                d="M8.84 3.248c.66-1.695 1.556-2.41 3.218-2.211l.226.04c.97.22 1.63.936 1.934 1.962l.053.206c.21.903.196 1.84-.017 3.012l-.038.201-.031.129.72-.07.444-.036c.917-.051 1.634.058 2.28.397 1.177.617 1.483 1.466 1.325 3.015-.025.247-.055.493-.095.781l-.09.59-.174 1.027c-.06.275-.144.561-.253.867-.07.199-.148.4-.247.634l-.198.455-.386.84c-1.053 2.134-2.71 3.378-4.864 3.51-.415.027-.78-.005-1.344-.104l-.284-.05c-.245-.042-.35-.055-.471-.06l-.34-.018c-.221-.014-.481-.035-.787-.062l-.906-.09-2.09-.231c-.81-.09-1.425-.775-1.425-1.59v-6.363c0-.25.059-.497.171-.721.098-.193.221-.382.373-.578.106-.137.225-.277.366-.428l.125-.13c.066-.068.142-.144.213-.213l.237-.224.27-.28c.348-.366.553-.606.711-.837l.128-.199c.011-.02.036-.069.068-.14l.118-.268.311-.77zM3 9c.513 0 .936.386.993.883L4 10v7c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L2 17v-7c0-.552.448-1 1-1zm8.77-5.985c-.51-.06-.67.012-.945.658l-.118.29-.752 2.004-.327.809c-.13.309-.231.525-.316.667-.269.45-.565.82-1.113 1.397l-.342.347c-.024.021-.139.131-.253.244l-.235.239c-.095.102-.176.197-.244.284l-.07.095-.056.083v5.9l1.724.192.887.088.39.033.467.032.293.015c.17.012.341.036.603.08l.287.052c.42.073.638.092.872.077 1.393-.086 2.442-.873 3.184-2.374l.365-.797.185-.424c.08-.19.144-.355.201-.516.082-.231.143-.438.175-.58l.162-.962.086-.563c.035-.252.062-.471.084-.692l.015-.154c.065-.675.03-.728-.28-.89-.28-.147-.651-.204-1.221-.172l-.26.019-.883.086-.86.077-1.854.13.356-1.589.283-1.155.072-.413c.122-.79.12-1.386-.002-1.907l-.038-.147c-.103-.35-.247-.504-.399-.54z"
                            />
                        </svg>
                        {{ likes }}
                    </span>
                    <span class="rate-counter">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 20 20">
                            <path
                                d="M12.647 1.407c2.154.133 3.81 1.376 4.864 3.511l.386.84.198.455c.099.234.177.435.247.634.109.305.192.591.253.867l.174 1.027.09.59c.027.191.05.365.068.532l.027.248c.158 1.55-.148 2.399-1.325 3.015-.646.34-1.363.449-2.28.397l-.444-.035-.72-.07.031.128.038.201c.213 1.173.228 2.11.017 3.013l-.053.205c-.304 1.027-.964 1.743-1.934 1.963l-.226.04c-1.662.199-2.558-.517-3.218-2.211l-.749-1.994-.31-.77-.119-.268-.053-.11-.015-.03c-.181-.303-.392-.565-.839-1.036l-.27-.28-.237-.223-.213-.213-.125-.13c-.14-.152-.26-.291-.366-.428-.152-.196-.275-.385-.373-.579-.112-.223-.171-.47-.171-.72V3.613c0-.816.614-1.501 1.426-1.59l2.089-.232.906-.09c.306-.027.566-.048.787-.062l.34-.017c.12-.005.226-.018.47-.06l.285-.05c.565-.1.929-.131 1.344-.105zm-.997 2.074l-.287.05c-.262.046-.433.07-.603.082l-.588.033c-.164.012-.351.028-.562.047l-.887.087L7 3.972v5.9l.056.083.07.096c.068.087.149.181.244.284l.235.239c.114.112.229.222.253.243l.342.348c.548.577.844.946 1.113 1.396.085.143.185.359.316.668l.327.808.752 2.005.118.29c.275.646.435.718.945.657l.123-.022c.152-.036.296-.191.399-.54l.038-.148c.121-.52.124-1.117.002-1.906l-.072-.413-.283-1.156-.356-1.588 1.853.13.861.076.883.087.26.018c.57.032.94-.024 1.222-.172l.116-.065c.2-.124.219-.246.163-.824l-.015-.155c-.022-.22-.049-.44-.084-.691l-.086-.563-.162-.963c-.032-.141-.093-.348-.175-.579-.057-.162-.122-.327-.2-.516l-.186-.425-.365-.796c-.742-1.501-1.79-2.289-3.184-2.375-.234-.015-.451.004-.872.078zM3 2c.552 0 1 .448 1 1v7l-.007.117c-.057.497-.48.883-.993.883-.552 0-1-.448-1-1V3l.007-.117C2.064 2.386 2.487 2 3 2z"
                            />
                        </svg>
                        {{ dislikes }}
                    </span>
                    <button (click)="OpenEvaluations = !OpenEvaluations">
                        <span>Afficher les {{ showTourInterests.length }} évaluations</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" [ngClass]="OpenEvaluations ? '_svgRotate' : ''">
                            <path
                                fill-rule="nonzero"
                                d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z"
                            />
                        </svg>
                    </button>
                    <div *ngIf="isAdmin && !isKeep">
                        <button type="button" id="btn-delete" *appPermission="5" class="btn-square" (click)="deleteTourShow()">
                            <i class="icon-trash" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div nz-col nzMd="24" *ngIf="OpenEvaluations">
                <div class="evaluationsList">
                    <div class="evaluationsList_Item" *ngFor="let item of showTourInterests">
                        <div class="evaluationsList_ItemInfos">
                            <img [src]="item.organization.logo" alt="" class="evaluationsList_ItemInfosImg" />
                            <div class="evaluationsList_ItemInfosContent">
                                <h4 class="mb-0">{{ item.organization.getTranslatedProperty(lang, 'name') }}</h4>
                                <p class="mb-0">{{ item.orgName }}</p>
                            </div>
                        </div>
                        <div class="evaluationsList_ItemStatus" *ngIf="item.interest === 1; else negative">
                            <i class="evaluationsList_ItemStatusIcon" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 20 20">
                                    <path
                                        d="M8.84 3.248c.66-1.695 1.556-2.41 3.218-2.211l.226.04c.97.22 1.63.936 1.934 1.962l.053.206c.21.903.196 1.84-.017 3.012l-.038.201-.031.129.72-.07.444-.036c.917-.051 1.634.058 2.28.397 1.177.617 1.483 1.466 1.325 3.015-.025.247-.055.493-.095.781l-.09.59-.174 1.027c-.06.275-.144.561-.253.867-.07.199-.148.4-.247.634l-.198.455-.386.84c-1.053 2.134-2.71 3.378-4.864 3.51-.415.027-.78-.005-1.344-.104l-.284-.05c-.245-.042-.35-.055-.471-.06l-.34-.018c-.221-.014-.481-.035-.787-.062l-.906-.09-2.09-.231c-.81-.09-1.425-.775-1.425-1.59v-6.363c0-.25.059-.497.171-.721.098-.193.221-.382.373-.578.106-.137.225-.277.366-.428l.125-.13c.066-.068.142-.144.213-.213l.237-.224.27-.28c.348-.366.553-.606.711-.837l.128-.199c.011-.02.036-.069.068-.14l.118-.268.311-.77zM3 9c.513 0 .936.386.993.883L4 10v7c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L2 17v-7c0-.552.448-1 1-1zm8.77-5.985c-.51-.06-.67.012-.945.658l-.118.29-.752 2.004-.327.809c-.13.309-.231.525-.316.667-.269.45-.565.82-1.113 1.397l-.342.347c-.024.021-.139.131-.253.244l-.235.239c-.095.102-.176.197-.244.284l-.07.095-.056.083v5.9l1.724.192.887.088.39.033.467.032.293.015c.17.012.341.036.603.08l.287.052c.42.073.638.092.872.077 1.393-.086 2.442-.873 3.184-2.374l.365-.797.185-.424c.08-.19.144-.355.201-.516.082-.231.143-.438.175-.58l.162-.962.086-.563c.035-.252.062-.471.084-.692l.015-.154c.065-.675.03-.728-.28-.89-.28-.147-.651-.204-1.221-.172l-.26.019-.883.086-.86.077-1.854.13.356-1.589.283-1.155.072-.413c.122-.79.12-1.386-.002-1.907l-.038-.147c-.103-.35-.247-.504-.399-.54z"
                                    />
                                </svg>
                            </i>
                        </div>
                        <ng-template #negative>
                            <div class="evaluationsList_ItemStatus negative">
                                <p class="evaluationsList_ItemStatusReason">
                                    <span *ngFor="let reason of item.reason; let i = index">
                                        {{ 'TOURS.REASON-' + reason | translate }} {{ i != item.reason.length - 1 ? ' - ' : '' }}
                                    </span>
                                </p>
                                <i class="evaluationsList_ItemStatusIcon" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 20 20">
                                        <path
                                            d="M12.647 1.407c2.154.133 3.81 1.376 4.864 3.511l.386.84.198.455c.099.234.177.435.247.634.109.305.192.591.253.867l.174 1.027.09.59c.027.191.05.365.068.532l.027.248c.158 1.55-.148 2.399-1.325 3.015-.646.34-1.363.449-2.28.397l-.444-.035-.72-.07.031.128.038.201c.213 1.173.228 2.11.017 3.013l-.053.205c-.304 1.027-.964 1.743-1.934 1.963l-.226.04c-1.662.199-2.558-.517-3.218-2.211l-.749-1.994-.31-.77-.119-.268-.053-.11-.015-.03c-.181-.303-.392-.565-.839-1.036l-.27-.28-.237-.223-.213-.213-.125-.13c-.14-.152-.26-.291-.366-.428-.152-.196-.275-.385-.373-.579-.112-.223-.171-.47-.171-.72V3.613c0-.816.614-1.501 1.426-1.59l2.089-.232.906-.09c.306-.027.566-.048.787-.062l.34-.017c.12-.005.226-.018.47-.06l.285-.05c.565-.1.929-.131 1.344-.105zm-.997 2.074l-.287.05c-.262.046-.433.07-.603.082l-.588.033c-.164.012-.351.028-.562.047l-.887.087L7 3.972v5.9l.056.083.07.096c.068.087.149.181.244.284l.235.239c.114.112.229.222.253.243l.342.348c.548.577.844.946 1.113 1.396.085.143.185.359.316.668l.327.808.752 2.005.118.29c.275.646.435.718.945.657l.123-.022c.152-.036.296-.191.399-.54l.038-.148c.121-.52.124-1.117.002-1.906l-.072-.413-.283-1.156-.356-1.588 1.853.13.861.076.883.087.26.018c.57.032.94-.024 1.222-.172l.116-.065c.2-.124.219-.246.163-.824l-.015-.155c-.022-.22-.049-.44-.084-.691l-.086-.563-.162-.963c-.032-.141-.093-.348-.175-.579-.057-.162-.122-.327-.2-.516l-.186-.425-.365-.796c-.742-1.501-1.79-2.289-3.184-2.375-.234-.015-.451.004-.872.078zM3 2c.552 0 1 .448 1 1v7l-.007.117c-.057.497-.48.883-.993.883-.552 0-1-.448-1-1V3l.007-.117C2.064 2.386 2.487 2 3 2z"
                                        />
                                    </svg>
                                </i>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div nz-row class="pb-5">
            <div nz-col nzMd="16">
                <div class="main-picture mb-4">
                    <img src="{{ show && show.image ? show.image : '/assets/images/notfound-square.png' }}" alt="" />
                </div>

                <div nzNoFlex class="mb-4 break-desc" [innerHtml]="descriptionModel ? descriptionModel : ('AUCUNE-DONNEE' | translate)"></div>

                <p class="mb-4">
                    <nz-switch [(ngModel)]="displayShortDesc" class="displayShortDescSwitch"></nz-switch>
                    <strong class="ml-3">{{ 'MONTRER-DESC-COURTE' | translate }}</strong>
                </p>

                <ng-container *ngIf="displayShortDesc">
                    <div nzNoFlex class="mb-4 break-desc" [innerHtml]="descriptionShort ? descriptionShort : ('AUCUNE-DONNEE' | translate)"></div>
                </ng-container>

                <ng-container *ngIf="show.tags.length">
                    <app-etiquettes-mini [tags]="show.tags"></app-etiquettes-mini>
                </ng-container>
            </div>
            <div nz-col nzMd="8" class="pl-4">
                <div>
                    <div *ngIf="show.statusId === 3">
                        <app-show-promote *ngIf="!show.isPromoted" [buttonLink]="promoteShowlink" [permission]="1" [organizationId]="show.organizationId"></app-show-promote>
                    </div>

                    <div nz-row class="mb-4" *ngIf="show.isPromoted">
                        <span *appPermission="1" class="text-highlight">{{ 'EN-PROMOTION' | translate }}{{ promotionInFuture }}</span>
                    </div>

                    <!-- LATERAL BAR -->

                    <!-- Aller chercher l'organisation -->
                    <div nz-row class="mb-3">
                        <p class="page-template-labels section-title mb-1">{{ 'FORM.PRODUIT-PAR' | translate }}</p>
                        <div class="producer" *ngIf="organisation">
                            <img src="{{ organisation.logo ? organisation.logo : '/assets/images/notfound-square.png' }}" alt="" />

                            <a [routerLink]="organizationLink | localize" class="fs-16">{{ organisation.getTranslatedProperty(lang, 'name') }}</a>
                        </div>
                    </div>

                    <div nz-row class="mb-3" *ngIf="show.producer">
                        <p class="page-template-labels section-title mb-0">{{ 'FORM.PRODUCTEUR-OFFICIEL' | translate }}</p>
                        <a [routerLink]="mainProducerLink | localize" class="fs-16">{{ show.producer.getTranslatedProperty(lang, 'name') }}</a>
                    </div>

                    <div nz-row class="mb-3" *ngIf="show.getTranslatedProperty(lang, 'coProducer') !== ''">
                        <p class="page-template-labels section-title mb-0">{{ 'FORM.CO-PRODUCTEUR' | translate }}</p>
                        <span class="fs-16">{{ show.getTranslatedProperty(lang, 'coProducer') }}</span>
                    </div>

                    <div nz-row class="mb-3">
                        <p class="page-template-labels section-title mb-1">{{ 'FORM.DISCIPLINES' | translate }}</p>
                        <div nz-col nzMd="24" class="pl-2">
                            <ng-container *ngIf="showClassificationCascade; else noData">
                                <span *ngFor="let categories of showClassificationCascade" class="dark-text-align classification fs-16">
                                    <ng-container *ngFor="let category of categories; let last = last">
                                        {{ category }}
                                        <!-- <svg *ngIf="!last" style='transform: rotate(-90deg); fill: rgba(0,0,0,.3); margin: -5px 0px;' xmlns='http://www.w3.org/2000/svg' width='20' height='20'><defs /><path d='M9.95 11.485l4.242-4.242a1 1 0 111.415 1.414l-4.95 4.95a1 1 0 01-1.414 0l-4.95-4.95a1 1 0 011.414-1.414l4.243 4.242z'/></svg> -->
                                        <div *ngIf="!last" class="arrow"></div>
                                    </ng-container>
                                </span>
                            </ng-container>
                            <ng-template #noData>
                                {{ 'AUCUNE-DONNEE' | translate }}
                            </ng-template>
                        </div>
                    </div>

                    <div nz-row class="mb-4">
                        <p class="page-template-labels section-title mb-1">{{ 'FORM.PUBLIC-CIBLE' | translate }}</p>
                        <div nz-col nzMd="24" class="pl-2">
                            <p *ngFor="let cible of publicCible" class="mb-13">
                                <span class="dark-text-align py--7 fs-16">{{ cible }}</span>
                            </p>
                        </div>
                    </div>

                    <ng-container>
                        <div class="show-contacts">
                            <p class="page-template-labels section-title mb-1">{{ 'FORM.PUBLIC-CONTACTS' | translate }}</p>

                            <div nz-row class="mb-4 show-contacts__contact">
                                <p class="page-template-labels section-title clr-black font-weight-bold mb-0">{{ 'REPRESENTANT' | translate }}</p>
                                <div nz-col nzMd="24">
                                    <div class="mb-0 fs-16 lh-20">{{ show.representative || '' }}</div>
                                    <div class="page-template-text page-template-text--smaller info show-contacts__link mb-0 fs-16">
                                        <a href="mailto:{{ show.repEmail }}" class="fs-16 lh-23">{{ show.repEmail }}</a>
                                    </div>
                                    <div class="page-template-text page-template-text--smaller info mb-1 fs-16">
                                        {{ show.repPhone }}
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngFor="let contact of show.contacts">
                                <div nz-row class="mb-4 show-contacts__contact">
                                    <p class="page-template-labels section-title clr-black font-weight-bold mb-0">{{ contact.title }}</p>
                                    <div nz-col nzMd="24">
                                        <div class="mb-0 fs-16 lh-20">{{ contact.firstName + ' ' + contact.lastName }}</div>
                                        <div class="page-template-text page-template-text--smaller info show-contacts__link mb-0 fs-16 lh-23">
                                            <a href="mailto:{{ contact.email }}">{{ contact.email }}</a>
                                        </div>
                                        <div class="page-template-text page-template-text--smaller info mb-1 fs-16">
                                            {{ contact.phone }}
                                            <span *ngIf="contact.phonePostNumber" class="ml-1">{{ 'FORM.POSTE' | translate }} {{ ' ' + contact.phonePostNumber }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <div nz-row class="mb-4">
                        <p class="page-template-labels section-title mb-0">{{ 'FORM.LANGUES-DU-SPECTACLE' | translate }}</p>
                        <p class="page-template-text page-template-text--small fs-16">{{ languagesList }}</p>
                    </div>

                    <ng-container *ngIf="this.show.hasSensibilisation">
                        <p class="mb-1 d-flex">
                            <i class="mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                    <path
                                        fill="#797775"
                                        d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321a1 1 0 011.946.467C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909a1 1 0 011.947-.458zm11.426-6.097a1 1 0 01.326 1.282l-.064.108-4.776 7a1 1 0 01-1.523.154l-.083-.092-2.224-2.77a1 1 0 011.475-1.344l.085.093 1.378 1.716 4.016-5.885a1 1 0 011.39-.262zM10 0c4.778 0 8.67 3.168 9.796 7.854a1 1 0 01-1.945.467C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367A1 1 0 11.19 7.909C1.3 3.194 5.202 0 10.001 0z"
                                    />
                                </svg>
                            </i>
                            <span class="page-template-text">{{ 'FORM.SENSIBILISATION-DISPO' | translate }}</span>
                        </p>
                    </ng-container>

                    <ng-container *ngIf="this.show.isTrainingAvailable">
                        <p class="mb-1 d-flex">
                            <i class="mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                    <path
                                        fill="#797775"
                                        d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321a1 1 0 011.946.467C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909a1 1 0 011.947-.458zm11.426-6.097a1 1 0 01.326 1.282l-.064.108-4.776 7a1 1 0 01-1.523.154l-.083-.092-2.224-2.77a1 1 0 011.475-1.344l.085.093 1.378 1.716 4.016-5.885a1 1 0 011.39-.262zM10 0c4.778 0 8.67 3.168 9.796 7.854a1 1 0 01-1.945.467C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367A1 1 0 11.19 7.909C1.3 3.194 5.202 0 10.001 0z"
                                    />
                                </svg>
                            </i>
                            <span class="page-template-text">{{ 'FORM.SPECTACLE-FORMATION-REDUITE' | translate }}</span>
                        </p>
                    </ng-container>

                    <ng-container *ngIf="nudityAndViolence; else nudityOrViolence">
                        <p class="mb-1 d-flex">
                            <i class="icon-warning mr-2 clr-red"></i>
                            <span class="page-template-text">{{ 'CONTIENT-NUDITE-VIOLENCE' | translate }}</span>
                        </p>
                    </ng-container>
                    <ng-template #nudityOrViolence>
                        <p class="mb-1 d-flex">
                            <i *ngIf="show.hasNudity == 1 || show.hasViolence == 1" class="icon-warning mr-2 clr-red"></i>
                            <span *ngIf="show.hasNudity == 1" class="page-template-text">{{ 'CONTIENT-NUDITE' | translate }}</span>
                            <span *ngIf="show.hasViolence == 1" class="page-template-text">{{ 'CONTIENT-VIOLENCE' | translate }}</span>
                        </p>
                    </ng-template>

                    <div nz-row class="mt-4">
                        <p class="page-template-labels-date mb-0 fs-14 ls-25">
                            {{ 'MODIFIER-DATE' | translate }}
                            {{ show.updatedAt | date: 'longDate' }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MATERIEL-AUDIOVISUEL -->
    <div class="container-fluid bg-gray py-5">
        <div class="container">
            <div nz-row>
                <div nz-col [nzMd]="15" class="audioVisual">
                    <h3 class="mb-3">{{ 'FORM.MATERIEL-AUDIOVISUEL' | translate }}</h3>
                    <div class="mb-3">
                        <app-video-player [src]="show.urlVideo"></app-video-player>
                    </div>
                    <div>
                        <app-audio-player [src]="show.urlAudio"></app-audio-player>
                    </div>
                </div>
                <div nz-col [nzMd]="9" class="promotional">
                    <h3 class="mb-3">{{ 'FORM.MATERIEL-PROMO' | translate }}</h3>
                    <app-download-photo-lightbox [photoAlbum]="photoAlbum"></app-download-photo-lightbox>
                </div>
            </div>
        </div>
    </div>

    <!-- DONNEES-TECHNIQUES -->
    <div class="container pb-5">
        <h3 class="mt-5 mb-3">{{ 'FORM.DONNEES-TECHNIQUES' | translate }}</h3>
        <div nz-row nzGutter="16">
            <div nz-col nzMd="6">
                <hr class="separator-clr" />
                <p class="page-template-labels section-title mb-0 pb-0">{{ 'FORM.CACHET' | translate }}</p>
                <p class="page-template-text mb-1">
                    ${{ formatNumbers(show.trayMinCost) || 'NA' }} - ${{
                        (show.trayMaxCost && (show.trayMaxCost > 20000 ? '+' + formatNumbers(20000) : formatNumbers(show.trayMaxCost))) || 'NA'
                    }}
                </p>
                <hr class="separator-clr mt-4" />
                <p class="page-template-labels section-title mb-0 pb-0">{{ 'FORM.JAUGE-MAXIMALE' | translate }}</p>
                <p class="page-template-text mb-1">{{ show.capacity || 'NA' }}</p>
            </div>
            <div nz-col nzMd="6">
                <hr class="separator-clr" />
                <p class="page-template-labels section-title mb-0 pb-0">{{ 'FORM.PERSONNES-SUR-LA-SCENE' | translate }}</p>
                <p class="page-template-text mb-1">{{ show.personsOnStage || 'NA' }}</p>
                <hr class="separator-clr mt-4" />
                <p class="page-template-labels section-title mb-0 pb-0">{{ 'FORM.PERSONNES-SUR-LA-ROUTE' | translate }}</p>
                <p class="page-template-text mb-1">{{ show.personsOnRoad || 'NA' }}</p>
            </div>
            <div nz-col nzMd="6">
                <hr class="separator-clr" />
                <p class="page-template-labels section-title mb-0 pb-0">{{ 'FORM.TEMPS-DE-MONTAGE' | translate }}</p>
                <p class="page-template-text mb-1">{{ (show.durationAssembly - (show.durationAssembly % 60)) / 60 }} h {{ show.durationAssembly % 60 }}</p>
                <hr class="separator-clr mt-4" />
                <p class="page-template-labels section-title mb-0 pb-0">{{ 'FORM.TEMPS-DE-DEMONTAGE' | translate }}</p>
                <p class="page-template-text mb-1">{{ (show.durationDisassembly - (show.durationDisassembly % 60)) / 60 }} h {{ show.durationDisassembly % 60 }}</p>
            </div>
            <div nz-col nzMd="6">
                <hr class="separator-clr" />
                <p class="page-template-labels section-title mb-0 pb-0">{{ 'FORM.DUREE-DE-SPECTACLE' | translate }}</p>
                <p>
                    <span>{{ (show.durationTotal - (show.durationTotal % 60)) / 60 }} h {{ show.durationTotal % 60 }}</span>
                    <span>{{ show.durationBreak && show.durationBreak != 0 ? ' avec entracte de ' + show.durationBreak + ' min' : ' ' + ('FORM.NO-ENTRACTE' | translate) }}</span>
                </p>
                <hr class="separator-clr mt-4" />
                <p class="page-template-labels section-title mb-0 pb-0">{{ 'Plateau' | translate }}</p>
                <p class="mb-0" *ngIf="show.trayWidth">{{ 'FORM.LARGEUR' | translate }} &mdash; {{ formatNumbers(show.trayWidth).replace('.', ',') || 'NA' }}m</p>
                <p class="mb-0" *ngIf="show.trayDepth">{{ 'FORM.PROFONDEUR' | translate }} &mdash; {{ formatNumbers(show.trayDepth).replace('.', ',') || 'NA' }}m</p>
                <p class="mb-2" *ngIf="show.trayHeight">{{ 'FORM.HAUTEUR' | translate }} &mdash; {{ formatNumbers(show.trayHeight).replace('.', ',') || 'NA' }}m</p>
                <ng-container *ngIf="show.urlTechSpecification">
                    <p class="mt-3">
                        <a href="{{ show.urlTechSpecification }}" class="btn-rect btn--larger py-1" target="_blank">
                            <i nz-icon type="file-pdf" class="mr-2" theme="outline" aria-hidden="true"></i>
                            <span>{{ 'FORM.DEVIS-TECHNIQUE' | translate }}</span>
                        </a>
                    </p>
                </ng-container>
            </div>
        </div>
    </div>

    <ng-container *ngIf="tourShow">
        <div class="container" *appIfAdminOrOwnerOf="tour">
            <div nz-row class="mb-5 rate infos">
                <div>{{ 'DEPOT-INFOS-SOUMISES' | translate }}</div>
                <button appButton class="blue" [routerLink]="['/tour', tourId, 'shows', route.snapshot.params['tourShowId'], 'edit'] | localize">
                    <i class="icon-pencil" aria-hidden="true"></i>
                    {{ 'MODIFIER' | translate }}
                </button>
            </div>
        </div>
    </ng-container>

    <app-show-identification [TourShow]="tourShow"></app-show-identification>

    <app-show-modalities [tourShow]="tourShow"></app-show-modalities>
</ng-container>
