import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProgramAdvancedSearchComponent } from './program-advanced-search.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NzFormModule,
        NzSelectModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule,
        SharedModule,
        OverlayModule,
        PortalModule
    ],
    exports: [ProgramAdvancedSearchComponent],
    declarations: [ProgramAdvancedSearchComponent]
})
export class ProgramAdvancedSearchModule {}
