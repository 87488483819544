import { Globals } from 'src/app/_configs/globals';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { AbstractOrganizationForm } from '../organization-abstract-form/organization-abstract-form.component';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';

@Component({
    selector: 'app-organization-type-form',
    templateUrl: './organization-type-form.component.html',
    styleUrls: [ './organization-type-form.component.scss' ],
})
export class OrganizationTypeFormComponent
    extends AbstractOrganizationForm
    implements OnInit {
    constructor(
        private fb: UntypedFormBuilder,
        private notification: RideauNotificationService,
        private translate: TranslateService,
        protected globals: Globals,
        public accountService: AccountService
    ) {
        super(accountService, globals);
    }

    public SCENE_PRO_ID = this.globals.SCENE_PRO_ORGID;

    ngOnInit(): void {
        this.initForm();
    }

    protected initForm(): void {
        this.formGroup = this.organization.getTypeFormGroup(this.fb);
    }

    public get isFormValid(): boolean {
        return Object.keys(this.formGroup.value).some(key => !!this.formGroup.value[ key ]);
    }
    doSubmit(param?: any) {
        this.organizationValue.types = [];
        if (this.formGroup.controls.isProducteur.value) {
            this.organizationValue.types.push(OrganizationTypes.IS_PRODUCTEUR);
        }
        if (this.formGroup.controls.isDiffuseur.value) {
            this.organizationValue.types.push(OrganizationTypes.IS_DIFFUSEUR);
        }
        if (this.formGroup.controls.isSalle.value) {
            this.organizationValue.types.push(OrganizationTypes.IS_SALLE);
        }
        if (this.formGroup.controls.isGovernmental.value) {
            this.organizationValue.types.push(OrganizationTypes.IS_GOVERNMENTAL);
        }
        if (this.formGroup.controls.isProvider.value) {
            this.organizationValue.types.push(OrganizationTypes.IS_PROVIDER);
        }
        if (this.formGroup.controls.isMedia.value) {
            this.organizationValue.types.push(OrganizationTypes.IS_MEDIA);
        }
        if (this.formGroup.controls.isOther.value) {
            this.organizationValue.types.push(OrganizationTypes.IS_OTHER);
        }

        this.organizationValue.tourAccess = this.formGroup.controls.tourAccess.value;

        super.doSubmit(param);

        this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
    }

    getSceneProAdmin(): number {
        return this.accountService.getCurrentCtxOrganizationId();
    }
}
