import { ChooseStandModalComponent } from './../../concepts/stand/components/choose-stand-modal/choose-stand-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandCreateComponent } from './components/stand-create/stand-create.component';
import { StandListComponent } from './components/stand-list/stand-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { StandFormComponent } from './components/stand-create/stand-form/stand-form.component';
import { StandRangeFormComponent } from './components/stand-create/stand-range-form/stand-range-form.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [ChooseStandModalComponent, StandCreateComponent, StandListComponent, StandFormComponent, ChooseStandModalComponent, StandRangeFormComponent],
    imports: [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, NzSelectModule, TranslateModule, SharedModule],
    exports: [StandCreateComponent, StandListComponent, StandFormComponent, StandRangeFormComponent]
})
export class StandModule {}
