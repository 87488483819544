import { NgModule } from '@angular/core';
import { SPMenuComponent } from './menu.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzMenuModule } from 'ng-zorro-antd/menu';

@NgModule({

    imports: [ CommonModule, RouterModule, NzMenuModule ],
    exports: [ SPMenuComponent ],
    declarations: [ SPMenuComponent ]
})
export class SPMenuModule { }
