import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sp-option',
    template: `
        <nz-option nzLabel="{{ label }}" nzValue="{{ value }}"></nz-option>
    `,
})
export class SpOptionComponent implements OnInit {
    @Input() label: string;
    @Input() value: any;
    constructor() { }

    ngOnInit() {}
}
