import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sp-list-item',
    template: `
        <nz-list>
            <nz-list-item>
                <ng-content></ng-content>
            </nz-list-item>
        </nz-list>
    `,
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
