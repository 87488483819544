<ng-container *ngIf="isReady">
    <div class="page-template-head">
        <div class="container">
            <div nz-row>
                <div nz-col>
                    <div *ngIf="venue.statusId === 2" class="approval-box">
                        <p>{{ 'VENUE-APPROVAL-STATE' | translate }}</p>
                        <div>
                            <button *appPermission="4" (click)="reject()" nz-button [nzLoading]="isLoading" class="btn btn--default rejected">
                                {{ 'REJETER' | translate }}
                            </button>
                            <button *appPermission="4" (click)="approve()" nz-button [nzLoading]="isLoading" class="btn btn--default approved ml-2">
                                {{ 'APPROUVER' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-row style="display: flex; align-items: center">
                <div nz-col nzMd="14" class="page-title--title">
                    <!-- TITLE BAR -->
                    <h1>{{ venue?.getTranslatedProperty(lang, 'name') }}</h1>
                </div>
                <div nz-col nzMd="5">
                    <!-- STATUS -->
                    <span *ngIf="venue.statusId === 2" class="rect">{{ 'EN-ATTENTE-APPROBATION' | translate }}</span>
                    <span *ngIf="venue.statusId === 4" class="rect">{{ 'STATUS.REJETEE' | translate }}</span>
                </div>

                <div nz-col nzMd="5" style="text-align: right">
                    <p class="mb-1" *ngIf="venue.statusId === 3">
                        <a class="btn btn-default" [routerLink]="['../../' | localize]">
                            <i nz-icon nzType="eye" nzTheme="outline" class="mr-2" aria-hidden="true"></i>
                            <span>{{ 'VOIR-LE-PROFIL-PUBLIC' | translate }}</span>
                        </a>
                    </p>
                    <p class="mb-1" *ngIf="venue.statusId !== 3 && totalErrorCount() > 0">
                        <span class="rect">
                            {{ totalErrorCount() }}
                            {{ totalErrorCount() > 1 ? ('CHAMPS-INCOMPLETS' | translate) : ('CHAMP-INCOMPLET' | translate) }}
                            :
                            <a *ngIf="!displayErrors" (click)="toggleDisplayErrors()">
                                {{ 'AFFICHER' | translate }}
                            </a>
                            <a *ngIf="displayErrors" (click)="toggleDisplayErrors()">
                                {{ 'CACHER' | translate }}
                            </a>
                        </span>
                    </p>
                    <div *ngIf="(venue.statusId === 1 || venue.statusId === 4) && totalErrorCount() === 0">
                        <button
                            *appPermission="3; objectOrganizationId: venue.organizationId; shouldBeApproved: false"
                            (click)="submitForApproval()"
                            nz-button
                            [nzLoading]="isLoading"
                            class="btn btn--default"
                        >
                            {{ 'FORM.SOUMETTRE-POUR-APPROBATION' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mb-5">
        <div nz-row nzGutter="16">
            <!-- MENU -->
            <div nz-col nzMd="6" class="list-filters mb-4 mb-md-0 pl-0">
                <sp-menu>
                    <ng-container *ngFor="let tab of tabs">
                        <sp-menu-item
                            [routerLink]="['../' + tab.id | localize]"
                            [label]="'VENUE-STEP.' + tab.id | translate"
                            [classList]="['errorCount']"
                            [selected]="current === tab.id"
                        >
                            <nz-badge *ngIf="displayErrors" [nzCount]="tab.varErrorCount" [nzStyle]="{ backgroundColor: '#FE544D' }"></nz-badge>
                        </sp-menu-item>
                    </ng-container>
                </sp-menu>
            </div>

            <div nz-col nzMd="18">
                <!-- COORDONÉES -->
                <ng-container *ngIf="current == 'COORDONNEES'">
                    <h2 class="mb-4">{{ 'VENUE-STEP.COORDONNEES' | translate }}</h2>
                    <app-venue-coordinate-form
                        [(venue)]="venue"
                        (submitCallback)="saveCoord($event)"
                        [isLoading]="isLoading"
                        [displayErrors]="displayErrors"
                    ></app-venue-coordinate-form>
                </ng-container>
                <!-- INFORMATION TECHNIQUE -->
                <ng-container *ngIf="current == 'INFORMATION-TECHNIQUE'">
                    <h2>{{ 'VENUE-STEP.INFORMATION-TECHNIQUE' | translate }}</h2>
                    <app-venue-technical-information-form
                        [(venue)]="venue"
                        (submitCallback)="saveTechnicalInfo($event)"
                        [isLoading]="isLoading"
                        [displayErrors]="displayErrors"
                    ></app-venue-technical-information-form>
                </ng-container>

                <!-- RESIDENCY -->
                <ng-container *ngIf="current == 'RESIDENCY'">
                    <h2>{{ 'VENUE-STEP.RESIDENCY' | translate }}</h2>
                    <app-venue-residency-form
                        [(venue)]="venue"
                        (submitCallback)="saveResidency($event)"
                        [isLoading]="isLoading"
                        [displayErrors]="displayErrors"
                    ></app-venue-residency-form>
                </ng-container>

                <!-- PROFILE -->
                <ng-container *ngIf="current == 'PROFIL'">
                    <!--Profile Tab-->
                    <h2>{{ 'PROFIL' | translate }}</h2>
                    <div nz-row>
                        <div nz-col nzMd="24">
                            <app-venue-profile-form
                                [(venue)]="venue"
                                (submitCallback)="saveProfile($event)"
                                [isLoading]="isLoading"
                                [displayErrors]="displayErrors"
                            ></app-venue-profile-form>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
