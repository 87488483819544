import { NgModule } from '@angular/core';
import { FloatNumberPipe } from './float-number.pipe';
import { ConvertTypePipe } from './convert-type.pipe';
import { StringShortenerPipe } from './string-shortener.pipe';
import { TranslatablePipe } from './translatable.pipe';
import { ExtractArtistListPipeablePipe } from './extract-artist-list-pipeable.pipe';
import { ShortenTextPipe } from './shorten-text.pipe';
import { TimeFromDatePipe } from './time-from-date.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { CasePipe } from './case.pipe';
import { HourMinPipe } from './hour-min.pipe';

const list = [
    FloatNumberPipe,
    ConvertTypePipe,
    StringShortenerPipe,
    TranslatablePipe,
    ExtractArtistListPipeablePipe,
    ShortenTextPipe,
    TimeFromDatePipe,
    EnumToArrayPipe,
    CasePipe,
    HourMinPipe,

];
@NgModule({
    exports: [ ...list ],
    declarations: [ ...list ],
})
export class PipesModule { }
