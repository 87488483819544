import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/*Components*/
import { NotificationsSingleComponent } from './components/notifications-single/notifications-single.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuillModule } from 'ngx-quill';

@NgModule({
    declarations: [NotificationsSingleComponent, NotificationsListComponent],
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, RouterModule, LocalizeRouterModule, SharedModule, QuillModule.forRoot(), NzToolTipModule],
    exports: [NotificationsSingleComponent, NotificationsListComponent]
})
export class NotificationsModule {}
