import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { ErrorsHandler } from 'src/app/shared/error-handling/errors-handler';
import { FormErrorHandler } from 'src/app/shared/error-handling/form-error-handler';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { FieldAreEqualsValidator } from 'src/app/shared/validators/fieldsAreEquals.validator';
import { Globals } from 'src/app/_configs/globals';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [ErrorsHandler]
})
export class RegisterComponent implements OnInit {

  registrationForm: UntypedFormGroup;

  isLoading = false;



  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private globals: Globals,
    private route: Router,
    private formBuilder: UntypedFormBuilder,
    private rideauNotification: RideauNotificationService,
    private errorHandlerService: ErrorsHandler
    ) {}

  ngOnInit(){
    this.initForm();
  }

  async sendRegistration(){

    if(this.registrationForm.valid){

      this.isLoading = true;

      const registerInfo = { ...this.registrationForm.value};

      registerInfo.receiverNewsletter = registerInfo.receiverNewsletter ? 1 : 0;
      registerInfo.confirmPassword = undefined;



      await this.authService.register(registerInfo).toPromise().then(response => {
        this.rideauNotification.success(this.translate.instant('CONFIRM_EMAIL'));

        if(registerInfo.receiverNewsletter){
          this.authService.registerToNewsLetter(registerInfo.email)
          .then( _ => {
            this.rideauNotification.success(this.translate.instant("CONFIRM_EMAIL_NEWSLETTER"))
          }).catch( _ => {
            this.rideauNotification.error(this.translate.instant("CONFIRM_EMAIL_NEWSLETTER_ERROR"))
          })
        }

        this.initForm();
        this.route.navigate([this.translate.currentLang, 'login']);
      }).catch(err => {

       this.handleRegistrationError(err);

      })

      this.isLoading = false;

    } else{
      this.registrationForm.markAllAsTouched();
    }
  }

  private initForm(){
    this.registrationForm = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.globals.emailRegex)]),
      firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
      password: new UntypedFormControl('', [Validators.required, Validators.pattern(this.globals.passwordRegex)]),
      receiverNewsletter: new UntypedFormControl(false),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, {
      validators: [FieldAreEqualsValidator.verify('password', 'confirmPassword')]
    })

  }

  private handleRegistrationError(err){
    if( err.error && err.error.errors){
      FormErrorHandler.getErrorByKey(err.error.errors, (err, key) => {
        switch (key) {
          case "email" : {
            this.rideauNotification.error(this.translate.instant("ERRORS.EMAIL-TAKEN"))
            break;
          }
        }
      })
    } else {
      this.errorHandlerService.handleError(err)
    }

  }
}
