<section>
    <div nz-row nzType="flex" nzJustify="end" nzGutter="24">
        <div nz-col *ngIf="useFilters && listItemsType != vitrineList" nzMd="6" class="{{ filterList.length ? 'list-filters list-filters--left' : '' }} mb-4 mb-md-0">
            <!-- FILTERS  -->
            <div *ngFor="let filter of filterList; index as f">
                <div class="list-filters__box">
                    <span *ngIf="filter.filterName" class="list-filters__box__title">{{ filter.filterName }}</span>
                    <sp-menu>
                        <!-- Angular migration: index variable must be changed -->
                        <ng-container *ngFor="let filterVal of filter.filterValues; index as i">
                            <sp-menu-item [classList]="['list-filters__item']" [selected]="filterVal.selected" (clicked)="onSelectFilter(filter.filterField, filterVal, i, f)">
                                <span class="mr-3">{{ filterVal.filterValueName }}</span>
                                <span class="list-filters__itemCount" *ngIf="filterVal.filterTotal">{{ filterVal.filterTotal }}</span>
                            </sp-menu-item>
                        </ng-container>
                    </sp-menu>
                </div>
            </div>
        </div>

        <div nz-col [nzMd]="useFilters && listItemsType != vitrineList ? 18 : 24">
            <ng-content select="[prefix]"></ng-content>

            <div nz-row *ngIf="useSearchBar" class="mb-3">
                <!--  SEARCH -->
                <div nz-col nzMd="11" class="searchbar mb-3 mb-md-0">
                    <nz-input-group nzSearch [nzAddOnAfter]="nzSuffixIconSearch">
                        <input
                            type="text"
                            nz-input
                            nzShowSearch
                            nzAllowClear
                            [formControl]="searchFormControl"
                            nzSize="default"
                            class="searchBarInput"
                            [placeholder]="searchBarPlaceHolder"
                        />
                    </nz-input-group>
                    <ng-template #nzSuffixIconSearch>
                        <button nz-button nzType="primary" class="searchbar__btn" nzSearch>
                            <i aria-hidden="true" class="d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                    <path
                                        fill="#FFF"
                                        d="M14.044 13.63l2.663 2.663a1 1 0 01-1.414 1.414l-2.663-2.663a1 1 0 011.414-1.414zM8 2a6 6 0 110 12A6 6 0 018 2zm0 2C5.79 4 4 5.79 4 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
                                    />
                                </svg>
                            </i>
                        </button>
                    </ng-template>
                </div>

                <!-- LINK FOR ADVANCED SEARCH-->
                <div nz-col nzMd="13" *ngIf="advancedSearchComponent" class="text-md-right">
                    <a class="search-advanced-link d-flex justify-content-end" (click)="rechercheAvancee()">
                        <span class="mr-1">{{ 'RECHERCHE-AVANCEE' | translate }}</span>
                        <svg [ngClass]="{ 'rotate-180': checkedCollapse, 'rotate-init': !checkedCollapse }" width="20" height="20" viewBox="0 0 20 20">
                            <path
                                fill-rule="nonzero"
                                d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z"
                            />
                        </svg>
                    </a>
                </div>
            </div>

            <!-- ADVANCED SEARCH -->
            <ng-content *ngIf="checkedCollapse" select="[advanced-search]"></ng-content>

            <div nz-row *ngIf="!hideHeader" class="mt-3 table-head p-3 mb-2">
                <div nz-col nzMd="12" class="d-flex align-items-center table-head__col">
                    <span *ngIf="!(useFilters && listItemsType == vitrineList)" class="table-head__count">{{ pagination?.total }} {{ elementsLabel }}</span>
                    <div *ngIf="useFilters && listItemsType == vitrineList">
                        <!-- FILTERS  -->
                        <nz-radio-group id="selectedFilter" class="input-radioBtn" [(ngModel)]="selectedFilter" [nzButtonStyle]="'solid'">
                            <ng-container *ngFor="let filter of filterList">
                                <label
                                    for="selectedFilter"
                                    nz-radio-button
                                    *ngFor="let filterVal of filter.filterValues"
                                    nzValue="{{ filterVal.filterValueName }}"
                                    (click)="onSelectFilter(filter.filterField, filterVal)"
                                >
                                    {{ filterVal.filterValueName }}
                                </label>
                            </ng-container>
                        </nz-radio-group>
                    </div>
                </div>
                <div nz-col nzMd="12" class="table-head__col text-right" id="button-wrapper">
                    <!-- SORT -->
                    <div *ngIf="useSorting" class="d-flex">
                        <span class="table-head__sort-by mr-2 flex-grow-0" style="padding-top: 19px; min-width: 89px">{{ 'TRIER-PAR' | translate }}</span>
                        <nz-select
                            style="width: 210px; right: 2px"
                            class="p-2"
                            nzAllowClear
                            nzPlaceHolder="{{ 'TRIER-PAR' | translate }}"
                            [ngModel]="sortModel"
                            (ngModelChange)="onSortChange($event)"
                        >
                            <nz-option *ngFor="let sort of sortList" [nzLabel]="sort.sortName" [nzValue]="sort.sortValue"></nz-option>
                        </nz-select>
                    </div>
                    <ng-container *ngIf="isDownloadFavorites">
                        <button class="btn btn--larger download-favorites-button" nzType="default" nzTrigger="click" (click)="exportFavorites.emit()">
                            {{ 'EXPORTER' | translate }}
                        </button>
                    </ng-container>
                    <ng-container *ngIf="useViewSwitcher">
                        <button id="list-item-row" (click)="changeListView()" class="filter-button {{ isListViewRow ? 'active' : '' }} "><i class="icon-list"></i></button>
                        <button id="list-item-card" (click)="changeListView()" class="filter-button {{ !isListViewRow ? 'active' : '' }} mr-2"><i class="icon-grid"></i></button>
                    </ng-container>
                </div>
            </div>

            <div nz-row class="mb-3">
                <div nz-col class="w-100">
                    <div class="table {{ isListViewRow ? '' : 'table--no-white' }}">
                        <div *ngIf="isLoading" class="loader">
                            <i nz-icon aria-hidden="true" nzType="loading" nzTheme="outline"></i>
                        </div>
                        <!-- ACTIVITY LIST -->
                        <ng-container *ngIf="listItemsType == activityList; else participantRow">
                            <ng-container *ngFor="let item of itemList">
                                <app-item-row
                                    [item]="item"
                                    [itemRowType]="listItemsType"
                                    [enableEditing]="enableEditing"
                                    [enableRemoving]="enableRemoving"
                                    (modifyItem)="modifyItemCallback($event)"
                                    (removeItem)="removeItemCallback($event)"
                                ></app-item-row>
                            </ng-container>
                        </ng-container>

                        <!-- PARTICIPANT LIST -->
                        <ng-template #participantRow>
                            <ng-container *ngIf="listItemsType == participantList; else defaultRow">
                                <ng-container *ngFor="let item of itemList">
                                    <app-item-row-participant
                                        [item]="item"
                                        [itemRowType]="listItemsType"
                                        [enableEditing]="enableEditing"
                                        [enableRemoving]="enableRemoving"
                                        (modifyItem)="modifyItemCallback($event)"
                                        (removeItem)="removeItemCallback($event)"
                                    ></app-item-row-participant>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        <ng-template #defaultRow>
                            <!-- IF VIEW SWITCHER AND NOT ROW-VIEW -->
                            <ng-container *ngIf="useViewSwitcher && !isListViewRow; else rowView">
                                <!-- CARDS VIEW -->
                                <div class="item-card-grid">
                                    <ng-container *ngFor="let item of itemList">
                                        <div class="item-card-wrapper">
                                            <app-item-card
                                                [item]="item"
                                                [itemRowType]="listItemsType"
                                                [itemToRate]="isToRate"
                                                (rateItem)="onRateItemCallback($event)"
                                                (updateKeepedShow)="onUpdateKeepedShowCallback($event)"
                                                [isAdmin]="isAdmin"
                                            ></app-item-card>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <!-- IF ROW-VIEW SELECTED OR DEFAULT -->
                            <ng-template #rowView>
                                <!-- ROWS VIEW -->
                                <ng-container *ngFor="let item of itemList">
                                    <app-item-row
                                        [item]="item"
                                        [itemRowType]="listItemsType"
                                        [enableEditing]="enableEditing"
                                        [enableRemoving]="enableRemoving"
                                        [itemToRate]="isToRate"
                                        (modifyItem)="modifyItemCallback($event)"
                                        (removeItem)="removeItemCallback($event)"
                                        (rateItem)="onRateItemCallback($event)"
                                        (updateKeepedShow)="onUpdateKeepedShowCallback($event)"
                                        [isAdmin]="isAdmin"
                                    ></app-item-row>
                                </ng-container>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </div>

            <!-- PAGINATION -->
            <ng-container *ngIf="usePagination && pagination">
                <nz-pagination
                    [nzPageIndex]="pagination.getPage()"
                    [nzTotal]="pagination.getTotal()"
                    [nzPageSize]="pagination.limit"
                    [nzItemRender]="renderItemTemplate"
                    (nzPageIndexChange)="onPageChange($event)"
                ></nz-pagination>
                <ng-template #renderItemTemplate let-type let-page="page">
                    <a class="precedent_suivant {{ pagination.getPage() == 1 ? 'disabled' : '' }}" *ngIf="type === 'pre'">
                        {{ 'PRECEDENT' | translate }}
                    </a>
                    <a class="precedent_suivant" *ngIf="type === 'next'">
                        {{ 'SUIVANT' | translate }}
                        <i style="padding-left: 5px" nz-icon aria-hidden="true" nzType="right" nzTheme="outline"></i>
                    </a>
                    <a *ngIf="type === 'page'">{{ page }}</a>
                </ng-template>
            </ng-container>
        </div>
    </div>
</section>
