import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { SharedModule } from '../../shared/shared.module';
import { AccountModule } from '../account/account.module';
import { QuillModule } from 'ngx-quill';
import { NzSelectModule } from 'ng-zorro-antd/select';
/*Components*/
import { OrganizationBillingComponent } from './components/organization-modify/organization-billing/organization-billing.component';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import { OrganizationMembersComponent } from './components/organization-modify/organization-members/organization-members.component';
import { OrganizationCoordinateFormComponent } from './components/organization-modify/organization-coordinate-form/organization-coordinate-form.component';
import { OrganizationModifyComponent } from './components/organization-modify/organization-modify.component';
import { OrganizationProfileFormComponent } from './components/organization-modify/organization-profile-form/organization-profile-form.component';
import { OrganizationTypeFormComponent } from './components/organization-modify/organization-type-form/organization-type-form.component';
import { OrganizationSingleComponent } from './components/organization-single/organization-single.component';
import { OrganizationTeamComponent } from './components/organization-team/organization-team.component';
import { OrganizationVenuesComponent } from './components/organization-venues/organization-venues.component';
import { OrganizationNetworkInfosComponent } from './components/organization-modify/organization-network-infos/organization-network-infos.component';
import { OrganizationContactsFormComponent } from './components/organization-modify/organization-contacts-form/organization-contacts-form.component';
import { OrganizationPromotionComponent } from './components/organization-modify/organization-promotion/organization-promotion.component';
import { PublicationModule } from '../publication/publication.module';
import { PromotionModule } from '../promotion/promotion.module';
import { AccountService } from '../account/services/account.service';
import { OrganisationDepotComponent } from './components/organization-modify/organisation-depot/organisation-depot.component';
import { OrganisationTourAccordionComponent } from './components/organization-modify/organisation-depot/organisation-tour-accordion/organisation-tour-accordion.component';
import { OrganisationShowAccordionBlockComponent } from './components/organization-modify/organisation-depot/organisation-tour-accordion/organisation-show-accordion-block/organisation-show-accordion-block.component';
import { OrganizationTourShowComponent } from './components/organization-tour-show/organization-tour-show.component';
import { OrganizationBannerComponent } from './components/organization-tour-show/organization-banner/organization-banner.component';
import { OrganizationBillingModule } from './components/organization-modify/organization-billing/organization-billing.module';
import { ImageCropperComponent } from 'ngx-image-cropper';
@NgModule({
    declarations: [
        OrganizationListComponent,
        OrganizationModifyComponent,
        OrganizationSingleComponent,
        OrganizationProfileFormComponent,
        OrganizationTypeFormComponent,
        OrganizationMembersComponent,
        OrganizationTeamComponent,
        OrganizationCoordinateFormComponent,
        OrganizationVenuesComponent,
        // OrganizationBillingComponent,
        OrganizationNetworkInfosComponent,
        OrganizationContactsFormComponent,
        OrganizationPromotionComponent,
        OrganisationDepotComponent,
        OrganisationTourAccordionComponent,
        OrganisationShowAccordionBlockComponent,
        OrganizationTourShowComponent,
        OrganizationBannerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        LocalizeRouterModule,
        AccountModule,
        SharedModule,
        QuillModule.forRoot(),
        PublicationModule,
        PromotionModule,
        OrganizationBillingModule,
        NzSelectModule,
        ImageCropperComponent
    ],
    exports: [OrganizationListComponent, OrganizationModifyComponent, OrganizationSingleComponent, OrganizationBillingModule],
    providers: [AccountService]
})
export class OrganizationModule {}
