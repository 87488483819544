import { Component, OnInit } from '@angular/core';
import { ProgramService } from '../../services/program.services';
import { Globals } from 'src/app/_configs/globals';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Calendar } from '../../model/calendar.model';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-calendars',
  templateUrl: './my-calendars.component.html',
  styleUrls: ['./my-calendars.component.scss'],
})
export class MyCalendarsComponent implements OnInit {
  isReady: boolean;
  calTitle: string;
  myCalendars: Calendar[];

  organizationId: number;
  newCalTitleInput: string;
  collapseCreateSection: boolean;

  currentLang = this.translate.currentLang;

  constructor(
    private programService: ProgramService,
    private globals: Globals,
    private translate: TranslateService,
    private modalService: NzModalService,
    public accountService: AccountService,
    private notification: RideauNotificationService
  ) {}

  ngOnInit(): void {
    this.organizationId = this.accountService.getCurrentCtxOrganizationId();
    this.getMyCalendars();
  }

  getMyCalendars(): Subscription {
    return this.programService.getCalendars().subscribe((data) => {
      this.myCalendars = data;
      this.isReady = true;
    });
  }

  createCalendar(): void {
    const newCal = new Calendar({});
    newCal.organizationId = this.organizationId;
    newCal.setTranslatedProperty(this.currentLang, 'title', this.newCalTitleInput);

    this.programService.createCalendar(newCal).subscribe((data) => {
      this.getMyCalendars();
      this.newCalTitleInput = '';
      this.collapseCreateSection = false;
    });
  }

  editCalendar(index) {
    this.myCalendars[index]['title'] = this.myCalendars[
      index
    ].getTranslatedProperty(this.currentLang, 'title');
    this.myCalendars[index]['isEditing'] = true;
  }

  updateCalendar(cal) {
    cal.setTranslatedProperty(this.currentLang, 'title', cal['title']);

    this.programService.updateCalendar(cal).subscribe((data) => {
      // Notify
      this.notification.success(this.translate.instant('CALENDRIER-SUPPRIME'));
      this.getMyCalendars();
    });
  }

  deleteCalendar(calId) {
    // Modal
    const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
    const acceptDelete = this.translate.instant('OUI');
    const rejectDelete = this.translate.instant('NON');
    this.modalService.confirm({
      nzContent: deleteMessages,
      nzOkText: acceptDelete,
      nzCancelText: rejectDelete,
      nzClosable: true,
      nzMaskClosable: true,
      nzOnOk: () => {
        this.programService.deleteCalendarById(calId).subscribe((data) => {
          // Notify
          this.notification.success(
            this.translate.instant('ETIQUETTE-SUPPRIME')
          );
          this.getMyCalendars();
        });
      },
    });
  }
}
