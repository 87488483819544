<app-connection-container [title]="'CONNEXION' | translate">
    <div *ngIf="showTemplateName === 'confirm'; else loading" class="row justify-content-center mt-4">
        <div class="EmailConfirmation__fsuccess mt-4 col-lg-4 col-sm-12 col-md-8 p-0">
            <i class="EmailConfirmation__success-icon mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                    <path
                        fill="#00D1AE"
                        d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321a1 1 0 011.946.467C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909a1 1 0 011.947-.458zm11.426-6.097a1 1 0 01.326 1.282l-.064.108-4.776 7a1 1 0 01-1.523.154l-.083-.092-2.224-2.77a1 1 0 011.475-1.344l.085.093 1.378 1.716 4.016-5.885a1 1 0 011.39-.262zM10 0c4.778 0 8.67 3.168 9.796 7.854a1 1 0 01-1.945.467C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367A1 1 0 11.19 7.909C1.3 3.194 5.202 0 10.001 0z"
                    />
                </svg>
            </i>
            <p class="EmailConfirmation__text EmailConfirmation__text-bold">
                {{ 'EMAIL_CONFIRMATION_SUCCESS' | translate }}
            </p>

            <form *ngIf="showTemplateName === 'confirm'" nz-form [formGroup]="connectionForm" (ngSubmit)="login($event)">
                <nz-form-item>
                    <nz-form-control>
                        <label style="color: white" for="email">{{ 'EMAIL_ADRESS' | translate }}</label>
                        <input formControlName="email" type="email" name="email" />
                        <span class="has-error" *ngIf="connectionForm.invalid && connectionForm.get('email').errors && connectionForm.get('email').touched">
                            <span *ngIf="connectionForm.get('email').errors['required']; else incorrectField">{{ 'CHAMP-REQUIS-SIMPLE' | translate }}</span>
                            <ng-template #incorrectField>{{ 'ENTRER-VALEUR' | translate }}</ng-template>
                        </span>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control>
                        <div class="d-flex justify-content-between">
                            <label style="color: white" for="password">{{ 'MOT-DE-PASSE' | translate }}</label>
                            <a class="cta_link cta_link-password" [routerLink]="['../../forgot-password']">{{ 'FORGOT_PASSWORD' | translate }}?</a>
                        </div>

                        <input formControlName="password" type="password" name="password" />
                    </nz-form-control>
                </nz-form-item>

                <div class="Login__action">
                    <app-button-connection
                        class="w-100"
                        buttonType="Primary"
                        [text]="'CONNEXION' | translate"
                        [isLoading]="isLoading"
                        type="submit"
                        [disable]="!connectionForm.valid"
                    ></app-button-connection>
                </div>
            </form>
        </div>
    </div>

    <ng-template #loading>
        <ng-container *ngIf="showTemplateName === 'confirming'; else error">
            <div class="d-flex justify-content-center mt-4">
                <nz-spin [nzTip]="'EMAIL_CONFIRMATION_PENDING' | translate" nzSimple></nz-spin>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #error>
        <div class="row justify-content-center mt-4">
            <div class="EmailConfirmation__fsuccess mt-4 col-lg-4 col-sm-12 col-md-8 p-0">
                <i class="EmailConfirmation__warning-icon icon-warning mr-1"></i>
                <p class="EmailConfirmation__text EmailConfirmation__text-bold">
                    {{ failureMessage | translate }}
                </p>
            </div>
        </div>
    </ng-template>
</app-connection-container>
