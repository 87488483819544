import { Pipe, PipeTransform } from "@angular/core";
import { Classification } from "../model/classification.model";

@Pipe({
  name: "extractSingleClassification",
})
export class ExtractSingleClassificationPipe implements PipeTransform {
  transform(
    classifications: Classification[],
    id: number
  ): Classification | null {
    return classifications
      ? classifications.find((classification) => classification.id === id)
      : null;
  }
}
