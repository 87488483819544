import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { ParticipantMeeting } from 'src/app/concepts/meeting/model/participant.model';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import * as Utils from '../../../../shared/utils/time-utils';
import { Activity } from '../../model/activity.model';
import { ActivityService } from '../../services/activity.service';
import { BreadcrumbService } from './../../../../shared/services/breadcrumb.services';
import { RideauNotificationService } from './../../../../shared/services/rideau-notification.service';
import { AccountService } from './../../../account/services/account.service';
import { Meeting } from './../../../meeting/model/meeting.model';
import { MeetingService } from './../../../meeting/services/meeting.service';
import { ActivityVitrine } from '../../../vitrine/model/vitrine.model';
import { ListItem } from '../../../../shared/model/list-item.model';
import { RowTypes } from '../../../../shared/enums/row-types.enum';

@Component({
    selector: 'app-activity-single',
    templateUrl: './activity-single.component.html',
    styleUrls: [ './activity-single.component.scss' ],
})
export class ActivitySingleComponent implements OnInit {
    isReady = false;
    isInscriptionReady = false;
    currentLang = this.translate.currentLang;
    activity: Activity;
    day: string;
    month: string;
    pageSubtitle: string;
    meeting: Meeting;
    private organization: Organization;
    activityVitrineList: ListItem[] = [];

    isActivityChoiceEnabled: boolean;
    itemRow: RowTypes = RowTypes.ACTIVITYVITRINE;
    isInscriptionOpen = true;

    // Tableau des Participants à la rencontre membres de mon organisation
    participants: ParticipantMeeting[];
    participations = {};

    // Tableau des participantIds à l'activité
    private activityParticipantsId: any[] = [];

    private meetingId: number;
    private activityId: number;
    public hasRemainingCapacity$: Observable<boolean> = this.activityService.hasRemainingCapacity$;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private meetingService: MeetingService,
        private accountService: AccountService,
        private activityService: ActivityService,
        private breadcrumbService: BreadcrumbService,
        private notification: RideauNotificationService,
        private organizationService: OrganizationService
    ) { }

    ngOnInit(): void {
        this.meetingId = this.route.snapshot.params.meetingId;
        this.activityId = this.route.snapshot.params.activityId;

        forkJoin([
            this.getMeeting(),
            this.getActivity(),
            this.getParticipants(),
            this.getParticipantsForActivity(),
            this.setCurrentOrganization()
        ]).subscribe(() => {
            this.setActivityBreadcrumb();

            // Une fois qu'on a les participants, et la liste de ceux déjà inscrits à l'actiivité,
            // on peut initialiser le tableau participations
            this.updateParticipations();
            this.isInscriptionReady = true;
        });
    }

    setActivityBreadcrumb(): void {
        // Set Custom Breadcrumb item list
        const breadcrumbItems: BreadcrumbItem[] = [];
        const meetingName = this.meeting.getTranslatedProperty(
            this.currentLang,
            'name'
        );
        const meetingUrl = '/pro-meeting/' + this.route.snapshot.params.meetingId;
        const activityName = this.activity.getTranslatedProperty(
            this.currentLang,
            'title'
        );
        breadcrumbItems.push(
            new BreadcrumbItem({ title: meetingName, url: meetingUrl })
        );
        breadcrumbItems.push(new BreadcrumbItem({ title: activityName }));

        this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);
    }

    getActivity(): Observable<any> {
        return this.activityService
            .getActivityById(this.activityId)
            .do((activity) => {
                this.activity = activity;
                const { capacity, remainingCapacity } = activity;
                this.activityService.updateRemainingCapacity({
                    capacity,
                    remainingCapacity
                });
                this.getActivityVitrines();

                if (this.activity.date) {
                    this.day = new Date(this.activity.date).getUTCDate().toString();
                    this.month = new Date(this.activity.date).toLocaleString('fr-CA', {
                        month: 'short',
                    });
                    const today = new Date();
                    const date = new Date(this.activity.date);
                    date.setHours(new Date(this.activity.hourFrom).getHours());
                    date.setMinutes(new Date(this.activity.hourFrom).getMinutes());
                    this.isInscriptionOpen = today <= date;
                } else {
                    this.day = '-';
                    this.month = '-';
                }
                if (this.activity.hourFrom) {
                    this.pageSubtitle = Utils.getTimeFromTimestamp(
                        this.activity.hourFrom
                    );
                }
                if (!!this.activity.hourTo) {
                    const a_ccent = this.translate.instant('A-CCENT');
                    this.pageSubtitle +=
                        a_ccent + ' ' + Utils.getTimeFromTimestamp(this.activity.hourTo);
                }
                this.isReady = true;
            });
    }

    getActivityVitrines(): void {
        this.activityService
            .getActivityVitrines(this.activity.id)
            .subscribe((data) => {
                this.activityVitrineList = data[ 'activity_vitrines' ].map(
                    (activityVitrine) => {
                        const item = new ActivityVitrine(activityVitrine).toListItem(
                            this.currentLang
                        );
                        item.itemUrl = `/pro-meeting/${this.meetingId}/showcase/${activityVitrine.vitrine.id}/show/${activityVitrine.vitrine.showId}`;
                        return item;
                    }
                );
            });
    }

    private getMeeting(): Observable<any> {
        return this.meetingService
            .getMeetingById(this.meetingId)
            .do((meeting: Meeting) => {
                this.meeting = meeting;
                this.isActivityChoiceEnabled = this.meeting.isActivityChoiceOpened();
            });
    }

    private getParticipants(): Observable<any> {
        const IS_PAID = true;
        const WITH_ACTIVITIES = true;
        return this.meetingService
            .getAllMeetingParticipantsForCurrentOrg(
                this.meetingId,
                IS_PAID,
                WITH_ACTIVITIES
            )
            .do((res) => (this.participants = res));
    }
    private getParticipantsForActivity(): Observable<any> {
        return this.activityService
            .getParticipantsForActivity(this.meetingId, this.activityId, false)
            .do((res: any[]) => (this.activityParticipantsId = res.map((x) => x.id)));
    }

    private setCurrentOrganization(): Observable<any> {
        const currentUserOrgId = this.accountService.getCurrentCtxOrganizationId();
        return this.organizationService
            .getOrganization(currentUserOrgId)
            .do((data: Organization) => {
                this.organization = data;
            });
    }

    /*
     * Indique si une des case (activity, participant) de la matrice
     * doit être disabled selon les paramètres de restriction de l'activité
     */
    private getEnabledState(participant: ParticipantMeeting): boolean {
        // Si désactivé de manière générale
        if (!this.isActivityChoiceEnabled) {
            return false;
        } else {
            return this.activity.isActivityChoiceEnabled(
                participant,
                this.organization
            );
        }
    }

    modifyActivity(): void {
        const modify = this.translate.instant('ROUTES.modify');
        const activityRoute = this.translate.instant('ROUTES.activity');
        const editLink = `/${this.currentLang}/${activityRoute}/${this.activity.meetingId}/${this.activity.id}/${modify}`;
        this.router.navigate([ editLink ]);
    }

    /**
     * Sauvegarde les choix d'activité.
     * Lancé quand on clique sur "AJOUTER-CHOIX-ACTIVITE"
     */
    saveActivityChoice(): void {
        // désinscrire à l'activités (les participantsId qui étaient dans activityParticipantsId et ne sont plus dans participations)
        const toDelete: any[] = this.activityParticipantsId.filter(
            (partId) => !this.participations[ partId ].value
        );
        // inscrire à l'activités (les participantsId qui sont dans participations n'étaient pas dans activityParticipantsId)
        const toAdd: any[] = this.participants
            .filter(
                (part) =>
                    this.participations[ part.id ].value &&
                    !this.activityParticipantsId.includes(part.id)
            )
            .map((part) => part.id);

        const remainingCapacity = this.activity.remainingCapacity;
        if (
            (remainingCapacity || remainingCapacity == 0) &&
            toAdd.length > 0 &&
            remainingCapacity < toAdd.length - toDelete.length
        ) {
            const message =
                remainingCapacity > 0
                    ? this.translate.instant('ERRORS.ACTIVITY-LACK-OF-CAPACITY') +
                    remainingCapacity
                    : this.translate.instant('ERRORS.ACTIVITY-FULL');
            this.notification.error(message);
            // initialiser le tableau de participation
            this.updateParticipations();
            return;
        }
        // on construit on tableau d'observables...
        const arrayOfObservablesToDelete: Observable<any>[] = toDelete.map(
            (participantId) => {
                return this.activityService.removeParticipantFromActivity(
                    this.meetingId,
                    participantId,
                    this.activityId
                );
            }
        );
        const arrayOfObservablesToAdd: Observable<any>[] = toAdd.map(
            (participantId) => {
                return this.activityService.addParticipantToActivity(
                    this.meetingId,
                    participantId,
                    this.activityId
                );
            }
        );
        let obs: Observable<any>;
        if (
            arrayOfObservablesToDelete.length == 0 ||
            arrayOfObservablesToAdd.length == 0
        ) {
            obs = forkJoin(
                ...arrayOfObservablesToDelete.concat(arrayOfObservablesToAdd)
            );
        } else {
            obs = forkJoin(...arrayOfObservablesToDelete).flatMap(() =>
                forkJoin(...arrayOfObservablesToAdd)
            );
        }
        obs.subscribe(
            () => {
                this.notification.success(
                    this.translate.instant('MODIFICATIONS-ENREGISTREES')
                );
                if (remainingCapacity || remainingCapacity == 0) {
                    this.activity.remainingCapacity =
                        remainingCapacity - toAdd.length + toDelete.length;
                }

                this.getParticipantsForActivity().subscribe(() => {
                    this.updateParticipations();
                });
            },
            (err) => {
                this.updateParticipations();
                try {
                    const { name, statusCode } = err.error;
                    // si l'activité est complet
                    if (statusCode === 403 && name === 'LackOfCapacity') {
                        this.notification.error(
                            this.translate.instant('ERRORS.ACTIVITY-AVAILABILITY-CHANGED')
                        );
                    } else {
                        throw err;
                    }
                } catch (e) {
                    this.notification.error(
                        this.translate.instant('ERRORS.SERVER-ERROR'),
                        ''
                    );
                    console.error('Error : ', err);
                }
            }
        );
    }
    updateParticipations(): void {
        this.participations = this.participants.reduce((acc, participant) => {
            acc[ participant.id ] = {
                value: this.activityParticipantsId.includes(participant.id),
                disabled: !this.getEnabledState(participant),
                fullSchedule: !this.activity.isParticipantScheduleFree(participant),
            };
            return acc;
        }, {});
    }
    updateCapacity(): void {
        const toDelete: any[] = this.activityParticipantsId.filter(
            (partId) => !this.participations[ partId ].value
        );
        // inscrire à l'activités (les participantsId qui sont dans participations n'étaient pas dans activityParticipantsId)
        const toAdd: any[] = this.participants
            .filter(
                (part) =>
                    this.participations[ part.id ].value &&
                    !this.activityParticipantsId.includes(part.id)
            )
            .map((part) => part.id);
        if (
            this.activity.remainingCapacity ||
            this.activity.remainingCapacity == 0
        ) {
            this.activityService.updateRemainingCapacity({
                capacity: this.activity.capacity,
                remainingCapacity: this.activity.remainingCapacity - toAdd.length + toDelete.length
            });
        }
    }
}
