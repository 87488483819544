import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

let unique = 0;

@Component({
    selector: 'sp-nz-date-picker',
    template: `
            <nz-date-picker
                [nzPlaceHolder]="'yyyy/mm/dd'"
                [nzAllowClear]="true"
                [nzFormat]="nzFormat"
                (ngModelChange)="ngModelChange($event)"
                [nzDisabled]="disabled"
                [ngModel]="control?.value"
            >
           </nz-date-picker>
    `,
    styles: [
        `
            :host {
                display: block;
            }

            :host /deep/ nz-date-picker,
            :host /deep/ nz-picker,
            :host /deep/ span.ant-calendar-picker,
            :host /deep/ input.ant-calendar-picker-input {
                display: block;
                width: 100%;
            }

            :host.warning /deep/ .ant-calendar-picker-input {
                border: 2px solid #faad14 !important;
                border-radius: 4px !important;
            }

            :host.warning /deep/ .ant-calendar-picker-input:hover {
                border: 2px solid #faad14 !important;
            }

            :host.error /deep/ .ant-calendar-picker-input {
                border: 2px solid #f5222d !important;
                border-radius: 4px !important;
            }

            :host.error /deep/ .ant-calendar-picker-input:hover {
                border: 2px solid #f5222d !important;
            }
        `
    ],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => NzDatePickerComponent),
          multi: true,
        },
      ],
})
export class NzDatePickerComponent implements ControlValueAccessor {
    private onChange: (value: Date | null) => void = () => {};
    private onTouched: () => void = () => {};
    @Input() disabled: boolean;
    @Input() control: UntypedFormControl | null = null;
    @Input() nzFormat: string = 'yyyy/MM/dd';
    @Input() validateStatus = '';
    @HostBinding('class.warning') get classWarning() {
        return this.validateStatus === 'warning';
    }
    @HostBinding('class.error') get classError() {
        return this.validateStatus === 'error';
    }
    @HostBinding('class.disabled') get classDisabled() {
        return this.disabled;
    }
    id = `DatePickerComponent-${unique++}`;

    ngModelChange(value: Date): void {
        const date = value ? moment(value).startOf('day').toDate() : null;
        if (this.control) {
            this.control.setValue(date);
        }
        this.onChange(date);
        this.onTouched();
    }
    writeValue(value: Date | null): void {
        if (this.control) {
            this.control.setValue(value);
        }
    }
    registerOnChange(fn: (value: Date) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}