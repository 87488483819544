import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: [ './contact-form.component.scss' ],
})
export class ContactFormComponent {
    @Input()
    contactForm: UntypedFormGroup;

    @Output()
    addContact = new EventEmitter<void>();

    @Output()
    cancel = new EventEmitter<any>();

    addNewContact() {
        this.addContact.emit();
    }

    dismiss() {
        this.cancel.emit();
    }
}
