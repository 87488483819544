import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: [ './action-button.component.scss' ]
})
export class ActionButtonComponent {
    // ! TO FIX: THIS COMPONENT IS NEVER USED
    @Output() actionClick = new EventEmitter();

    onClick() {
        this.actionClick.emit();
    }
}
