import { NgModule } from '@angular/core';
import { SPRadioComponent } from './radio.component';
import { FormsModule } from '@angular/forms';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzFormModule } from 'ng-zorro-antd/form';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [ CommonModule, FormsModule, NzFormModule, NzRadioModule, TranslateModule ],
    exports: [ SPRadioComponent ],
    declarations: [ SPRadioComponent ],
})
export class SPRadioModule { }
