import { Injectable } from '@angular/core';

import { NetworkService } from './services/network.service';
import { Organization } from './model/organization.model';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';

@Injectable({ providedIn: 'root' })
export class NetworkResolver  {
  constructor(
    private readonly networkService: NetworkService,
    private readonly userOrganizationIdResolver: UserOrganizationIdResolver,
  ) {
  }

  resolve(): Observable<Organization[]> {
    return this.userOrganizationIdResolver.resolve().pipe(
      switchMap(contextOrganizationId => this.networkService.getNetworkDetail(contextOrganizationId))
    );
  }
}