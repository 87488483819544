import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class RideauNotificationService {
  constructor(private notify: NzNotificationService) {}

  public success(message: string, messageDescription?: string): void {
    this.notify.success(message, messageDescription ? messageDescription : '', {
      nzClass: 'rideau-notify rideau-notify--success',
    });
  }

  public error(message: string, messageDescription?: string): void {
    this.notify.error(message, messageDescription ? messageDescription : '', {
      nzClass: 'rideau-notify rideau-notify--error',
    });
  }

  public warning(message: string, messageDescription?: string): void {
    this.notify.warning(message, messageDescription ? messageDescription : '', {
      nzClass: 'rideau-notify rideau-notify--warning',
    });
  }
}
