<ng-container *ngIf="isReady">
    <div class="page-template-head organisationPage">
        <div class="container">
            <div nz-row>
                <div nz-col>
                    <div *ngIf="organization.statusId === 2" class="approval-box">
                        <p>{{ 'ORGANIZATION-APPROVAL-STATE' | translate }}</p>
                        <div>
                            <button *appPermission="4" (click)="reject()" nz-button [nzLoading]="isLoading" class="btn btn--default rejected">
                                {{ 'REJETER' | translate }}
                            </button>
                            <button *appPermission="4" (click)="approve()" nz-button [nzLoading]="isLoading" class="btn btn--default approved ml-2">
                                {{ 'APPROUVER' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-row style="display: flex; align-items: center" class="row">
                <div class="col" class="page-title--title">
                    <!-- TITLE BAR -->
                    <h1>{{ organization?.getTranslatedProperty(lang, 'name') }}</h1>
                </div>
                <div class="col">
                    <!-- STATUS -->
                    <span *ngIf="organization.statusId === 2" class="rect">{{ 'EN-ATTENTE-APPROBATION' | translate }}</span>
                    <span *ngIf="organization.statusId === 4" class="rect">{{ 'STATUS.REJETEE' | translate }}</span>
                    <span *ngIf="organization.statusId === 1 || organization.statusId === 4" class="ml-5">
                        <button
                            *appPermission="2; objectOrganizationId: organization.id; shouldBeApproved: false"
                            (click)="deleteOrganization(organization.id)"
                            nz-button
                            [nzLoading]="isLoading"
                            class="btn btn--default"
                        >
                            {{ 'FORM.SUPPRIMER' | translate }}
                        </button>
                    </span>
                </div>

                <div class="col d-flex justify-content-end">
                    <p class="mb-1" à *ngIf="organization.statusId === 3">
                        <a class="btn btn-default" [routerLink]="['/organization/', organization.id] | localize">
                            <i nz-icon nzType="eye" nzTheme="outline" class="mr-2" aria-hidden="true"></i>
                            <span>{{ 'VOIR-LE-PROFIL-PUBLIC' | translate }}</span>
                        </a>
                    </p>
                    <p class="mb-1" *ngIf="organization.statusId !== 3 && totalErrorCount() > 0">
                        <span class="rect">
                            {{ totalErrorCount() }}
                            {{ totalErrorCount() > 1 ? ('CHAMPS-INCOMPLETS' | translate) : ('CHAMP-INCOMPLET' | translate) }}
                            :
                            <a *ngIf="!displayErrors" (click)="toggleDisplayErrors()">
                                {{ 'AFFICHER' | translate }}
                            </a>
                            <a *ngIf="displayErrors" (click)="toggleDisplayErrors()">
                                {{ 'CACHER' | translate }}
                            </a>
                        </span>
                    </p>
                    <div *ngIf="(organization.statusId === 1 || organization.statusId === 4) && totalErrorCount() === 0">
                        <button
                            *appPermission="3; objectOrganizationId: organization.id; shouldBeApproved: false"
                            (click)="submitForApproval()"
                            nz-button
                            [nzLoading]="isLoading"
                            class="btn btn--default"
                        >
                            {{ 'FORM.SOUMETTRE-POUR-APPROBATION' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container pb-5">
        <div nz-row nzGutter="24">
            <!-- MENU -->
            <div nz-col nzMd="6" class="list-filters mb-4 mb-md-0 px-0">
                <sp-menu>
                    <sp-menu-item
                        label="{{ 'COORDONNEES' | translate }}"
                        [routerLink]="['../coordonnees'] | localize"
                        [classList]="['errorCount', 'list-filters__item']"
                        [selected]="openedTab === 'coordonnees'"
                    >
                        <nz-badge *ngIf="displayErrors" [nzCount]="coordonneesErrorCount" [nzStyle]="{ backgroundColor: '#FE544D' }"></nz-badge>
                    </sp-menu-item>
                    <sp-menu-item
                        label="{{ 'FORM.TYPE-ORGANISATION' | translate }}"
                        [routerLink]="['../type'] | localize"
                        [classList]="['errorCount', 'list-filters__item']"
                        [selected]="openedTab === 'type'"
                    >
                        <nz-badge *ngIf="displayErrors" [nzCount]="typeErrorCount" [nzStyle]="{ backgroundColor: '#FE544D' }"></nz-badge>
                    </sp-menu-item>
                    <sp-menu-item
                        label="{{ 'PROFIL' | translate }}"
                        [routerLink]="['../profile'] | localize"
                        [classList]="['errorCount', 'list-filters__item']"
                        [selected]="openedTab === 'profile'"
                    >
                        <nz-badge *ngIf="displayErrors" [nzCount]="profileErrorCount" [nzStyle]="{ backgroundColor: '#FE544D' }"></nz-badge>
                    </sp-menu-item>

                    <!-- Ces onglets ne sont accessibles qu'une fois l'organisation approuvée -->
                    <ng-container *ngIf="organization.statusId === 3">
                        <sp-menu-item
                            label="{{ 'EQUIPE-ADMIN' | translate }}"
                            [routerLink]="['../team'] | localize"
                            [classList]="['list-filters__item']"
                            [selected]="openedTab === 'team'"
                        ></sp-menu-item>

                        <sp-menu-item
                            label="{{ 'PROMOTION' | translate }}"
                            [routerLink]="['../promotion'] | localize"
                            [classList]="['list-filters__item']"
                            [selected]="openedTab === 'promotion'"
                        ></sp-menu-item>

                        <sp-menu-item
                            label="{{ 'SHOW-STEP.CONTACTS' | translate }}"
                            [routerLink]="['../contacts'] | localize"
                            [classList]="['list-filters__item']"
                            [selected]="openedTab === 'contacts'"
                        ></sp-menu-item>

                        <sp-menu-item
                            *ngIf="broadcaster"
                            label="{{ 'PARAMETRE-DIFFUSEUR' | translate }}"
                            [routerLink]="['../broadcaster'] | localize"
                            [classList]="['list-filters__item']"
                            [selected]="openedTab === 'broadcaster'"
                        ></sp-menu-item>

                        <sp-menu-item
                            *ngIf="organization.types.indexOf(3) > -1"
                            label="{{ 'SALLES' | translate }}"
                            [routerLink]="['../venues'] | localize"
                            [classList]="['list-filters__item']"
                            [selected]="openedTab === 'venues'"
                        ></sp-menu-item>

                        <sp-menu-item
                            label="{{ 'FACTURATION' | translate }}"
                            [routerLink]="['../billing'] | localize"
                            [classList]="['list-filters__item']"
                            [selected]="openedTab === 'billing'"
                        ></sp-menu-item>

                        <sp-menu-item
                            *ngIf="organization.isNetworkHead"
                            label="{{ 'ORGANISATIONS-MEMBRES' | translate }}"
                            [routerLink]="['../members'] | localize"
                            [classList]="['list-filters__item']"
                            [selected]="openedTab === 'members'"
                        ></sp-menu-item>

                        <sp-menu-item
                            *ngIf="isRideauMember"
                            label="{{ 'NETWORK-INFOS' | translate }}"
                            [routerLink]="['../network-infos'] | localize"
                            [classList]="['list-filters__item']"
                            [selected]="openedTab === 'network-infos'"
                        ></sp-menu-item>
                    </ng-container>

                    <sp-menu-item
                        *ngIf="isProducteur"
                        label="{{ 'DEPOT' | translate }}"
                        [routerLink]="['../user-depot'] | localize"
                        [classList]="['list-filters__item']"
                        [selected]="openedTab === 'user-depot'"
                    ></sp-menu-item>
                </sp-menu>
            </div>

            <div nz-col nzMd="18">
                <!-- COORDONÉES -->
                <ng-container *ngIf="openedTab == 'coordonnees'">
                    <h2 class="mb-4">{{ 'COORDONNEES' | translate }}</h2>
                    <app-organization-coordinate-form
                        [(organization)]="organization"
                        (submitCallback)="saveCoord($event)"
                        [isLoading]="isLoading"
                        [displayErrors]="displayErrors"
                    ></app-organization-coordinate-form>
                </ng-container>
                <!-- TYPE D'ORGANISATION -->
                <ng-container *ngIf="openedTab == 'type'">
                    <h2>{{ 'FORM.TYPE-ORGANISATION' | translate }}*</h2>
                    <app-organization-type-form
                        [(organization)]="organization"
                        (submitCallback)="saveTypes($event)"
                        [isLoading]="isLoading"
                        [displayErrors]="displayErrors"
                    ></app-organization-type-form>
                </ng-container>

                <!-- PROFILE -->
                <ng-container *ngIf="openedTab == 'profile'">
                    <!--Profile Tab-->
                    <h2>{{ 'PROFIL' | translate }}</h2>
                    <div nz-row>
                        <div nz-col nzMd="24">
                            <app-organization-profile-form
                                [(organization)]="organization"
                                (submitCallback)="saveProfile($event)"
                                [isLoading]="isLoading"
                                [displayErrors]="displayErrors"
                            ></app-organization-profile-form>
                        </div>
                    </div>
                </ng-container>

                <!-- PROMOTION -->
                <ng-container *ngIf="openedTab == 'promotion'">
                    <app-organization-promotion></app-organization-promotion>
                </ng-container>

                <!-- ÉQUIPE ET ADMINISTRATION -->
                <ng-container *ngIf="openedTab == 'team'">
                    <app-organization-team [organizationId]="organizationId"></app-organization-team>
                </ng-container>

                <!-- CONTACTS -->
                <ng-container *ngIf="openedTab == 'contacts'">
                    <app-organization-contacts-form [organizationId]="organizationId"></app-organization-contacts-form>
                </ng-container>

                <!-- PARAMÈTRES DIFFUSEUR -->
                <ng-container *ngIf="openedTab == 'broadcaster'">
                    <!--Broadcaster Tab-->
                    <h2>{{ 'PARAMETRE-DIFFUSEUR' | translate }}</h2>
                    <!-- TODO : MAKE A REUSABLE COMPONENT -->
                </ng-container>

                <!-- SALLES -->
                <ng-container *ngIf="openedTab == 'venues'">
                    <!--Venues Tab-->
                    <app-organization-venues [organizationId]="organizationId"></app-organization-venues>
                </ng-container>

                <!-- ARTISTES -->
                <ng-container *ngIf="openedTab == 'artists'">
                    <!--Artists Tab-->
                    <h2>{{ 'ARTISTES' | translate }}</h2>
                </ng-container>

                <!-- FACTURATION -->
                <ng-container *ngIf="openedTab == 'billing'">
                    <!--Billing Tab-->
                    <app-organization-billing [(organization)]="organization"></app-organization-billing>
                </ng-container>

                <!-- MEMBRES -->
                <ng-container *ngIf="openedTab == 'members'">
                    <!--Billing Tab-->
                    <app-organization-members [organization]="organization"></app-organization-members>
                </ng-container>

                <!-- NETWORK-INFOS -->
                <ng-container *ngIf="openedTab == 'network-infos'">
                    <h2>{{ 'NETWORK-INFOS' | translate }}</h2>
                    <app-organization-network-infos [organization]="organization"></app-organization-network-infos>
                </ng-container>

                <ng-container *ngIf="openedTab === 'user-depot'">
                    <h2>{{ 'DEPOT' | translate }}</h2>
                    <app-organisation-depot [organisationId]="organizationId"></app-organisation-depot>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
