<ng-container *ngIf="isReady">
  <app-page-title title="{{ activity.getTranslatedProperty(lang, 'title') }}">
  </app-page-title>

  <div class="container pb-5">
    <div nz-row
         nzGutter="16">
      <!-- MENU -->
      <div nz-col
           nzMd="6"
           class="mb-4 mb-md-0">
        <div class="list-filters__box">
          <div class="list-filters ">
            <sp-menu>
                <ng-container *ngFor="let route of routes">
                    <sp-menu-item [classList]="route.classList || []" [routerLink]="[route.link] | localize" [label]="route.label | translate"></sp-menu-item>
                </ng-container>
            </sp-menu>
          </div>
        </div>
      </div>
      <div nz-col
           nzMd="18">
        <!-- GENERAL FORM -->
        <ng-container *ngIf="openedTab == 'general'">
          <h2 class="mb-4">{{ "RENSEIGNEMENTS-GENERAUX" | translate }}</h2>
          <app-activity-general-form [activity]="activity"
                                     (saveActivity)="saveActivity($event)"></app-activity-general-form>
        </ng-container>
        <!-- DISPONIBILITY FORM -->
        <ng-container *ngIf="openedTab == 'disponibility'">
          <h2 class="mb-4">{{ "DISPONIBILITE" | translate }}</h2>
          <app-activity-disponibility-form [activity]="activity"
                                           (saveActivity)="saveActivity($event)"></app-activity-disponibility-form>
        </ng-container>
        <!-- PARTICIPANTS FORM -->
        <ng-container *ngIf="openedTab == 'participants'">
          <h2 class="mb-4">{{ "PARTICIPANTS" | translate }}</h2>
          <app-activity-participants-form [activity]="activity"></app-activity-participants-form>
        </ng-container>
        <!-- VITRINES FORM -->
        <ng-container *ngIf="openedTab == 'vitrines'">
          <app-activity-vitrines [activity]="activity"></app-activity-vitrines>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
