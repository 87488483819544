import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ListItem, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Publication } from '../../model/publication.model';
import { PublicationService } from '../../services/publication.service';
import { getRemainingDays } from '../../../../shared/utils/time-utils';
import { ShoppingCartService } from 'src/app/concepts/account/services/shopping-cart.service';

@Component({
  selector: 'app-my-publications',
  templateUrl: './my-publications.component.html',
  styleUrls: ['./my-publications.component.scss'],
})
export class MyPublicationsComponent implements OnInit {
  pubItemArray: ListItem[] = [];

  private publications: Publication[];
  private orgId: number;
  private lang: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
    private publicationService: PublicationService,
    private notification: RideauNotificationService,
    private shoppingCartService: ShoppingCartService
  ) {}

  ngOnInit(): void {
    // TODO : Get my publication
    this.orgId = this.route.snapshot.params['organizationId'];
    this.lang = this.translate.currentLang;
    this.getPublications();
  }

  getPublications() {
    const filters: SelectedFilter[] = [
      {
        field: 'organizationId',
        value: this.orgId,
      } as SelectedFilter,
    ];
    this.publicationService.getPublications(filters).subscribe((res) => {
      this.publications = res;
      this.pubItemArray = this.publications.map((pub) => {
        const item = pub.toListItem(this.lang);
        const itemDayStart = new Date(pub.dateFrom);
        const payedDays = 7 * Number(pub.weeksNumber);
        const itemDayEnd = new Date(itemDayStart);
        itemDayEnd.setDate(itemDayEnd.getDate() + payedDays);
        item.itemDate = getRemainingDays(
          itemDayStart,
          itemDayEnd,
          this.translate
        );
        return item;
      });
    });
  }

  modifier(item: ListItem) {
    this.router.navigate(
      this.localizeRouter.translateRoute([
        '/admin/publication',
        item.itemId,
        'modify',
      ]) as any[]
    );
  }

  supprimer(item: ListItem) {
    this.publicationService.deletePublication(item.itemId).subscribe(() => {
      this.notification.success(
        this.translate.instant('PUBLICATION-SUPPRIMEE')
      );
      this.shoppingCartService.updateCard('removed');
      this.getPublications();
    });
  }

  newPublication() {
    const route = this.localizeRouter.translateRoute('new-publication');
    this.router.navigate([`./${route}`], { relativeTo: this.route });
  }
}
