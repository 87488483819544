<ng-container>
    <div class="instructions">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path
                fill="#1C1C1B"
                fill-rule="nonzero"
                d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321a1 1 0 0 1 1.946.467C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909a1 1 0 0 1 1.947-.458zm11.426-6.097a1 1 0 0 1 .326 1.282l-.064.108-4.776 7a1 1 0 0 1-1.523.154l-.083-.092-2.224-2.77a1 1 0 0 1 1.475-1.344l.085.093 1.378 1.716 4.016-5.885a1 1 0 0 1 1.39-.262zM10 0c4.778 0 8.67 3.168 9.796 7.854a1 1 0 0 1-1.945.467C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367a1 1 0 1 1-1.947-.458C1.3 3.194 5.202 0 10.001 0z"
            />
        </svg>
        <span
            *ngIf="!selected"
            [innerHTML]="(routeData.tourShows.length === 1 ? 'TOURNEE-SELECTIONNER-DATES-1' : 'TOURNEE-SELECTIONNER-DATES') | translate: { count: routeData.tourShows.length }"
        ></span>
        <span
            *ngIf="selected"
            [innerHTML]="(routeData.tourShows.length === 1 ? 'TOURNEE-INDIQUER-DATES-1' : 'TOURNEE-INDIQUER-DATES') | translate: { count: routeData.tourShows.length }"
        ></span>
    </div>
    <sp-select [(ngModel)]="selected" [options]="options" [placeholder]="'TOURNEE-CHOSIR-SPECTACLE' | translate" (ngModelChange)="ngModelChange()" [withClear]="'false'">
        <ng-template let-option>
            <span class="title">{{ option.tourShow.show.getTranslatedProperty(translateService.currentLang, 'title') }}</span>
            <span class="subtext ml-3">{{ (option.count ? (option.count === 1 ? 'DATE-COUNT-1' : 'DATE-COUNT') : 'AUCUNE-DATE') | translate: { count: option.count } }}</span>
        </ng-template>
    </sp-select>
</ng-container>
