import { Globals } from './../../../../_configs/globals';
import { debounceTime } from 'rxjs/operators';
import { AccountService } from './../../../account/services/account.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BillingItem } from '../../model/billing-item.model';
import { NetworkService } from '../../../organization/services/network.service';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';

@Component({
    selector: 'app-membership-billing-list',
    templateUrl: './membership-billing-list.component.html',
    styleUrls: ['./membership-billing-list.component.scss']
})
export class MembershipBillingListComponent implements OnInit {
    searchFormControl = new UntypedFormControl();
    isLoading = false;
    allBillingList: BillingItem[] = [];
    billingList: BillingItem[] = [];
    nbBillsNotSent: number;
    nbBillsNotSendable: number;
    nbItemIndicator = {};
    active: string = 'ALL';
    constructor(private globals: Globals, private networkService: NetworkService, private translate: TranslateService, private notification: RideauNotificationService) {}

    ngOnInit(): void {
        this.getNetworkBills();
        this.searchFormControl.valueChanges.pipe(debounceTime(300)).subscribe((searchVal) => {
            if (searchVal.length > 0) {
                this.filterList(searchVal);
            } else {
                this.filterList();
            }
        });
    }

    getNetworkBills(): void {
        this.isLoading = true;
        this.networkService.getNetworkBills(this.globals.RIDEAU_ORGID).subscribe((res) => {
            // on trie les données pour avoir les factures prètes à envoyer en premier,
            // puis les "données manquantes"
            this.allBillingList = this.billingList = res.sort((a, b) => {
                if (a.isReadyForPay === b.isReadyForPay) {
                    if (a.hasFinanceInfo === b.hasFinanceInfo) {
                        return a.customFee > b.customFee ? -1 : 1;
                    }
                    return b.hasFinanceInfo - a.hasFinanceInfo;
                }
                return a.isReadyForPay - b.isReadyForPay;
            });
            this.setSentBillsIndicators();
            this.isLoading = false;
        });
    }

    public setSentBillsIndicators(): void {
        this.nbBillsNotSent = this.allBillingList.filter((bill) => bill.isReadyForPay === 0).length;
        this.nbBillsNotSendable = this.allBillingList.filter((bill) => bill.hasFinanceInfo === 0 && bill.customFee === null).length;
        this.nbItemIndicator = {
            value: this.nbBillsNotSent - this.nbBillsNotSendable
        };
    }

    filterList(filter?: string): void {
        this.active = filter || 'ALL';
        switch (filter) {
            case 'DATA':
                this.billingList = this.allBillingList.filter((bill) => !bill.hasFinanceInfo);
                break;
            case 'PAYED':
                this.billingList = this.allBillingList.filter((bill) => bill.isPaid);
                break;
            case 'NOT-PAYED':
                this.billingList = this.allBillingList.filter((bill) => bill.isReadyForPay && !bill.isPaid);
                break;
            case null || undefined:
                this.billingList = this.allBillingList;
                break;
            default:
                this.billingList = this.allBillingList.filter((bill) => {
                    const name = bill.getTranslatedProperty(this.translate.currentLang, 'name').toUpperCase();
                    return name.indexOf(filter.toUpperCase()) > -1;
                });
        }
    }

    sendTheBills(): void {
        this.isLoading = true;
        this.networkService.sendTheBills(this.globals.RIDEAU_ORGID).subscribe(() => {
            this.notification.success(this.translate.instant('FACTURES-ENVOYEES'));
            this.getNetworkBills();
        });
    }

    startNewBillingPeriod(): void {
        this.isLoading = true;
        this.networkService.startNewBillingPeriod(this.globals.RIDEAU_ORGID).subscribe(() => {
            this.notification.success(this.translate.instant('NOUVELLE-FACTURATION'));
            this.getNetworkBills();
        });
    }
}
