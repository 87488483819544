import { Location } from '@angular/common';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { Organization } from './../../../../concepts/organization/model/organization.model';
import { BillInfos } from '../model/bill-infos.model';
import { Globals } from 'src/app/_configs/globals';
import { ShoppingCartService } from 'src/app/concepts/account/services/shopping-cart.service';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss'],
})
export class BillComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  organization: Organization;

  @Input()
  user: PxUser;

  _products: any[];

  @Input()
  set products(value: any[]) {
    if (!value) return;
    this._products = value;
    this.productRefund = this._products.map((prod) => ({
      transactionProductId: prod.id,
      amount: null,
    }));
  }

  get products(): any[] {
    return this._products;
  }

  @Input()
  billInfos: BillInfos;

  @Input()
  isRefund = false;

  formatter = new Intl.NumberFormat('fr-CA', { minimumFractionDigits: 2 });

  productRefund: any[] = [];

  refund: BillInfos = new BillInfos();

  isLoading = false;

  constructor(
    @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
    private global: Globals,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private notification: RideauNotificationService,
    private shoppingCartService: ShoppingCartService
  ) {}

  ngOnInit(): void {
  }

  doRefund(): void {
    // TODO: call refund
    this.isLoading = true;

    const refundInfos = {
      transactionId: this.billInfos.numero,
      cart: this.productRefund.filter((refund) => !!refund.amount),
    };

    this.shoppingCartService.refund(refundInfos).subscribe((res) => {
      this.notification.success(this.translate.instant('FACTURE-REMBOURSEE'));

      const transRoute: any[] = this.organization
        ? (this.localizeRouter.translateRoute([
            '/organization/',
            this.organization.id,
            'bill',
            res['transactionId'],
          ]) as any[])
        : (this.localizeRouter.translateRoute([
            '/admin/users/',
            'bill',
            this.user.id,
            res['transactionId'],
          ]) as any[]);
      this.router.navigate([...transRoute]);
    });
  }

  calculateTotal(): void {
    this.refund.subtotal = this.productRefund.reduce(
      (acc: number, curr: any) => acc + curr.amount,
      0
    );

    const taxTps =
      parseInt(
        Math.round(
          this.refund.subtotal * this.global.taxes.TPS * 100
        ).toString()
      ) / 100;
    const taxTvq =
      parseInt(
        Math.round(
          this.refund.subtotal * this.global.taxes.TVQ * 100
        ).toString()
      ) / 100;

    this.refund.taxTps = taxTps.toFixed(2);
    this.refund.taxTvq = taxTvq.toFixed(2);
    this.refund.total = (this.refund.subtotal + taxTps + taxTvq).toFixed(2);
  }

  print(): void {
    window.print();
  }

  back(): void {
    this.location.back();
  }
}
