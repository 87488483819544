import { NgModule } from '@angular/core';
import { AddClassOnClickDirective } from './add-class-on-click.directive';
import { ButtonDirective } from './button/button.directive';
import { IfOwnerOfDirective } from './if-owner-of.directive';
import { IfAdminDirective } from './if-admin.directive';
import { IfAdminOrOwnerOfDirective } from './if-admin-or-owner-of.directive';
import { ClickOutsideElementDirective } from './click-outside-element.directive';
import { AlternativeAssetImageDirective } from './alternative-asset-image.directive';
import { OnlyDecimalDirective } from './only-decimal.directive';
import { PermissionDirective } from './permission.directive';

const list = [
    AddClassOnClickDirective,
    ButtonDirective,
    IfOwnerOfDirective,
    IfAdminDirective,
    IfAdminOrOwnerOfDirective,
    ClickOutsideElementDirective,
    AlternativeAssetImageDirective,
    OnlyDecimalDirective,
    PermissionDirective
];
@NgModule({
    exports: [ ...list ],
    declarations: [ ...list ],
})
export class DirectivesModule { }
