import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { Pagination } from '../../../../shared/model/list-item.model';
import { RowTypes } from '../../../../shared/enums/row-types.enum';
import { ToursService } from '../../services/tours.service';
import { Tour } from '../../model/tour.model';

@Component({
  selector: 'app-listed-tour',
  templateUrl: './listed-tour.component.html',
  styleUrls: ['./listed-tour.component.scss'],
})
export class ListedTourComponent implements OnInit {
  tourList: Tour[];
  itemList: ListItem[] = [];
  pagination: Pagination = new Pagination();
  tourId: number;
  tour: Tour;
  pageTitle: string;
  pageSubTitle: string;
  lang: string;
  isLoading: boolean;
  isReady: boolean;
  listType: RowTypes = RowTypes.PRODUCT;
  tours: Tour[];
  createNewDepositLink: string;

  constructor(
    private router: Router,
    private tourService: ToursService,
    private translate: TranslateService,
    @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
  ) {
    localizeRouter.init();
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.createNewDepositLink = `/admin/tour/add`;

    this.initPagination();
    this.getTour();
  }

  getTours() {
    this.tourService.getAllTours(this.pagination).subscribe((tours: Tour[]) => {
      this.tours = tours;
    });
  }

  getTour(noLoad?: boolean) {
    if (!noLoad) this.isLoading = true;

    this.tourService.getAllTours(this.pagination).subscribe((tours: Tour[]) => {
      this.tourList = tours.map((tour) => new Tour(tour));
      this.itemList = tours.map((tour: Tour) => {
        const tourItem = new Tour(tour);
        const item = tourItem.toListItem(this.lang);
        item.itemUrl = `/admin/tour/${tourItem.id}/edit`;
        return item;
      });

      this.isReady = true;
      if (!noLoad) this.isLoading = false;
    });
  }

  initPagination() {
    this.pagination.limit = 20;
    this.pagination.offset = 0;
    this.pagination.total = 0;
  }

  onPageChange(event: Pagination) {
    this.pagination.offset = event.offset;
    this.getTour();
  }

  createNewTour() {
    const route: any[] = this.localizeRouter.translateRoute([
      '/admin/tour/add',
    ]) as any[];
    this.router.navigate(route);
  }

  modifyTour(tourId: number) {
    const route: any[] = this.localizeRouter.translateRoute([
      `/admin/tour/${tourId}/edit`,
    ]) as any[];
    this.router.navigate(route);
  }
}
