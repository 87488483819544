<app-page-title title="{{ 'LEGAL-TITLE' | translate }}"></app-page-title>

<div class="page-template container my-5">
    <div class="row">
        <div class="col-sm-4">
            <span class="page-template page-template-text page-template-text--smaller">Dermière mise à jour: 1<sup>er</sup> Août 2020</span>
        </div>
        <div class="col-sm-8">
            <h3>VEUILLEZ LIRE ATTENTIVEMENT TOUTES LES CONDITIONS AVANT TOUTE NAVIGATION, INSCRIPTION ET CONTRIBUTION AU SITE</h3>
            <p>Les conditions générales d’utilisation qui suivent s’appliquent à votre utilisation, abonnement, contribution ou recours au site Scène Pro ainsi qu’à tout site opéré par Rideau (le « Site »).</p>
            <p>Les présentes conditions générales d’utilisation et toute autre version révisée de temps à autre par Rideau (les « Conditions d’utilisation ») constituent une entente légale entre Rideau inc. (ci-après « Rideau » ou « nous ») et vous lorsque vous utiliser ou naviguez sur le Site (l’« Utilisateur » ou « vous »).</p>
            <p>Si vous ne souhaitez pas être lié (e) par ce contrat ou si vous n’acceptez pas les Conditions d’utilisation, ne naviguez pas sur le site, ne vous inscrivez pas et n’y téléchargez aucun contenu. Votre seul et unique recours est d’en cesser l’utilisation.</p>
            
            <h3>ACCEPTATION DES CONDITIONS D’UTILISATION</h3>
            <p>En accédant au Site ou en y navigant, vous déclarez avoir lu et compris les Conditions d’utilisation et déclarez être lié(e) par ces conditions. Veuillez noter que nous pouvons modifier les Conditions d’utilisation à tout moment et sans préavis. Votre utilisation continue du Site sera considérée comme votre acceptation des Conditions d’utilisation telles que révisées. En utilisant les services offerts, vous déclarez utiliser les services offerts par Rideau dans le Site (les « Services ») en accord avec le droit applicable au Québec et les Conditions d’utilisation.</p>
            
            <h3>PROPRIÉTÉ INTELLECTUELLE</h3>
            <p>Toute propriété intellectuelle sur le Site, à l’exception du contenu généré par les Utilisateurs tel que défini ci-dessous (le « CGU »), est possédée par nous ou nos concédants et inclut tout élément protégé par droits d’auteur, marque de commerce ou brevet. Toutes les marques déposées sont possédées, enregistrées et/ou sous licence par nous. </p>
            <p>Tout le contenu du Site, à l’exception de tout CGU, y compris, mais sans s’y limiter, le texte, le logiciel, le code, la conception, les graphiques, les photos, les sons, la musique, les vidéos, les applications, les fonctionnalités interactives ainsi que tout autre contenu et leur sélection, leur arrangement et leur composition (le « Contenu de Rideau » et collectivement avec le CGU, le « Contenu du Site »), constitue une œuvre dite de recueil, tel que plus amplement défini par la Loi sur le droit d’auteur (LRC [1985], ch. C-42) et est la propriété de Rideau.</p>
            <p>Le Contenu du Site ou tout contenu reproduit sur le Site par Rideau appartiennent à Rideau ou sont sous licence de tiers et sont protégés par la loi et par le droit d’auteur, et ce, pour le monde entier. </p>
            <p>Sous réserve des dispositions des Conditions d’utilisation relatives à certains utilisateurs, aucune licence ou droit d’utilisation ne vous est conféré ou ne peut être inféré du fait de votre navigation sur le Site. Il vous est interdit de notamment modifier, copier, distribuer, publier, transmettre, diffuser, reproduire, compiler, arranger ou sauvegarder les Contenus du Site et de créer des compilations, incluant en tout ou en partie des Contenus de Rideau ainsi que tout CGU, ou de transférer à quiconque ces contenus de quelque manière que ce soit, incluant par la reproduction et transmission d’un extrait des contenus accompagné ou non d’un hyperlien, sans autorisation écrite de Rideau ou sans que la loi ou les fonctionnalités du Site ne vous le permettent expressément. Il vous est aussi interdit de vendre ou tirer profit de quelque manière que ce soit du Contenu du site, information, logiciel, produit ou service obtenus à partir du Site ou encore de créer des compilations et œuvres dérivées des éléments précités.</p>

            <h3>UTILISATION DU CONTENU DU SITE ET DES SERVICES</h3>
            <p>Nous pouvons vous fournir certaines informations en raison de votre utilisation du Site, y compris, sans s’y limiter, des documents, des données ou des informations développés par nous, ou tout autre élément qui pourrait vous aider dans l’utilisation du Site. Sous réserve des dispositions des Conditions d’utilisation relatives à certains utilisateurs, les Contenus du Site ou les Services ne peuvent être utilisés pour aucun autre objet que l’utilisation du Site et des Services offerts sur le Site. Rien dans les présentes ne peut être interprété comme vous attribuant une licence ou des droits de propriété intellectuelle.</p>
         
            <h3>CONTENU GÉNÉRÉ PAR LES UTILISATEURS</h3>
            <p>Le Contenu généré par les utilisateurs (CGU) inclut les communications, éléments, informations, données, opinions, photos, profils, messages, publicités, notes, hyperliens, informations textuelles, conceptions, graphiques, sons ou tout autre contenu que vous et/ou d’autres Utilisateurs du site publient ou rendent disponibles sur ou à travers le site, à l’exception du contenu qui est notre propriété.</p>
            <p>En tout temps vous déclarez et garantissez détenir l’intégralité des droits requis, notamment les droits de propriété intellectuelle, sur tout CGU et toutes les contributions effectuées sur le Site (« Contribution »). En soumettant votre Contribution, et notamment, mais sans s’y limiter, au moyen de la fonctionnalité Fiche de spectacle, vous acceptez et reconnaissez ainsi accorder, et ce, sans aucune rémunération, une licence non exclusive, transférable et perpétuelle, valide pour tous les médias et supports, connus ou à être inventés, à Rideau ainsi qu’aux utilisateurs autorisés par Rideau, à savoir tout détenteur d’une licence d’utilisation de Scène Pro, une plateforme numérique conçue et exploitée par Rideau (« Scène Pro »). En conséquence, Rideau ainsi que tout détenteur d’une licence d’utilisation de Scène Pro, pourront publiquement ou non, diffuser, télédiffuser, coder, distribuer, reproduire (en tout ou en partie), modifier, présenter, communiquer, adapter, afficher, éditer, publier, traduire et plus généralement utiliser votre Contribution dans le cadre des activités de Rideau et de la promotion de tout engagement, représentation ou activité vous liant à tout tel détenteur d’une licence d’utilisation Scène Pro. En outre, vous renoncez à votre droit moral concernant votre Contribution. Sous réserve des droits accordés précédemment, vous conservez les droits de propriété intellectuelle et d’auteur sur votre Contribution.</p>

            <h3>ARCHIVAGE ET DONNÉES OUVERTES</h3>
            <p>Nous pouvons conserver toute contribution à des fins d’archives et de partage avec des tiers dans le cadre de toute Politique de données ouvertes que nous pourrions adopter.  Toutefois, et conformément à notre Politique relative à la protection de la vie privée et à la confidentialité, nous ne conservons vos Renseignements personnels que pour les fins y prévues.</p>

            <h3>COMPTE D’UTILISATEUR ET UTILISATION DU COMPTE</h3>
            <p>Si votre utilisation du Site requiert un compte vous identifiant comme utilisateur du site (un « Compte d’utilisateur ») :</p>
            <ol type="a">
                <li>vous êtes le seul responsable de votre Compte d’utilisateur, du maintien, de la confidentialité et de la sécurité de votre Compte d’utilisateur et de tous les mots de passe reliés à votre Compte d’utilisateur ainsi que de l’activité de toute personne qui a accès à votre Compte utilisateur avec ou sans votre permission ;</li>
                <li>vous acceptez de nous signaler immédiatement toute utilisation non autorisée de votre Compte d’utilisateur, de services à travers votre Compte d’utilisateur, d’un mot de passe relié à votre Compte d’utilisateur ou de toute autre atteinte à la sécurité de votre Compte d’utilisateur ou de service fourni à travers votre Compte d’utilisateur, et vous acceptez de nous venir en aide, tel que pourrons vous le demander, pour arrêter ou remédier à toute atteinte à la sécurité reliée à votre compte ; et</li>
                <li>vous acceptez de fournir des informations d’utilisateur véridiques, exactes et actuelles, tel que nous pouvons le demander de temps à autre, et vous acceptez de nous signaler tout changement à vos informations d’utilisateur tel que requis afin que les informations que nous détenons soient véridiques, exactes et actuelles.</li>
            </ol>

            <h3>RÉSILIATION DU COMPTE D’UTILISATEUR</h3>
            <p>Nous pouvons suspendre, limiter ou résilier votre Compte d’utilisateur et votre utilisation du Site à notre seule discrétion, à tout moment, sans préavis et pour quelque raison que ce soit, incluant le fonctionnement ou l’efficacité du site ou d’équipement ou de réseau nous appartenant ou appartenant à un tiers qui est perturbé par votre utilisation ou votre abus du Site ou si vous avez été ou êtes actuellement en violation des termes des Conditions d’utilisation. Nous n’aurons aucune responsabilité vis-à-vis des tiers, incluant un fournisseur tiers pour toute suspension, limite ou résiliation de votre accès au site.</p>

            <h3>PAIEMENT</h3>
            <p>Lorsque vous effectuez un achat sur le Site, vous acceptez de fournir un instrument de paiement valide. Faites attention aux détails de la transaction puisque le prix total peut inclure taxes et autres frais desquels vous êtes responsables.</p>
            <p>Lorsque vous nous fournissez un instrument de paiement, vous déclarez avoir la permission d’utiliser cet instrument de paiement. Lorsque vous effectuez un paiement, vous nous autorisez, ainsi que le service de paiement que nous utilisons, à charger le montant total sur l’instrument de paiement que vous avez désigné pour la transaction. Vous nous autorisez également à recueillir et enregistrer cet instrument de paiement ainsi que toute autre information reliée à la transaction.</p>
            <p>Si vous payez par carte de débit ou de crédit, nous pouvons obtenir de l’émetteur de la carte une préautorisation d’un montant allant jusqu’au montant total de la transaction. Si vous choisissez d’annuler une transaction avant qu’elle soit complétée, les fonds utilisés pour la préautorisation peuvent ne pas être disponibles immédiatement sur votre compte.</p>
            <p>Nous nous réservons le droit d’annuler toute transaction si nous sommes d’avis que la transaction enfreint les présentes Conditions d’utilisation ou si nous sommes d’avis qu’en agissant ainsi nous pouvons prévenir une perte financière.</p>
            <p>Afin de prévenir toute perte financière, nous pouvons contacter l’émetteur de votre instrument de paiement, les autorités policières ou toute tierce partie atteinte (incluant d’autres utilisateurs) et pouvons partager les détails de tout paiement auquel vous êtes associé(e) si nous sommes d’avis qu’une telle action puisse prévenir une perte financière ou une infraction à la loi.</p>
            <p>Le paiement pour tout service en cours sera facturé automatiquement jusqu’à ce que vous nous avisiez que vous souhaitez mettre fin aux services.</p>
            
            <h3>UTILISATION ACCEPTABLE DU SITE</h3>
            <p>Vous acceptez de ne pas utiliser le Site pour des fins illicites ou toute autre fin interdite en vertu des Conditions d’utilisation. Vous acceptez de ne pas utiliser le Site de quelconque façon qui pourrait nuire au Site, aux services ou à l’activité commerciale de Rideau.</p>
            <p>Vous acceptez également de ne pas utiliser le Site pour les fins suivantes :</p>
            <ol type="a">
                <li>harceler, abuser ou menacer autrui ou autrement violer les droits d’une personne ;</li>
                <li>violer la propriété intellectuelle de Rideau ou d’autrui ;</li>
                <li>télécharger ou transmettre des virus informatiques ou tout autre logiciel qui pourrait endommager la propriété de Rideau ou d’autrui ;</li>
                <li>commettre une fraude ;</li>
                <li>créer des activités de jeu, de la loterie ou un système pyramidal illicite ou y participer ;</li>
                <li>publier ou distribuer du matériel obscène ou diffamatoire ;</li>
                <li>publier ou distribuer tout matériel qui incite è la violence, à la haine, ou à la discrimination de quelque groupe que ce soit ; et/ou</li>
                <li>recueillir illicitement des informations sur autrui.</li>
            </ol>

            <h3>PROTECTION DE LA VIE PRIVÉE</h3>
            <p>En utilisant le Site, il est possible que vous nous fournissiez certaines informations. En utilisant le Site, vous nous autorisez à recueillir des renseignements personnels vous concernant ou des données techniques et à utiliser ses informations au Canada et dans tout pays dans lequel nous pourrions opérer.</p>
            <p>Lorsque vous vous inscrivez pour un Compte d’utilisateur, vous nous fournissez une adresse courriel valide et vous pourriez également nous fournir certaines informations additionnelles, telles que votre nom et/ou vos informations de facturation. Selon l’utilisation que vous faites du Site, nous pouvons également recevoir de l’information d’applications externes que vous utilisez pour accéder à au Site ou nous pouvons recevoir de l’information sur vous par diverses technologies du web telles que les cookies, les historiques, les pixels invisibles (aussi appelés « GIF invisibles »), les balises et autres.</p>
            <p>Nous utilisons l’information recueillie auprès de vous pour vous assurer une bonne expérience sur le site. Nous pouvons aussi tracer une partie de l’information passive reçue pour améliorer notre marketing et analytique et, pour ce faire, il se peut que nous travaillions avec des fournisseurs tiers.</p>
            <p>Si vous désirez bloquer notre accès à toute information passive que nous recevons de l’utilisation de diverses technologies, vous pouvez choisir d’inactiver les cookies dans votre navigateur web. Sachez que nous recevrons tout de même les informations vous concernant que vous nous fournirez, tel que votre adresse courriel.</p>
            <p>Si vous choisissez de résilier votre compte, nous entreposerons et retiendrons vos informations pendant la durée de temps suivante: six (6) mois. Après ce temps, toute information à votre sujet sera effacée.</p>
            <p>Pour de plus amples renseignements à l’égard de la cueillette, de l’utilisation et de la conservation des renseignements personnels et des données techniques, veuillez consulter la Politique relative à la protection de la vie privée et à la confidentialité de Rideau à laquelle l’Utilisateur doit consentir pour accéder au service.</p>

            <h3>INGÉNIERIE INVERSE ET SÉCURITÉ</h3>
            <p>Vous ne pouvez entreprendre aucune des actions qui suivent :</p>
            <ol type="a">
                <li>utiliser l’ingénierie inverse ou désassembler tout code ou logiciel sur ou de ce Site ;</li>
                <li>violer ou tenter de violer la sécurité du Site par tout accès non autorisé, le contournement du cryptage ou de tout autre outil de sécurité, l’exploitation des données ou l’interférence avec tout hôte, utilisateur ou réseau.</li>
            </ol>

            <h3>PERTES DE DONNÉES</h3>
            <p>Rideau n’est pas responsables de la sécurité de votre Compte d’utilisateur ou du contenu de votre compte non plus que de toute Contribution. L’utilisation du site est à vos risques et périls.</p>

            <h3>POLITIQUE CONCERNANT LES POURRIELS</h3>
            <p>Il vous est strictement interdit d’utiliser le Site ou tout Service à des fins d’activités illégales reliées aux pourriels, incluant la collecte d’adresses et d’informations personnelles d’autrui ou l’envoi de courriels commerciaux de masse.</p>

            <h3>INTERRUPTIONS DE SERVICE</h3>
            <p>Il se peut que nous ayons à interrompre votre accès au Site afin d’effectuer des travaux de maintenance ou des travaux d’urgence non planifiés. Vous convenez que votre accès au Site peut être affecté par une indisponibilité non planifiée ou non anticipée, pour quelque raison que ce soit, et que nous ne serons en aucun cas tenus responsables de dommages ou de pertes découlant de cette indisponibilité.</p>

            <h3>LIENS ET CONTENU DE TIERS</h3>
            <p>Des liens menant à des sites ou services de tiers peuvent être publiés sur le Site ou dans le cadre des Services. Nous ne sommes aucunement responsables de dommages ou pertes liés à l’utilisation des services de tiers dont le lien se trouve sur notre site.</p>

            <h3>CONFIDENTIALITÉ</h3>
            <p>Les communications via Internet sont sujettes à interception, perte ou altération, par conséquent, vous reconnaissez que les renseignements ou éléments que vous fournissez par voie électronique du fait que vous accédez à ce site ou en faites usage ne sont ni confidentiels ni exclusifs, sauf dans la mesure requise par les lois applicables et que les communications par courrier électronique non protégées sur Internet peuvent être interceptées, altérées ou se perdre.</p>

            <h3>EXONÉRATION DE GARANTIE ET LIMITATION DE RESPONSABILITÉ</h3>
            <p>Dans la pleine mesure permise par le droit applicable, Rideau ne donne aucune garantie et ne fait aucune déclaration à l’Utilisateur ou à quiconque quant au Site et aux Services, notamment que le Site et les Services répondront à ses besoins ou qu’ils seront compatibles avec du matériel ou un logiciel fourni par des tiers, que le Site et les Services ne seront pas interrompus, qu’ils seront exempts de problèmes ou d’erreurs, de virus ou de composantes nuisibles ou que toutes les erreurs seront corrigées. Il n’est pas non plus possible de garantir que le Site et les Services ainsi que les serveurs qui les rendent disponibles en sont exempts. Rideau fournit le Site et le Service « tel quel » et « tel que disponible » sans déclaration, garantie ou condition de quelque nature que ce soit, expresse ou implicite.</p>
            <p>Si vous décidez de vous abonner à des services ou à des fonctions du Site qui nécessitent un abonnement, vous acceptez de fournir des renseignements exacts et à jour à votre sujet comme l’exige le processus d’inscription ou d’abonnement pertinent, et de vous assurer de leur exactitude en effectuant les mises à jour nécessaires dès que possible. Vous acceptez d’assurer la confidentialité de tous les mots de passe ou autres identificateurs de compte que vous aurez choisis ou qui vous seront attribués au moment d’une inscription ou d’un abonnement sur Rideau ou ses partenaires et d’assumer la responsabilité à l’égard de toutes les activités reliées à l’utilisation de ces mots de passe ou de ces comptes. De plus, vous acceptez de nous prévenir de toute utilisation non autorisée de votre mot de passe ou de votre Compte utilisateur. Nous ne pouvons aucunement être tenus responsables, directement ou indirectement, des pertes ou dommages de quelque nature que ce soit découlant du défaut de vous conformer à la présente disposition ou liés à un tel défaut.</p>
            <p>Vous reconnaissez que nous pouvons, à notre seule et absolue discrétion, et ce, sans préavis, suspendre, annuler ou mettre fin à votre Compte utilisateur, à votre utilisation ou à votre accès au Site ou à un des Services, et retirer et supprimer toute Contribution ainsi que tout renseignement ou contenu se rapportant au Site ou à un des Services (et mettre fin à l’utilisation que vous en faites), pour quelque motif que ce soit, y compris si nous croyons que vous avez violé les Conditions d’utilisation. En outre, vous reconnaissez que nous ne serons aucunement responsables envers vous ou envers quiconque à la suite d’une telle suspension, annulation ou fin. Si vous êtes insatisfait de Rideau, du Site, ou d’un des Services, ou d’une des Conditions d’utilisation ou de toutes règles, politiques, lignes directrices ou pratiques relativement à l’exploitation de Rideau, du Site ou d’un des Services, votre seul recours consiste à cesser d’utiliser le Site ou tout tel Service.</p>
            <p>Dans la pleine mesure permise par le droit applicable, les garanties et recours de Rideau (le cas échéant) qui sont expressément prévus aux présentes sont exclusifs et ils remplacent toutes les autres garanties, expresses ou implicites, dans les faits ou par opération de la loi, les devoirs, les déclarations verbales ou écrites ou autrement, notamment les garanties implicites quant à la qualité marchande, la disponibilité, le rendement, la compatibilité, l’adaptation à une fin particulière, la qualité satisfaisante, le respect de la description et l’absence de contrefaçon, lesquelles sont toutes expressément exonérées. </p>
            <p>Dans la pleine mesure permise par le droit applicable, en aucun cas Rideau, ses administrateurs, dirigeants, employés, agents, mandataires, fournisseurs, sous-traitants ou représentants ne sauraient être tenus responsables, que ce soit fondé sur un contrat ou un délit (y compris la négligence) ou la responsabilité stricte, des dommages-intérêts incidents, indirects, conséquents, spéciaux ou punitifs de quelque nature que ce soit, ou des pertes matérielles ou de revenu ou de profits, des pertes d’occasions d’affaires ou d’achalandage, des pertes ou de la corruption de renseignements de données ou d’un accès non autorisé à ceux-ci ou d’une divulgation des renseignements ou des données ou d’autres pertes financières qui découlent de l’utilisation, de l’exécution, du défaut ou de l’interruption du Site ou des Services ou liés à ceux-ci, que ce soit prévisible ou non, et ce, même si rideau avait été avisée de la possibilité de tels dommages. Si Rideau est tenu de payer à l’Utilisateur des dommages-intérêts, l’obligation cumulative totale de Rideau envers l’Utilisateur aux termes de la présente entente ne saurait excéder 100 CAD. Les limitations de la responsabilité susmentionnées ne sauraient être touchées si un recours prévu aux présentes ne répond pas à son objet essentiel.</p>

            <h3>INDEMNISATION</h3>
            <p>Rideau n’est pas responsable des actions et des Contributions que vous générez et celles de tiers sur le Site ou en lien avec le Site. Vous acceptez que vos Contributions soient sous votre seule et unique responsabilité et reconnaissez les avoir effectuées en connaissance de cause. Rideau ne pourrait être considéré comme responsable de toute transgression aux Conditions d’utilisation que vous, un tiers ou faites avec un tiers. Rideau, ses administrateurs, dirigeants, employés, agents, mandataires, fournisseurs, sous-traitants ou représentants, n’endossent aucunement les propos, contenus, intentions ou jugements de vous ou de tiers. En conséquence, convenez de défendre, dédommager et de tenir indemne Rideau, ses administrateurs, dirigeants, employés, agents, mandataires, fournisseurs, sous-traitants ou représentants contre toute réclamation, poursuite ou demande, incluant les frais d’avocats, qui pourrait découler de ou qui se rapporte à votre utilisation ou votre abus du Site, votre violation des présentes ou votre conduite et vos actions. Si nous choisissons de le faire, nous choisirons notre avocat et participerons à notre propre défense.</p>

            <h3>FORCE MAJEURE</h3>
            <p>Rideau n’est pas responsable, notamment, du retard ou de l’inexécution, de l’interruption, de la suspension, réduction ou dérangements, en tout ou en partie, de l’une quelconque de ses obligations, ni des dommages ou pertes que vous pourriez subir, si le retard, l’inexécution, l’interruption, la suspension, la réduction, les dérangements ou les dommages ou les pertes résultent d’un cas de force majeure ou d’une circonstance hors de son contrôle.</p>

            <h3>DISPOSITIONS GÉNÉRALES</h3>
            <p>La présente entente est régie par et interprétée conformément aux lois de la province de Québec (Canada) et les lois du Canada applicables aux contrats intervenus entre les résidents du Québec et exécutés par ceux-ci. Par les présentes, les parties se soumettent irrévocablement à la compétence des tribunaux du district de Montréal, dans la province de Québec. </p>
            <p>Les Conditions d’utilisation constituent l’intégralité de l’entente intervenue entre Rideau et l’Utilisateur relativement au Site et au Service, et la présente entente remplace toute entente antérieure entre Rideau et l’Utilisateur relativement au Site et au Service. </p>
            <p>L’Utilisateur s’engage à ne pas céder ou autrement transférer la présente entente ou les droits ou obligations prévus aux termes de celle-ci à un tiers sans avoir obtenu au préalable le consentement écrit de Rideau, lequel consentement est à l’entière discrétion de Rideau. Aucune cession ou délégation effectuée par l’Utilisateur ne saurait le décharger ou le libérer de ses obligations aux termes de la présente entente ou de toute loi ou réglementation. Sous réserve de ce qui précède, la présente entente a force exécutoire sur chacune des parties et leurs successeurs et ayants droits respectifs, et elle s’applique au profit de tous ceux-ci et peut être exécutée par ceux-ci. Rideau peut unilatéralement céder la présente entente à un tiers. </p>
            <p>Aucune disposition de la présente entente ne saurait constituer une société de personnes ou une coentreprise entre l’utilisateur et Rideau.</p>
            <p>Si une disposition en particulier de la présente entente est déclarée invalide par un tribunal compétent, la disposition sera réputée exclue de la présente entente et n’aura aucune incidence sur la validité de la présente entente dans son ensemble.</p>
            <p>Les Conditions d’utilisation sont susceptibles d’être modifiées par Rideau à tout moment, notamment afin de refléter les modifications apportées au Site ou aux Services. En conséquence de quoi, Rideau pourra ajouter, modifier ou retirer des Conditions d’utilisation, des éléments et des fonctionnalités du Site ou des Services. Dans pareils cas, ces modifications entreront en vigueur dès publication. En conséquence, vous devez vérifier régulièrement les Conditions d’utilisation et vous engager à arrêter immédiatement toute utilisation et navigation du Site ou utilisation des Services si vous veniez à être en désaccord avec celles-ci. Le fait de continuer à utiliser le Site après des modifications aux Conditions d’utilisation emporte et confirme votre acceptation de l’intégralité des Conditions d’utilisation incluant toutes modifications ultérieures que Rideau pourrait, ponctuellement, y apporter.</p>

            <h3>QUESTIONS ET INFORMATIONS ADDITIONNELLES</h3>
            <p>Pour toute question ou pour obtenir de plus amples renseignements, nous vous prions de communiquer avec nous à l’adresse suivante: <a class="page-template page-template-link" href="mailto:support@scenepro.ca">support&#64;scenepro.ca</a>.</p>

        </div>
    </div>
</div>