import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map, share } from 'rxjs/operators';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { Role } from 'src/app/shared/enums/roles.enum';
import { Globals } from '../../../_configs/globals';
import { PermissionPerRole, UserMembership } from '../model/membership.model';
import { OrgRoles, Permission } from './../../../shared/enums/roles.enum';
import { MainService } from './../../../shared/services/main.services';
import { OrganisationStatus } from './../../organization/enums/organization-status.enum';
import { PxUser } from '../model/project-x-user';
import { SelectedFilter, Pagination } from 'src/app/shared/model/list-item.model';
import { OrganizationService } from '../../organization/services/organization.service';
import { OrganizationManagerService } from '../../organization/services/organizations-manager.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthorizationService } from './auth/authorization.service';
export interface CurrentCtxOrganization {
    userID: number;
    orgaId: string;
}
@Injectable({
    providedIn: 'root'
})
export class AccountService extends MainService {
    private userID: any = null;
    // notificationCount
    public notificationCount: number = null;
    // Organisation de contexte courante
    private currentCtxOrganizationId: number = this.globals.NO_ORGA;
    // Tableau des Organisations dont l'utilisateur est membre
    private userOrganizationsCache: Organization[] = [];
    // Tableau des rôles pour les organisations dont l'utilisateur est membre
    private userMembershipsSubject: BehaviorSubject<UserMembership[]> = new BehaviorSubject([]);
    // Tableau des permissions pour chaque rôle
    private permissionPerRoleCache: PermissionPerRole[] = [];
    // observable pour ne pas avoir plusieur fois la même requête en même temps
    private userOrganizationObservable: Observable<Organization[]>;
    private selectedUserOrganization$: Observable<Organization> = this.organizationManager.selectedUserOrganization$;
    public currentOrganizationChange: EventEmitter<any> = new EventEmitter<any>();

    public get isScenePro(): boolean {
        return this.getCurrentCtxOrganizationId() !== this.globals.SCENE_PRO_ORGID;
    }

    constructor(
        protected httpClient: HttpClient,
        protected globals: Globals,
        private authService: AuthService,
        private organizationManager: OrganizationManagerService,
        private authorizationService: AuthorizationService
    ) {
        super(httpClient, globals);

        this.authService.userObservable$.subscribe((user) => {
            if (user) {
                this.setUserID(user.id);
            }
        });
    }

    public setUserID(userId: any): void {
        const usrId = Number(userId);
        if (usrId && !Number.isNaN(userId)) {
            this.userID = userId;
        }
    }
    public getUserId(): number {
        return this.userID;
    }

    public populateUserIdAsync(): Observable<any> {
        if (this.userID) {
            return of({});
        } else {
            return of(this.authService.User).do((usr) => {
                this.setUserID(usr.id);
            });
        }
    }

    getAllUsers(selectedFilters?: SelectedFilter[], pagination?: Pagination, search?: any): Observable<PxUser[]> {
        let endpoint = this.uri + this.globals.endpoints.user.users;
        if (selectedFilters || pagination) {
            endpoint += '?';
        }

        if (selectedFilters && selectedFilters.length) {
            endpoint += this.formatGetFilters(selectedFilters);
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }
        if (search) {
            endpoint += '&search=' + encodeURIComponent(search);
        }

        return this.httpClient.get(endpoint).map((data) => {
            if (pagination) pagination.total = data[ 'total' ];
            return data[ 'users' ];
        });
    }

    getUser(userId: number): Observable<PxUser> {
        const endpoint = this.uri + this.globals.endpoints.user.getUserEndpoint(userId);
        return this.httpClient.get<PxUser>(endpoint);
    }

    acceptLegalTerms(userId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.user.main;
        const body = { id: userId, hasAcceptedLegal: 1 };
        return this.httpClient.put(endpoint, body);
    }

    uploadAccountAvatar(avatar): Observable<any> {
        const endpoint = this.uri + 'upload/profileImage';
        return this.httpClient.post(endpoint, avatar);
    }

    updateAccount(account: UpdateAccountBody): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.user.main;
        return this.httpClient.put(endpoint, this.cleanUpNullValues(account));
    }

    updatePassword(userId: number, data: { oldPassword?: string; newPassword: string }) {
        return this.httpClient.patch(this.uri + this.globals.endpoints.user.getChangePasswordEndpoint(userId), data);
    }

    addUserCreditCard(userId): Observable<any> {
        //TODO *RIDEAU-123*
        const endpoint = this.uri + this.globals.endpoints.user;
        return this.httpClient.post(endpoint, userId);
    }

    setCurrentCtxOrganizationId(id: number): void {
        // On sauvegarde l'organisation de contexte en localStorage
        const ctx: CurrentCtxOrganization = {
            userID: this.userID,
            orgaId: id.toString()
        };
        localStorage.setItem('currentCtxOrganizationId', JSON.stringify(ctx));
        this.currentCtxOrganizationId = Number(id);
        this.organizationManager.selectUserOrganizationById(id);
        this.currentOrganizationChange.emit();
    }

    getCurrentCtxOrganizationId(): number {
        // si pas defini, on cherche dans localStorage
        if (this.currentCtxOrganizationId === this.globals.NO_ORGA) {
            const storedCurrentCtxOrganizationId: CurrentCtxOrganization = JSON.parse(localStorage.getItem('currentCtxOrganizationId'));
            if (storedCurrentCtxOrganizationId && Number(storedCurrentCtxOrganizationId[ 'userID' ]) === Number(this.userID)) {
                this.currentCtxOrganizationId = Number(storedCurrentCtxOrganizationId[ 'orgaId' ]);
            }
            // si toujours pas défini on laisse à -1
        }
        return this.currentCtxOrganizationId;
    }

    getUserOrganizations(userId?: string): Observable<Organization[]> {
        if (userId) {
            return this.getOrganizations4User(userId);
        }
        const endpoint = this.uri + this.globals.endpoints.user.getUserOrganizationEndpoint(this.userID);
        if (this.userOrganizationsCache.length < 1) {
            // observable pour ne pas avoir plusieur fois la même requête en même temps
            if (!this.userOrganizationObservable) {
                this.userOrganizationObservable = this.httpClient.get(endpoint).pipe(
                    map((data) => {
                        this.notificationCount = data[ 'notificationCount' ];
                        this.userOrganizationsCache = data[ 'organizations' ].map((orga: Organization) => new Organization(orga));
                        const currentOrganization = this.userOrganizationsCache.find((organization) => organization.id === this.getCurrentCtxOrganizationId());
                        this.organizationManager.setUserOrganizations(this.userOrganizationsCache);
                        this.organizationManager.selectUserOrganization(currentOrganization);
                        return this.userOrganizationsCache;
                    }),
                    share() // Pour que tous les subscribers partagent le même observable.
                );
            }
            return this.userOrganizationObservable;
        } else {
            return of(this.userOrganizationsCache);
        }
    }

    getOrganizations4User(userId: string): Observable<Organization[]> {
        const endpoint = this.uri + this.globals.endpoints.user.getUserOrganizationEndpoint(userId);
        return this.httpClient.get(endpoint).map((data) => {
            this.notificationCount = data[ 'notificationCount' ];
            return data[ 'organizations' ].map((orga) => new Organization(orga));
        });
    }

    getUserMemberShips(organizationId?: number): Observable<UserMembership[] | UserMembership> {
        if (organizationId) {
            const index = this.userMembershipsSubject.value.findIndex((membership) => membership.organizationId === organizationId);
            if (index > 0) {
                return of(this.userMembershipsSubject.value[ index ]);
            } else {
                return this.authorizationService.requestUserMemberships().pipe(
                    map((data) => {
                        const memberships = data[ 'memberships' ].map((member) => new UserMembership(member));
                        this.userMembershipsSubject.next(memberships);
                        return memberships.find((m: UserMembership) => m.organizationId === organizationId);
                    })
                );
            }
        }
        return this.authorizationService.requestUserMemberships().pipe(
            map((data) => {
                const memberships = data[ 'memberships' ].map((member) => new UserMembership(member));
                this.userMembershipsSubject.next(memberships);
                return memberships;
            })
        );
    }

    getPermissionsPerRoles(): Observable<PermissionPerRole[]> {
        const endpoint = this.uri + this.globals.endpoints.user.permissions;
        if (this.permissionPerRoleCache.length < 1) {
            return this.httpClient.get(endpoint).pipe(
                map((data: PermissionPerRole[]) => {
                    this.permissionPerRoleCache = data.map((perm) => new PermissionPerRole(perm));
                    return this.permissionPerRoleCache;
                })
            );
        } else {
            return of(this.permissionPerRoleCache);
        }
    }

    getPermissionsForRole(roleId: Role): Observable<Permission[]> {
        return this.getPermissionsPerRoles().pipe(
            map((permsPerRole: PermissionPerRole[]) => {
                return permsPerRole.find((elt) => elt.roleId === roleId).permissions;
            })
        );
    }
    /**
     * Vérifie que l'utilisateur dispose de la Permission passé en paramètre, via son role
     * au sein de l'organisation passée en paramète.
     * @param action : Permission à vérifier
     * @param orgaId : Organisation sur laquele vérifier
     */
    hasPermission(action: Permission, orgaId?: number): Observable<boolean> {
        // On prends l'organisation de contexte si aucune organisation n'est fournie
        orgaId = orgaId || this.getCurrentCtxOrganizationId();

        return this.getUserMemberShips(orgaId).mergeMap((membership: UserMembership) => {
            // si on n'est pas membre de l'organisation, pas de permission
            if (!membership) {
                return of(false);
            }
            // SI Scene_Pro permission always true
            if (orgaId === this.globals.SCENE_PRO_ORGID) {
                return of(true);
            } else {
                // Sinon of vérifie que l'action demandé est dans la list des permissions associées au rôle
                return this.getPermissionsForRole(membership.roleId).map((perms: Permission[]) => {
                    return perms.indexOf(action) > -1;
                });
            }
        });
    }

    /**
     * Vérifie que l'utilisateur dispose du role passé en param sur l'orga passée en param
     * @param role
     * @param orgaId
     */
    hasRole(role: Role | OrgRoles, orgaId?: number): Observable<boolean> {
        // On prends l'organisation de contexte si aucune organisation n'est fournie
        orgaId = orgaId || this.getCurrentCtxOrganizationId();
        return this.getUserMemberShips().map((memberships: UserMembership[]) => {
            const currentOrgMembership = memberships.find((mem) => mem.organizationId === orgaId);
            return currentOrgMembership && currentOrgMembership.roleId === role;
        });
    }

    /**
     * Vérifie si l'organisation courante a le type passé en paraméte (Producteur, Difuseur, Gestionnaire de sale ...)
     * @param type
     */
    doesCurrentOrgaHasType(type: OrganizationTypes): Observable<boolean> {
        return this.getUserOrganizations().map((orgas: Organization[]) => {
            const currentOrga = orgas.find((orga) => orga.id === this.getCurrentCtxOrganizationId());
            if (currentOrga) {
                if (currentOrga.id === this.globals.SCENE_PRO_ORGID) {
                    return true;
                }
                return currentOrga.types.indexOf(type) > -1;
            } else {
                return false;
            }
        });
    }

    /**
     * Indique si l'utilisateur est membre d'une organisation donnée
     * @param orgId
     */
    async isUserMemberOfOrganisation(orgId: number) {
        this.getUserMemberShips().subscribe((list: UserMembership[]) => list.filter((x) => x.organizationId == orgId).length > 0);
    }

    /**
     * Returns an observable that emits a boolean indicating whether the current organization is approved.
     * @returns {Observable<boolean>} Observable that emits a boolean indicating whether the current organization is approved.
     */
    isCurrentOrgaApprouved(): Observable<boolean> {
        return this.getUserOrganizations().pipe(
            map((orgas: Organization[]) => {
                const currentOrga = orgas.find((orga) => orga.id === this.getCurrentCtxOrganizationId());
                if (!currentOrga) {
                    return false;
                }
                return currentOrga.statusId === OrganisationStatus.APPROUVE;
            }),
            share()
        );
    }

    /**
     * Vérifie que l'utilisateur courant, dans son organisation courante, dispose du droit d'accès
     * correspondant aux paramètres.
     * @param appPermission Permission à vérifier
     * @param objectOrganizationId Organisation de l'objet sur lequel on cherche à verifier la permission (ex: show.organizationId)
     * @param organizationType Producteur, Diffuseur, Gestionaiere de salles...
     */
    checkAccess(appPermission: Permission, objectOrganizationId: number, organizationType: OrganizationTypes, shouldBeApproved: boolean): Observable<boolean> {
        const isPermissionUndefined = appPermission === undefined;
        const isObjectOrgaIdDifferentFromCtxId =
            appPermission !== undefined &&
            objectOrganizationId !== undefined &&
            this.getCurrentCtxOrganizationId() !== this.globals.SCENE_PRO_ORGID && // admin Scene_Pro
            objectOrganizationId !== this.getCurrentCtxOrganizationId();
        const isOrganizationTypeUndefined = organizationType === undefined;
        return this.isCurrentOrgaApprouved().mergeMap((isApproved: boolean) => {
            // Si L'organisation courante doit être approuvé, mais qu'elle ne l'est pas --> False;
            if (shouldBeApproved && !isApproved) {
                return of(false);
            }
            // Si pas de permission passée en param, on a l'acces
            if (isPermissionUndefined) {
                return of(true);
            } else if (isObjectOrgaIdDifferentFromCtxId) {
                // Si l'organizationId de l'objet est défini et est différent de l'organizationId du contexte
                // on n'a pas accès.
                return of(false);
            } else if (!isOrganizationTypeUndefined) {
                // Si _organizationType est défini, on vérifie que l'organisation courante a le bon type.
                return this.doesCurrentOrgaHasType(organizationType).mergeMap((res: boolean) => {
                    if (res) {
                        // Si oui, on verifie les permissions.
                        return this.hasPermission(appPermission);
                    } else {
                        // Si non, on n'a pas accès
                        return of(false);
                    }
                });
            } else {
                // Si on a seulement appPermission, ou si objectOrganizationId === ctxOrganizationId,
                // on check les permissions.
                return this.hasPermission(appPermission, this.getCurrentCtxOrganizationId());
            }
        });
    }

    closeFirstLoginNotification(): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.user.closeNotif;
        return this.httpClient.put(endpoint, {});
    }

    clearUserOrgaCache(): void {
        this.userOrganizationsCache = [];
    }

    clearUserMemberShipCache(): void {
        this.userMembershipsSubject.next([]);
    }


    public get isProducer(): boolean {
        return !!JSON.parse(localStorage.getItem('isProducer'));
    }
}
export interface UpdateAccountBody {
    id: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    phonePostNumber?: string;
    isReachable?: number;
    avatar?: string;
    isClosedFirstNotif?: number;
    isEmailChanged: 0 | 1;
    password?: string;
}
