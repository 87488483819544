import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    setItem<T>(key: string, value: T): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    getItem<T>(key: string): T | null {
        const storedValue = sessionStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : null;
    }

    removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    setItem<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getItem<T>(key: string): T | null {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : null;
    }
}