<ng-container *ngIf="isReady">
  <app-page-title title="{{ product.getTranslatedProperty(lang,'name') }}" [isMainPage]="true">
  </app-page-title>


  <div class="container pb-5">
    <div nz-row nzGutter="16">
      <div nz-col nzMd="6">
        <div  class="list-filters ">
          <div class="list-filters__box">
            <sp-menu>
                <ng-container *ngFor="let tab of tabs">
                    <sp-menu-item [selected]="current == tab" [routerLink]="['../'+tab] | localize" [classList]="['list-filters__item']">{{'PRODUCT-STEP.' + tab | translate}}</sp-menu-item>
                </ng-container>
            </sp-menu>
          </div>
        </div>
      </div>
      <div nz-col nzMd="18">
        <!-- RENSEIGNEMENT-GENERAUX -->
        <ng-container *ngIf="current == 'RENSEIGNEMENTS-GENERAUX'">
          <h2 class="mb-4">{{ 'PRODUCT-STEP.RENSEIGNEMENTS-GENERAUX' | translate}}</h2>
          <app-product-general-form [(product)]="product" (submitCallback)="saveGeneral($event)">
          </app-product-general-form>
        </ng-container>
        <!-- COMPTABILITE -->
        <ng-container *ngIf="current == 'COMPTABILITE'">
          <h2 class="mb-4">{{ 'PRODUCT-STEP.COMPTABILITE' | translate}}</h2>
          <app-product-compatibility-form [(product)]="product" (submitCallback)="saveGeneral($event)">
          </app-product-compatibility-form>
        </ng-container>
        <!-- TARTIFICATION -->
        <ng-container *ngIf="current == 'TARIFICATION'">
          <h2 class="mb-4">{{ 'PRODUCT-STEP.TARIFICATION' | translate}}</h2>
          <app-product-tarification-form [(product)]="product" (submitCallback)="saveGeneral($event)">
          </app-product-tarification-form>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
