<div>
  <!-- CURRENT -->
  <ng-container *ngIf="!!currentImage; else noAvatar">
    <div
      class="avatar-upload {{isDisabled || disabled ? '' : 'avatar-upload--before'}} ant-upload ant-upload-select ant-upload-select-picture-card">
      <img [src]="currentImage" class="avatar" alt="">
      <i *ngIf="!isDisabled && !disabled" class="avatar-upload__remove icon-trash" (click)="deleteAvatar()"
        aria-hidden="true"></i>
    </div>
  </ng-container>
  <ng-template #noAvatar>
    <div class="image-container">
      <!-- IMAGE DISPLAY -->
      <ng-container>
        <div
          class="avatar-upload {{ avatarUrl ? 'avatar-upload--before' : ''}} ant-upload ant-upload-select ant-upload-select-picture-card"
          style="padding-top: 30px;">
          <ng-container *ngIf="avatarUrl; else newUpload">
            <img class="avatar" [src]="avatarUrl" width="100" alt="">
            <i class="avatar-upload__remove icon-trash" (click)="deleteAvatar()" aria-hidden="true"></i>
          </ng-container>
          <ng-template #newUpload>
            <div (click)="addNewAvatar()">
              <i nz-icon aria-hidden="true" class="upload-icon"  [nzType]="loading ? 'loading' : 'plus'"></i>
              <div class="ant-upload-text">{{ 'AJOUTER' | translate }}</div>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <!-- UPLOAD TRIGGER -->
      <input id="file-input-account" type="file" [(ngModel)]="fileInput" (change)="fileChangeEvent($event)"
        style="display: none;" />

      <div class="image-cropper-box" [ngClass]="isVisible ? 'd-block' : 'd-none'">
        <image-cropper
           [imageChangedEvent]="imageChangedEvent"
           [maintainAspectRatio]="true"
           [cropperMaxWidth]="maxCropperWidth"
           [cropperMaxHeight]="maxCropperHeight"
           [aspectRatio]="1"
           format="png"
           (imageCropped)="imageCropped($event)"
           (imageLoaded)="imageLoaded()"
           output="base64"
           class="image-cropper">
        </image-cropper>
        <div class="image-cropper-actions">
          <a (click)="deleteAvatar()" class="light-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path fill-rule="nonzero"
                d="M15 7a1 1 0 0 1 1.01.991l.09 10a1 1 0 0 1-1 1.009H5.9a1 1 0 0 1-1-1.009l.09-10a1 1 0 0 1 2 .018L6.91 17h7.18l-.08-8.991A1 1 0 0 1 15 7zM9 7a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm3 0a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm1-6a1 1 0 0 1 1 1v2h3a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2h3V2a1 1 0 0 1 1-1h5zm-1 2H9v1h3V3z" />
            </svg>
          </a>
          <a (click)="saveCrop()" class="btn btn--small">OK</a>
        </div>
      </div>

      <img [src]="croppedImage" style="display: none;" alt="" />

      <p class="image-info">{{'FORM.PHOTO-PROFILE-RULES' | translate}}</p>
    </div>
  </ng-template>
  <p *ngIf="ngControl && ngControl.touched && ngControl.errors && ngControl.errors.required" class="error">
    {{ requiredTranslateKey | translate }}
  </p>
</div>
