import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Pagination,
  SelectedFilter,
} from 'src/app/shared/model/list-item.model';
import { ShowService } from '../../services/show.service';
import {
  Filter,
  FilterValue,
  ListItem,
  Sort,
} from './../../../../shared/model/list-item.model';
import { AccountService } from './../../../account/services/account.service';
import { ShowStatus } from './../../enums/show-status.enum';
import { Show } from './../../model/show.model';
import { Subscription } from 'rxjs';
import { Globals } from '../../../../_configs/globals';
import { RowTypes } from '../../../../shared/enums/row-types.enum';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';

@Component({
  selector: 'app-my-shows',
  templateUrl: './my-shows.component.html',
  styleUrls: ['./my-shows.component.scss'],
})
export class MyShowsComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  showList: Show[];
  itemList: ListItem[] = [];

  filters: Filter[] = [];

  sorts: Sort[] = [];

  pagination: Pagination;

  lang: string;

  searchText: string;

  defaultImg: string;

  isLoading: boolean;

  isDisabled: boolean;

  defaultFilters: SelectedFilter[] = [
    {
      field: 'organizationId',
      value: 0,
    } as SelectedFilter,
    {
      field: 'withOrg',
      value: 1,
    } as SelectedFilter,
  ];
  listType: RowTypes = RowTypes.SHOW;

  private selectedFilters: SelectedFilter[] = [];

  constructor(
    private translate: TranslateService,
    private showService: ShowService,
    private globals: Globals,
    private breadcrumbService: BreadcrumbService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.lang = this.translate.currentLang;

    this.initFilters();
    this.initSort();
    this.initPagination();
    this.getMyShows();

    this.subscription = this.accountService.currentOrganizationChange.subscribe(
      () => this.getMyShows()
    );
  }

  getMyShows() {
    this.defaultFilters[0].value = this.accountService.getCurrentCtxOrganizationId();
    this.isLoading = true;
    // Get my shows with filters & pagination
    this.showService
      .getShows(
        [...this.defaultFilters, ...this.selectedFilters],
        this.pagination,
        null,
        this.searchText
      )
      .subscribe((shows: Show[]) => {
        this.showList = shows;
        this.itemList = this.showList.map((show: Show) => {
          const item = show.toListItem(this.lang);
          item.itemSubtitle = this.showService.extractArtistList(show);
          const str = this.translate.instant('ROUTES.shows-offers');
          item.itemUrl = `/${str}/${show.organizationId}/${show.id}`;

          switch (show.statusId) {
            case ShowStatus.NON_SOUMIS:
              item.itemInfo3 = this.translate.instant('STATUS.NON_SOUMIS');
              break;
            case ShowStatus.SOUMIS:
              item.itemInfo3 = this.translate.instant('STATUS.SOUMIS');
              break;
            case ShowStatus.APPROUVE:
              item.itemInfo3 = this.translate.instant('STATUS.APPROUVE');
              break;
            case ShowStatus.REJETE:
              item.itemInfo3 = this.translate.instant('STATUS.REJETE');
              break;
            case ShowStatus.EXPIRE:
              item.itemInfo3 = this.translate.instant('STATUS.EXPIRE');
              break;
          }
          return item;
        });

        this.isLoading = false;
      });
  }

  initFilters() {
    const statusFilter = new Filter();
    statusFilter.filterField = 'statusId';
    statusFilter.filterName = this.translate.instant('SHOW-STATUS');
    statusFilter.filterValues = Object.keys(ShowStatus)
      .filter((val) => isNaN(Number(val)))
      .map((key) => {
        return {
          filterValueName: this.translate.instant('STATUS.' + key),
          filterValue: ShowStatus[key],
        } as FilterValue;
      });
    statusFilter.filterValues.unshift({
      filterValueName: this.translate.instant('TOUS'),
      filterValue: '',
    } as FilterValue);

    this.filters = [statusFilter];
  }

  initSort() {
    const sort1 = new Sort(),
      sort2 = new Sort(),
      sort3 = new Sort();
    sort1.sortName = 'Status';
    sort1.sortValue = 'statusId';
    sort2.sortName = 'Date de creation';
    sort2.sortValue = 'creationDate';
    sort3.sortName = 'Nom';
    sort3.sortValue = 'name';

    this.sorts = [sort1, sort2, sort3];
  }

  initPagination() {
    this.pagination = new Pagination();
    this.pagination.offset = 0;
    this.pagination.limit = 12;
    this.pagination.total = 0;
  }

  newShow() {
    this.isDisabled = true;
    this.showService.createBlankShowAndRedirectToForm();
  }

  onFilterChange(event: SelectedFilter) {
    this.selectedFilters = [event];
    this.getMyShows();
  }

  onSearchChange(event: string) {
    this.searchText = event;
    this.getMyShows();
  }

  onSortChange(event: any) {
    this.getMyShows();
  }

  onPageChange(event: Pagination) {
    this.pagination.offset = event.offset;
    this.getMyShows();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
