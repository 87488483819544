import { Injectable } from '@angular/core';
import { Globals } from '../../_configs/globals';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class IndexedDBStorage {
    private dbName: string = 'Scene Pro';
    private dbVersion: number = 2;
    private db: IDBDatabase;
    private timeoutDuration: number = Number(environment.TIMEOUT_INDEXED_DB) || this.globals.default_TIMEOUT_INDEXED_DB;
    userMemberShipsStore: string = 'userMemberShips';

    constructor(private globals: Globals) {
        this.openDatabase();
    }

    openDatabase() {
        const request = indexedDB.open(this.dbName, this.dbVersion);

        request.onsuccess = (event) => {
            this.db = (event.target as IDBOpenDBRequest).result;
        };

        request.onupgradeneeded = (event) => {
            this.db = (event.target as IDBOpenDBRequest).result;

            if (!this.db.objectStoreNames.contains(this.userMemberShipsStore)) {
                this.db.createObjectStore(this.userMemberShipsStore, {
                    keyPath: 'userId'
                });
            }
        };
    }

    addData(storeName: string, data: any, key: any) {
        this.checkKeyExistence(storeName, key).subscribe((keyExists) => {
            if (!keyExists) {
                const transaction = this.db.transaction(storeName, 'readwrite');
                const objectStore = transaction.objectStore(storeName);
                // Calculate expiration time
                const expirationTime = Date.now() + this.timeoutDuration;

                // Combine data with expiration time
                const dataWithTimeout = {
                    ...data,
                    expirationTime: expirationTime
                };
                const request = objectStore.add(dataWithTimeout);

                request.onsuccess = (event) => {
                    const key = (event.target as IDBRequest).result;
                    // Schedule deletion after timeout
                    setTimeout(() => {
                        this.deleteData(storeName, key);
                    }, this.timeoutDuration);
                };
            }
        });
    }

    private deleteData(storeName: string, key: any) {
        const transaction = this.db.transaction(storeName, 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        objectStore.delete(key);
    }

    getDataByKey(storeName: string, key: any): Observable<any> {
        return new Observable((observer) => {
            const transaction = this.db.transaction(storeName, 'readonly');
            const objectStore = transaction.objectStore(storeName);
            const request = objectStore.get(key);

            request.onsuccess = (event) => {
                const data = (event.target as IDBRequest).result;
                const currentTime = Date.now();
                if (data && data.expirationTime > currentTime) {
                    delete data.expirationTime;
                    observer.next(data);
                } else {
                    this.deleteData(storeName, key);
                    observer.next(null);
                }
                observer.complete();
            };
        });
    }

    clearObjectStore(storeName: string) {
        const transaction = this.db.transaction(storeName, 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        objectStore.clear();
    }

    private checkKeyExistence(storeName: string, key: any): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            const transaction = this.db.transaction(storeName, 'readonly');
            const objectStore = transaction.objectStore(storeName);
            const request = objectStore.get(key);

            request.onsuccess = () => {
                const data = request.result;
                observer.next(!!data); // Emit true if data exists, false if not
                observer.complete();
            };
        });
    }
}
