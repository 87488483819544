<nz-form-item>
    <nz-form-control>
        <label>{{ label }}</label>
        <div class="quill-editor-edit d-inline-block w-100" [class.ql-disabled]="disabled" tabindex="disabled ? null : 0">
            <quill-editor
                class="w-100"
                [(ngModel)]="value"
                [disabled]="disabled"
                [sanitize]="true"
                [modules]="modules"
                (onContentChanged)="handleContentChanged($event)"
                [placeholder]="placeholder"
                [ngModelOptions]="{ standalone: true }"
            ></quill-editor>
        </div>
    </nz-form-control>
</nz-form-item>
