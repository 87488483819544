import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { UserAccountComponent } from './components/user-account.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { AccountModule } from '../account/account.module';
import { ImageCropperComponent } from 'ngx-image-cropper';

@NgModule({
    declarations: [UserListComponent, UserAccountComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        LocalizeRouterModule,
        RouterModule,
        SharedModule,
        AccountModule,
        ImageCropperComponent
    ],
    exports: [UserListComponent]
})
export class UserModule {}
