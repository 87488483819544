import { Injectable } from '@angular/core';
import { MainService } from '../../../shared/services/main.services';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../_configs/globals';
import { Observable } from 'rxjs';
import { IStand, IStandRequest, IStandResponse, Stand } from '../model/stand.model';
import { Pagination, SelectedFilter } from '../../../shared/model/list-item.model';
import { AccountService } from '../../account/services/account.service';
import { IUpdatedResponse } from '@app/shared/model/response.model';
import { SessionStorageService } from '@app/shared/services/storage.service';
import { map } from 'rxjs/operators';

const ORG_ID_QS_PREFIX = '?organizationId=';
@Injectable({
    providedIn: 'root'
})
export class StandService extends MainService {
    private readonly sessionStorageParticipantsOrganizationKey = 'sp.meeting.participants.organizations';

    constructor(protected httpClient: HttpClient, protected accountService: AccountService, protected globals: Globals,
        private sessionStorageService: SessionStorageService) {
        // ratacher à  l'organisation courante
        super(httpClient, globals);
    }

    public createStand(stand: Stand): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.stand.main;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();

        return this.httpClient.post(endpoint, this.cleanUpNullValues(stand));
    }

    public createStandRange(standRange: any): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.stand.all;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();

        return this.httpClient.post(endpoint, standRange);
    }

    public deleteStand(standId: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.stand.main + '/' + standId;
        endpoint += ORG_ID_QS_PREFIX + this.accountService.getCurrentCtxOrganizationId();

        return this.httpClient.delete(endpoint);
    }

    public getStands(meetingId?: number, filters?: SelectedFilter[], pagination?: Pagination): Observable<IStandResponse> {
        // Prepare query parameters for the HTTP request
        const queryParams: string[] = [];
        if (meetingId) {
            queryParams.push(`meetingId=${meetingId}`);
        }
        if (filters && filters.length) {
            queryParams.push(this.formatGetFilters(filters));
        }
        if (pagination) {
            queryParams.push(this.formatPagination(pagination));
        }

        // Construct the query string
        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        // Construct the endpoint URL
        const endpoint = this.uri + this.globals.endpoints.stand.all + queryString;

        // Perform the HTTP GET request
        return this.httpClient.get<IStandResponse>(endpoint).pipe(
            map((response: IStandResponse) => ({
                ...response,
                stands: (response.stands as IStand[]).map((x: Stand) => new Stand(x))
            }))
        );
    }

    public updateStand(payload: IStandRequest): Observable<IUpdatedResponse> {
        const contextOrganizationId = this.accountService.getCurrentCtxOrganizationId()
        const endpointWithOrgId = this.addContextOrgIdToEndpoint(this.uri + this.globals.endpoints.stand.main, contextOrganizationId);
        return this.httpClient.put<IUpdatedResponse>(endpointWithOrgId, {
            ...this.cleanUpNullValues(payload),
            contextOrganizationId
        });
    }

    private addContextOrgIdToEndpoint(endpoint: string, contextOrganizationId: number): string {
        return `${endpoint}${ORG_ID_QS_PREFIX}${contextOrganizationId}`;
    }
}
