import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'sp-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SPMenuItemComponent implements OnInit {
    private _selected: boolean = false;
    private initialClass = 'list-filters__item';

    @Input() public routerLink?: string;
    @Input() public label?: string;
    @Input() classList?: string[] = [];
    @Input()
    get selected(): boolean {
        return this._selected;
    }
    set selected(value: boolean) {
        this._selected = value;
        this.updateClassList();
    }
    @Output() clicked?: EventEmitter<void> = new EventEmitter();
    ngOnInit(): void {
        this.classList.push(this.initialClass);
    }

    public clickHandler() {
        this.clicked.emit();
    }

    private updateClassList() {
        if (this._selected) {
            if (!this.classList.includes('active')) {
                this.classList.push('active');
            }
        } else {
            const index = this.classList.indexOf('active');
            if (index > -1) {
                this.classList.splice(index, 1);
            }
        }
    }
}
