import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { Globals } from '../../../../../_configs/globals';
import { MeetingService } from '../../../../meeting/services/meeting.service';
import { StandService } from '../../../services/stand.service';

@Component({
  selector: 'app-stand-range-form',
  templateUrl: './stand-range-form.component.html',
  styleUrls: ['./stand-range-form.component.scss'],
})
export class StandRangeFormComponent implements OnInit {
  @Input()
  meetingId: number;

  @Input()
  meetingOwnerOrgId: number;

  formGroup: UntypedFormGroup;
  displayErrors = false;
  suffixChecked = false;

  standRange: any;

  constructor(
    private fb: UntypedFormBuilder,
    private globals: Globals,
    private translate: TranslateService,
    private notificaton: RideauNotificationService,
    private meetingService: MeetingService,
    private standServices: StandService
  ) {}

  ngOnInit(): void {
    this.standRange = {
      meetingId: this.meetingId,
      numberFrom: null,
      numberTo: null,
      prefix: null,
      suffix: null,
    };

    this.formGroup = this.fb.group(
      {
        numberFrom: new UntypedFormControl(undefined, Validators.required),
        numberTo: new UntypedFormControl(undefined, Validators.required),
        prefix: new UntypedFormControl(undefined, Validators.required),
        suffixChecked: new UntypedFormControl(this.suffixChecked),
        suffix: new UntypedFormControl(undefined),
      },
      { validator: this.rangeValidator }
    );
  }

  createStandRange() {
    if (this.formGroup.valid) {
      this.updateStandRange();
      this.standServices.createStandRange(this.standRange).subscribe(() => {
        this.notificaton.success(this.translate.instant('FORM.SAUVEGARDE'));
      });
      return true;
    } else {
      this.displayErrors = true;
      return false; // Retrun false to prevent the modal close
    }
  }

  rangeValidator(formGroup: AbstractControl) {
    const numberFrom = formGroup.get('numberFrom').value;
    const numberTo = formGroup.get('numberTo').value;
    return numberFrom && numberTo && numberFrom < numberTo
      ? null
      : { invalidRange: true };
  }

  updateSuffix() {
    return this.formGroup.get('suffixChecked').value;
  }

  updateStandRange() {
    this.standRange.numberFrom = this.formGroup.controls['numberFrom'].value;
    this.standRange.numberTo = this.formGroup.controls['numberTo'].value;
    this.standRange.prefix = this.formGroup.controls[
      'prefix'
    ].value.toUpperCase();
    this.standRange.suffix = this.formGroup.get('suffixChecked').value
      ? (this.formGroup.controls['suffix'].value || '').toUpperCase()
      : undefined;
  }
}
