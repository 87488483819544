import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from '../../../services/product.service';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { registerLocaleData } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import fr from '@angular/common/locales/fr';
import { TranslateService } from '@ngx-translate/core';
import { AbstractProductForm } from '../product-abstract-form/product-abstract-form.component';

@Component({
  selector: 'app-product-compatibility-form',
  templateUrl: './product-compatibility-form.component.html',
  styleUrls: ['./product-compatibility-form.component.scss'],
})
export class ProductCompatibilityFormComponent
  extends AbstractProductForm
  implements OnInit {
  @Input()
  simpleButton = false;

  public formGroup: UntypedFormGroup;
  public lang: string;
  public checkboxTaxable: boolean;
  public isMembershipProduct: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    public productService: ProductService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    registerLocaleData(fr);
    this.initForm();
  }

  protected initForm(): void {
    // init du formulaire
    this.formGroup = this.product.getCompatibilityFormGroup(this.fb, this.lang);
    this.formGroup.value.taxable == 0
      ? (this.checkboxTaxable = false)
      : (this.checkboxTaxable = true);

    this.formGroup.value.productTypeId === 2
      ? (this.isMembershipProduct = true)
      : (this.isMembershipProduct = false);
  }

  doSubmit(param?: any) {
    this.productValue.adminOrgAccountingNumber = this.formGroup.value.adminOrgAccountingNumber;
    this.productValue.adminOrgProjectNumber = this.formGroup.value.adminOrgProjectNumber;
    this.productValue.uniqueCode = this.formGroup.value.uniqueCode;
    this.productValue.orgAccountingNumber = this.formGroup.value.orgAccountingNumber;
    this.productValue.orgProjectNumber = this.formGroup.value.orgProjectNumber;
    this.formGroup.value.taxable
      ? (this.productValue.taxable = 1)
      : (this.productValue.taxable = 0);
    super.doSubmit(param);
  }
}
