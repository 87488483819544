import { Component, Input, OnInit } from '@angular/core';
import { getDomainName } from '../../../utils/other-utils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Globals } from '../../../../_configs/globals';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
    @Input() src: string;
    @Input() password: string;

    isReady = false;
    videoType: string;

    youtubeVideo: SafeResourceUrl;
    vimeoVideo: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer, private globals: Globals) {}

    ngOnInit(): void {
        this.videoType = this.src ? getDomainName(this.src) : undefined;

        switch (this.videoType) {
            case 'youtube': {
                // Clean youtube url
                this.setYoutubeVideo();
                break;
            }
            case 'vimeo': {
                // Clean vimeo url
                this.setVimeoVideo();
                break;
            }
            default: {
                this.isReady = true;
                break;
            }
        }
    }

    private setVimeoVideo(): void {
        let vimeoVideoId = this.src.split('.com/').pop();
        if (vimeoVideoId.indexOf('?') <= 0) {
            vimeoVideoId = vimeoVideoId.split('?').shift();
        }
        const url = this.globals.embeedMediaLinks.vimeo(vimeoVideoId);
        this.vimeoVideo = this.sanitizer.bypassSecurityTrustResourceUrl(url);

        this.isReady = true;
    }

    private setYoutubeVideo(): void {
        const url = this.globals.embeedMediaLinks.youtube(this.src.split('/watch?v=').pop());
        this.youtubeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isReady = true;
    }
}
