import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsNotLogginGuard  {

  constructor(private authService: AuthService){}

  canActivate(): boolean{
    return this.authService.User === null
  }
}
