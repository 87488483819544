import { Component, Input, OnInit, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

let unique = 0;

@Component({
    selector: 'app-date-picker',
    template: `
        <nz-form-item>
            <div class="row w-100">
                <div class="col-12 mb-1">
                    <label [attr.for]="id">
                        <ng-content></ng-content>
                    </label>
                </div>
                <div class="col-12">
                    <nz-form-control [nzValidateStatus]="ngControl.touched && ngControl.errors ? 'error' : ''">
                        <nz-date-picker
                            placeholder="yyyy/mm/dd"
                            nzAllowClear="true"
                            nzFormat="yyyy/MM/dd"
                            [(ngModel)]="selected"
                            (ngModelChange)="ngModelChange()"
                            [nzDisabled]="disabled"
                        ></nz-date-picker>
                        <span class="has-error" *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.required">
                            {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                        </span>
                    </nz-form-control>
                </div>
            </div>
        </nz-form-item>
    `,
    styles: [
        `
            :host {
                display: block;
            }

            :host /deep/ nz-date-picker,
            :host /deep/ nz-picker,
            :host /deep/ span.ant-calendar-picker,
            :host /deep/ input.ant-calendar-picker-input {
                display: block;
                width: 100%;
            }

            :host.warning /deep/ .ant-calendar-picker-input {
                border: 2px solid #faad14 !important;
                border-radius: 4px !important;
            }

            :host.warning /deep/ .ant-calendar-picker-input:hover {
                border: 2px solid #faad14 !important;
            }

            :host.error /deep/ .ant-calendar-picker-input {
                border: 2px solid #f5222d !important;
                border-radius: 4px !important;
            }

            :host.error /deep/ .ant-calendar-picker-input:hover {
                border: 2px solid #f5222d !important;
            }
        `
    ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
    private onChange: (value: Date) => void;
    private onTouched: () => void;
    @Input() disabled: boolean;
    @Input() validateStatus = '';
    @Input() control: UntypedFormControl;
    @HostBinding('class.warning') get classWarning() {
        return this.validateStatus === 'warning';
    }
    @HostBinding('class.error') get classError() {
        return this.validateStatus === 'error';
    }
    @HostBinding('class.disabled') get classDisabled() {
        return this.disabled;
    }
    id = `DatePickerComponent-${unique++}`;
    selected: Date;
    constructor(readonly translateService: TranslateService, readonly ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }
    ngOnInit(): void {
        this.translateService.currentLang;
    }
    ngModelChange(): void {
        const date = this.selected ? moment(this.selected).startOf('day').toDate() : null;
        this.onChange(date);
        this.onTouched();
    }
    writeValue(value: Date): void {
        this.selected = value;
    }
    registerOnChange(fn: (value: Date) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
