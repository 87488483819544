import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
export class OneOrManyFilesValidator extends Validators {
    /** This static method named validate is used to create a custom
     * * validation function that checks if a group of form controls
     * * have values in specified fields, and it returns validation
     * * errors if certain conditions related to these values are not met.
     */
    static validate<T>({ fields, formControlNameList }: { fields: string[]; formControlNameList?: string[] }): ValidatorFn {
        // The function returned here will be used as a validator function for a FormGroup.

        return (formGroup: UntypedFormGroup) => {
            // Initialize an array to hold FormControl instances.
            let controlList: UntypedFormControl[] = [];

            // Check if formControlNameList is not provided or is an empty array.
            if (!formControlNameList || (formControlNameList && !formControlNameList.length)) {
                // If not provided or empty, get all controls from the formGroup.
                controlList = Object.keys(formGroup.controls).map((k: string) => formGroup.get(k) as UntypedFormControl);
            } else {
                // If formControlNameList is provided, get controls by their names.
                controlList = formControlNameList.map((name: string) => formGroup.get(name) as UntypedFormControl);
            }

            // Check if every control in the controlList does not have a value for the specified field.
            if (controlList.every((control: UntypedFormControl) => !control.value[fields[0]])) {
                return {
                    // Return an error object if all controls have no value for the specified field.
                    hasOneOrManyFilesError: true
                };
            }

            // Create an array of boolean values based on the presence of values in specified fields.
            const compatibleFieldValues = controlList
                .map((control: UntypedFormControl) => control.value)
                .map((value: T) => {
                    if (!value[fields[0]]) {
                        return true;
                    }
                    if (!value[fields[1]]) {
                        return false;
                    }
                    return true;
                });

            // Check if any value in compatibleFieldValues is false.
            if (compatibleFieldValues.some((x) => !x)) {
                return {
                    // Return an error object if any value in the array is false.
                    hasOneOrManyFieldValueError: true
                };
            }

            // If all checks pass, return null indicating no validation error.
            return null;
        };
    }
}
