import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TarifTypes, VolumeTypes } from 'src/app/concepts/product/enums/tarifs-enum';
import { Meeting } from '../../model/meeting.model';
import { Product, Tarif } from './../../../product/model/product.model';
import { ProductService } from './../../../product/services/product.service';

@Component({
    selector: 'app-meeting-product-list',
    templateUrl: './meeting-product-list.component.html',
    styleUrls: ['./meeting-product-list.component.scss']
})
export class MeetingProductListComponent implements OnInit {
    @Input()
    public meeting: Meeting;

    public products: Product[];

    public lang = this.translate.currentLang;
    /**
     * An array of objects that represents the available tariff types.
     * The objects contain an id and a label that is translated using the ngx-translate module.
     * @type {Array<{ id: number; label: string }>}
     */
    public tarifTypes: { id: number; label: string }[];

    volumeTypes = [
        { id: VolumeTypes.ALL, label: this.translate.instant('VOLUME_TYPES.ALL') },
        { id: VolumeTypes.TWO, label: this.translate.instant('VOLUME_TYPES.TWO') },
        {
            id: VolumeTypes.THREE,
            label: this.translate.instant('VOLUME_TYPES.THREE')
        },
        {
            id: VolumeTypes.FOUR,
            label: this.translate.instant('VOLUME_TYPES.FOUR')
        }
    ];

    formatter = new Intl.NumberFormat('fr-CA', {
        style: 'currency',
        currency: 'CAD',
        //currencyDisplay: 'symbol',
        minimumFractionDigits: 2
    });

    constructor(private translate: TranslateService, private productService: ProductService) {}

    ngOnInit(): void {
        this.getProductsForMeeting();
        this.tarifTypes = this.getTarifTypes();
    }

    getProductsForMeeting(): void {
        const meetingFilter = {
            field: 'meetingId',
            value: this.meeting.id
        };
        this.productService.getProducts([meetingFilter]).subscribe((products: Product[]) => {
            this.products = products;

            this.products.forEach((product) => {
                if (product.tariffs && product.tariffs.length > 1) {
                    // Trier les tarifs par participantType, puis par minInscription, puis par price
                    product.tariffs = product.tariffs.sort((a: Tarif, b: Tarif) => {
                        if (a.participantTypeId < b.participantTypeId) {
                            return -1;
                        } else if (a.participantTypeId > b.participantTypeId) {
                            return 1;
                        } else {
                            if (a.minInscriptions < b.minInscriptions) {
                                return -1;
                            } else if (a.minInscriptions > b.minInscriptions) {
                                return 1;
                            } else {
                                if (a.price < b.price) {
                                    return -1;
                                } else if (a.price > b.price) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    });
                }
            });
        });
    }

    getPrice(product: Product): string {
        if (product.isFree) {
            return this.translate.instant('GRATUIT');
        } else if (product.singlePrice) {
            return this.formatter.format(Number(product.singlePrice));
        } else {
            return this.translate.instant('TARIFS-MULTIPLES');
        }
    }

    getTarfiTypeLabel(tarif: Tarif): string {
        if (tarif.participantTypeId === null || tarif.participantTypeId === undefined) {
            return '';
        }

        let label = this.tarifTypes.find((type) => type.id === tarif.participantTypeId).label;

        if (tarif.participantTypeId === TarifTypes.ORGA_MEMBER) {
            label += ` ${this.meeting.organizationName}`;
        }
        return label;
    }

    getVolumeTypeLabel(tarif: Tarif): string {
        if (tarif.minInscriptions === null || tarif.minInscriptions === undefined) {
            return '';
        }
        return this.volumeTypes.find((type) => type.id === tarif.minInscriptions).label;
    }

    /**
     * Returns an array of objects with id and label properties for each Tarif type.
     * The label is localized using the translation service, and may include additional information for certain types.
     *
     * @returns {Array<{ id: number, label: string }>} An array of objects representing each Tarif type.
     */
    private getTarifTypes = (): { id: number; label: string }[] => {
        return Object.values(TarifTypes)
            .splice(0, Object.keys(TarifTypes).length / 2)
            .map((x: string) => ({
                id: TarifTypes[x],
                label: this.translate.instant(`TARIF_TYPES.${x}`)
            }));
    };
}
