import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'src/app/_configs/globals';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-etiquettes-large',
  templateUrl: './etiquettes-large.component.html',
  styleUrls: ['./etiquettes-large.component.scss'],
})
export class EtiquettesLargeComponent implements OnInit {
  @Input() tagsList: any;

  @Output() onDeleteTags: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageChange: any;

  defaultImg: string;
  currentLang: string;

  constructor(
    private globals: Globals,
    private translate: TranslateService,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.defaultImg = this.globals.imgFallback;
  }

  deleteTag(id) {
    // send id to delete
    const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
    const acceptDelete = this.translate.instant('OUI');
    const rejectDelete = this.translate.instant('NON');
    this.modalService.confirm({
      nzContent: deleteMessages,
      nzOkText: acceptDelete,
      nzCancelText: rejectDelete,
      nzClosable: true,
      nzMaskClosable: true,
      nzOnOk: () => {
        this.onDeleteTags.emit(id);
      },
    });
  }
}
