<!-- password-input.component.html -->
<nz-form-control class="full-width">
    <div class="d-flex justify-content-between c-password-input" [ngClass]="['c-password-input--' + labelStyle]">
      <label for="password">{{ label | translate }}</label>
      <a *ngIf="showForgotPassword" class="cta_link cta_link-password" [routerLink]="['../forgot-password']">{{ 'FORGOT_PASSWORD' | translate }}?</a>
    </div>
  
    <nz-input-group [nzSuffix]="eyeIcon">
      <input [(ngModel)]="value" (input)="onInputChange($event.target.value)" (blur)="onBlur()" [type]="isPasswordVisible ? 'text' : 'password'" name="password" />
    </nz-input-group>
  </nz-form-control>
  
  <ng-template #eyeIcon>
    <i nz-icon class="eye_icon" [nzType]="isPasswordVisible ? 'eye-invisible' : 'eye'" (click)="isPasswordVisible = !isPasswordVisible"></i>
  </ng-template>