import { UntypedFormArray, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

export class OneOrManyValidatorArrayValues extends Validators {
    static validate(formGroupName: string): ValidatorFn {
        return (formArray: UntypedFormArray) => {
            const formGroupList = formArray.controls as UntypedFormGroup[];
            const controlListHasValues = formGroupList.map((fg: UntypedFormGroup) => {
                const control = fg.get(formGroupName);
                if (!control) {
                    return null;
                }
                return control.value.length > 0;
            });
            const invalid = controlListHasValues.every((c) => !c);
            return invalid ? { atLeastOneError: true } : null;
        };
    }
}
