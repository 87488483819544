<form *ngIf="formGroup" id="activityAccesRestrictions" [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row nzGutter="16">
        <div nz-col nzMd="4">
            <app-nz-form-item [label]="'FORM.CAPACITE' | translate" [control]="formGroup.get('capacity')" inputType="number"></app-nz-form-item>
        </div>
    </div>
    <label for="orgaTypeAcces">
        {{ 'FORM.ORGANIZATION-TYPE-ACCESS' | translate }}
    </label>

    <sp-radio-group
        [formControl]="formGroup.get('hasOrgaTypeRestrictions')"
        [options]="options"
        (selectionChange)="checkDisableOrganizationTypeRestriction($event)"
    ></sp-radio-group>

    <div formGroupName="orgaTypeRestrictions" style="margin-left: 2%">
        <sp-checkbox
          size="medium"
          [disabled]="formGroup.get('orgaTypeRestrictions').get('hasOrgaId').disabled"
          (ngModelChange)="checkDisableOrganizationNetworkRestriction($event)"
          formControlName="hasOrgaId"
        >
         {{ 'RESTRICTION-ORGANISATION-SPECIFIQUE' | translate }}
        </sp-checkbox>

        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <nz-select
                    nzPlaceHolder="{{ 'SELECT-NETWORK' | translate }}"
                    nzShowSearch
                    nzAllowClear
                    style="width: 100%"
                    formControlName="orgaId"
                    [nzDisabled]="!formGroup.get('orgaTypeRestrictions.hasOrgaId').value"
                >
                    <nz-option *ngFor="let network of networkList" [nzLabel]="network.getTranslatedProperty(currentLang, 'name')" [nzValue]="network.id"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <sp-checkbox size="medium" formControlName="allowProducers">
            {{ 'ORGANIZATION-TYPE_1' | translate }}
        </sp-checkbox>
        <sp-checkbox size="medium" formControlName="allowDiffusers">
            {{ 'ORGANIZATION-TYPE_2' | translate }}
        </sp-checkbox>
        <sp-checkbox size="medium" formControlName="allowVenuesOwners">
            {{ 'ORGANIZATION-TYPE_3' | translate }}
        </sp-checkbox>
        <sp-checkbox size="medium" formControlName="allowGovernmental">
            {{ 'ORGANIZATION-TYPE_4' | translate }}
        </sp-checkbox>
        <sp-checkbox size="medium" formControlName="allowProviders">
            {{ 'ORGANIZATION-TYPE_5' | translate }}
        </sp-checkbox>
        <sp-checkbox size="medium" formControlName="allowMedia">
            {{ 'ORGANIZATION-TYPE_6' | translate }}
        </sp-checkbox>
        <sp-checkbox size="medium" formControlName="allowOthers">
            {{ 'ORGANIZATION-TYPE_7' | translate }}
        </sp-checkbox>
        <sp-checkbox size="medium" formControlName="allowIndividuals">
            {{ 'INDIVIDUS' | translate }}
        </sp-checkbox>
    </div>
    <!-- Restriction de l'activité selon les produits de la rencontre -->
    <label>{{ 'FORM.MEETING-PRODUCT-ACCESS' | translate }}</label>
    <nz-form-item>
        <nz-form-control nzSpan="14">
            <nz-radio-group formControlName="hasNoProductsRestrictions" (ngModelChange)="checkDisableProductsRestrictions($event)">
                <label nz-radio class="input-radioBtn" [nzValue]="true">
                    {{ 'AVAILABLE-ALL-PRODUCTS' | translate }}
                </label>
                <label nz-radio class="input-radioBtn" [nzValue]="false">
                    {{ 'AVAILABLE-CERTAIN-PRODUCTS' | translate }}
                </label>
            </nz-radio-group>
        </nz-form-control>
    </nz-form-item>
    <div style="margin-left: 2%">
        <div *ngIf="areProductsLoading">
            <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
        </div>
        <span *ngFor="let product of meetingProducts; let i = index">
            <nz-form-item nz-row>
                <nz-form-control nzSpan="14">
                  <label
                    nz-checkbox
                    [nzDisabled]="formGroup.get('hasNoProductsRestrictions').value"
                    [ngModel]="meetingProductsRestrictions.includes(product.id)"
                    (ngModelChange)="addNewProductRestriction($event, product.id)"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    {{ product.getTranslatedProperty(currentLang, "name") ?
                       product.getTranslatedProperty(currentLang, "name") :
                       ('NO-NAME-PRODUCT') | translate
                    }}
                  </label>
                </nz-form-control>
              </nz-form-item>
        </span>
    </div>

    <button nz-button [nzLoading]="isLoading" class="btn btn--default mt-3">
        {{ 'ENREGISTER' | translate }}
    </button>
</form>
