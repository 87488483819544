<div nz-row [formGroup]="formGroup">
    <div nz-col nzMd="18">
        <div>
            <h2>{{ 'FORM.MATERIEL-AUDIOVISUEL' | translate }}</h2>
        </div>
        <div>
            <strong>{{ 'FORM.MATERIEL-PROMO' | translate }} *</strong>
            <p>{{ 'FORM.MATERIEL-PROMO-SUB' | translate }}</p>
        </div>

        <span class="has-error" *ngIf="displayErrors && !show.urlPromoPhoto1">
            {{ 'DEPOSER-FICHIER' | translate }}
        </span>
        <div class="mb-3"></div>
        <app-item-row-file
            formControlName="promoPhoto1"
            title="{{ 'FORM.MATERIEL-PROMO-1' | translate }}"
            [isDisabled]="formGroup.disabled"
            [item]="formGroup.get('promoPhoto1').value"
            [acceptedFileTypes]="fileTypes"
        ></app-item-row-file>
        <app-item-row-file
            formControlName="promoPhoto2"
            title="{{ 'FORM.MATERIEL-PROMO-2' | translate }}"
            [isDisabled]="formGroup.disabled"
            [item]="formGroup.get('promoPhoto2').value"
            [acceptedFileTypes]="fileTypes"
        ></app-item-row-file>
        <app-item-row-file
            formControlName="pressPhoto1"
            title="{{ 'FORM.MATERIEL-PRESS-1' | translate }}"
            [isDisabled]="formGroup.disabled"
            [item]="formGroup.get('pressPhoto1').value"
            [acceptedFileTypes]="fileTypes"
        ></app-item-row-file>
        <app-item-row-file
            formControlName="pressPhoto2"
            title="{{ 'FORM.MATERIEL-PRESS-2' | translate }}"
            [isDisabled]="formGroup.disabled"
            [item]="formGroup.get('pressPhoto2').value"
            [acceptedFileTypes]="fileTypes"
        ></app-item-row-file>
        <ng-container [ngTemplateOutlet]="formGroup.errors ? errors : null" [ngTemplateOutletContext]="{ $implicit: formGroup.errors }"></ng-container>

        <div class="mt-5">
            <strong>{{ 'Liens audio et video' | translate }}</strong>
        </div>

        <hr />

        <nz-form-item>
            <nz-form-control>
                <label for="urlVideo">
                    {{ 'LIEN-VIDEO' | translate }}
                    <p class="item-row__text item-row__text--subtitle mb-1">{{ 'LIEN-VIDEO-DESC' | translate }}</p>
                    <input formControlName="urlVideo" nz-input id="urlVideo" />
                </label>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-control>
                <label for="urlAudio">
                    {{ 'LIEN-AUDIO' | translate }}
                    <p class="item-row__text item-row__text--subtitle mb-1">{{ 'LIEN-AUDIO-DESC' | translate }}</p>
                    <input formControlName="urlAudio" nz-input id="urlAudio" />
                </label>
            </nz-form-control>
        </nz-form-item>

        <br />
        <form nz-form (ngSubmit)="doSubmit()">
            <ng-container *ngIf="!formGroup.disabled">
                <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || hasFormErrors" class="btn btn--primary">
                    {{ 'ENREGISTER' | translate }}
                </button>
            </ng-container>
        </form>
    </div>
</div>
<ng-template #errors let-error>
    <p class="has-error d-fex flex-column u-gap(xs)">
        <span *ngIf="error['hasOneOrManyFilesError']">{{ 'DEPOSER-FICHIER' | translate }}</span>
    </p>
</ng-template>
