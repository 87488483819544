import { Component, OnInit, Inject } from '@angular/core';
import { ObjectStatus } from 'src/app/shared/enums/object-status.enum';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { PublicationService } from '../../services/publication.service';
import { SelectedFilter, ListItem } from 'src/app/shared/model/list-item.model';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Globals } from '@app/_configs/globals';

@Component({
    selector: 'app-my-publications-little',
    templateUrl: './my-publications-little.component.html',
    styleUrls: [ './my-publications-little.component.scss' ],
})
export class MyPublicationsLittleComponent implements OnInit {
    public isDisabled = false;
    public isLoading = false;
    public objectStatus = ObjectStatus;
    public organizationId: number;
    public publications: ListItem[] = [];

    constructor(
        private globals: Globals,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private accountService: AccountService,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private publicationService: PublicationService
    ) { }

    public getPublications() {
        const filters: SelectedFilter[] = [
            {
                field: 'organizationId',
                value: this.organizationId,
            } as SelectedFilter,
        ];
        this.publicationService.getPublications(filters).subscribe((res) => {
            this.publications = res.map((pub) =>
                pub.toListItem(this.translate.currentLang)
            );
        });
    }

    public getRemainingTime(item: ListItem) {
        // config
        const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
        // Renvoyer temps restant
        const today = new Date();
        const itemDayStart = new Date(item.itemDate);
        // itemInfo1 = nombre de semaines
        const payedDays = 7 * Number(item.itemInfo1);
        const itemDayEnd = new Date(itemDayStart);
        itemDayEnd.setDate(itemDayEnd.getDate() + payedDays);
        // Si c'est en cours
        if (itemDayEnd > today && itemDayStart < today) {
            return `Jusqu'au ${itemDayEnd.toLocaleDateString(this.globals.locales.frFR, options)}`;
        } else if (itemDayEnd > today && itemDayStart > today) {
            options.month = 'short';
            //si c'est à venir
            return `Du ${itemDayStart.toLocaleDateString(
                this.globals.locales.frFR,
                options
            )} au ${itemDayEnd.toLocaleDateString(this.globals.locales.frFR, options)}`;
        } else {
            return this.translate.instant('STATE.TERMINE');
        }
    }

    public newPublication() {
        const route = this.localizeRouter.translateRoute([
            '/organization/',
            this.organizationId,
            'modify',
            'promotion',
            'new-publication',
        ]) as any[];
        this.router.navigate(route, { relativeTo: this.route });
    }

    public ngOnInit(): void {
        this.organizationId = this.accountService.getCurrentCtxOrganizationId();
        this.getPublications();
        this.accountService.currentOrganizationChange.subscribe(() =>
            this.getPublications()
        );
    }
}
