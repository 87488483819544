import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuillModule } from 'ngx-quill';
import { TagsSingleComponent } from './components/tags-single/tags-single.component';
import { TagsListComponent } from './components/tags-list/tags-list.component';

@NgModule({
    declarations: [TagsListComponent, TagsSingleComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        LocalizeRouterModule,
        SharedModule,
        QuillModule.forRoot(),
        NzToolTipModule
    ],
    exports: []
})
export class TagsModule {}
