import { BillingItem } from './../../../../membership/model/billing-item.model';
import { OrganizationService } from './../../../services/organization.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CreditCard } from 'src/app/concepts/payment/models/credit-card.model';
import { Transaction } from 'src/app/concepts/payment/models/transaction.model';
import { CreditCardService } from 'src/app/concepts/payment/services/credit-card.service';
import { Organization } from '../../../model/organization.model';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Pagination } from '@app/shared/model/list-item.model';
import { tap } from 'rxjs/operators';
import { Globals } from '@app/_configs/globals';
@Component({
    selector: 'app-organization-billing',
    templateUrl: './organization-billing.component.html',
    styleUrls: ['./organization-billing.component.scss']
})
export class OrganizationBillingComponent implements OnInit {
    @Input()
    public organization: Organization;
    public creditCard: CreditCard;
    public creditCards: Array<any>;
    public isLoading = false;
    public usePagination = true;
    public isSavingCard = false;
    public hasCreditCard = false;
    public showCreditCardForm = false;
    public billingHistory: Transaction[];
    public paginatedBillingHistory: Transaction[];
    public imageChangedEvent: any = '';
    public croppedImage: any = '';

    public currentStatusType = 'TO-PAY';
    public filteredMembershipBills: BillingItem[] = [];
    membershipBills: BillingItem[];
    pagination: Pagination = new Pagination();

    constructor(
        private creditCardService: CreditCardService,
        private organizationService: OrganizationService,
        private translate: TranslateService,
        private globals: Globals,
        private notification: RideauNotificationService,
        private modalService: NzModalService
    ) {}

    ngOnInit(): void {
        this.initPagination();
        this.getCards();
        this.getHistory();
        this.getBills();
    }

    getHistory(): void {
        this.creditCardService
            .getHistory(this.organization.id)
            .pipe(tap(() => (this.isLoading = true)))
            .subscribe((data: Transaction[]) => {
                // https://logient.atlassian.net/browse/RIDEAU-1152
                // limit must be 12
                // sort rule: by date, newest to oldest
                this.billingHistory = this.toSorted(data);
                this.pagination.total = data.length;
                this.usePagination = data.length > this.globals.pagination.limit;
                this.paginatedBillingHistory = this.updatePaginatedList<Transaction>(this.billingHistory);
                this.isLoading = false;
            });
    }

    getCards(): void {
        this.creditCardService
            .getCards(this.organization.id)
            .pipe(tap(() => (this.isLoading = true)))
            .subscribe((data) => {
                this.creditCards = data;
                this.isLoading = false;
            });
    }

    getBills(): void {
        this.organizationService
            .getOrganisationBills(this.organization.id)
            .pipe(tap(() => (this.isLoading = true)))
            .subscribe((res) => {
                this.membershipBills = res;
                this.toggleCotisationsType(this.currentStatusType);
                this.isLoading = false;
            });
    }

    saveCreditCard(formGroup): void {
        this.isSavingCard = true;
        formGroup.organizationId = this.organization.id;
        this.creditCardService.saveCard(formGroup).subscribe(
            () => {
                this.notification.success(this.translate.instant('FORM.CARTE-AJOUTEE'));
                this.isSavingCard = false;
                this.showCreditCardForm = false;
                this.getCards();
            },
            (err) => {
                this.notification.error(this.translate.instant('ERRORS.UPLOADING-CARD'));
                this.isSavingCard = false;
            }
        );
    }

    removeCard(cardId) {
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: this.translate.instant(acceptDelete),
            nzCancelText: this.translate.instant(rejectDelete),
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.creditCardService.removeCard(cardId, this.organization.id).subscribe(() => {
                    this.getCards();
                });
            }
        });
    }

    onPaid() {
        this.getBills();
        this.getHistory();
    }

    toggleCotisationsType(currentStatusType) {
        this.currentStatusType = currentStatusType;
        switch (currentStatusType) {
            case 'TO-PAY':
                this.filteredMembershipBills = this.membershipBills.filter((x) => !x.isPaid);
                break;

            case 'PAYED':
                this.filteredMembershipBills = this.membershipBills.filter((x) => x.isPaid);
                break;

            default:
                this.filteredMembershipBills = this.membershipBills;
        }
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    onPageChange(event: Pagination) {
        this.pagination.offset = event.offset;
        // get next of previous section of the array here
        this.paginatedBillingHistory = this.updatePaginatedList<Transaction>(this.billingHistory);
    }

    private initPagination = (): void => {
        this.pagination.offset = this.globals.pagination.offset;
        this.pagination.limit = this.globals.pagination.limit;
    };

    private updatePaginatedList = <T>(list: T[]): T[] => {
        let newLimit = this.pagination.limit + this.pagination.offset;
        if (newLimit >= this.pagination.total) {
            newLimit = this.pagination.total;
        }
        return [...list].slice(this.pagination.offset, newLimit);
    };

    private toSorted = <T extends { createdAt: Date }>(list: T[]): T[] => [...list].sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
}
