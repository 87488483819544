import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SPCheckboxGroupComponent } from './checkbox-group.component';

@NgModule({
    imports: [ CommonModule, FormsModule, ReactiveFormsModule ],
    exports: [ SPCheckboxGroupComponent ],
    declarations: [ SPCheckboxGroupComponent ],
})
export class SPCheckboxGroupModule { }
