import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { DynamicSearchBarComponent } from 'src/app/shared/components/dynamic-search-bar/dynamic-search-bar.component';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Tour } from '../../model/tour.model';
import { ToursService } from '../../services/tours.service';

@Component({
  selector: 'app-edit-tour-admins',
  templateUrl: './edit-tour-admins.component.html',
  styleUrls:[ './edit-tour-admins.component.scss']
})
export class EditTourAdminsComponent implements OnInit {

  currentUser: PxUser;
  tour: Tour;
  adminUser: PxUser[]
  organizationUser: PxUser[]
  searchedUser: PxUser[]
  userCardSubtitle = '';
  currentOrganization: Observable< Organization >;
  lastSearchString = ''
  loading = true

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private accountService: AccountService,
    private organizationService: OrganizationService,
    private translateService: TranslateService,
    private tourService: ToursService,
    private modalService: NzModalService,
    private notification: RideauNotificationService
  ) {}

  async ngOnInit() {
    this.currentUser = this.authService.User;
    this.tour = this.activatedRoute.snapshot.data.tour;
    this.adminUser = ( await this.tourService.getTourAdmins(this.tour.id).toPromise()).tourAdmins;

    this.organizationUser = this.removeAdminFromOrganizationUser( (await this.organizationService.getOrganizationTeam(this.accountService.getCurrentCtxOrganizationId())
    .toPromise()).members.map(member => member.user), this.adminUser );

    this.searchedUser = this.organizationUser;

    this.userCardSubtitle = (await this.organizationService.getOrganization(this.accountService.getCurrentCtxOrganizationId()).toPromise()).getTranslatedProperty(this.translateService.currentLang, 'name');
    this.loading = false
  }

  onSearch(stringSearched: string){

    this.loading = true;

    this.searchedUser = this.organizationUser.filter( user => (user.firstName + user.lastName).toLowerCase().includes(stringSearched.toLowerCase()) );

    this.loading = false;
  }

  addAdmin(userId, dynamicBar: DynamicSearchBarComponent){

    const userIndex = this.organizationUser.findIndex(user => user.id === userId);
    this.tourService.addTourAdmin(this.tour.id, userId).subscribe( response => {
      const addedUser = this.organizationUser.splice(userIndex, 1)[0];
      this.adminUser = [ ...this.adminUser, addedUser ];
      dynamicBar.showResult = false;
      this.notification.success(this.translateService.instant("ADMIN_ADDED", { name: addedUser.firstName + ' ' + addedUser.lastName }));
    }, error => {

    })
  }

  removeAdmin(userId){
    this.modalService.confirm({
      nzContent: this.translateService.instant('DELETE-ADMIN'),
      nzOkText: this.translateService.instant('OUI'),
      nzCancelText: this.translateService.instant('NON'),
      nzClosable: true,

      nzOnOk: () => {
        const userIndex = this.adminUser.findIndex(user => user.id === userId);

        this.tourService.deleteTourAdmin(this.tour.id, userId).subscribe(response => {
          const removedUser = this.adminUser.splice(userIndex, 1)[0];
          this.organizationUser.push(removedUser);
          this.notification.success(this.translateService.instant("ADMIN_DELETED", { name: removedUser.firstName + ' ' + removedUser.lastName }));
        })
      }
    })
  }

  private removeAdminFromOrganizationUser(organisationUsers: PxUser[], adminUsers: PxUser[]){

    for (let index = 0; index < adminUsers.length; index++) {

      const adminIndex = organisationUsers.findIndex(user => user.id === adminUsers[index].id)

      if( adminIndex !== -1 ){
        organisationUsers.splice(adminIndex, 1);
      }

    }

    return organisationUsers;
  }

}
