import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '@env';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.User) {
            return true;
        }

        window.location.href = environment.VITRINE_URL;

        return false;
    }
}
