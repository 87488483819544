
import { Component, Input, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';

@Component({
  selector: 'app-organisation-show-accordion-block',
  templateUrl: './organisation-show-accordion-block.component.html',
  styleUrls: ['./organisation-show-accordion-block.component.scss'],
  providers: [ LocalizeRouterService ]
})
export class OrganisationShowAccordionBlockComponent implements OnInit {

  @Input() tourShow : TourShow;
  @Input() tourId: number;

  showTitle: string;



  constructor(
    private translateService: TranslateService,
    private router: Router,
    @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.showTitle = this.tourShow.show.getTranslatedProperty(this.translateService.currentLang, 'title')
  }

  navigate(){
    const route = this.localizeRouter.translateRoute(['../../tour', this.tourId, 'show', this.tourShow.id]) as any[];
    this.router.navigate(route, {relativeTo: this.activateRoute})
  }

}
