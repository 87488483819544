import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sp-menu',
    templateUrl: './menu.component.html',
    styleUrls: [ './menu.component.scss' ]
})
export class SPMenuComponent implements OnInit {
    @Input() menuItems: { label: string; link: string }[] = [];
    @Input() classList?: string[] = [];
    private initialClass = 'list-filters__box';
    ngOnInit() {
        this.classList.push(this.initialClass);
    }
}
