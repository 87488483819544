import { Output, Input, EventEmitter, Component, Directive } from '@angular/core';
import { Product } from '../../../model/product.model';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { UntypedFormGroup } from '@angular/forms';
import { ObjectUtils } from '@app/shared/utils/object.utils';

@Directive()
export abstract class AbstractProductForm {
    @Input()
    public displayErrors: boolean;
    public formGroup: UntypedFormGroup;
    @Input()
    public isLoading: boolean;
    @Output()
    public productChange = new EventEmitter<Product>();
    public productValue: Product;
    @Output()
    public submitCallback = new EventEmitter<any>();

    constructor(protected accountService: AccountService) { }

    @Input()
    public get product(): Product {
        return this.productValue;
    }

    public set product(value) {
        if (this.productValue && value.id !== this.productValue.id) {
            this.productValue = new Product(value);
            this.initForm();
        } else {
            this.productValue = new Product(value);
        }
        // this.checkDisable();

        this.productChange.emit(this.productValue);
    }

    public doSubmit(param?: any) {
        ObjectUtils.cleanUpNullValues<Product>(this.productValue);
        this.productChange.emit(this.productValue);
        this.submitCallback.emit(param);
    }

    protected abstract initForm(): void;
}
