import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'sp-list',
    template: `
        <nz-list [nzBordered] [nzDataSource]="nzDataSource" [nzRenderItem]="nzRenderItem" [nzItemLayout]="nzItemLayout">
            <ng-content></ng-content>
        </nz-list>
    `,
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
    @Input() nzDataSource: any[]; // Adjust the type according to your data
    @Input() nzRenderItem: TemplateRef<any>;
    @Input() nzItemLayout: 'horizontal' | 'vertical';
    constructor() { }

    ngOnInit() { }
}
