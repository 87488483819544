import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../services/product.service';
import { Product } from '../model/product.model';
import { Filter, FilterValue, ListItem, Pagination, SelectedFilter, Sort } from 'src/app/shared/model/list-item.model';
import { ProductTypes } from '../enums/product-types-enum';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Globals } from '../../../_configs/globals';
import { RowTypes } from '../../../shared/enums/row-types.enum';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit {
    public buttonLink = ' ';
    public formatter;
    public isLoading: boolean;
    public itemList: ListItem[] = [];
    public lang: string;
    public leftSideFilters: Filter[] = [];
    public listType: RowTypes = RowTypes.PRODUCT;
    public pagination: Pagination = new Pagination();
    public productList: Product[];
    public products;
    public sorts: Sort[] = [];

    private selectedFilters: SelectedFilter[] = [];

    constructor(public translate: TranslateService, protected globals: Globals, private productService: ProductService, private localize: LocalizeRouterService) {}

    public addRideauFilter(): void {
        const statusFilter = new Filter();
        statusFilter.filterField = 'productTypeId';
        statusFilter.filterName = this.translate.instant('FORM.TYPE-DE-PRODUIT');
        statusFilter.filterValues = Object.keys(ProductTypes)
            .filter((val) => isNaN(Number(val)) && val !== 'COTISATION')
            .map((key) => {
                return {
                    filterValueName: this.translate.instant('PRODUCT-TYPE.' + key),
                    filterValue: ProductTypes[key]
                } as FilterValue;
            });
        statusFilter.filterValues.unshift({
            filterValueName: this.translate.instant('TOUS'),
            filterValue: '',
            selected: true
        } as FilterValue);

        this.leftSideFilters.push(statusFilter);
    }

    public getProducts(): void {
        this.isLoading = true;
        // rest call with filters & pagination
        this.productService.getProducts([...this.selectedFilters], this.pagination).subscribe((products: Product[]) => {
            this.productList = products;
            this.itemList = this.productList.map((product: Product) => {
                const item = product.toListItem(this.lang, this.translate);
                item.itemUrl = `/admin/product/${product.id}/modify/RENSEIGNEMENTS-GENERAUX`;
                return item;
            });
            this.isLoading = false;
        });
    }

    public initFilter(): void {
        this.selectedFilters = [];
        this.leftSideFilters = [];
        this.addRideauFilter();
    }

    /*Filters*/
    public initPagination(): void {
        this.pagination.limit = 12;
        this.pagination.offset = 0;
        this.pagination.total = 0;
    }

    public async newProduct(): Promise<void> {
        this.productService.createBlankProductAndRedirectToForm();
    }

    public ngOnInit(): void {
        this.lang = this.translate.currentLang;
        this.formatter = new Intl.NumberFormat('fr-CA', {
            style: 'currency',
            currency: 'CAD',
            //currencyDisplay: 'symbol',
            minimumFractionDigits: 2
        });

        this.initFilter();
        this.initPagination();
        this.getProducts();
    }

    public onFilterChange(event: SelectedFilter) {
        this.selectedFilters = [event];
        this.getProducts();
    }

    public onPageChange(event: Pagination) {
        this.pagination.offset = event.offset;
        this.getProducts();
    }

    public onSearchChange(event: string) {
        //Currently Not Used in Backend...
        this.getProducts();
    }

    public onSortChange(event: any) {
        this.getProducts();
    }
}
