import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enumToArray' })
export class EnumToArrayPipe implements PipeTransform {
    transform<T>(value: T, arg: string): any[] {
        const enumLength = Object.keys(value).length;
        const enumKeys = Object.values(value);
        enumKeys.splice(0, enumLength / 2);
        if (arg && arg === 'usePropsAsValues') {
            return enumKeys.reduce(
                (acc: any[], curr: any) => [
                    ...acc,
                    {
                        [curr]: value[curr]
                    }
                ],
                []
            );
        }
        return enumKeys.reduce(
            (acc: any[], curr: any) => [
                ...acc,
                {
                    [value[curr]]: curr
                }
            ],
            []
        );
    }
}
