import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { ReportsComponent } from './components/reports/reports.component';

@NgModule({
    declarations: [ReportsComponent],
    imports: [FormsModule, ReactiveFormsModule, TranslateModule, SharedModule],
    exports: []
})
export class ReportsModule {}
