import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/_configs/globals';
import { MainService } from 'src/app/shared/services/main.services';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { IndexedDBStorage } from 'src/app/shared/services/indexedDB.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService extends MainService {
    private userID: any = null;

    constructor(protected httpClient: HttpClient, protected globals: Globals, private authService: AuthService, private indexedDBStorage: IndexedDBStorage) {
        super(httpClient, globals);

        this.authService.userObservable$.subscribe((user) => {
            if (user) {
                this.setUserID(user.id);
            }
        });
    }
    public setUserID(userId: any): void {
        const usrId = Number(userId);
        if (usrId && !Number.isNaN(userId)) {
            this.userID = userId;
        }
    }
    requestUserMemberships = (): Observable<any> => {
        return this.indexedDBStorage.getDataByKey(this.indexedDBStorage.userMemberShipsStore, this.userID).pipe(
            switchMap((data) => {
                if (data) {
                    return of(data);
                }
                const endpoint = this.uri + this.globals.endpoints.user.getUserMembershipsEndpoint(this.userID);
                return this.httpClient.get(endpoint).pipe(
                    switchMap((data) => {
                        this.indexedDBStorage.addData(this.indexedDBStorage.userMemberShipsStore, data, this.userID);
                        return of(data);
                    })
                );
            })
        );
    };
}
