import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-calendar',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
  ],
  template: `
    <div class="header">
      <button type="button" (click)="month = month - 1"></button>
      <button type="button" (click)="month = month + 1"></button>
      <div class="spacer"></div>
      <select [(ngModel)]="month">
        <option *ngFor="let month of months" [value]="month">
          {{ monthToString(month) }}
        </option>
      </select>
      <select [(ngModel)]="year">
        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
      </select>
    </div>
    <div class="days">
      <div *ngFor="let weekday of weekdays">{{ weekdayToString(weekday) }}</div>
      <ng-container *ngFor="let day of days">
        <button
          type="button"
          [class.selected]="areEqual(day, selected)"
          *ngIf="day && containsInclusive(min, max, day)"
          (click)="select(day)"
        >
          {{ day.getDate() }}
        </button>
        <div
          class="disabled"
          [class.selected]="areEqual(day, selected)"
          *ngIf="day && !containsInclusive(min, max, day)"
        >
          {{ day.getDate() }}
        </div>
        <div class="empty" *ngIf="!day"></div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, ControlValueAccessor {
  private displayed: Date;
  selected: Date;
  @Input() visible = false;
  private onChange: (value: Date) => void;
  @Input() locale = 'fr-CA';
  @Input() min: Date = new Date(2019, 11, 5);
  @Input() max: Date = new Date(2019, 11, 23);
  days: Date[];
  years: number[];
  months = Array.from(Array(12).keys());
  weekdays = Array.from(Array(7).keys());
  set month(month: number) {
    this.displayed.setMonth(month);
    this.computeDays();
  }
  get month() {
    return this.displayed.getMonth();
  }
  set year(year: number) {
    this.displayed.setFullYear(year);
    this.computeDays();
  }
  get year() {
    return this.displayed.getFullYear();
  }
  containsInclusive(min: Date, max: Date, value: Date): boolean {
    return (
      min &&
      max &&
      min.getFullYear() <= value.getFullYear() &&
      value.getFullYear() <= max.getFullYear() &&
      min.getMonth() <= value.getMonth() &&
      value.getMonth() <= max.getMonth() &&
      min.getDate() <= value.getDate() &&
      value.getDate() <= max.getDate()
    );
  }
  areEqual(value1: Date, value2: Date): boolean {
    return (
      value1 &&
      value2 &&
      value1.getFullYear() === value2.getFullYear() &&
      value1.getMonth() === value2.getMonth() &&
      value1.getDate() === value2.getDate()
    );
  }
  computeDays() {
    this.days = [];
    const current = new Date(this.displayed);
    current.setDate(1);
    for (let i = 0; i < current.getDay(); i += 1) {
      this.days.push(null);
    }
    while (current.getMonth() === this.month) {
      this.days.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
  }
  weekdayToString(weekday: number): string {
    const date = new Date();
    while (date.getDay() !== weekday) {
      date.setDate(date.getDate() + 1);
    }
    return date.toLocaleString(this.locale, { weekday: 'short' });
  }
  monthToString(month: number): string {
    const date = new Date();
    while (date.getMonth() !== month) {
      date.setMonth(date.getMonth() + 1);
    }
    return date.toLocaleString(this.locale, { month: 'long' });
  }
  ngOnInit(): void {
    this.years = Array.from(Array(20 + 1).keys()).map((v) => v + 2019 - 10);
    this.displayed = new Date();
    this.displayed.setDate(1);
    this.month = this.displayed.getMonth();
  }
  select(value: Date): void {
    this.selected = value;
    this.onChange(value);
  }
  writeValue(value: Date): void {
    this.selected = value;
  }
  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    return;
  }
  setDisabledState(isDisabled: boolean): void {
    return;
  }
}
