import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observer, Observable } from 'rxjs';
import { RideauNotificationService } from '../../../services/rideau-notification.service';
import { ItemFileType, ItemRowFile } from '../../../model/item-row-file.model';
import { FormItemSelect, FormItemSelectAction, FormItemSelectService } from 'src/app/shared/services/form.row.select.service';
import { Globals } from 'src/app/_configs/globals';
import { ContentType, VitrineAdditionalMaterialTypeBase } from 'src/app/concepts/vitrine/enums/vitrine-additional-material-type.enum';

@Component({
    selector: 'app-item-row-file',
    templateUrl: './item-row-file.component.html',
    styleUrls: ['./item-row-file.component.scss']
})
export class ItemRowFileComponent implements OnInit {
    // A boolean indicating whether a file has been successfully uploaded or not.
    public fileUploaded: boolean;
    // The URL where the file will be uploaded.
    public uploadUrl: string;
    // The URL of the uploaded image.
    public urlImageResponse: string;
    // The name of the person who should be credited for the uploaded image.
    public creditPhoto: string;
    // The name of the uploaded file.
    public uploadName: string;
    // The name of the file being uploaded.
    public fileName: string;
    // A boolean indicating whether the component is currently in the process of loading.
    public loading = false;
    // The type of file that can be uploaded.
    public selectedFileType: ItemFileType;
    // The type of item selected with the UI widget
    public selectedFormSelect: number;
    // A boolean indicating whether to display the image or not.
    @Input() displayImage = true;
    // A boolean indicating whether to display the image or not.
    @Input() shouldDisplayImage = true;
    // An array of file types that can be uploaded.
    @Input() types: FormItemSelect[];
    // The title of the file input field.
    @Input() title: string;
    // The name of the file input field.
    @Input() name: string;
    // The source URL of the file.
    @Input() fileSrc: string;
    // The maximum allowed size of the file in bytes.
    @Input() maxFileSize: number;
    // The attribute for the file source.
    @Input() attribute;
    // The accepted file types.
    @Input() acceptedFileTypes: string[];
    // A boolean indicating whether the component is in vitrine mode.
    @Input() isVitrine?: boolean;
    // The file item.
    @Input() item: ItemRowFile;
    // A boolean indicating whether the component is disabled or not.
    @Input() isDisabled = false;
    // A boolean indicating whether to display the credit information for the uploaded image.
    @Input() showCredit = true;
    // A boolean indicating whether to display the "Select File" label or not.
    @Input() showSelectFileLabel: boolean;
    // An event emitter that emits the index of the selected file.
    @Output() selected: EventEmitter<number> = new EventEmitter();
    // An event emitter that emits whether the file has been successfully uploaded and the URL of the uploaded file.
    @Output() uploaded: EventEmitter<{ fileUploaded: boolean; itemId: string; item: ItemRowFile }> = new EventEmitter();
    // An event emitter that emits the removed file item.
    @Output() removed: EventEmitter<ItemRowFile> = new EventEmitter();
    @Output() itemChanged = new EventEmitter<ItemRowFile>();

    // An observable that holds the remaining items.
    public readonly remainingItems$: Observable<FormItemSelect[]> = this.formItemSelectService.remainingItems$;
    // An observable that holds the selected items.
    public readonly selectedItem$: Observable<FormItemSelect> = this.formItemSelectService.selectedItem$;

    constructor(
        private globals: Globals,
        private formItemSelectService: FormItemSelectService,
        private notification: RideauNotificationService,
        private translate: TranslateService
    ) {}

    /**
     * Initializes the component after Angular first displays the data-bound properties and sets the default image display settings
     * @returns {void}
     */
    ngOnInit(): void {
        if (!this.maxFileSize) {
            this.maxFileSize = this.globals.maxImageSize;
        }
        if (this.item.file !== '') {
            this.uploadUrl = this.item.file;
            this.urlImageResponse = this.item.file;
            this.creditPhoto = this.item.desc;
            if (this.item.fileTypes && this.item.fileTypes.length) {
                this.formItemSelectService.setSelectedItem({
                    id: this.item.fileTypes[0].typeId,
                    action: FormItemSelectAction.SELECT
                });
                this.selectedFileType = this.item.fileTypes[0];
                this.selectedFormSelect = +this.item.fileTypes[0].typeId;
            }
            this.uploadName = this.item.desc;
            this.displayImage = this.uploadUrl && !this.uploadUrl.endsWith('.pdf');
        } else {
            this.uploadUrl = '';
            this.urlImageResponse = '';
        }
    }

    /**
     * Executes before uploading a file, checks if the file type and size are valid, and displays the image if applicable.
     * @param {File} file: The file to be uploaded
     * @returns {Observable<unknown>}: The result of the upload operation as an observable
     */
    beforeUpload = (file: File): Observable<unknown> => {
        return new Observable((observer: Observer<boolean>) => {
            const isFileTypeOk = this.acceptedFileTypes.indexOf(file.type) > -1;
            if (!isFileTypeOk) {
                this.notification.error(this.translate.instant('FORM.FORMAT-NON-SUPPORTE'));
                observer.complete();
                return;
            }

            const isLt2M = file.size > this.maxFileSize;
            if (isLt2M) {
                this.notification.error(this.translate.instant('FORM.FICHIER-TROP-VOLUMINEUX'));
                observer.complete();
                return;
            }

            if (file.type.indexOf('image') > -1) {
                this.displayImage = true;
                // check height
                this.checkImageDimension(file).then((dimensionRes) => {
                    if (!dimensionRes) {
                        this.notification.error(this.translate.instant('ERRORS.LARGEUR-HAUTEUR-MIN'));
                        observer.complete();
                        return;
                    }
                    observer.next(isFileTypeOk && !isLt2M && dimensionRes);
                    observer.complete();
                });
            } else if (file.type.indexOf('application') > -1) {
                observer.next(isFileTypeOk && !isLt2M);
                observer.complete();
                this.displayImage = false;
            }
        });
    };

    /**
     * Returns the VitrineAdditionalMaterialTypeBase corresponding to the given id.
     * @param {string} id: The id of the VitrineAdditionalMaterialTypeBase.
     * @returns {string} The corresponding VitrineAdditionalMaterialTypeBase.
     */
    public getAdditionalMaterialTypeBaseById = (id: string): string => VitrineAdditionalMaterialTypeBase[Number(id)];

    /**
     * Checks the dimensions of an image file to ensure it meets the minimum required height and width
     * @param {File} file: The image file to check the dimensions of
     * @returns {Promise<boolean>}: A promise that resolves to a boolean indicating whether the dimensions meet the minimum requirements or not
     */
    private checkImageDimension(file: File): Promise<boolean> {
        return new Promise((resolve) => {
            const img = new Image(); // create image
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);
                resolve(height >= 1 && width >= 1);
            };
        });
    }

    /**
     * Converts a File object to a base64-encoded string and invokes the provided callback with the result.
     * @param {File} img - The image file to be converted to a base64-encoded string.
     * @param {(img: string) => void} callback - A function to be called with the resulting base64-encoded string.
     * @returns {void}
     */
    private getBase64(img: File, callback: (img: string) => void): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result.toString()));
        reader.readAsDataURL(img);
    }

    /**
     *Handles the file upload and updates the component's properties accordingly
     *@param {Object} info - Object containing information about the uploaded file
     *@param {Object} info.file - Information about the uploaded file
     *@param {string} info.file.status - Status of the uploaded file (uploading, done, or error)
     *@returns {void}
     */
    public handleChange(info: { file: NzUploadFile }): void {
        if (info.file.status === 'uploading') {
            this.loading = true;
            this.fileUploaded = false;
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, (img: string) => {
                this.loading = false;
                this.uploadName = info.file.name;
                this.uploadUrl = img;
                this.displayImage = this.uploadUrl && !this.uploadUrl.endsWith('.pdf');
                this.fileUploaded = true;
            });

            this.urlImageResponse = info.file.response.completeUrl;
            this.item.file = this.urlImageResponse;
        }
        if (info.file.status === 'error') {
            this.loading = false;
            this.fileUploaded = false;
            throw info.file.error;
        }
        const itemRowFile: ItemRowFile = {
            desc: this.isVitrine ? this.selectedFileType.typeId.toString() : info.file.name,
            file: info.file.response.completeUrl,
            id: this.isVitrine ? ContentType.VITRINE : this.item.id,
            hasUpload: true
        };

        this.uploaded.emit({
            fileUploaded: this.fileUploaded,
            itemId: this.item.id as string,
            item: itemRowFile
        });
        this.formItemSelectService.setSelectedItem(undefined);
    }

    /**
     * Updates the credit photo field of the current item with the provided value
     * @param {string} evt - The new value for the credit photo field
     * @returns {void}
     */
    creditChange(evt: string): void {
        this.item.desc = this.creditPhoto = evt;
    }

    /**
     * Handles the selection of a file type.
     * If a file type is cancelled after it's been selected, and
     * add the selected items to the remaining items back.
     * @param {number} typeId - The ID of the selected file type.
     * @returns {void}
     */
    selectFileTypeHandler(id: number): void {
        if (!id) {
            this.formItemSelectService.setSelectedItem(<FormItemSelect>{
                id: Number(this.selectedFileType.typeId),
                action: FormItemSelectAction.DELETE,
                rowId: null
            });
            this.selectedFileType = undefined;
            return;
        }
        const _typeId = +id;
        this.selectedFileType = this.getFileTypeById(_typeId);
        if (!this.selectedFileType) {
            return;
        }
        this.item = {
            ...this.item,
            desc: this.selectedFileType.label
        };
        this.uploadName = ''; // must be reset to display the upload button
        this.selected.emit(_typeId);
    }

    /**
     * Removes an uploaded file and emits the removed item.
     * @param {string} selectedFileType - The ID of the selected file type to remove.
     * @returns {void}
     */
    removeUpload(): void {
        if (this.isVitrine) {
            this.removeUploadForVitrine();
        }
        this.fileUploaded = false;
        this.uploadUrl = '';
        this.urlImageResponse = '';
        this.creditPhoto = null;
        this.item.file = null;
        this.item.desc = null;
    }

    /**
     * Removes the upload for a vitrine and emits the removed item
     * @returns {void}
     */
    private removeUploadForVitrine(): void {
        /** Define the item to remove */
        const itemToRemove: ItemRowFile = {
            ...this.item,
            desc: VitrineAdditionalMaterialTypeBase[+this.selectedFileType.typeId]
        };

        /** Emit the removed item */
        this.removed.emit(itemToRemove);

        /** If no type ID is selected, return */
        if (!this.selectedFileType.typeId) {
            return;
        }

        /** Set the selected item and action to delete */
        this.formItemSelectService.setSelectedItem(<FormItemSelect>{
            id: Number(this.selectedFileType.typeId),
            action: FormItemSelectAction.DELETE
        });

        /** Clear the selected file and form select items */
        this.selectedFileType = undefined;
        this.selectedFormSelect = undefined;
    }

    /**
     * Gets the file type from the given ID
     * @param {number} id: the ID of the file type
     * @returns {ItemFileType|null}: an object representing the file type if found, null otherwise
     */
    private getFileTypeById = (id: number, list?: FormItemSelect[]): ItemFileType => this.formItemSelectService.getFileTypeById(id, list);

    onItemChange(updatedItem: ItemRowFile, originalItem: ItemRowFile) {
        this.itemChanged.emit(updatedItem);
      }
}
