<form id="profile-form" class="mb-4" nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div class="row">
        <div class="col-md-6">
            <sp-quill-editor
                label="{{ 'FORM.DESCRIPTION' | translate }} *"
                [value]="descriptionModel"
                [modules]="descriptionModules"
                placeholder="{{ 'FORM.DESCRIPTION-PLACEHOLDER' | translate }}"
                (contentChanged)="contentChanged($event)"
                [disabled]="formGroup.disabled"
            ></sp-quill-editor>
        </div>
        <div class="col-md-6">
            <nz-form-control>
                <label>{{ 'FORM.PHOTO' | translate }} *</label>
            </nz-form-control>

            <app-img-upload
                [currentImage]="venue.photo"
                [uploadAction]="uploadAction"
                [isDisabled]="formGroup.disabled"
                [maxFileWeigth]="1000"
                uploadActionName="venuemain"
                (onUploadFile)="onUploadFileCallback($event)"
            ></app-img-upload>

            <span class="has-error" *ngIf="displayErrors && formGroup.get('photo').errors">
                {{ 'CHAMP-REQUIS' | translate }}
            </span>
        </div>
    </div>
    <ng-container *ngIf="venue.statusId === 1 || venue.statusId === 4">
        <button *appPermission="3; objectOrganizationId: venue.organizationId" nz-button [nzLoading]="isLoading" class="btn btn--default">
            {{ 'ENREGISTER-ET-SOUMETTRE' | translate }}
        </button>
        <p *appPermission="1; objectOrganizationId: venue.organizationId" class="d-inline-block ml-2">
            {{ 'OU' | translate }}
            <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
            <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
        </p>
    </ng-container>
    <ng-container *ngIf="(venue.statusId === 3 || venue.statusId === 2) && !formGroup.disabled">
        <button
            *appPermission="1; objectOrganizationId: venue.organizationId"
            nz-button
            [disabled]="formGroup.invalid || !descriptionModel || !formGroup.get('photo').value"
            [nzLoading]="isLoading"
            class="btn btn--default"
        >
            {{ 'ENREGISTER' | translate }}
        </button>
    </ng-container>
</form>
