<div *ngIf="!onlyResidency">
    <div nz-row class="mb-3" nzGutter="24">
        <ng-container *ngIf="onlyOrgas">
            <div nz-col nzMd="12">
                <label>{{ 'FORM.MEMBER-NAME' | translate }}</label>
                <input nz-input [(ngModel)]="memberName" type="text" name="memberName" id="memberName" (ngModelChange)="this.memberNameSubject.next($event)" />
            </div>
            <div nz-col nzMd="12">
                <label for="networkId" class="repertory-filter__label d-block">{{ 'FORM.RESEAU' | translate }}</label>
                <nz-select
                    name="networkId"
                    id="networkId"
                    nzShowSearch
                    nzAllowClear
                    style="width: 100%"
                    [(ngModel)]="networkFilter.value"
                    (ngModelChange)="handleChange()"
                >
                    <nz-option *ngFor="let network of networkList" [nzLabel]="network.getTranslatedProperty(lang, 'name')" [nzValue]="network.id"></nz-option>
                </nz-select>
            </div>
        </ng-container>
        <div nz-col nzMd="12" *ngIf="onlyVenues">
            <label>{{ 'CAPACITE-DE-SALLE' | translate }}</label>
            <div style="display: flex; line-height: 45px">
                <span class="mr-1">{{ 'ENTRE' | translate }}</span>
                <input type="number" nz-input [(ngModel)]="capacityMin" (ngModelChange)="this.capacityMinSubject.next($event)" placeholder="Min" />
                <span class="mr-1 ml-1">et</span>
                <input type="number" nz-input [(ngModel)]="capacityMax" (ngModelChange)="this.capacityMaxSubject.next($event)" placeholder="Max" />
                <span class="ml-1">{{ 'PLACES' | translate }}</span>
            </div>
        </div>
    </div>
    <div nz-row class="mb-2" nzGutter="24">
        <div nz-col nzMd="12">
            <label>{{ 'PROVINCE' | translate }}</label>
            <input nz-input [(ngModel)]="state" type="text" name="state" id="state" (ngModelChange)="this.stateSubject.next($event)" />
        </div>
        <div nz-col nzMd="12">
            <label>{{ 'FORM.VILLE' | translate }}</label>
            <input nz-input [(ngModel)]="city" type="text" name="city" id="city" (ngModelChange)="this.citySubject.next($event)" />
        </div>
    </div>
</div>

<div *ngIf="onlyResidency">
    <div nz-row class="mb-2" nzGutter="24">
        <div nz-col nzMd="12">
            <label for="residencyType">{{ 'RESIDENCY-FORM.RESIDENCY-TYPE-TITLE' | translate }}</label>
            <br />
            <nz-select
                class="residency-select-search"
                id="residencyType"
                [(ngModel)]="residencyType"
                (ngModelChange)="setResidencyType($event)"
                nzMode="multiple"
            >
                <ng-container *ngFor="let type of residencyTypes">
                    <nz-option [nzValue]="type" nzLabel="{{ getResidencyTypeById(type) }}"></nz-option>
                </ng-container>
            </nz-select>
        </div>

        <div nz-col nzMd="12">
            <label for="disponibilityPeriod"> {{ 'RESIDENCY-FORM.DISPONIBILITY-TITLE' | translate }}</label>
            <br />
            <nz-select
                class="residency-select-search"
                id="disponibilityPeriod"
                [(ngModel)]="disponibilityPeriod"
                (ngModelChange)="setResidencyAvailabilityPeriod($event)"
                nzMode="multiple"
            >
                <ng-container *ngFor="let availability of residencyAvailabilities">
                    <nz-option [nzValue]="availability" nzLabel="{{ getResidencyAvailabilityById(availability) }}"></nz-option>
                </ng-container>
            </nz-select>
        </div>

        <div nz-col nzMd="8" class="mt-3">
            <label for="disponibilityPeriod">{{ 'RESIDENCY-FORM.MINIMUM-STAGE-WIDTH' | translate }}</label>
            <br />
            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" #totalStageWidthValue="ngModel" [(ngModel)]="totalStageWidth" (ngModelChange)="setResidencyTotalStageWidth($event)"></app-input-meter>
            <form-error *ngIf="totalStageWidthValue.errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
        </div>
        <div nz-col nzMd="8" class="mt-3">
            <label for="disponibilityPeriod">{{ 'RESIDENCY-FORM.MINIMUM-STAGE-DEPTH' | translate }}</label>
            <br />
            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" #totalSceneDepthValue="ngModel" [(ngModel)]="totalSceneDepth" (ngModelChange)="setResidencyTotalSceneDepth($event)"></app-input-meter>
            <form-error *ngIf="totalSceneDepthValue.errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
        </div>
        <div nz-col nzMd="8" class="mt-3">
            <label for="disponibilityPeriod">{{ 'RESIDENCY-FORM.MINIMUM-CLEARANCE-HEIGHT' | translate }}</label>
            <br />
            <app-input-meter pattern="^\d+(\.\d+)?(,\d+)?$" #clearHeightLampsValue="ngModel" [(ngModel)]="clearHeightLamps" (ngModelChange)="setResidencyClearHeightLamps($event)"></app-input-meter>
            <form-error *ngIf="clearHeightLampsValue.errors?.pattern" [value]="'ERRORS.INVALID-FORMAT' | translate"></form-error>
        </div>

        <div nz-col nzMd="24" class="mt-4">
            <label>{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-TITLE' | translate }}</label>
        </div>
        <div nz-col nzMd="8" class="mt-3">
            <sp-checkbox [(ngModel)]="hasAttachmentPoints" (ngModelChange)="setResidencyHasAttachmentPoint($event)">
                <div class="d-inline-block float-right">
                    <span class="checkbox-search-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-1' | translate }}</span>
                </div>
            </sp-checkbox>
        </div>
        <div nz-col nzMd="12" class="mt-3">
            <sp-checkbox [(ngModel)]="hasDanceFloor" (ngModelChange)="setResidencyHasDanceFloor($event)" >
                <div class="d-inline-block float-right">
                    <span class="checkbox-search-title d-block pt-1">{{ 'RESIDENCY-FORM.TECHNICAL-EQUIPMENT-FIELD-3' | translate }}</span>
                </div>
            </sp-checkbox>
        </div>
    </div>
</div>
