import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MainService } from 'src/app/shared/services/main.services';

import { Globals } from '../../../../_configs/globals';
import { PxUser } from '../../model/project-x-user';
import { OrganizationManagerService } from '@app/concepts/organization/services/organizations-manager.service';
import { environment } from '@env';
import { IndexedDBStorage } from '@app/shared/services/indexedDB.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends MainService {
    private user: PxUser | null;

    public userChange: EventEmitter<any> = new EventEmitter();

    private userSubject$: BehaviorSubject<PxUser> = new BehaviorSubject<PxUser>(null);
    public userObservable$: Observable<PxUser> = this.userSubject$.asObservable();

    get User(): PxUser {
        return this.user;
    }

    constructor(protected globals: Globals, private http: HttpClient, private organizationManagerService: OrganizationManagerService, private indexedDBStorage: IndexedDBStorage) {
        super(http, globals);
    }

    /**
     * @summary Return the idToken in the JWT format.
     * @return {string} id_token in JWT Format
     */
    getRawIdToken(): string {
        return localStorage.getItem(StorageKey.RIDEAU_TOKEN);
    }

    loginV2(credentials: { email: string; password: string }): Observable<HttpResponse<{ user: PxUser; isUpdate: boolean }>> {
        return this.http
            .post<{ user: PxUser; isUpdate: boolean }>(
                this.uri + this.globals.endpoints.auth.login,
                { ...credentials },
                {
                    observe: 'response'
                }
            )
            .pipe(this.loginPipe.bind(this));
    }

    logout(shouldBeRedirected = true): void {
        this.clearUser();
        this.clearUserOrganizations();
        if (shouldBeRedirected) {
            window.location.href = environment.VITRINE_URL;
        }
    }

    clearUserOrganizations = (): void => {
        this.organizationManagerService.setUserOrganizations([]);
    };

    clearUser(): void {
        localStorage.clear(); // Clear the application data
        this.user = null;
        this.userSubject$.next(null);
        this.indexedDBStorage.clearObjectStore(this.indexedDBStorage.userMemberShipsStore);
    }
    /**
     * @deprecated
     *
     * Was used for authentification with the msal service connected to Azure
     * To get the current user you should use authService.User or subscribe to userObservable$
     *
     * @returns
     */
    async populateUser(): Promise<PxUser> {
        return new Promise((resolve) => {
            resolve(this.user);
        });
    }

    resetPasswordRequest(email: string): Observable<any> {
        return this.http.patch(this.uri + this.globals.endpoints.auth.askForNewPassword, { email });
    }

    sendNewPassword(newPasswordInfo: { newPassword: string; userId }): Observable<any> {
        return this.http.patch(this.uri + this.globals.endpoints.auth.resetPassword, { ...newPasswordInfo });
    }

    sendEmailConfirmation(userId): Observable<any> {
        return this.http.patch(this.uri + this.globals.endpoints.auth.emailConfirmationRoute, { userId: userId });
    }

    register(registerInfo: { firstName: string; lastName: string; email: string; password: string; receiverNewsletter: 0 | 1 }): Observable<any> {
        return this.http.post(this.uri + this.globals.endpoints.auth.register, {
            ...registerInfo
        });
    }

    confirmEmailChangeRequest(token: string): Observable<any> {
        return this.http.patch(this.uri + this.globals.endpoints.auth.confirmEmailChangeRequest, { requestId: token });
    }

    onAppInit(): Promise<unknown> {
        return this.fetchUserData();
    }

    getUserIdFromStorage(): string {
        return localStorage.getItem(StorageKey.USERID);
    }

    registerToNewsLetter(email: string): Promise<unknown> {
        return new Promise((resolve, reject) => {
            try {
                const formData = new FormData();

                formData.append('ci_email', email);
                formData.append('ci_groups', '20');
                formData.append('ci_account', '91f582af-62a4-420f-0596-377b527203fa');
                formData.append('ci_language', 'fr_ca');

                const request = new XMLHttpRequest();

                request.open('POST', 'https://app.cyberimpact.com/optin');

                request.setRequestHeader('Access-Control-Allow-Origin', '*');
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

                request.onload = (event) => {
                    if (request.readyState === request.DONE) {
                        resolve(request.response);
                    }
                };

                request.send(formData);
            } catch (err) {
                console.error(err);
                reject(err);
            }
        });
    }

    private loginPipe(loginObserverSource: Observable<HttpResponse<{ user: PxUser; isUpdate: boolean }>>) {
        return new Observable<HttpResponse<PxUser>>((subscriber) => {
            return loginObserverSource.subscribe({
                next: (httpResponseUser) => this.onLoginSuccess(httpResponseUser),
                error: (error) => subscriber.error(error),
                complete: () => subscriber.complete()
            });
        });
    }

    private onLoginSuccess(httpResponseUser: HttpResponse<{ user: PxUser; isUpdate: boolean }>) {
        this.saveAndSetUser(httpResponseUser.body.user);
        this.saveAuthorizationTokenAndRefreshToken(httpResponseUser.headers.get('access-token'), httpResponseUser.headers.get('refresh-token'));
    }

    private fetchUserData() {
        return new Promise(async (resolve, reject) => {
            const userId = this.getUserIdFromStorage();
            const token = this.getRawIdToken();
            const refreshToken = localStorage.getItem(StorageKey.RIDEAU_REFRESH_TOKEN);
            if (userId && token && refreshToken) {
                try {
                    await this.refreshToken().toPromise();

                    await this.http
                        .get(this.uri + this.globals.endpoints.user.getUserEndpoint(userId), {
                            headers: new HttpHeaders().append('access-token', `Bearer ${token}`)
                        })
                        .pipe(this.onFetchUser.bind(this))
                        .toPromise()
                        .catch((error) => {
                            this.unsetTokens();
                            this.unsetUser();
                        });

                    resolve(true);
                } catch (error) {
                    this.user = null;
                    this.userSubject$.next(null);
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
    }

    private onFetchUser(observable: Observable<PxUser>) {
        return new Observable<PxUser>((subscriber) => {
            return observable.subscribe({
                next: (user) => this.saveAndSetUser(user),
                error: (error) => subscriber.error(error),
                complete: () => subscriber.complete()
            });
        });
    }

    refreshToken(): Observable<any> {
        return this.http
            .post(
                this.uri + this.globals.endpoints.auth.refreshToken,
                {
                    refreshToken: localStorage.getItem(StorageKey.RIDEAU_REFRESH_TOKEN)
                },
                {
                    observe: 'response'
                }
            )
            .pipe(map((response) => this.saveAuthorizationTokenAndRefreshToken(response.headers.get('access-token'), response.headers.get('refresh-token'))));
    }

    private saveAndSetUser(user: PxUser) {
        localStorage.setItem(StorageKey.USERID, user.id);
        this.user = user;
        this.userChange.emit(user);
        this.userSubject$.next(user);
    }

    private unsetUser() {
        localStorage.removeItem(StorageKey.USERID);
        this.user = null;
        this.userChange.emit(null);
    }

    private unsetTokens() {
        localStorage.removeItem(StorageKey.RIDEAU_TOKEN);
        localStorage.removeItem(StorageKey.RIDEAU_REFRESH_TOKEN);
    }

    private saveAuthorizationTokenAndRefreshToken(token: string, refreshToken: string) {
        localStorage.setItem(StorageKey.RIDEAU_TOKEN, `${token}`);
        localStorage.setItem(StorageKey.RIDEAU_REFRESH_TOKEN, `${refreshToken}`);
        return of({ token, refreshToken });
    }
}

export enum StorageKey {
    USERID = 'userID',
    RIDEAU_TOKEN = 'rideau.token',
    RIDEAU_REFRESH_TOKEN = 'rideau.refresh.token'
}
