<app-page-title title="{{ 'PRIVACY-TITLE' | translate }}"></app-page-title>

<div class="container my-5">
    <div class="row">
        <div class="col-sm-4">
            <span class="page-template page-template-text page-template-text--smaller">Dermière mise à jour: 1<sup>er</sup> Août 2020</span>
        </div>
        <div class="col-sm-8">
            <p>Le respect de votre vie privée est de la plus haute importance pour Rideau, qui est responsable du site Scène Pro (le « Site »).</p>
            <p>La présente Politique relative à la protection de la vie privée et à la confidentialité (la « Politique ») a pour but de vous exposer :</p>
            <ul>
                <li>La manière dont sont collectés et traités vos Renseignements personnels. Doivent être considérés comme Renseignements personnels tous les Renseignements susceptibles de vous identifier. Il s’agit notamment, mais sans s’y limiter, de votre prénom et nom, de votre adresse postale, de votre adresse courriel de votre localisation ou encore de votre adresse IP (les « Renseignements personnels ») </li>
                <li>Quels sont vos droits concernant ces Renseignements personnels </li>
                <li>Qui est responsable du traitement des Renseignements personnels collectés et traités </li>
                <li>À qui ces Renseignements sont transmis </li>
            </ul>
            <p>Cette Politique de confidentialité complète les Conditions générales d’utilisation du Site que vous pouvez consulter à l’adresse suivante : <a class="page-template page-template-link" href="https://scenepro.ca/">https://scenepro.ca</a>.</p>

            <h3>COLLECTE DE RENSEIGNEMENTS PERSONNELS</h3>
            <p>Nous collectons les Renseignements personnels suivants :</p>

            <ul>
                <li>Nom</li>
                <li>Prénom</li>
                <li>Adresse postale</li>
                <li>Code postal</li>
                <li>Adresse électronique</li>
                <li>Numéro de téléphone</li>
                <li>Numéro de carte de crédit</li>
                <li>Profession</li>
            </ul>
            <p>Les Renseignements personnels que nous collectons sont recueillis par l’entremise des modes de collecte décrits ci-bas, dans les sections « Formulaires et modes de collecte » et suivantes.</p>

            <h3>FORMULAIRES ET MODES DE COLLECTE</h3>
            <p>Vos Renseignements personnels sont collectés par l’entremise des méthodes suivantes :</p>
            <ul>
                <li>Formulaire d’inscription au site Web ;</li>
            </ul>
            <p>Nous utilisons les Renseignements ainsi collectés pour les finalités suivantes :</p>

            <h3>INTERACTIVITÉ</h3>
            <p>Les Renseignements personnels sont également collectés par le biais de l’interactivité pouvant s’établir entre vous et le Site. Ces Renseignements sont collectés par les moyens suivants :</p>
            <ul>
                <li>Commentaires</li>
                <li>Information pour des offres promotionnelles</li>
                <li>Base de données relative à l’offre de spectacle</li>
                <li>Contact</li>
            </ul>
            <p>Nous utilisons les Renseignements ainsi collectés pour les finalités suivantes :</p>
            <ul>
                <li>Statistiques</li>
                <li>Contact</li>
                <li>Gestion du Site</li>
            </ul>


            <h3>FICHIERS JOURNAUX ET TÉMOINS</h3>
            <p>Nous recueillons certaines informations par le biais de fichiers journaux (« log files ») et de fichiers témoins (« cookies »). Ces fichiers nous permettent de traiter des statistiques et des informations sur le trafic, de faciliter la navigation et d’améliorer le Site.</p>

            <h4>a) Description des fichiers témoins utilisés par le site</h4>
            <p>Il s’agit principalement des informations suivantes :</p>
            <ul>
                <li>Adresse IP</li>
                <li>Système d’exploitation</li>
                <li>Pages visitées et requêtes</li>
                <li>Heure et jour de connexion</li>
            </ul>

            <p>Le recours à de tels fichiers nous permet d’atteindre les objectifs suivants :</p>
            <ul>
                <li>Gestion et amélioration du Site</li>
                <li>Création de profils personnalisés </li>
                <li>Études statistiques</li>
            </ul>

            <p>En naviguant sur le site, des fichiers témoins de tiers peuvent être enregistrés. Il s’agit particulièrement des tiers suivants :</p>
            <ul>
                <li>Google Analytics</li>
            </ul>

            <h4>b) Opposition à l’utilisation de fichiers témoins par le Site</h4>
            <p>Vous pouvez vous opposer à l’enregistrement de ces fichiers témoins en configurant votre logiciel de navigation.</p>
            <p>Dans le cas où vous décidez de désactiver les fichiers témoins, vous pourrez ensuite poursuivre votre navigation sur le Site. Toutefois, tout dysfonctionnement du Site provoqué par cette manipulation ne peut être considéré comme étant de notre fait ou responsabilité.</p>

            <p>Seuls nos employés, et le cas échéant ceux de nos fournisseurs, qui ont besoin d’accéder à vos Renseignements personnels dans le cadre de leurs fonctions peuvent avoir accès à vos Renseignements personnels. Nous nous assurons que les personnes qui ont besoin d’accéder à vos Renseignements personnels ont été formées pour traiter ces renseignements de la façon appropriée. Nous nous engageons à déployer des efforts commercialement raisonnables afin d’éviter la perte, l’usage abusif, la divulgation non autorisée, la modification ou la destruction de vos renseignements personnels. </p>
            <p>Nous nous engageons à ne pas vendre à des tiers ou généralement commercialiser les Renseignements personnels collectés. Cependant, il est possible que nous partagions ces informations avec des tiers pour les raisons suivantes :</p>
            <ul>
                <li>Création de profils de consommation</li>
                <li>Partenariat </li>
                <li>Publicité</li>
                <li>Gestion du Site ou de services</li>
                <li>Hébergement</li>
                <li>Transaction</li>
            </ul>
            <p>Si vous ne souhaitez pas que vos Renseignements personnels soient partagés à des tiers, vous pouvez vous y opposer au moment de la collecte ou à tout moment par la suite, tel que mentionné dans la section « Droit d’opposition, de modification et de retrait ».</p>


            <h3>DURÉE DE CONSERVATION DES RENSEIGNEMENTS PERSONNELS</h3>
            <p>Le responsable du traitement des Renseignements personnels conservera dans ses systèmes informatiques du Site et dans des conditions raisonnables de sécurité l’ensemble des Renseignements personnels collectés pour toute la durée de votre utilisation du Site.</p>
            <p>Si vous désactivez tout Compte utilisateur, nous ne communiquerons pas avec vous par courriel pour vous inviter à réactiver votre compte. Si vous recevez un courriel à cet effet, veuillez nous le communiquer pour qu’une enquête puisse être amorcée. </p>


            <h3>HÉBERGEMENT DES RENSEIGNEMENTS PERSONNELS</h3>
            <p>Pour la durée de l’utilisation du Site, il est possible que vos renseignements personnels soient transités, traités et conservés à l’extérieur du Canada et notamment aux États-Unis.  En conséquence, vous consentez à l’application de la législation en vigueur localement ainsi qu’à la compétence des tribunaux locaux au regard des conditions d’accès à vos renseignements personnels qui pourraient être mis à la disposition des autorités policières et des services de sécurité des pays concernés.</p>


            <h3>RESPONSABLE DU TRAITEMENT DES RENSEIGNEMENTS PERSONNELS</h3>
            <h4>a) La responsable du traitement des Renseignements personnels</h4>
            <p>La responsable du traitement des Renseignements personnels est : Marie-Pier Pilote, Directrice — Projets et développement numérique. Elle peut être contactée par courriel à l’adresse suivante : <a class="page-template page-template-link" href="mailto:mppilote@associationrideau.ca">mppilote&#64;associationrideau.ca</a></p>
            <p>La responsable du traitement des Renseignements personnels est chargée de déterminer les finalités et les moyens relatifs au traitement des Renseignements personnels.</p>

            <h4>b) Obligations de la responsable du traitement des Renseignements personnels</h4>
            <p>La responsable du traitement des Renseignements personnels s’engage à protéger les Renseignements personnels collectés, à ne pas les transmettre à des tiers sans que vous n’en ayez été informé(e) et à respecter les finalités pour lesquelles ces Renseignements ont été collectés.</p>
            <p>De plus, la responsable du traitement des Renseignements personnels s’engage à vous aviser en cas de rectification ou de suppression des Renseignements personnels, à moins que cela n’entraîne pour Rideau des formalités, coûts ou démarches disproportionnés.</p>
            <p>Dans le cas où l’intégrité, la confidentialité ou la sécurité de vos Renseignements personnels est compromise, la responsable du traitement s’engage à vous en informer par tout moyen commercialement raisonnable.</p>


            <h3>DROIT D’OPPOSITION, DE MODIFICATION ET DE RETRAIT</h3>
            <p>Vous avez le droit de vous opposer au traitement de vos Renseignements personnels par le Site. Vous avez également le droit de demander à ce que vos Renseignements personnels soient modifiés ou ne figurent plus, par exemple, dans une liste de diffusion.</p>
            <p>Afin de formuler une opposition au traitement de vos Renseignements personnels ou demander la modification ou le retrait de vos Renseignements personnels, vous devez communiquer par écrit avec la responsable du traitement des Renseignements personnels en lui soumettant suffisamment de renseignements permettant de vous identifier et de repérer vos renseignements personnels. Le cas échéant, nous vous communiquerons les renseignements personnels que nous détenons à votre égard, à moins que des exceptions légales ne s’appliquent. Nous rectifierons, modifierons et supprimerons également tout renseignement personnel qui est inexact et aviserons tout tiers des modifications nécessaires, à moins que ces exceptions légales ne s’appliquent.</p>
            <p>Vous pouvez vous désabonner en tout temps de nos messages électroniques commerciaux ou de toute communication, sauf quant à celles que nous sommes obligés de vous transmettre en vertu de la loi, en suivant le processus décrit dans le message ou la communication que vous avez reçu ou en communiquant avec notre service <a class="page-template page-template-link" href="mailto:support@scenepro.ca">support&#64;scenepro.ca</a>. Le cas échéant, vous pourriez avoir à spécifier quelle adresse courriel est visée par le désabonnement. Dans tous les cas, nous nous engageons à traiter votre demande de désabonnement dans un délai maximal de 10 jours ouvrables.</p>


            <h3>SÉCURITÉ</h3>
            <p>Les Renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.</p>
            <p>Pour assurer la sécurité de vos informations personnelles, nous avons recours aux mesures suivantes :</p>
            <ul>
                <li>Protocole SSL (Secure Sockets Layer)</li>
                <li>Protocole SET (Secure Electronic Transaction) </li>
                <li>Gestion des accès — personne autorisée</li>
                <li>Gestion des accès — personne concernée</li>
                <li>Logiciel de surveillance du réseau</li>
                <li>Sauvegarde automatique</li>
                <li>Développement d’un certificat numérique</li>
                <li>Identifiant/mot de passe</li>
                <li>Pare-feu (Firewalls)</li>
            </ul>
            <p>Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d’assurer la confidentialité de vos contributions ainsi qu’en matière de transactions. Toutefois, comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente lorsqu’on utilise Internet pour transmettre des informations personnelles.</p>

            <h3>LIEN VERS D’AUTRES SERVICES</h3>
            <p>Le Site peut comprendre des hyperliens vers des sites Web qui ne sont pas exploités ou supervisés par nous. La présente Politique ne vise pas les renseignements personnels recueillis sur ces sites et nous vous prions d’examiner toute politique sur la protection des renseignements personnels affichés sur un site que vous visitez avant d’utiliser le site ou de fournir vos renseignements personnels. </p>
            <p>Notez que la présente politique ne vise pas les renseignements personnels recueillis par l’appareil (ou son fabricant) sur lequel vous avez installé le service. </p>

            <h3>CERTIFICATIONS</h3>
            <p>Nos engagements en matière de protection des Renseignements personnels répondent aux exigences du ou des programme(s) suivants :</p>
            <ul>
                <li>TRUSTe : <a class="page-template page-template-link" href="http://truste.org">http://truste.org</a></li>
                <li>WebTrust : <a class="page-template page-template-link" href="http://webtrust.net">http://webtrust.net</a> </li>
            </ul>

            <h3>CONDITIONS DE MODIFICATION DE LA POLITIQUE </h3>
            <p>La présente Politique peut être consultée à tout moment à l’adresse ci-après indiquée : <a class="page-template page-template-link">scenepro.ca</a>.</p>
            <p>Nous nous réservons le droit de la modifier afin de garantir sa conformité avec le droit en vigueur. Par conséquent, vous êtes invité(e) à venir consulter régulièrement cette Politique afin de vous tenir informé(e) des derniers changements qui lui seront apportés. Toutefois, en cas de modification substantielle de cette politique, vous en serez informé(e) par l’envoi d’une infolettre à cet effet.</p>

            <h3>ACCEPTATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h3>
            <p>En navigant sur le Site, vous attestez avoir lu et compris la présente Politique et en acceptez les conditions, en ce qui concerne plus particulièrement la collecte et le traitement de vos Renseignements personnels, ainsi que de l’utilisation de fichiers témoins.</p>

            <h3>LÉGISLATION ET COMMISSION D’ACCÈS À L’INFORMATION</h3>
            <p>Nous nous engageons à respecter les dispositions législatives énoncées dans <em>Loi sur la protection des renseignements personnels dans le secteur privé</em> (RLRQ c. P-39.1).</p>
            <p>Si vous n’êtes pas satisfait de la façon dont nous répondons à vos demandes ou questions en lien avec la cueillette, la conservation, l’utilisation ou la communication de vos renseignements personnels, vous pouvez communiquer avec la Commission d’accès à l’information, un organisme public chargé de surveiller les pratiques en matière de renseignements personnels dans le secteur privé. </p>

            <p class="mb-0">Par téléphone : <a class="page-template page-template-link" href="tel:18885287741">1 888 528-7741</a></p>
            <p class="mb-0">Par télécopieur: <a class="page-template page-template-link" href="tel:14185293102">1 418 529-3102</a> ou <a class="page-template page-template-link" href="tel:15148446170">1 514 844-6170</a></p>
            <p class="mb-0">Par courriel: <a class="page-template page-template-link" href="mailto:cai.communications@cai.gouv.qc.ca">cai.communications&#64;cai.gouv.qc.ca</a></p>
            <p class="mb-0">Par courrier : Bureau 2.36</p>
            <p class="mb-0">525, boul. René-Lévesque Est</p>
            <p>Québec (Québec) G1R 5S9</p>

            <p>ou</p>

            <p class="mb-0">Bureau 18.200</p>
            <p class="mb-0">500, boul. René-Lévesque Ouest</p>
            <p class="mb-0">Montréal (Québec) H2Z 1W7</p>
            <p class="mb-0">Par courriel: <a class="page-template page-template-link" href="http://www.cai.gouv.qc.ca/">http://www.cai.gouv.qc.ca/</a></p>

        </div>
    </div>
</div>
