<ng-container *ngIf="isReady">
    <form nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()">
        <div nz-row nzGutter="16">
            <div nz-col nzMd="16">
                <nz-form-item>
                    <nz-form-control>
                        <label for="editionName">{{ 'FORM.NOM-EDITION' | translate }}</label>
                        <input id="editionName" formControlName="name" />
                    </nz-form-control>
                </nz-form-item>

                <sp-quill-editor
                    label="{{ 'FORM.DESCRIPTION' | translate }}"
                    [value]="descriptionModel"
                    [modules]="descriptionModules"
                    placeholder="{{ 'FORM.DESCRIPTION-PLACEHOLDER' | translate }}"
                    (contentChanged)="contentChanged($event)"
                    [disabled]="formGroup.disabled"
                ></sp-quill-editor>
            </div>
            <div nz-col nzMd="4">
                <app-img-upload
                    [currentImage]="meeting.image"
                    [uploadAction]="uploadAction"
                    [maxCropperWidth]="imageConfig.maxWidth"
                    [maxCropperHeight]="imageConfig.maxHeight"
                    [maxFileWeigth]="2000"
                    uploadActionName="meetingmain"
                    [isDisabled]="formGroup.disabled"
                    (onUploadFile)="onUploadFileCallback($event)"
                    (onFileRemoved)="onFileRemovedCallback($event)"
                ></app-img-upload>
            </div>
        </div>
        <div nz-row nzGutter="16" class="mb-5">
            <div nz-col nzMd="12">
                <sp-select formControlName="organizationId" labelProperty="name" [options]="organizationList">
                    <label for="organizer" class="repertory-filter__label d-block">{{ 'FORM.ORGANISATION-PROPRIETAIRE' | translate }}</label>
                </sp-select>

                <nz-form-item>
                    <nz-form-control>
                        <label for="place">{{ 'FORM.VILLE' | translate }}</label>
                        <input id="place" formControlName="place" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control>
                        <label for="website">{{ 'FORM.SITE-WEB' | translate }}</label>
                        <input id="website" formControlName="website" />
                    </nz-form-control>
                </nz-form-item>

                <sp-radio-button
                    class="input-radioBtn"
                    [Options]="{ false: 'FORM.ONE-DAY', true: 'FORM.MANY-DAYS' }"
                    [translateKeyPrefix]=""
                    [disabled]="false"
                    [validateStatus]="''"
                    formControlName="isDatePeriod"
                    [valueSelected]="formGroup.value.isDatePeriod.toString()"
                    (setValueOnchange)="setIsDatePeriodValue(isDatePeriod, $event)"
                >
                    <label>{{ 'FORM.DATE' | translate }}</label>
                </sp-radio-button>

                <nz-form-item class="m-0">
                    <nz-form-control>
                        <div class="row">
                            <div class="col">
                                <sp-nz-date-picker [control]="formGroup.get('dateFrom')"></sp-nz-date-picker>
                            </div>
                            <ng-container *ngIf="this.formGroup.value.isDatePeriod == 'true' || this.formGroup.value.isDatePeriod == true">
                                <div class="col-1 p-0 text-center">
                                    <span>{{ 'FORM.TO' | translate }}</span>
                                </div>
                                <div class="col">
                                    <sp-nz-date-picker [control]="formGroup.get('dateTo')"></sp-nz-date-picker>
                                </div>
                            </ng-container>
                        </div>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="m-0">
                    <nz-form-control>
                        <label>{{ 'FORM.INSCRIPTION-PERIOD' | translate }}</label>
                        <div class="row">
                            <div class="col">
                                <sp-nz-date-picker [control]="formGroup.get('subscriptionDateFrom')"></sp-nz-date-picker>
                            </div>
                            <ng-container>
                                <div class="col-1 p-0 text-center">
                                    <span>{{ 'FORM.TO' | translate }}</span>
                                </div>
                                <div class="col">
                                    <sp-nz-date-picker [control]="formGroup.get('subscriptionDateTo')"></sp-nz-date-picker>
                                </div>
                            </ng-container>
                        </div>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="m-0">
                    <nz-form-control>
                        <label>{{ 'FORM.PROPOSER-VITRINE-PERIOD' | translate }}</label>
                        <div class="row">
                            <div class="col">
                                <sp-nz-date-picker [control]="formGroup.get('proposalVitrineDateFrom')"></sp-nz-date-picker>
                            </div>
                            <ng-container>
                                <div class="col-1 p-0 text-center">
                                    <span>{{ 'FORM.TO' | translate }}</span>
                                </div>
                                <div class="col">
                                    <sp-nz-date-picker [control]="formGroup.get('proposalVitrineDateTo')"></sp-nz-date-picker>
                                </div>
                            </ng-container>
                        </div>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="m-0">
                    <sp-checkbox formControlName="isActivityOptional">{{ 'FORM.ACTIVITY-CHOICE' | translate }}</sp-checkbox>

                    <p class="font-small ml-4">{{ 'FORM.ACTIVITY-CHOICE-DESC' | translate }}.</p>
                    <nz-form-control>
                        <div class="row pl-4">
                            <div class="col">
                                <sp-nz-date-picker [control]="formGroup.get('activityDateFrom')" [disabled]="!formGroup.value.isActivityOptional"></sp-nz-date-picker>
                            </div>
                            <div class="col-sm-1 p-0 text-center">
                                <span>{{ 'FORM.TO' | translate }}</span>
                            </div>
                            <div class="col">
                                <sp-nz-date-picker [control]="formGroup.get('activityDateTo')" [disabled]="!formGroup.value.isActivityOptional"></sp-nz-date-picker>
                            </div>
                        </div>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="m-0">
                    <sp-checkbox formControlName="isStandOptional">{{ 'FORM.STAND-CHOICE' | translate }}</sp-checkbox>

                    <p class="font-smaller ml-4">{{ 'FORM.STAND-CHOICE-DESC' | translate }}.</p>
                    <nz-form-control>
                        <div class="row pl-4">
                            <div class="col">
                                <sp-nz-date-picker [control]="formGroup.get('standDateFrom')" [disabled]="!formGroup.value.isStandOptional"></sp-nz-date-picker>
                            </div>
                            <div class="col-sm-1 p-0 text-center">
                                <span>{{ 'FORM.TO' | translate }}</span>
                            </div>
                            <div class="col">
                                <sp-nz-date-picker [control]="formGroup.get('standDateTo')" [disabled]="!formGroup.value.isStandOptional"></sp-nz-date-picker>
                            </div>
                        </div>
                    </nz-form-control>
                </nz-form-item>

                <sp-checkbox [classList]="['mb-2', 'py-2']" formControlName="isPublished">
                    <strong>{{ 'FORM.PUBLISH-MEETING' | translate }}</strong>
                </sp-checkbox>
            </div>

            <div nz-col nzMd="4"></div>
        </div>

        <!-- Bouton simple si orga approuvée -->
        <button nz-button [disabled]="formGroup.invalid" class="btn btn--default">
            {{ 'ENREGISTER' | translate }}
        </button>
    </form>
</ng-container>
