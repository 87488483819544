import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Component, EventEmitter, Input, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from '../../../model/organization.model';
import { NetworkService } from '../../../services/network.service';
import { OrganizationService } from '../../../services/organization.service';
import { OrganisationStatus } from '../../../enums/organization-status.enum';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Permission } from '@app/shared/enums/roles.enum';

@Component({
    selector: 'app-organization-members',
    templateUrl: './organization-members.component.html',
    styleUrls: ['./organization-members.component.scss']
})
export class OrganizationMembersComponent implements OnInit {
    @Input()
    organization: Organization;
    organizationList: Organization[] = [];
    networkMembers: Organization[] = [];
    checkedCollapse = false;
    isAdding = false;
    elementsLabel?: string;
    searchChange = new EventEmitter<string>();
    lang = this.translate.currentLang;
    public permissions = Permission;
    orgaToAddId: number;

    constructor(
        private organizationService: OrganizationService,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private networkService: NetworkService,
        private translate: TranslateService,
        private modalService: NzModalService,
        private notification: RideauNotificationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getOrganizationMembers();
        this.elementsLabel = this.elementsLabel || this.translate.instant('ELEMENTS');
    }

    getOrganizationMembers(): void {
        this.networkService.getNetworkDetail(this.organization.id).subscribe((organizations) => {
            this.networkMembers = organizations;
            this.getOrganizations();
        });
    }

    /**
     * récupère la liste des organisations approuvées
     */
    getOrganizations(): void {
        this.organizationService.getOrganizations([{ field: 'statusId', value: OrganisationStatus.APPROUVE }], null, null).subscribe((data) => {
            this.organizationList = this.orgasNotInNetwork(data);
        });
    }

    goToOrga(orgId: number) {
        const transTab: any[] = this.localizeRouter.translateRoute(['/organization/', orgId]) as any[];
        this.router.navigate([...transTab]);
    }

    /**
     * Renvoie la liste passée en parametre sans les organisation déjà
     * présentes dans le réseau.
     * @param list
     */
    private orgasNotInNetwork(list: Organization[]): Organization[] {
        return list.filter((elt) => !this.networkMembers.find((mbr) => mbr.id === elt.id));
    }

    addNewMember(): void {
        this.isAdding = true;
        this.networkService.addOrganizationToNetwork(this.organization.id, this.orgaToAddId).subscribe(() => {
            this.orgaToAddId = null;
            this.checkedCollapse = false;
            this.isAdding = false;
            this.notification.success(this.translate.instant('AJOUTEE'));
            this.getOrganizationMembers();
        });
    }

    removeOrganizationMember(orgId: number) {
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: acceptDelete,
            nzCancelText: rejectDelete,
            nzClosable: true,
            nzMaskClosable: true,
            // DELETE SERVICE CALL
            nzOnOk: () => {
                this.networkService.removeOrganizationFromNetwork(this.organization.id, orgId).subscribe(() => {
                    this.notification.success(this.translate.instant('RETIREE'));
                    this.getOrganizationMembers();
                });
            }
        });
    }

    toogleCollapse(): void {
        this.checkedCollapse = !this.checkedCollapse;
    }
}
