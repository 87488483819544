<ng-container *ngIf="!readonly">
    <div nz-row nzType="flex" nzJustify="space-between">
        <h2 class="mb-0">{{ 'JURIES' | translate }}</h2>

        <button type="button" class="btn pt-2" [disabled]="collapseForm" (click)="collapseForm = true">{{ 'AJOUTER-JURY' | translate }}</button>
    </div>

    <div *ngIf="collapseForm" class="form-filter mb-3">
        <form [formGroup]="juryForm">
            <div nz-row nzGutter="18" nzType="flex" nzAlign="bottom" class="form-filter__container p-3">
                <div nz-col [nzSpan]="16">
                    <app-nz-form-item inputId="'email'" [control]="juryForm.get('email')" inputType="email">
                        <div class="input-custom-label">
                            <label for="email" class="repertory-filter__label d-block">{{ 'FORM.COURRIEL' | translate }} *</label>
                        </div>

                        <div class="input-custom-error-msg">
                            <span class="has-error" *ngIf="juryForm.invalid && juryForm.get('email').errors && (juryForm.get('email').touched || juryForm.get('email').touched)">
                                <span *ngIf="juryForm.get('email').errors['required']; else incorrectField">{{ 'CHAMP-REQUIS-SIMPLE' | translate }}</span>
                                <ng-template #incorrectField>{{ 'ENTRER-VALEUR' | translate }}</ng-template>
                            </span>
                        </div>
                    </app-nz-form-item>
                </div>
                <div nz-col [nzSpan]="8" class="text-right py-4 px-4 d-flex align-items-center">
                    <button class="btn-rect px-3 mr-md-3 pt-2" (click)="collapseForm = false">{{ 'ANNULER' | translate }}</button>
                    <button class="btn btn--small" nz-button nzSize="large" nzType="default" (click)="juryForm.valid ? addJuryToMeeting() : undefined">
                        {{ 'AJOUTER' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="this.existingJuries.length; else noExisting">
    <div class="table mt-3">
        <div *ngFor="let jury of existingJuries" class="item-row">
            <div class="item-row__col item-row__col--avatar item-row__avatar">
                <span class="item-row__avatar-container">
                    <img class="item-row__avatar-src" src="{{ jury.user.avatar }}" alt="" />
                </span>
            </div>

            <div class="item-row__col py-2">
                <p class="item-row__text item-row__text--title">{{ jury.user.firstName + ' ' + jury.user.lastName }}</p>
                <p class="item-row__text item-row__text--subtitle">{{ jury.user.email }}</p>
            </div>

            <div class="item-row__col text-right py-2 pr-3">
                <button type="button" *appPermission="5" class="btn-square" (click)="deleteInvitation(jury.id)"><i class="icon-trash"></i></button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #noExisting></ng-template>

<!-- INVITATIONS LIST -->
<ng-container *ngIf="pendingJuries.length > 0">
    <nz-divider></nz-divider>
    <h3 class="mt-3">{{ 'INVITATIONS' | translate }}</h3>
    <div class="table invitations">
        <div *ngFor="let invit of pendingJuries">
            <p>{{ invit.email }}</p>
            <button type="button" *appPermission="5" class="btn-square" (click)="deleteInvitation(invit.id)"><i class="icon-trash"></i></button>
        </div>
    </div>
</ng-container>
