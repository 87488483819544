<ng-container *ngIf="!isLoading">
    <app-page-title [title]="meeting.title" [subtitle]="meeting.subtitle" [image]="meeting.image"></app-page-title>

    <div class="container">
        <div nz-row nzType="flex" nzJustify="start">
            <div nz-col nzSpan="19">
                <h2>{{ 'PROPOSER-VITRINE' | translate }}</h2>

                <nz-divider></nz-divider>

                <!-- Sélecteurs de spectacle et de produit -->
                <div nz-row nzGutter="16" class="d-flex align-items-center">
                    <div nz-col nzSpan="11">
                        <p class="mb-2">
                            <label for="showId">{{ 'FORM.SPECTACLE' | translate }} *</label>
                        </p>
                        <nz-select
                            id="showId"
                            name="showId"
                            nzPlaceHolder="{{ 'FORM.SELECTIONNER' | translate }}"
                            style="width: 100%"
                            required
                            [(ngModel)]="selectedShow"
                            #show="ngModel"
                        >
                            <nz-option *ngFor="let show of shows" nzCustomContent [nzLabel]="show.getTranslatedProperty(lang, 'title')" [nzValue]="show">
                                {{ show.getTranslatedProperty(lang, 'title') }}
                            </nz-option>
                        </nz-select>
                        <span class="has-error" *ngIf="show.invalid && (show.dirty || show.touched)">
                            {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                        </span>
                    </div>
                    <div nz-col nzSpan="11">
                        <p class="mb-2">
                            <label for="productId">{{ 'PRODUCT' | translate }} *</label>
                        </p>
                        <nz-select
                            id="productId"
                            name="productId"
                            nzPlaceHolder="{{ 'FORM.SELECTIONNER' | translate }}"
                            style="width: 100%"
                            required
                            [(ngModel)]="selectedProduct"
                            #product="ngModel"
                        >
                            <nz-option *ngFor="let product of products" [nzLabel]="product.getTranslatedProperty(lang, 'name')" [nzValue]="product"></nz-option>
                        </nz-select>
                        <span class="has-error" *ngIf="product.invalid && (product.dirty || product.touched)">
                            {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                        </span>
                    </div>
                    <div nz-col class="price ml-auto mt-3" nzSpan="2">
                        <p><!-- left blank on purpose --></p>
                        <strong>{{ formatter.format(getPrice(selectedProduct)) }}</strong>
                    </div>
                </div>

                <nz-divider></nz-divider>

                <ng-container *ngIf="displayFullForm && !!rideauForm">
                    <!-- FORM RIDEAU -->
                    <app-vitrine-rideau-form [rideauForm]="rideauForm" [materielSupList]="additionalMaterialList" [reloading]="reloading"></app-vitrine-rideau-form>
                </ng-container>
            </div>
        </div>
    </div>
    <footer class="w-75 px-5 u-background-light-grey mt-5">
        <div nz-row nzType="flex" nzJustify="start">
            <div nz-col nzSpan="19">
                <div class="d-flex align-items-center justify-content-end u-gap(xs) py-5">
                    <button appButton class="btn btn-light" (click)="cancel()">{{ 'ANNULER' | translate }}</button>
                    <button appButton class="btn btn-light" [disabled]="show.invalid || product.invalid || rideauForm?.invalid" (click)="sendToCart()">
                        {{ 'AJOUTER-AU-PANIER-MAJ' | translate }}
                    </button>
                    <button appButton (click)="payNow()" class="btn btn-primary blue" [disabled]="show.invalid || product.invalid || rideauForm?.invalid || disabled">
                        {{ 'PAYER-MAINTENANT' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </footer>
</ng-container>
<div *ngIf="isLoading" class="loader">
    <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
</div>

<ng-template #mediaListTpl let-list>
    <ul class="p-0">
        <ng-container *ngFor="let item of list">
            <li class="pb-1 d-flex">
                <a href="{{ item.file }}" class="u-link-primary d-flex u-gap(xxs) align-items-center" target="_blank">
                    <i aria-hidden="true" class="icon-arrow-down"></i>
                    <span class="pl-1">{{ getAdditionalMaterialType(+item.desc) }}</span>
                </a>
            </li>
        </ng-container>
    </ul>
</ng-template>
<ng-template #emptySpace>
    <h4>{{ 'FORM.NO-ADDITIONAL-MATERIEL' | translate }}</h4>
</ng-template>
