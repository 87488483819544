import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { LocationService } from 'src/app/concepts/location/services/location.service';
import { Role } from 'src/app/shared/enums/roles.enum';
import { MainService } from 'src/app/shared/services/main.services';
import { Globals } from '../../../_configs/globals';
import { ICreatedOrganization, IOrganization, IOrganizationTypes, Organization, SocialMediaAccount } from '../model/organization.model';
import { Pagination } from './../../../shared/model/list-item.model';
import { AccountService } from './../../account/services/account.service';
import { Address, IAddress } from './../../location/models/location.model';
import { BillingItem } from './../../membership/model/billing-item.model';
import { IMember, Invitation } from './../model/organization.model';
import { OrganisationStatus } from '../enums/organization-status.enum';
import { OrganizationTypes } from '../enums/organization-types.enum';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService extends MainService {
    public organizationsChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        protected httpClient: HttpClient,
        protected globals: Globals,
        private accountService: AccountService,
        public locationService: LocationService,
        private translate: TranslateService,
    ) {
        super(httpClient, globals);
    }

    //[GET] organizations
    getOrganizations(
        filters?: Array<{ field: 'organizationTypeId'; value: OrganizationTypes } | { field: 'statusId'; value: OrganisationStatus }>,
        pagination?: Pagination,
        searchText?: string
    ): Observable<Array<Organization>> {
        let endpoint: string = this.globals.endpoints.organizations.main;
        const text = searchText ? searchText.trim() : null;

        if (filters || pagination || text) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination || text ? '&' : '';
        }
        if (text) {
            endpoint += `name=${encodeURIComponent(text)}`;
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get(this.uri + endpoint).map((data) => {
            if (pagination) pagination.total = data['total'];
            return data['organizations'].map((orga) => new Organization(orga));
        });
    }

    //[GET] organizations list
    getOrganizationsList(): Observable<any> {
        const endpoint: string = this.globals.endpoints.organizations.main;
        return this.httpClient.get<any>(this.uri + endpoint).pipe();
    }

    getProducers(): Observable<Array<Organization>> {
        return this.getOrganizations([
            { field: 'organizationTypeId', value: OrganizationTypes.IS_PRODUCTEUR },
            { field: 'statusId', value: OrganisationStatus.APPROUVE }
        ]);
    }

    getDiffusers(): Observable<Array<Organization>> {
        return this.getOrganizations([
            { field: 'organizationTypeId', value: OrganizationTypes.IS_DIFFUSEUR },
            { field: 'statusId', value: OrganisationStatus.APPROUVE }
        ]);
    }

    //[GET] organizations/roles
    getOrganizationsRoles(): Observable<any> {
        const endpoint: string = this.globals.endpoints.organizations.roles;

        return this.httpClient.get<any>(this.uri + endpoint).pipe();
    }

    //[GET] organizations/types
    getOrganizationsTypes(): Observable<any> {
        const endpoint: string = this.globals.endpoints.organizations.types;

        return this.httpClient.get<any>(this.uri + endpoint).pipe();
    }

    //[GET] organization/id
    getOrganization(id: number): Observable<Organization> {
        const endpoint: string = this.globals.endpoints.organization.main;
        // si pas d'organisation, on renvoie une orga vide
        if (id === this.globals.NO_ORGA) {
            // new Organization({ id: -1 })
            return of(null);
        }

        return this.httpClient.get<Organization>(this.uri + endpoint + '/' + id).pipe(map((data) => new Organization(data)));
    }

    //[GET] organization/id/types
    getOrganizationTypes(id: number) {
        const endpoint = this.uri + this.globals.endpoints.organization.getTypesEndpoint(id);
        return this.httpClient.get(endpoint);
    }

    //[GET] organization/member/id
    getOrganizationMember(memberId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.getMemberEndpoint(memberId);
        return this.httpClient.get(endpoint);
    }

    //[GET] organization/id/members
    getOrganizationTeam(id: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.getMembersEndpoint(id);
        return this.httpClient.get(endpoint);
    }

    //[GET] organization/id/invitations
    getOrganizationInvitations(id: number): Observable<Invitation[]> {
        const endpoint = this.uri + this.globals.endpoints.organization.getAllInvitationsEndpoint(id);
        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                return data['invitations'].map((invit) => new Invitation(invit));
            })
        );
    }

    //[GET] organization/id/social-media
    getOrganizationSocialMedia(id: number): Observable<SocialMediaAccount[]> {
        const endpoint = this.uri + this.globals.endpoints.organization.getSocialMediaEndpoint(id);
        return this.httpClient.get(endpoint).map((data) => {
            return data['socialMediaAccounts'].map((account) => new SocialMediaAccount(account));
        });
    }

    getOrganisationBills(id: number): Observable<BillingItem[]> {
        const endpoint = this.uri + this.globals.endpoints.organization.getBillsEndpoint(id);
        return this.httpClient.get(endpoint).map((res: any[]) => res.map((elt) => new BillingItem(elt)));
    }

    payNetworkMemberShip(paiementInfos: any): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.transaction.membership;
        return this.httpClient.post(endpoint, paiementInfos);
    }

    //[POST] organization
    createOrganization(organization: IOrganization): Observable<ICreatedOrganization> {
        const endpoint: string = this.globals.endpoints.organization.main;
        return this.httpClient.post<ICreatedOrganization>(this.uri + endpoint, organization).pipe(
            tap(() => {
                this.accountService.clearUserOrgaCache();
                this.accountService.clearUserMemberShipCache();
                this.organizationsChanged.emit('created');
            })
        );
    }

    async createBlankOrganization(): Promise<Organization> {
        const organization = new Organization({});
        organization.setTranslatedProperty('fr', 'name', this.translate.instant('NOUVELLE-ORGANISATION'));

        const address = await this.locationService.createAddress({
            address1: '',
            address2: '',
            zipCode: '',
            city: '',
            countryId: 124,
            otherState: 'Québec'
        } as IAddress);

        organization.address = address.address as Address;
        organization.addressId = address.address.id;

        return organization;
    }
    createNewOrganization = (): Observable<IOrganization> =>
        from(this.createBlankOrganization()).pipe(
            mergeMap((organization: Organization) =>
                this.createOrganization(organization).pipe(
                    filter((result: ICreatedOrganization) => !!result.isCreated),
                    map((result: ICreatedOrganization) => ({
                        ...result.organization,
                        address: organization.address,
                        trans: organization.trans,
                        isNetworkHead: organization.isNetworkHead,
                        networks: organization.networks,
                        socialMediaAccounts: organization.socialMediaAccounts,
                        types: organization.types,
                        website: organization.website
                    }))
                )
            )
        );

    //[POST] organization/types
    addOrganizationTypes(organizationTypes: IOrganizationTypes): Observable<ICreatedOrganization> {
        const endpoint: string = this.globals.endpoints.organization.types;

        return this.httpClient.post<ICreatedOrganization>(this.uri + endpoint, organizationTypes);
    }
    //[POST] organization/social-media
    addSocialMedias(organizationId: number, socialMediaAccounts: SocialMediaAccount[]): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.socialMedia;
        const body = {
            organizationId: organizationId,
            socialMediaAccounts: socialMediaAccounts
        };
        return this.httpClient.post(endpoint, body);
    }

    //[POST] organization/members
    addOrganizationMembers(member: IMember): Observable<IMember> {
        const endpoint = this.uri + this.globals.endpoints.organization.member;
        return this.httpClient.post<IMember>(endpoint, member);
    }

    inviteMember(orgId: number, email: string, roleId: Role): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.getInvitationEndpoint(orgId);
        const body = {
            roleId: roleId,
            organizationId: orgId,
            email: email
        };
        this.accountService.clearUserOrgaCache();
        this.accountService.clearUserMemberShipCache();
        return this.httpClient.post(endpoint, body);
    }

    //[PUT] organization
    modifyOrganization(organization: IOrganization): Observable<any> {
        this.accountService.clearUserOrgaCache();
        const endpoint = this.uri + this.globals.endpoints.organization.main;
        const body = new Organization(organization);
        return this.httpClient.put<IOrganization>(endpoint, body);
    }

    //[PUT] organization/types
    modifyOrganizationTypes(body: IOrganizationTypes) {
        const endpoint = this.uri + this.globals.endpoints.organization.types;
        return this.httpClient.put<IOrganization>(endpoint, body);
    }

    //[PUT] organization/members
    modifyOrganizationMembers(member: IMember): Observable<any> {
        this.accountService.clearUserOrgaCache();
        this.accountService.clearUserMemberShipCache();
        const endpoint = this.uri + this.globals.endpoints.organization.member;
        return this.httpClient.put(endpoint, member);
    }

    //[PUT] organization/approval
    modifyOrganizationApproval(approvalState): Observable<any> {
        this.accountService.clearUserOrgaCache();
        const endpoint = this.uri + this.globals.endpoints.organization.approval;
        return this.httpClient.put(endpoint, approvalState).pipe(
            tap(() => {
                this.organizationsChanged.emit('approval updated');
            })
        );
    }

    //[DELETE] organization/member
    removeUserFromOrganization(userId: number, memberId: number): Observable<any> {
        this.accountService.clearUserOrgaCache();
        this.accountService.clearUserMemberShipCache();
        const endpoint = this.uri + this.globals.endpoints.user.getRemoveUserFromOrganizationEndpoint(userId, memberId);
        return this.httpClient.delete(endpoint);
    }

    //[DELETE] organization/member
    removeOrganizationMember(orgaId: number, memberId: number): Observable<any> {
        this.accountService.clearUserOrgaCache();
        this.accountService.clearUserMemberShipCache();
        const endpoint = this.uri + this.globals.endpoints.organization.getDeleteMemberEndpoint(orgaId, memberId);
        return this.httpClient.delete(endpoint);
    }

    deleteInvitation(invitId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.invitation;
        return this.httpClient.delete(endpoint + invitId);
    }

    deleteOrganization(orgId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.getDeleteOrgaEndpoint(orgId);
        return this.httpClient.delete(endpoint).do(() => {
            this.accountService.setCurrentCtxOrganizationId(-1);
            this.accountService.clearUserOrgaCache();
            this.accountService.clearUserMemberShipCache();
            this.organizationsChanged.emit('deleted');
        });
    }

    getOrganizationContacts(orgId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.getOrgaContactsEndpoint(orgId);
        return this.httpClient.get(endpoint);
    }

    createOrganizationContact(orgId: number, contact: any): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.contact;
        return this.httpClient.post(endpoint, contact);
    }

    modifyOrganizationContact(contact: any): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.contact;
        return this.httpClient.put(endpoint, contact);
    }

    removeOrganizationContact(orgId: number, contactId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.organization.getDeleteOrgaContactsEndpoint(orgId, contactId);
        return this.httpClient.delete(endpoint);
    }
}
