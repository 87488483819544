import { Component, Input, OnInit, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

let unique = 0;

@Component({
    selector: 'app-time-picker',
    template: `
        <nz-form-item>
            <label [attr.for]="id">
                <ng-content></ng-content>
            </label>
            <nz-form-control [nzValitimeStatus]="ngControl.touched && ngControl.errors ? 'error' : ''">
                <nz-time-picker nzAllowClear="true" nzFormat="HH:mm" [(ngModel)]="selected" (ngModelChange)="ngModelChange()" [nzDisabled]="disabled"></nz-time-picker>
                <span class="has-error" *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.required">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </nz-form-control>
        </nz-form-item>
    `,
    styles: [
        `
            :host {
                display: block;
            }

            :host /deep/ nz-time-picker,
            :host /deep/ nz-picker,
            :host /deep/ span.ant-calendar-picker,
            :host /deep/ input.ant-calendar-picker-input {
                display: block;
                width: 100%;
            }

            :host.warning /deep/ .ant-calendar-picker-input {
                border: 2px solid #faad14 !important;
                border-radius: 4px !important;
            }

            :host.warning /deep/ .ant-calendar-picker-input:hover {
                border: 2px solid #faad14 !important;
            }

            :host.error /deep/ .ant-calendar-picker-input {
                border: 2px solid #f5222d !important;
                border-radius: 4px !important;
            }

            :host.error /deep/ .ant-calendar-picker-input:hover {
                border: 2px solid #f5222d !important;
            }
        `
    ]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
    private onChange: (value: Date) => void;
    private onTouched: () => void;
    @Input() disabled: boolean;
    @Input() format: string;
    @Input() validateStatus = '';
    @Input() control: UntypedFormControl;
    @HostBinding('class.warning') get classWarning() {
        return this.validateStatus === 'warning';
    }
    @HostBinding('class.error') get classError() {
        return this.validateStatus === 'error';
    }
    @HostBinding('class.disabled') get classDisabled() {
        return this.disabled;
    }
    id = `timePickerComponent-${unique++}`;
    selected: Date;
    constructor(readonly translateService: TranslateService, readonly ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }
    ngOnInit(): void {
        this.translateService.currentLang;
    }
    ngModelChange(): void {
        this.onChange(this.selected);
        this.onTouched();
    }
    writeValue(value: Date): void {
        this.selected = value;
    }
    registerOnChange(fn: (value: Date) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
