<ng-container *ngIf="tour$ | async as tour">
    <app-page-title *ngIf="tour" [title]="tour.getTranslatedProperty(this.translateService.currentLang, 'name')"></app-page-title>

    <div class="container">
        <div nz-row nzGutter="16">
            <div nz-col nzMd="6">
                <div class="list-filters">
                    <div class="list-filters__box">
                        <sp-menu>
                            <sp-menu-item [label]="'RENSEIGNEMENTS-GENERAUX' | translate" [classList]="['list-filters__item active']"></sp-menu-item>
                        </sp-menu>

                    </div>
                </div>
            </div>
            <div nz-col nzMd="12" *ngIf="form">
                <i nz-icon nzType="loading" nzTheme="outline" *ngIf="!tour"></i>

                <h2>{{ 'RENSEIGNEMENTS-GENERAUX' | translate }}</h2>

                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <app-text-input formControlName="name">{{ 'FORM.NOM-DEPOT' | translate }} *</app-text-input>

                    <app-rich-text formControlName="description" [modules]="global.InputRichTextModules" [countMaxLength]="1000">
                        {{ 'TOURS.DESCRIPTION-MODALITES' | translate }}
                        <p class="text-smaller mb-2">{{ 'TOURS.DESCRIPTION-MODALITES-DETAILS' | translate }}</p>
                    </app-rich-text>
                </form>
                <app-alert prefix class="mb-3 success" *ngIf="tour.isDepositOpen">
                    <i nz-icon nzType="unlock" nzTheme="fill"></i>
                    <span [innerHTML]="'ACCES-DEPOT-PRODUCTEUR-OUVERT-COURT' | translate"></span>
                    <button appButton [click]="closeForDeposit.bind(this)">
                        {{ 'FERMER-DEPOT' | translate }}
                    </button>
                </app-alert>

                <app-alert prefix class="mb-3 danger" *ngIf="!tour.isDepositOpen && !tour.isVoteOpen">
                    <i nz-icon nzType="lock" nzTheme="fill"></i>
                    <span [innerHTML]="'ACCES-DEPOT-PRODUCTEUR-FERME-COURT' | translate"></span>
                    <button appButton [click]="openForDeposit.bind(this)">
                        {{ 'OUVRIR-DEPOT' | translate }}
                    </button>
                </app-alert>

                <app-alert prefix class="mb-3 info" *ngIf="!tour.isDepositOpen && tour.isVoteOpen">
                    <i nz-icon nzType="lock" nzTheme="fill"></i>
                    <span [innerHTML]="'ACCES-DEPOT-FORM-PRODUCTEUR-IMPOSSIBLE' | translate"></span>
                    <button appButton [disabled]="true">
                        {{ 'OUVRIR-DEPOT' | translate }}
                    </button>
                </app-alert>

                <app-alert prefix class="mb-3 success" *ngIf="tour.isVoteOpen">
                    <i nz-icon nzType="unlock" nzTheme="fill"></i>
                    <span [innerHTML]="'ACCES-DEPOT-DIFFUSEUR-OUVERT-COURT' | translate"></span>
                    <button appButton [click]="closeForVote.bind(this)">
                        {{ 'ACCES-DEPOT-FERMER' | translate }}
                    </button>
                </app-alert>

                <app-alert prefix class="mb-3 danger" *ngIf="!tour.isVoteOpen && !tour.isDepositOpen && !!tourShows.length">
                    <i nz-icon nzType="lock" nzTheme="fill"></i>
                    <span [innerHTML]="'ACCES-DEPOT-DIFFUSEUR-FERME-COURT' | translate"></span>
                    <button appButton [click]="openForVote.bind(this)">
                        {{ 'ACCES-DEPOT-OUVRIR' | translate }}
                    </button>
                </app-alert>

                <app-alert prefix class="mb-3 info" *ngIf="!tour.isVoteOpen && tour.isDepositOpen">
                    <i nz-icon nzType="lock" nzTheme="fill"></i>
                    <span [innerHTML]="'ACCES-DEPOT-FORM-DIFFUSEUR-IMPOSSIBLE' | translate"></span>
                    <button appButton [disabled]="true">
                        {{ 'ACCES-DEPOT-OUVRIR' | translate }}
                    </button>
                </app-alert>

                <app-alert prefix class="mb-3 info" *ngIf="!tour.isDepositOpen && !tourShows.length">
                    <i nz-icon nzType="lock" nzTheme="fill"></i>
                    <span [innerHTML]="'ACCES-DEPOT-VIDE-DIFFUSEUR-IMPOSSIBLE' | translate"></span>
                </app-alert>
            </div>
        </div>
    </div>

    <div class="footer">
        <div class="container">
            <div nz-row nzGutter="16">
                <div nz-col nzOffset="6" nzMd="18" *ngIf="form">
                    <button appButton class="blue" [disabled]="!form.valid" [click]="onSubmit.bind(this)">
                        {{ 'ENREGISTER' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
