<form nz-form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row nzGutter="16">
        <div nz-col nzMd="12">
            <nz-form-item>
                <nz-form-control>
                    <label for="name">{{ 'FORM.NOM-ORGANISATION' | translate }} *</label>
                    <input type="text" nzInput name="name" id="name" formControlName="name" />
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('name').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row nzGutter="16">
        <div nz-col nzMd="12">
            <nz-form-item>
                <nz-form-control>
                    <label for="address1">{{ 'FORM.ADRESSE' | translate }} *</label>
                    <input type="text" id="address1" name="address1" formControlName="address1" />
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('address1').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="12">
            <nz-form-item>
                <nz-form-control>
                    <label for="city">{{ 'FORM.VILLE' | translate }} *</label>
                    <input nz-input id="city" name="city" formControlName="city" />
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('city').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row nzGutter="16">
        <div nz-col nzMd="8">
            <nz-form-item>
                <nz-form-control>
                    <label for="zipcode">{{ 'FORM.CODE-POSTAL' | translate }} *</label>
                    <input nz-input id="zipcode" name="zipcode" formControlName="zipcode" />
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('zipcode').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <nz-form-item>
                <nz-form-control>
                    <label for="country">{{ 'FORM.PAYS' | translate }} *</label>
                    <select formControlName="country">
                        <option *ngFor="let country of countries" [ngValue]="country.id">
                            {{ country.name }}
                        </option>
                    </select>
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('country').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <nz-form-item *ngIf="states$ | async as states">
                <ng-container *ngIf="canadaSelected; else otherCountries">
                    <nz-form-control>
                        <label for="province">{{ 'FORM.PROVINCE' | translate }} *</label>
                        <select class="" name="province" id="province" formControlName="province">
                            <option value="null" disabled>{{ 'FORM.CHOISIR-PROVINCE' | translate }}</option>
                            <option *ngFor="let province of states" [value]="province.getTranslatedProperty(lang, 'name')">
                                {{ province.getTranslatedProperty(lang, 'name') }}
                            </option>
                        </select>
                        <span class="has-error" *ngIf="displayErrors && formGroup.get('province').errors">
                            {{ 'CHAMP-REQUIS' | translate }}
                        </span>
                    </nz-form-control>
                </ng-container>
                <ng-template #otherCountries>
                    <nz-form-control>
                        <label for="province">{{ 'FORM.PROVINCE' | translate }}</label>
                        <input id="province" name="province" formControlName="province" />
                        <span class="has-error" *ngIf="displayErrors && formGroup.get('province').errors">
                            {{ 'CHAMP-REQUIS' | translate }}
                        </span>
                    </nz-form-control>
                </ng-template>
            </nz-form-item>
        </div>
    </div>
    <div nz-row class="d-flex mb-3" nzGutter="16">
        <div nz-col nzMd="8">
            <nz-form-item>
                <nz-form-control>
                    <label for="phone">{{ 'FORM.TELEPHONE' | translate }} *</label>
                    <input nz-input id="phone" formControlName="phone" />
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('phone').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <nz-form-item>
                <nz-form-control>
                    <label for="phonePostNumber">{{ 'FORM.POSTE' | translate }}</label>
                    <input nz-input id="phonePostNumber" name="phonePostNumber" formControlName="phonePostNumber" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzMd="8">
            <nz-form-item>
                <nz-form-control>
                    <label for="courriel">{{ 'FORM.COURRIEL' | translate }} *</label>
                    <input type="email" nz-input id="email" name="email" formControlName="email" />
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('email').errors">
                        <span *ngIf="formGroup.get('email').errors['required']; else incorrectField">{{ 'CHAMP-REQUIS' | translate }}</span>
                        <ng-template #incorrectField>{{ 'ERRORS.EMAIL-FORMAT' | translate }}</ng-template>
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nz-form-item>
                <nz-form-control>
                    <label for="website">{{ 'FORM.SITE-WEB' | translate }}</label>
                    <input id="website" nz-input formControlName="website" />
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <!-- wikidata & artsdata -->
    <div class="row mb-5">
        <div class="col-md-6">
            <nz-form-item>
                <div class="col">
                    <div class="row">
                        <nz-form-label for="wikidataId" class="label-only label-with-icon">
                            <span>{{ 'VENUE-DATA.IDENTIFIER' | translate }} {{ 'VENUE-DATA.WIKIDATA_TITLE' | translate }}</span>
                            <a class="ml-3 d-inline-flex" [routerLink]="'/help' | localize" [fragment]="'AIDE-MENU-ID.BOTTIN' | translate">
                                <span
                                    class="help-icon help-icon--blue help-icon--small"
                                    nz-tooltip
                                    nzTooltipTitle="{{ 'VENUE-DATA.TOOLTIP_LINK' | translate }}"
                                    nzTooltipPlacement="right"
                                ></span>
                            </a>
                        </nz-form-label>
                    </div>
                    <div class="row">
                        <nz-form-control [ngClass]="{ 'u-cursor-not-allowed': !isSceneProAdmin }">
                            <input nz-input id="wikidataId" [disabled]="!isSceneProAdmin" name="wikidataId" formControlName="wikidataId" />
                            <span class="has-error" *ngIf="shouldDisplayFormControlError('wikidataId', 'regex')">
                                {{ 'VENUE-DATA.ERROR-FORMAT' | translate : { format: 'Q111857243' } }}
                            </span>
                        </nz-form-control>
                    </div>
                </div>
            </nz-form-item>
        </div>
        <div class="col-md-6">
            <nz-form-item>
                <div class="col">
                    <div class="row">
                        <nz-form-label for="artsdataId" class="label-only label-with-icon">
                            <span>{{ 'VENUE-DATA.IDENTIFIER' | translate }} {{ 'VENUE-DATA.ARTSDATA_TITLE' | translate }}</span>
                            <a class="ml-3 d-inline-flex" [routerLink]="'/help' | localize" [fragment]="'AIDE-MENU-ID.BOTTIN' | translate">
                                <span
                                    class="help-icon help-icon--blue help-icon--small"
                                    nz-tooltip
                                    nzTooltipTitle="{{ 'VENUE-DATA.TOOLTIP_LINK' | translate }}"
                                    nzTooltipPlacement="right"
                                ></span>
                            </a>
                        </nz-form-label>
                    </div>
                    <div class="row">
                        <nz-form-control [ngClass]="{ 'u-cursor-not-allowed': !isSceneProAdmin }">
                            <input nz-input id="artsdataId" [disabled]="!isSceneProAdmin" nz-input name="artsdataId" formControlName="artsdataId" />
                            <span class="has-error" *ngIf="shouldDisplayFormControlError('artsdataId', 'regex')">
                                {{ 'VENUE-DATA.ERROR-FORMAT' | translate : { format: 'K16-150' } }}
                            </span>
                        </nz-form-control>
                    </div>
                </div>
            </nz-form-item>
        </div>
    </div>
    <!-- Boutons si orga non soumise ou rejetée  -->
    <ng-container *ngIf="organization.statusId === 1 || organization.statusId === 4">
        <button
            *appPermission="1; objectOrganizationId: organization.id; shouldBeApproved: false"
            nz-button
            [nzLoading]="isLoading"
            class="btn btn--default"
            style="margin-bottom: 35px"
            [disabled]="formGroup.invalid"
        >
            {{ 'ENREGISTER-CONTINUER' | translate }}
        </button>
        <p *appPermission="1; objectOrganizationId: organization.id; shouldBeApproved: false" class="d-inline-block ml-2">
            {{ 'OU' | translate }}
            <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
            <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
        </p>
    </ng-container>
    <!-- Bouton simple si orga approuvée -->
    <ng-container *ngIf="(organization.statusId === 3 || organization.statusId === 2) && !formGroup.disabled">
        <button
            *appPermission="1; objectOrganizationId: organization.id; shouldBeApproved: false"
            nz-button
            [nzLoading]="isLoading"
            class="btn btn--default"
            [disabled]="formGroup.invalid"
        >
            {{ 'ENREGISTER' | translate }}
        </button>
    </ng-container>
</form>
