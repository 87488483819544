import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { OrganizationService } from '../../../services/organization.service';
import { ShowContact } from './../../../../show/model/show.model';
import { finalize } from 'rxjs/operators';
import { Globals } from '@app/_configs/globals';

@Component({
  selector: 'app-organization-contacts-form',
  templateUrl: './organization-contacts-form.component.html',
  styleUrls: ['./organization-contacts-form.component.scss'],
})
export class OrganizationContactsFormComponent implements OnInit {
  @Input()
  organizationId: number;

  public orgaContacts: any = {};
  public collapseNewContactForm = false;
  public currentContactModification: number;
  public orgaContactForm: UntypedFormGroup;
  public isLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private organizationService: OrganizationService,
    private notification: RideauNotificationService,
    private translate: TranslateService,
    private globals: Globals
  ) {}

  ngOnInit(): void {
    this.orgaContactForm = this.fb.group({
      firstName: '',
      lastName: '',
      title: '',
      phone: '',
      phonePostNumber: '',
      organizationId: [this.organizationId],
      email: ['', [Validators.pattern(this.globals.emailRegex), Validators.required]],
    });

    this.getorgaContacts();
  }

  orgaNewContactForm() {
    this.collapseNewContactForm = true;
    this.currentContactModification = null;
  }
  getorgaContacts() {
    this.isLoading = true;
    this.orgaContacts = []; //reset list
    this.organizationService
      .getOrganizationContacts(this.organizationId)
      .subscribe((data) => {
        this.isLoading = false;
        this.orgaContacts = data['contacts'];
      });
  }

  addNewContact() {
    this.isLoading = true;
    this.organizationService
      .createOrganizationContact(
        this.organizationId,
        this.orgaContactForm.value
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        this.orgaContactForm.reset();
        this.orgaContactForm
          .get('organizationId')
          .setValue(this.organizationId);
        this.notification.success(
          this.translate.instant('FORM.CONTACT-AJOUTE')
        );
        this.orgaContacts.push(new ShowContact(data['contact']));
      });
  }

  onModifyorgaContact(event: any) {
    this.isLoading = true;
    event.organizationId = this.organizationId;
    this.organizationService.modifyOrganizationContact(event).subscribe(() => {
      this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
      this.isLoading = false;
      this.getorgaContacts();
      this.currentContactModification = null;
    });
  }

  onRemoveorgaContact(event: any) {
    this.isLoading = true;
    this.organizationService
      .removeOrganizationContact(this.organizationId, event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.notification.success(
          this.translate.instant('FORM.CONTACT-SUPPRIME')
        );
        this.getorgaContacts();
      });
  }

  onHideOtherModifyForms(event) {
    this.collapseNewContactForm = false; //hide New Contact Form
    this.currentContactModification = event;
  }

  orgaHideModifyForm(id) {
    return this.currentContactModification == id;
  }

  //   `POST /meeting/<id>/participant/<id>/activity/<id>`
  // `DELETE  /meeting/<id>/participant/<id>/activity/<id>`
}
