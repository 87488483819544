import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, share, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { ShoppingCartService } from 'src/app/concepts/account/services/shopping-cart.service';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { Role } from 'src/app/shared/enums/roles.enum';
import { Globals } from 'src/app/_configs/globals';
import { AccountService } from './../../../../concepts/account/services/account.service';
import { NotificationsService } from 'src/app/concepts/notifications/services/notifications.service';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { tagUserOrganisation } from 'src/app/data-layer';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OrganizationManagerService } from 'src/app/concepts/organization/services/organizations-manager.service';
import { ErrorsHandler } from '@app/shared/error-handling/errors-handler';
import { MenuItem, menuItems } from './main-navigation-menu';
@Component({
    selector: 'app-main-navigation',
    templateUrl: './main-navigation.component.html',
    styleUrls: [ './main-navigation.component.scss' ]
})
export class MainNavigationComponent implements OnInit, OnDestroy {
    user: PxUser;
    lang: string;
    notificationCount: number;
    notificationCount$: Observable<number>;
    ctxOrganizationId: number;
    userOrganizations$: Observable<Organization[]> = this.organizationManager.userOrganizations$;
    selectedUserOrganization$: Observable<Organization> = this.organizationManager.selectedUserOrganization$.pipe(share());
    selectedUserOrganizationId$: Observable<number> = this.organizationManager.selectedUserOrganizationId$;
    selectedUserOrganizationName$: Observable<string> = this.organizationManager.selectedUserOrganization$.pipe(map((organization: Organization) => organization.getTranslatedProperty(
        this.translate.currentLang,
        'name'
    )));
    hasValidOrganizations$: Observable<boolean> = this.organizationManager.hasValidOrganizations$;
    cartCount = 0;
    isSceneProAdmin$: Observable<boolean> = of(false);
    public readonly menu: MenuItem[] = menuItems;
    public isVisible = false;
    public isAdminMenuVisible = false;
    private destroyed: Subject<void> = new Subject();

    constructor(
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        public translate: TranslateService,
        private authService: AuthService,
        private accountService: AccountService,
        private notificationService: NotificationsService,
        private errorsHandler: ErrorsHandler,
        private organizationService: OrganizationService,
        private organizationManager: OrganizationManagerService,
        private shoppingCartService: ShoppingCartService,
        private globals: Globals,
        private router: Router,
        private httpClient: HttpClient,
    ) { }

    ngOnInit(): void {
        this.lang = this.translate.currentLang;
        this.selectedUserOrganization$
            .pipe(
                distinctUntilChanged(),
                withLatestFrom(this.isSceneProAdmin$, this.selectedUserOrganizationName$),
                tap(([ organization, isRideauAdmin, name ]: [ Organization, boolean, string ]) => {
                    this.ctxOrganizationId = organization.id;
                    const types = organization.types && organization.types.length ? organization.types.map((type) => OrganizationTypes[ type ]) : null;
                    this.organizationService.organizationsChanged.subscribe((changed: string) => {
                        if ([ 'deleted', 'created' ].includes(changed)) {
                            return;
                        }
                        this.organizationService.getOrganization(organization.id).subscribe(
                            (data: Organization) => {
                                const isProducer = data.types.some((type) => type === OrganizationTypes.IS_PRODUCTEUR);
                                localStorage.setItem('isProducer', JSON.stringify(isProducer));
                            },
                            (error) => console.log(error)
                        );
                    });
                    tagUserOrganisation(
                        {
                            admin: isRideauAdmin,
                            organisation: name,
                            types
                        },
                        this.httpClient
                    );
                    this.currentOrganizationChanged();
                }),
                catchError((error: Error | HttpErrorResponse) => of(error)),
                takeUntil(this.destroyed)
            )
            .subscribe((result: [ Organization, boolean, string ] | (Error | HttpErrorResponse)) => {
                if (result instanceof Error || result instanceof HttpErrorResponse) {
                    this.errorsHandler.handleError(result);
                }
            });
        this.selectedUserOrganizationId$.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.isSceneProAdmin$ =
                this.ctxOrganizationId === this.globals.SCENE_PRO_ORGID
                    ? this.accountService.hasRole(Role.ADMINISTRATOR).pipe(takeUntil(this.destroyed))
                    : of(false);
        });

        this.getUserOrganizations();
        this.getCartCount();
        // Refreshes organisation list when a new organisation is created.
        this.organizationService.organizationsChanged
            .pipe(
                filter((value: string) => value !== 'created'),
                takeUntil(this.destroyed)
            )
            .subscribe(() => {
                this.getUserOrganizations();
                this.getNotificationCount();
            });

        this.shoppingCartService.shoppingCartChanged.pipe(takeUntil(this.destroyed)).subscribe(() => this.getCartCount());
        this.notificationService.updateNotificationCount.pipe(takeUntil(this.destroyed)).subscribe(() => this.getNotificationCount());

        try {
            this.user = this.authService.User;
        } catch (error) {
            console.error(error);
        }

        this.authService.userChange.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.user = this.authService.User;
        });

    }

    private currentOrganizationChanged(): void {
        this.ctxOrganizationId = this.accountService.getCurrentCtxOrganizationId();
        this.getCartCount();
        // si on est de mofification d'une organisation, on redirige sur la modification de la nouvelle organisation courante
        const organisationLabel = this.translate.instant('ROUTES.organization');
        const modifierLabel = this.translate.instant('ROUTES.modify');
        const hasOrganizationLabel = location.pathname.indexOf(organisationLabel) > -1;
        const hasModificationLabel = location.pathname.indexOf(modifierLabel) > -1;
        if (hasOrganizationLabel && hasModificationLabel) {
            this.navigateToOrganizationEdition();
        }
    }

    /**
     * Initialise le selecteur d'organisation de context
     */
    getUserOrganizations(): void {
        this.accountService
            .getUserOrganizations()
            .pipe(takeUntil(this.destroyed))
            .subscribe((data: Organization[]) => {
                this.organizationManager.setLocalUserOrganizations(data);
                this.ctxOrganizationId = this.accountService.getCurrentCtxOrganizationId();
                if (this.ctxOrganizationId === this.globals.NO_ORGA && data.length > 0) {
                    this.ctxOrganizationId = data[ 0 ].id;
                    this.accountService.setCurrentCtxOrganizationId(this.ctxOrganizationId);
                }
            });
    }

    getNotificationCount(): void {
        this.notificationService
            .getNotificationCount(this.ctxOrganizationId >= 0 ? this.ctxOrganizationId : null)
            .pipe(takeUntil(this.destroyed))
            .subscribe((data) => {
                this.notificationCount = data[ 'count' ];
            });
    }

    getCartCount(): void {
        this.shoppingCartService
            .getCart()
            .pipe(takeUntil(this.destroyed))
            .subscribe((cart) => {
                this.cartCount = cart.products.length;
            });
    }

    setCurrentCtxOrganizationId(id: number): void {
        this.isVisible = false;
        this.accountService.setCurrentCtxOrganizationId(id);
    }

    onLogout(): void {
        this.isVisible = false;
        this.authService.logout();
        this.organizationManager.resetLocalUserOrganizations();
    }

    navigateTo = (url: string[], menuName: string) => {
        if (menuName === 'admin') {
            this.isAdminMenuVisible = false;
        } else if (menuName === 'account') {
            this.isVisible = false;
        }
        this.navigateToTranslatedRoute(url);
    }

    navigateToOrganizationEdition(): void {
        this.selectedUserOrganizationId$.subscribe((id: number) => {
            this.navigateToTranslatedRoute([ '/organization/', id.toString(), 'modify', 'coordonnees' ]);
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    public get isDashboardTheme(): boolean {
        return this.localizeRouter.translateRoute('/dashboard') === this.router.url;
    }

    private navigateToTranslatedRoute(route: string[]): void {
        const transTab: any[] = this.localizeRouter.translateRoute(route) as any[];
        this.router.navigate([ ...transTab ]);
    }
}
