import { MenuItem } from "@app/shared/components/layout/main-navigation/main-navigation-menu";

export const accountRoutes: MenuItem[] = [
    {
        link: '../info',
        label: 'PROFIL'
    },
    {
        link: '../organizations',
        label: 'ORGANIZATIONS'
    },
];
