import { Globals } from './../../../../../_configs/globals';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { AbstractVenueFormComponent } from '../venue-abstract-form/venue-abstract-form.component';
import { isBlankValidator } from 'src/app/shared/validators/not-blank.validator';
import { ImgUploadService, ImgUploadState, ImgUploadStatus } from '@app/shared/components/img-upload/img-upload.service';

@Component({
    selector: 'app-venue-profile-form',
    templateUrl: './venue-profile-form.component.html',
    styleUrls: [ './venue-profile-form.component.scss' ]
})
export class VenueProfileFormComponent extends AbstractVenueFormComponent {
    public lang: string;

    // photo
    public uploadAction: string = `${this.globals.api.ENDPOINT}${this.globals.endpoints.upload.mainVenue}`;
    public photoUrl: string;
    public hasPhoto = false;
    public loading = false;

    public imageChangedEvent: any = '';
    public croppedImage: any = '';

    descriptionModules;
    isBlankDescValidator = isBlankValidator;
    descriptionModel = '';

    constructor(
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        private notification: RideauNotificationService,
        protected globals: Globals,
        protected accountService: AccountService,
        private imgUploadService: ImgUploadService
    ) {
        super(accountService, globals);
    }

    ngOnInit(): void {
        registerLocaleData(fr);

        this.lang = this.translate.currentLang;
        this.initForm();
    }

    protected initForm() {
        this.formGroup = this.venue.getProfileFormGroup(this.fb, this.translate.currentLang);
        this.descriptionModel = this.venue.getTranslatedProperty(this.translate.currentLang, 'description');
        this.descriptionModules = this.globals.InputRichTextModules;
        if (this.venue.photo && this.venue.photo.length) {
            this.hasPhoto = true;
        }
        this.imgCurrentStatusHandler();
    }

    doSubmit(param?: any) {
        // reafect values for orga, emit for orga emit for submit
        this.venueValue.setTranslatedProperty(this.translate.currentLang, 'description', this.descriptionModel);
        this.venueValue.photo = this.formGroup.value.photo;

        super.doSubmit(param);
    }

    private limitDescriptionLength($event) {
        if ($event.editor.getLength() > this.globals.MAXDESCRIPTIONLENGHT) {
            $event.editor.deleteText(this.globals.MAXDESCRIPTIONLENGHT, $event.editor.getLength());
        }
    }

    contentChanged($event) {
        this.limitDescriptionLength($event);
        this.descriptionModel = $event.html;
    }

    onUploadFileCallback($event) {
        this.formGroup.patchValue({
            photo: $event
        });
    }

    private imgCurrentStatusHandler = (): void => {
        this.imgUploadService.currentStatus$.pipe(filter((x) => x.status !== ImgUploadStatus.INIT)).subscribe((state: ImgUploadState) => {
            switch (state.status) {
                case ImgUploadStatus.UPLOADING:
                    this.loading = true;
                    this.formGroup.get('photo').setErrors({ required: true });
                    break;
                case ImgUploadStatus.DELETED:
                    this.loading = false;
                    this.formGroup.patchValue({ photo: null });
                    break;
                case ImgUploadStatus.SUCCESS:
                case ImgUploadStatus.ERROR:
                    this.loading = false;
                    this.formGroup.get('photo').setErrors(null);
                    break;
            }
            this.formGroup.get('photo').updateValueAndValidity();
        });
    };
}
