import { Component, OnInit, Inject } from '@angular/core';
import {
    ListItem,
    SelectedFilter,
    Filter,
    Pagination,
    FilterValue,
} from 'src/app/shared/model/list-item.model';
import { Promotion } from '../../model/promotion.model';
import { TranslateService } from '@ngx-translate/core';
import { PromotionService } from '../../services/promotion.service';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ObjectStatus } from 'src/app/shared/enums/object-status.enum';
import { Globals } from '@app/_configs/globals';

@Component({
    selector: 'app-promotion-list',
    templateUrl: './promotion-list.component.html',
})
export class PromotionListComponent implements OnInit {
    public isLoading = false;
    public leftSideFilters: Filter[] = [];
    public pagination: Pagination = new Pagination();
    public promItemArray: ListItem[] = [];

    private lang: string;
    private promotion: Promotion[];
    private selectedFilters: SelectedFilter[] = [];

    constructor(
        private globals: Globals,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private promotionService: PromotionService,
        private notification: RideauNotificationService
    ) { }

    public deletePromotion(itemId) {
        this.promotionService.deletePromotion(itemId).subscribe(() => {
            this.notification.success(
                this.translate.instant('PUBLICATION-SUPPRIMEE')
            );
            this.getPromotions();
        });
    }

    public getPromotions(): void {
        this.isLoading = true;
        this.promotionService
            .getPromotions([ ...this.selectedFilters ], this.pagination)
            .subscribe((res) => {
                this.promotion = res;
                const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
                this.promItemArray = this.promotion.map((promotion) => {
                    const item = promotion.toListItem(this.lang);

                    switch (promotion.statusId) {
                        case ObjectStatus.APPROUVE:
                        case ObjectStatus.OUTDATED:
                        case ObjectStatus.SOUMIS:
                            item.itemInfo1 = this.translate.instant('SOUMIS-LE') + ' ';
                            item.itemInfo1 += new Date(
                                promotion.createdAt
                            ).toLocaleDateString(this.globals.locales.frFR, options);
                            break;

                        default:
                            item.itemInfo1 = '';
                    }

                    item.itemInfo2 = this.translate.instant('PROMOTION-LE') + ' ';
                    item.itemInfo2 += new Date(promotion.dateFrom).toLocaleDateString(
                        this.globals.locales.frFR,
                        options
                    );
                    return item;
                });

                this.isLoading = false;
            });
    }

    public initFilter() {
        // Tous les élements
        const allItemsFilter = new Filter();
        allItemsFilter.filterField = '';
        allItemsFilter.filterValues = [
            {
                filterValueName: this.translate.instant('TOUS'),
                selected: true,
            } as FilterValue,
        ];

        const statusFilter = new Filter();
        statusFilter.filterField = 'statusId';
        statusFilter.filterName = this.translate.instant('SHOW-STATUS');
        statusFilter.filterValues = Object.keys(ObjectStatus)
            .filter((val) => isNaN(Number(val)))
            .map((key) => {
                return {
                    filterValueName: this.translate.instant('STATUS.' + key),
                    filterValue: ObjectStatus[ key ],
                } as FilterValue;
            });

        this.leftSideFilters = [ allItemsFilter, statusFilter ];
    }

    public initPagination() {
        this.pagination.limit = 12;
        this.pagination.offset = 0;
        this.pagination.total = 0;
    }

    public modifyPromotion(itemId) {
        const route = this.localizeRouter.translateRoute('modify');
        this.router.navigate([ `./${itemId}/${route}` ], { relativeTo: this.route });
    }

    public ngOnInit(): void {
        this.lang = this.translate.currentLang;
        this.initFilter();
        this.initPagination();
        this.getPromotions();
    }

    public onFilterChange(event: SelectedFilter) {
        this.selectedFilters = [ event ];
        this.getPromotions();
    }

    public onPageChange(event: Pagination) {
        this.pagination.offset = event.offset;
        this.getPromotions();
    }
}
