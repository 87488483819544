import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sp-quill-editor',
    templateUrl: './sp-quill-editor.component.html'
})
export class SpQuillEditorComponent {
    @Input() label: string;
    @Input() placeholder: string;
    @Input() modules: any;
    @Output() contentChanged = new EventEmitter<any>();

    @Input() value: any;
    @Input() disabled: boolean = false;

    onChange = (value: any) => { };
    onTouched = () => { };

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    handleContentChanged(event: any): void {
        const value = event.html;
        this.value = value;
        this.onChange(value);
        this.onTouched();
        this.contentChanged.emit(event);
    }
}
