import { Injectable, Inject } from '@angular/core';
import { Globals } from 'src/app/_configs/globals';
import { HttpClient } from '@angular/common/http';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Product, IProduct, ICreatedProduct } from '../model/product.model';
import { MainService } from 'src/app/shared/services/main.services';
import { Observable } from 'rxjs';
import { SelectedFilter, Pagination } from 'src/app/shared/model/list-item.model';
import { IProductCreatedResponse } from '@app/shared/model/product-response.model';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductService extends MainService {
    constructor(
        protected httpClient: HttpClient,
        protected globals: Globals,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private translate: TranslateService,
        private router: Router
    ) {
        super(httpClient, globals);
    }

    createProduct(product: IProduct): Observable<ICreatedProduct> {
        const endpoint: string = this.globals.endpoints.product.main;
        return this.httpClient.post<ICreatedProduct>(this.uri + endpoint, this.cleanUpNullValues(product));
    }

    modifyProduct(product: IProduct): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.product.main;
        return this.httpClient.put<IProduct>(endpoint, product);
    }

    getProduct(id: number): Observable<Product> {
        //[GET] - Gets all information about a product
        const endpoint = this.uri + this.globals.endpoints.product.getProductEndpoint(id);
        return this.httpClient.get<Product>(endpoint).map((data) => new Product(data));
    }

    //[GET] Products
    getProducts(filters?: SelectedFilter[], pagination?: Pagination, userId?: number): Observable<Array<Product>> {
        let endpoint: string = this.globals.endpoints.product.all;

        if (filters || pagination) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }

        return this.httpClient.get(this.uri + endpoint).map((data) => {
            if (pagination) pagination.total = data[ 'total' ];
            return data[ 'products' ].map((product) => {
                if (userId && product.gratuities) product.gratuities = product.gratuities.filter((grat) => grat.userId == userId);
                return new Product(product);
            });
        });
    }

    async createBlankProductAndRedirectToForm(): Promise<void> {
        const product = new Product({
            organizationId: 1,
            productTypeId: 1,
            isAlwaysValid: 0,
            validFrom: null,
            validTo: null,
            isQuantityUnlimited: 0,
            quantity: 1,
            remainingQuantity: 1,
            uniqueCode: null,
            adminOrgAccountingNumber: null,
            adminOrgProjectNumber: null,
            orgAccountingNumber: null,
            orgProjectNumber: null,
            isFree: 0,
            singlePrice: null,
            taxable: 0
        });

        product.setTranslatedProperty(this.translate.currentLang, 'name', this.translate.instant('FORM.NOUVEAU-PRODUIT'));

        this.createProduct(product)
            .pipe(
                filter((response: IProductCreatedResponse) => !!response.isCreated),
                map((response: IProductCreatedResponse) => response.product))
            .subscribe(({ id: productId }: IProduct) => {
                const url = this.localizeRouter.translateRoute(`/admin/product/${productId}/modify/RENSEIGNEMENTS-GENERAUX`);
                this.router.navigate([ url ], { queryParams: { isNew: true } });
            });
    }
}
