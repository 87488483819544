import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ITourShowCreatedResponse, TourShow } from '../../model/tour-show.model';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.services';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { BreadcrumbItem } from 'src/app/shared/model/breadcrumb.model';
import { ToursService } from '../../services/tours.service';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { TourEquipage } from '../../enums/tour-equipage.enum';
import { forkJoin, Observable } from 'rxjs';
import { Show } from '../../../show/model/show.model';
import { ShowStatus } from '../../../show/enums/show-status.enum';
import { ShowService } from '../../../show/services/show.service';
import { CachetTypes } from '../../enums/tour-show-cachet.enum';
import { tap, take } from 'rxjs/operators';
import { Tour } from '../../model/tour.model';

@Component({
    selector: 'app-tours-add-show-offer-form',
    templateUrl: './add-show-offer-form.component.html',
    styleUrls: [ './add-show-offer-form.component.scss' ]
})
export class AddShowOfferFormComponent implements OnInit {
    @Input()
    tour: any;
    tourShow: TourShow;
    formGroup: UntypedFormGroup;
    hasNoQCArtists = true;
    hasEmergingArtists = true;
    description: string;
    extraRequirements: string;
    personsOnStage: number;
    tourShowId: number;
    public radioDateValue: string;
    public tourId: number;
    public isCreation = true;
    public organizationId: number;
    public isReady = false;
    public isLoading = false;
    public lang = this.translate.currentLang;
    public spectacles: Show[];
    public openedTab: string;
    public cachetTypes;
    public show: Show;

    conditionsGroup = new UntypedFormGroup({});
    cachetFixeGroup = new UntypedFormGroup({});
    cachetDegressifGroup = new UntypedFormGroup({});
    cachetGroupeGroup = new UntypedFormGroup({});

    constructor(
        private toursService: ToursService,
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private notification: RideauNotificationService,
        private accountService: AccountService,
        private showService: ShowService
    ) {
        this.formGroup = new UntypedFormGroup({});
    }

    ngOnInit(): void {
        this.tourId = this.route.snapshot.params[ 'tourId' ];

        this.breadcrumbService.resetBreadcrumb();
        this.openedTab = 'info';
        this.cachetTypes = CachetTypes;

        forkJoin([ this.getTour(), this.getShowForUser() ])
            .pipe(take(1))
            .subscribe(async ([ tour, shows ]) => {
                this.tour = tour;
                this.description = this.tour.getTranslatedProperty(this.lang, 'description');

                this.breadcrumbService.addBreadcrumbCascade([ new BreadcrumbItem({ title: this.tour.getTranslatedProperty(this.lang, 'name') }) ]);
                this.tourShowId = this.route.snapshot.params[ 'tourShowId' ];

                if (this.tourShowId) {
                    // edition

                    this.tourShow = await this.toursService.getTourShowById(this.tourShowId).toPromise();

                    this.showService
                        .getShow(this.tourShow.showId)
                        .pipe(take(1))
                        .subscribe((show) => {
                            this.show = show;
                            this.formGroup.controls.selectedShow.setValue(show);
                        });

                    let cachetType = CachetTypes.GROUPE;
                    if (this.tourShow.showsFee) {
                        cachetType = CachetTypes.FIXE;
                    } else if (this.tourShow.showsFeeDiscount1) {
                        cachetType = CachetTypes.DEGRESSIF;
                    }
                    this.conditionsGroup = this.fb.group({
                        cachetType: [ cachetType ],
                        revenuePercentage: [ this.tourShow.revenuePercentage ],
                        broadcastExpenses: [ this.tourShow.broadcastExpenses ],
                        isHelpNeeded: [ this.tourShow.isHelpNeeded ]
                    });

                    this.cachetFixeGroup.addControl('showsFee', new UntypedFormControl(this.tourShow.showsFee, [ Validators.required, Validators.min(0) ]));
                    this.cachetDegressifGroup.addControl(
                        'showsFeeDiscount1',
                        new UntypedFormControl(this.tourShow.showsFeeDiscount1, [ Validators.required, Validators.min(0) ])
                    );
                    this.cachetDegressifGroup.addControl('showsFeeDiscount2', new UntypedFormControl(this.tourShow.showsFeeDiscount2, [ Validators.min(0) ]));
                    this.cachetDegressifGroup.addControl('showsFeeDiscount3', new UntypedFormControl(this.tourShow.showsFeeDiscount3, [ Validators.min(0) ]));
                    this.cachetDegressifGroup.addControl('showsFeeDiscount4', new UntypedFormControl(this.tourShow.showsFeeDiscount4, [ Validators.min(0) ]));
                    this.cachetGroupeGroup.addControl('shows3Fee', new UntypedFormControl(this.tourShow.shows3Fee, [ Validators.required, Validators.min(0) ]));
                    this.cachetGroupeGroup.addControl('shows4To6Fee', new UntypedFormControl(this.tourShow.shows4To6Fee, [ Validators.min(0) ]));
                    this.cachetGroupeGroup.addControl('shows7To9Fee', new UntypedFormControl(this.tourShow.shows7To9Fee, [ Validators.min(0) ]));
                    this.cachetGroupeGroup.addControl('shows10PlusFee', new UntypedFormControl(this.tourShow.shows10PlusFee, [ Validators.min(0) ]));
                    this.conditionsGroup.addControl('fixe', this.cachetFixeGroup);
                    this.conditionsGroup.addControl('degressif', this.cachetDegressifGroup);
                    this.conditionsGroup.addControl('groupe', this.cachetGroupeGroup);
                    this.formGroup = this.fb.group({
                        selectedShow: [],
                        conditions: this.conditionsGroup,
                        hasNoQCArtists: [ !!this.tourShow.hasNoQCArtists ],
                        hasEmergingArtists: [ !!this.tourShow.hasEmergingArtists ],
                        directeur: [ this.tourShow.equipage.includes(TourEquipage.DIRECTEUR_TOURNEE) ],
                        sonorisateur: [ this.tourShow.equipage.includes(TourEquipage.SONORISATEUR_ARTISTE) ],
                        eclaragist: [ this.tourShow.equipage.includes(TourEquipage.ECLAIRAGISTE_ARTISTE) ],
                        equipmentSonore: [ this.tourShow.equipage.includes(TourEquipage.EQUIPEMENT_SONORE) ],
                        equipmentEclarage: [ this.tourShow.equipage.includes(TourEquipage.EQUIPEMENT_ECLAIRAGE) ],
                        premierePartie: [ this.tourShow.equipage.includes(TourEquipage.PREMIERE_PARTIE) ],
                        extraRequirements: [ this.tourShow.extraRequirements ],
                        personsOnStage: [ this.tourShow.personsOnStage, [ Validators.required, Validators.min(1) ] ],
                        isAvailableAllTime: [ this.tourShow.isAvailableAllTime ],
                        toursRoseqDateRangeBloc1: [ [ this.tourShow.dateFromBlock1, this.tourShow.dateToBlock1 ] ],
                        toursRoseqDateRangeBloc2: this.tourShow.dateFromBlock2 ? [ [ this.tourShow.dateFromBlock2, this.tourShow.dateToBlock2 ] ] : []
                    });
                    this.cachetTypeChange();

                    this.radioDateValue = this.formGroup.value.isAvailableAllTime === 1 ? 'roseqRadioAllTime' : 'roseqRadioValidityDate';
                } else {
                    // creation
                    this.initTour();
                }
                this.tourShow.tourId = tour.id;
                this.tourShow.organizationId = this.accountService.getCurrentCtxOrganizationId();
                this.tourShow.organizationHeadId = tour.organizationId;
                this.isReady = true;
            });
    }

    /**
     * Initializes the tour and sets up the breadcrumb trail.
     * @returns {void}
     */
    initTour(): void {
        // Set isCreation flag to true.
        this.isCreation = true;
        // Create a new TourShow object and assign it to tourShow.
        this.tourShow = new TourShow({});
        // Call initForm() method to initialize the form.
        this.initForm();
        // Set isReady flag to true.
        this.isReady = true;
        // Create an empty array to store breadcrumb items.
        const breadcrumbItems: BreadcrumbItem[] = [];
        // Get translated tour name and add it to breadcrumb trail.
        const tourShowName = this.tour.getTranslatedProperty(this.translate.currentLang, 'name');
        breadcrumbItems.push(new BreadcrumbItem({ title: tourShowName, url: null, isEditing: false }));
        // Add the breadcrumb trail to the breadcrumb service.
        this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);
    }

    protected initForm() {
        this.formGroup = this.tourShow.getSaisonReguliereFormGroup(this.fb);
        this.formGroup.addControl('selectedShow', new UntypedFormControl());
        this.formGroup.addControl('conditions', this.conditionsGroup);
        this.formGroup.addControl('personsOnStage', new UntypedFormControl(this.tourShow.personsOnStage, [ Validators.required, Validators.min(1) ]));
        this.conditionsGroup.addControl('cachetType', new UntypedFormControl());
        this.cachetFixeGroup.addControl('showsFee', new UntypedFormControl(this.tourShow.showsFee, [ Validators.required, Validators.min(0) ]));
        this.cachetDegressifGroup.addControl('showsFeeDiscount1', new UntypedFormControl(this.tourShow.showsFeeDiscount1, [ Validators.required, Validators.min(0) ]));
        this.cachetDegressifGroup.addControl('showsFeeDiscount2', new UntypedFormControl(this.tourShow.showsFeeDiscount2, [ Validators.min(0) ]));
        this.cachetDegressifGroup.addControl('showsFeeDiscount3', new UntypedFormControl(this.tourShow.showsFeeDiscount3, [ Validators.min(0) ]));
        this.cachetDegressifGroup.addControl('showsFeeDiscount4', new UntypedFormControl(this.tourShow.showsFeeDiscount4, [ Validators.min(0) ]));
        this.cachetGroupeGroup.addControl('shows3Fee', new UntypedFormControl(this.tourShow.shows3Fee, [ Validators.required, Validators.min(0) ]));
        this.cachetGroupeGroup.addControl('shows4To6Fee', new UntypedFormControl(this.tourShow.shows4To6Fee, [ Validators.min(0) ]));
        this.cachetGroupeGroup.addControl('shows7To9Fee', new UntypedFormControl(this.tourShow.shows7To9Fee, [ Validators.min(0) ]));
        this.cachetGroupeGroup.addControl('shows10PlusFee', new UntypedFormControl(this.tourShow.shows10PlusFee, [ Validators.min(0) ]));
        this.conditionsGroup.addControl('fixe', this.cachetFixeGroup);
        this.conditionsGroup.addControl('degressif', this.cachetDegressifGroup);
        this.conditionsGroup.addControl('groupe', this.cachetGroupeGroup);
        if (this.tour.showsFee) {
            this.conditionsGroup.controls[ 'cachetType' ].setValue(CachetTypes.FIXE);
        } else if (this.tour.showsFeeDiscount1 || this.tour.showsFeeDiscount2 || this.tour.showsFeeDiscount3 || this.tour.showsFeeDiscount4) {
            this.conditionsGroup.controls[ 'cachetType' ].setValue(CachetTypes.DEGRESSIF);
        } else if (this.tour.shows3Fee || this.tour.shows4To6Fee || this.tour.shows7To9Fee || this.tour.shows10PlusFee) {
            this.conditionsGroup.controls[ 'cachetType' ].setValue(CachetTypes.GROUPE);
        }
        this.conditionsGroup.addControl('revenuePercentage', new UntypedFormControl(this.tourShow.revenuePercentage));
        this.conditionsGroup.addControl('broadcastExpenses', new UntypedFormControl(this.tourShow.broadcastExpenses));
        this.conditionsGroup.addControl('isHelpNeeded', new UntypedFormControl(!!this.tourShow.isHelpNeeded));

        this.cachetTypeChange();

        this.radioDateValue = this.formGroup.value.isAvailableAllTime == 1 ? 'roseqRadioAllTime' : 'roseqRadioValidityDate';

        this.formGroup.controls.selectedShow.valueChanges.subscribe((value) => {
            this.formGroup.controls.personsOnStage.setValue(value.personsOnStage);
        });
    }

    getTour(): Observable<Tour> {
        return this.toursService.getTourById(this.tourId);
    }

    cachetTypeChange() {
        switch (this.conditionsGroup.controls.cachetType.value) {
            case CachetTypes.FIXE:
                this.cachetFixeGroup.enable();
                this.cachetDegressifGroup.disable();
                this.cachetGroupeGroup.disable();
                return;
            case CachetTypes.DEGRESSIF:
                this.cachetFixeGroup.disable();
                this.cachetDegressifGroup.enable();
                this.cachetGroupeGroup.disable();
                return;

            case CachetTypes.GROUPE:
                this.cachetFixeGroup.disable();
                this.cachetDegressifGroup.disable();
                this.cachetGroupeGroup.enable();
                return;
        }
    }

    doSubmit() {
        this.isLoading = true;

        this.tourShow.equipage = [];
        if (this.formGroup.value.directeur) {
            this.tourShow.equipage.push(TourEquipage.DIRECTEUR_TOURNEE);
        }
        if (this.formGroup.value.sonorisateur) {
            this.tourShow.equipage.push(TourEquipage.SONORISATEUR_ARTISTE);
        }
        if (this.formGroup.value.eclaragist) {
            this.tourShow.equipage.push(TourEquipage.ECLAIRAGISTE_ARTISTE);
        }
        if (this.formGroup.value.equipmentSonore) {
            this.tourShow.equipage.push(TourEquipage.EQUIPEMENT_SONORE);
        }
        if (this.formGroup.value.equipmentEclarage) {
            this.tourShow.equipage.push(TourEquipage.EQUIPEMENT_ECLAIRAGE);
        }
        if (this.formGroup.value.premierePartie) {
            this.tourShow.equipage.push(TourEquipage.PREMIERE_PARTIE);
        }

        this.tourShow.showId = this.formGroup.controls.selectedShow.value.id;
        this.tourShow.hasNoQCArtists = this.formGroup.value.hasNoQCArtists ? 1 : 0;
        this.tourShow.hasEmergingArtists = this.formGroup.value.hasEmergingArtists ? 1 : 0;
        this.tourShow.extraRequirements = this.formGroup.value.extraRequirements;
        this.tourShow.personsOnStage = this.formGroup.value.personsOnStage;
        switch (this.conditionsGroup.controls.cachetType.value) {
            case CachetTypes.FIXE:
                this.tourShow.showsFeeDiscount1 = 0;
                this.tourShow.showsFeeDiscount2 = 0;
                this.tourShow.showsFeeDiscount3 = 0;
                this.tourShow.showsFeeDiscount4 = 0;
                this.tourShow.shows3Fee = 0;
                this.tourShow.shows4To6Fee = 0;
                this.tourShow.shows7To9Fee = 0;
                this.tourShow.shows10PlusFee = 0;

                this.tourShow.showsFee = Number(this.conditionsGroup.value.fixe.showsFee);
                break;

            case CachetTypes.DEGRESSIF:
                this.tourShow.showsFee = 0;
                this.tourShow.shows3Fee = 0;
                this.tourShow.shows4To6Fee = 0;
                this.tourShow.shows7To9Fee = 0;
                this.tourShow.shows10PlusFee = 0;

                this.tourShow.showsFeeDiscount1 = Number(this.conditionsGroup.value.degressif.showsFeeDiscount1);
                this.tourShow.showsFeeDiscount2 = Number(this.conditionsGroup.value.degressif.showsFeeDiscount2);
                this.tourShow.showsFeeDiscount3 = Number(this.conditionsGroup.value.degressif.showsFeeDiscount3);
                this.tourShow.showsFeeDiscount4 = Number(this.conditionsGroup.value.degressif.showsFeeDiscount4);
                break;

            case CachetTypes.GROUPE:
                this.tourShow.showsFee = 0;
                this.tourShow.showsFeeDiscount1 = 0;
                this.tourShow.showsFeeDiscount2 = 0;
                this.tourShow.showsFeeDiscount3 = 0;
                this.tourShow.showsFeeDiscount4 = 0;

                this.tourShow.shows3Fee = Number(this.conditionsGroup.value.groupe.shows3Fee);
                this.tourShow.shows4To6Fee = Number(this.conditionsGroup.value.groupe.shows4To6Fee);
                this.tourShow.shows7To9Fee = Number(this.conditionsGroup.value.groupe.shows7To9Fee);
                this.tourShow.shows10PlusFee = Number(this.conditionsGroup.value.groupe.shows10PlusFee);
        }

        this.tourShow.revenuePercentage = Number(this.conditionsGroup.value.revenuePercentage);
        this.tourShow.broadcastExpenses = Number(this.conditionsGroup.value.broadcastExpenses);

        this.tourShow.isHelpNeeded = this.conditionsGroup.value.isHelpNeeded ? 1 : 0;
        this.tourShow.isAvailableAllTime = this.radioDateValue === 'roseqRadioValidityDate' ? 0 : 1;
        if (this.radioDateValue == 'roseqRadioValidityDate') {
            if (this.formGroup.value.toursRoseqDateRangeBloc1 != null) {
                this.tourShow.dateFromBlock1 = new Date(this.formGroup.value.toursRoseqDateRangeBloc1[ 0 ]);
                this.tourShow.dateToBlock1 = new Date(this.formGroup.value.toursRoseqDateRangeBloc1[ 1 ]);
                this.tourShow.dateFromBlock1.setHours(0, 0, 0, 0);
                this.tourShow.dateToBlock1.setHours(0, 0, 0, 0);
            }

            if (this.formGroup.value.toursRoseqDateRangeBloc2 != null) {
                this.tourShow.dateFromBlock2 = new Date(this.formGroup.value.toursRoseqDateRangeBloc2[ 0 ]);
                this.tourShow.dateToBlock2 = new Date(this.formGroup.value.toursRoseqDateRangeBloc2[ 1 ]);
                this.tourShow.dateFromBlock2.setHours(0, 0, 0, 0);
                this.tourShow.dateToBlock2.setHours(0, 0, 0, 0);
            } else {
                this.tourShow.dateFromBlock2 = null;
                this.tourShow.dateToBlock2 = null;
            }
        }

        if (this.route.snapshot.data.tourShow) {
            // edition
            return this.toursService.modifyTourShow(this.tourShow).subscribe(
                () => {
                    this.isLoading = false;
                    this.notification.success(this.translate.instant('MODIFICATIONS-ENREGISTREES'));
                },
                (error) => this.onError(error),
                () => this.router.navigate([ this.localizeRouter.translateRoute(`/tour/${this.tour.id}/shows/${this.tourShow.id}`) ])
            );
        }
        // creation
        return this.toursService.createTourShow(this.tourShow).subscribe(
            ({ isCreated }: ITourShowCreatedResponse) => {
                if (isCreated) {
                    this.isLoading = false;
                    this.notification.success(this.translate.instant('SAVE-DEPOT-RESEAUX-CONFIRMATION'));
                }
            },
            (error) => this.onError(error),
            () => this.router.navigate([ this.localizeRouter.translateRoute(`/pro-meeting`) ])
        );
    }

    private getShowForUser(): Observable<Show[]> {
        const showFilters = [
            {
                field: 'statusId',
                value: ShowStatus.APPROUVE
            },
            {
                field: 'isPaid',
                value: 1
            },
            {
                field: 'organizationId',
                value: this.accountService.getCurrentCtxOrganizationId()
            }
        ];

        return this.showService.getShows(showFilters).do((res) => {
            this.spectacles = res;
        });
    }

    changeTab(tab) {
        this.openedTab = tab;
    }

    private onError = (error: any): void => {
        if (error.error && error.error.message && error.error.message === 'The tour is closed for producers') {
            this.notification.error(this.translate.instant('ERRORS.CLOSED-DEPOT'));
        }
    };
}
