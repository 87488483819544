import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { NzSelectModule } from 'ng-zorro-antd/select';
/*Components*/
import { PromotionFormComponent } from './components/promotion-form/promotion-form.component';
import { PromotionListComponent } from './components/promotion-list/promotion-list.component';
import { MyPromotionsComponent } from './components/my-promotions/my-promotions.component';

@NgModule({
    declarations: [PromotionFormComponent, PromotionListComponent, MyPromotionsComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, LocalizeRouterModule, RouterModule, SharedModule, NzSelectModule],
    exports: [MyPromotionsComponent]
})
export class PromotionModule { }
