import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { timeToMinutes } from 'src/app/shared/utils/time-utils';
import { Globals } from 'src/app/_configs/globals';
import { AbstractShowForm } from '../show-abstract-form/show-abstract-form.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShowStatus } from '@app/concepts/show/enums/show-status.enum';

@Component({
    selector: 'app-show-cachet-form',
    templateUrl: './show-cachet-form.component.html',
    styleUrls: [ './show-cachet-form.component.scss' ],
})
export class ShowCachetFormComponent
    extends AbstractShowForm
    implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public showTechFile: string;
    public showTechFileName: string;
    public uploadAction: string;
    public loading = false;

    trayCostValues = [
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
        19000,
        20000,
    ];

    constructor(
        private fb: UntypedFormBuilder,
        protected globals: Globals,
        protected accountService: AccountService
    ) {
        super(accountService, globals);
    }

    ngOnInit(): void {
        this.initForm();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected initForm() {
        this.formGroup = this.show.getCachetFormGroup(this.fb);
        this.checkDisable();
        this.formGroup
            .get('coutPlateauMin')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((v) => this.clearMaxTrayCostIfTooBig());
    }

    /**
     * Vide le champ coutPlateauMax si il est inferieur au coutPlateauMin
     * quand le coutPlateauMin change.
     */
    private clearMaxTrayCostIfTooBig() {
        const max = this.formGroup.get('coutPlateauMax');
        const min = this.formGroup.get('coutPlateauMin');

        if (max && min && max.value <= min.value) {
            max.setValue(0);
        }
    }

    displaySubmit() {
        return this.formGroup && !this.formGroup.disabled && this.show && (this.show.statusId === ShowStatus.SOUMIS || this.show.statusId === ShowStatus.APPROUVE);
    }

    displaySaveForLater() {
        return this.show && (this.show.statusId === ShowStatus.NON_SOUMIS || this.show.statusId === ShowStatus.REJETE || this.show.statusId === ShowStatus.EXPIRE);
    }

    filteredTrayCost(): number[] {
        const minVal = this.formGroup.get('coutPlateauMin');
        const index =
            minVal && minVal.value
                ? this.trayCostValues.indexOf(minVal.value) + 1
                : 0;
        return this.trayCostValues.slice(index);
    }

    resetCachet() {
        this.formGroup.patchValue({
            coutPlateauMin: 0,
            coutPlateauMax: 0,
        });
    }

    doSubmit(param?: any) {
        this.cleanUpFormGroupValues();
        this.showValue.trayMinCost = Number(this.formGroup.value.coutPlateauMin);
        this.showValue.trayMaxCost = Number(this.formGroup.value.coutPlateauMax);
        this.showValue.hasBreak = this.formGroup.value.showHasEntracte ? 1 : 0;
        this.showValue.durationBreak = Number(
            this.formGroup.value.showEntracteDuration
        );
        this.showValue.trayWidth = Number(this.formGroup.value.plateauWidth);
        this.showValue.trayDepth = Number(this.formGroup.value.plateauDepth);
        this.showValue.trayHeight = Number(this.formGroup.value.plateauHeight);
        this.showValue.capacity = Number(this.formGroup.value.jaugeMax);
        this.showValue.personsOnRoad = Number(this.formGroup.value.personOnRoad);
        this.showValue.personsOnStage = Number(this.formGroup.value.personsOnStage);
        this.showValue.urlTechSpecification = this.formGroup.value.urlTechSpecification;
        this.show.durationTotal = timeToMinutes({
            hours: this.formGroup.value.showDurationHour,
            minutes: this.formGroup.value.showDurationMinutes
        }
        );
        this.show.durationAssembly = timeToMinutes({
            hours: this.formGroup.value.showMountingDurationHour,
            minutes: this.formGroup.value.showMountingDurationMinutes
        });
        this.show.durationDisassembly = timeToMinutes({
            hours: this.formGroup.value.showDemountingDurationHour,
            minutes: this.formGroup.value.showDemountingDurationMinutes
        });
        super.doSubmit(param);
    }

    private cleanUpFormGroupValues = (formGroup?: UntypedFormGroup): void => {
        Object.keys((formGroup || this.formGroup).controls).forEach(key => {
            const control = (formGroup || this.formGroup).get(key);

            if (control instanceof UntypedFormControl) {
                const value = control.value;
                if (value === null && typeof value === 'number') {
                    control.patchValue(0);
                } else if (value === null && (typeof value === 'string' || typeof value === 'object')) {
                    control.patchValue(undefined);
                }
            } else if (control instanceof UntypedFormGroup) {
                this.cleanUpFormGroupValues(control);
            }
        });
    }
}
