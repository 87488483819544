import { Injectable, Inject } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.services';
import { HttpClient } from '@angular/common/http';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Globals } from 'src/app/_configs/globals';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Calendar } from '../model/calendar.model';
import { map } from 'rxjs/operators';
import { AccountService } from '../../account/services/account.service';
import { IProgramCreatedResponse, Program } from '../model/program.model';
import * as moment from 'moment';

const ORG_ID_QS_PREFIX = '?organizationId=';
@Injectable({
    providedIn: 'root'
})
export class ProgramService extends MainService {
    constructor(
        protected httpClient: HttpClient,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        public accountService: AccountService,
        protected globals: Globals,
        private router: Router
    ) {
        super(httpClient, globals);
    }

    getCalendars(organizationId?: number): Observable<Calendar[]> {
        const orgId = organizationId || this.accountService.getCurrentCtxOrganizationId();

        let endpoint = this.uri + this.globals.endpoints.calendars.all;
        endpoint += ORG_ID_QS_PREFIX + orgId;

        return this.httpClient.get(endpoint).map((data) => data[ 'calendars' ].map((calendar) => new Calendar(calendar)));
    }

    createCalendar(cal): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.calendars.main;
        return this.httpClient.post(endpoint, cal);
    }

    updateCalendar(cal: Calendar): Observable<any> {
        // var endpoint = this.uri + this.global.endpoints.calendars.singleCalendar(cal.id);
        const endpoint = this.uri + this.globals.endpoints.calendars.main;
        return this.httpClient.put(endpoint, cal);
    }

    deleteCalendarById(calId: number): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        let endpoint = this.uri + this.globals.endpoints.calendars.singleCalendar(calId);
        endpoint += ORG_ID_QS_PREFIX + orgId;
        return this.httpClient.delete(endpoint);
    }

    createDateInscriptionAndRedirect(cal) {
        const endpoint = this.uri + this.globals.endpoints.program.main;

        return this.httpClient.post(endpoint, cal).subscribe((res) => {
            const url = this.localizeRouter.translateRoute(`/programmations/${res[ 'program' ][ 'id' ]}/`);
            this.router.navigate([ url ]);
        });
    }

    getOrganizationPrograms(params?: Record<string, string>, organizationId?: number): Observable<Program[]> {
        return this.httpClient
            .get(this.uri + this.globals.endpoints.program.all, {
                params: {
                    ...params,
                    organizationId: (organizationId || this.accountService.getCurrentCtxOrganizationId()) + ''
                }
            })
            .pipe(
                map<{ total: number; programs: Array<Record<string, any>> }, Program[]>((data) => data.programs.map((data) => new Program(data)))
            );
    }

    getPrograms(params?: Record<string, string>): Observable<Program[]> {
        return this.httpClient
            .get(this.uri + this.globals.endpoints.program.all, {
                params
            })
            .pipe(
                map<{ total: number; programs: Array<Record<string, any>> }, Program[]>((data) => data.programs.map((data) => new Program(data)))
            );
    }

    getSingleProgram(programId: number): Observable<Program> {
        return this.httpClient.get(this.uri + this.globals.endpoints.program.singleProgram(programId)).map((data) => new Program(data));
    }

    createProgram(data: any): Observable<IProgramCreatedResponse> {

        if (data.hour == null) {
            delete data.hour;
        }
        if (data.hour) {
            data.hour = moment(data.hour).format(this.globals.time_format_default);
        }
        data.date = moment(data.date).format(this.globals.date_format_output);
        return this.httpClient.post<IProgramCreatedResponse>(this.uri + this.globals.endpoints.program.main, this.cleanUpNullValues(data));
    }

    updateProgram(data: any): Observable<any> {

        if (data.hour == null) {
            delete data.hour;
        }
        if (data.hour && data.hour != null) {
            data.hour = moment(data.hour).format(this.globals.time_format_default);
        }
        data.date = moment(data.date).format(this.globals.date_format_output);
        return this.httpClient.put(this.uri + this.globals.endpoints.program.main, this.cleanUpNullValues(data));
    }

    deleteSingleProgram(programId) {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        let endpoint = this.uri + this.globals.endpoints.program.singleProgram(programId);
        endpoint += ORG_ID_QS_PREFIX + orgId;
        return this.httpClient.delete(endpoint);
    }
}
