import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SPCheckboxComponent),
    multi: true
};
let unique = 0;

@Component({
    selector: 'sp-checkbox',
    template: `
     <label [attr.for]="id" [ngClass]="['checkbox-container', 'checkbox-container--'+size]">
      <span class="checkbox-span">
        <input type="checkbox" [disabled]="disabled" [id]="id" [(ngModel)]="value" (ngModelChange)="ngModelChange($event)"  class="checkbox-input">
        <span class="inner"></span>
      </span>
      <span class="checkbox-label"><ng-content></ng-content></span>
    </label>
    <div class="description">
      <ng-content select="[description]"></ng-content>
    </div>
  `,
    styleUrls: [ './checkbox.component.scss' ],
    providers: [ CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR ]
})
export class SPCheckboxComponent implements ControlValueAccessor {
    @Input() disabled: boolean;
    @Input() size: 'small' | 'medium' | 'large' = 'medium';
    id = `SPCheckboxComponent-${unique++}`;
    value: boolean;

    private onChange: (value: boolean) => void;
    private onTouched: () => void;

    ngModelChange(value: boolean): void {
        this.value = value;
        if (this.onChange) {
            this.onChange(value);
        }
        if (this.onTouched) {
            this.onTouched();
        }
    }

    writeValue(value: boolean): void {
        this.value = value;
    }

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
