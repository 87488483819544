<ng-container *ngIf="isReady">
    <app-page-title title="{{ 'COTISATIONS' | translate }}"></app-page-title>

    <div class="container pb-5">
        <div nz-row nzGutter="24">
            <div nz-col nzMd="6">
                <div class="list-filters">
                    <sp-menu>
                        <sp-menu-item
                            *ngFor="let tab of tabs"
                            [label]="'MEMBERSHIP-TAB.' + tab | translate"
                            [routerLink]="'../' + tab | localize"
                            [classList]="['errorCount']"
                            [selected]="current === tab"
                        ></sp-menu-item>
                    </sp-menu>
                </div>
            </div>
            <div nz-col nzMd="18">
                <!-- FACTURATION -->
                <ng-container *ngIf="current == 'FACTURATION'">
                    <app-membership-billing-list></app-membership-billing-list>
                </ng-container>
                <!-- CONFIGURATION -->
                <ng-container *ngIf="current == 'CONFIGURATION'">
                    <app-membership-configuration></app-membership-configuration>
                </ng-container>
                <ng-container *ngIf="current == 'COMPTABILITE' && product">
                    <app-product-compatibility-form [(product)]="product" (submitCallback)="saveCompatibility($event)" [simpleButton]="true"></app-product-compatibility-form>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
