import { NgModule } from '@angular/core';
import { SPButtonComponent } from './button.component';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';

const component = [
    SPButtonComponent
];

@NgModule({
    imports: [ CommonModule, NzButtonModule ],
    exports: [ ...component ],
    declarations: [ ...component ],
})
export class SPButtonModule { }
