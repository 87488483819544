import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { SharedModule } from '../../shared/shared.module';
import { AccountModule } from '../account/account.module';
/*Components*/
// import { VenueListComponent } from './components/venue-list/venue-list.component';
import { VenueCoordinateFormComponent } from './components/venue-modify/venue-coordinate-form/venue-coordinate-form.component';
import { VenueModifyComponent } from './components/venue-modify/venue-modify.component';
import { VenueProfileFormComponent } from './components/venue-modify/venue-profile-form/venue-profile-form.component';
import { VenueTechnicalInformationFormComponent } from './components/venue-modify/venue-technical-information-form/venue-technical-information-form.component';
import { VenueSingleComponent } from './components/venue-single/venue-single.component';
import { QuillModule } from 'ngx-quill';
// import { VenueSingleComponent } from './components/venue-single/venue-single.component';
import { VenueResidencyFormComponent } from './components/venue-modify/venue-residency-form/venue-residency-form.component';
import { VenueSingleResidencyModule } from './components/venue-single/venue-single-residency/venue-single-residency.module';
import { ImageCropperComponent } from 'ngx-image-cropper';

@NgModule({
    declarations: [
        VenueModifyComponent,
        VenueProfileFormComponent,
        VenueCoordinateFormComponent,
        VenueTechnicalInformationFormComponent,
        VenueSingleComponent,
        VenueResidencyFormComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        LocalizeRouterModule,
        AccountModule,
        SharedModule,
        QuillModule.forRoot(),
        VenueSingleResidencyModule,
        ImageCropperComponent
    ],
    exports: [
        VenueModifyComponent,
        VenueProfileFormComponent,
        VenueCoordinateFormComponent,
        VenueTechnicalInformationFormComponent,
        VenueSingleComponent,
        VenueResidencyFormComponent
    ]
})
export class VenueModule {}
