import { NgModule } from '@angular/core';
import { SPCheckboxComponent } from './checkbox.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [ CommonModule, FormsModule, ReactiveFormsModule ],
    exports: [ SPCheckboxComponent ],
    declarations: [ SPCheckboxComponent ],
})
export class SPCheckboxModule { }
