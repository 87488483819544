import { Component, Input, OnInit } from '@angular/core';
import {
  ListItem,
  Pagination,
  SelectedFilter,
} from 'src/app/shared/model/list-item.model';
import { Meeting } from './../../../model/meeting.model';
import { ParticipantMeeting } from './../../../model/participant.model';
import { MeetingService } from './../../../services/meeting.service';
import { TranslateService } from '@ngx-translate/core';
import { RowTypes } from 'src/app/shared/enums/row-types.enum';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Product } from '../../../../product/model/product.model';
import { Observable } from 'rxjs';
import { ModifiedParticipantRowEvent } from 'src/app/shared/components/item-rows/item-row-participant/item-row-participant.component';

@Component({
  selector: 'app-meeting-participant',
  templateUrl: './meeting-participant.component.html',
  styleUrls: ['./meeting-participant.component.scss'],
})
export class MeetingParticipantComponent implements OnInit {
  @Input()
  meeting: Meeting;

  meetingParticipantsItems: ListItem[];
  listType: RowTypes = RowTypes.CONTACT;
  isLoading = true;
  lang = this.translate.currentLang;
  pagination: Pagination = new Pagination();

  defaultFilters: SelectedFilter[] = [
    {
      field: 'isPaid',
      value: 1,
    },
  ];
  private advancedFilters: SelectedFilter[] = [];

  searchText: string;

  constructor(
    private notification: RideauNotificationService,
    private meetingService: MeetingService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initPagination();
    this.getMeetingParticipants();
  }

  getMeetingParticipants(): void {
    const allFilters = [...this.defaultFilters, ...this.advancedFilters];
    this.meetingService
      .getAllMeetingParticipants(
        this.meeting.id,
        allFilters,
        this.pagination,
        this.searchText
      )
      .subscribe((participants: ParticipantMeeting[]) => {
        this.meetingParticipantsItems = participants.map((part) => {
          const item: ListItem = part.toListItem(this.lang);
          if (part.products) {
            part.products.map((product: Product) => {
              item.itemList.push(product.toListItem(this.lang, this.translate));
            });
          }
          return item;
        });
        this.isLoading = false;
      });
  }

  deleteParticipant(participantId: number): void {
    this.isLoading = true;
    this.meetingService
      .deletePaidParticipant(this.meeting.id, participantId)
      .subscribe(() => {
        this.notification.success(
          this.translate.instant('PARTICIPANT-SUPPRIME')
        );
        this.getMeetingParticipants();
      });
  }

  modifyParticipant(item: ModifiedParticipantRowEvent): void {
    this.isLoading = true;
    this.updateParticipant(item).subscribe(
      () => {
        this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
        this.isLoading = false;
      },
      (err) => {
        this.getMeetingParticipants();
        throw err;
      }
    );
  }

  updateParticipant(item: ModifiedParticipantRowEvent): Observable<any> {
    if (item.productsDeleted && item.productsDeleted.length > 0) {
      return this.meetingService.removeProductsFromParticipant(
        this.meeting.id,
        item.itemId,
        item.productsDeleted
      );
    } else {
      return this.meetingService.modifyParticipantType(
        this.meeting.id,
        item.itemId,
        item.participantType
      );
    }
  }

  initPagination(): void {
    this.pagination.limit = 12;
    this.pagination.offset = 0;
    this.pagination.total = 0;
  }

  onAdvancedFilterChange(event: SelectedFilter[]): void {
    // On remet à la page 1
    this.pagination.offset = 0;
    this.advancedFilters = event;
    this.getMeetingParticipants();
  }

  onSearchChange(event: string): void {
    this.searchText = event;
    this.getMeetingParticipants();
  }

  onPageChange(event: Pagination): void {
    this.pagination.offset = event.offset;
    this.getMeetingParticipants();
  }
}
