<div class="sidenav-container">
    <app-section-title title="{{ 'A-PAYER' | translate }}"></app-section-title>

    <section class="sidenav-container__content">
        <div nz-row nzGutter="24" class="sidenav-container__list" style="padding-left: 12px; padding-right: 12px">
            <sp-list *ngIf="billsToPay.length > 0">
                <div *ngIf="isLoading" class="loader">
                    <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
                </div>

                <!-- Si il y a des factures -->
                <sp-list-item class="p-2">
                    <div nz-col nzSpan="16">
                        <!--<div style="display:flex;flex-direction: column">-->
                        <span>
                            <a routerLink="{{ '/organization/' + organizationId + '/modify/billing' | localize }}">
                                {{ 'COTISATION-RIDEAU' | translate }}
                            </a>
                        </span>
                        <!--</div>-->
                    </div>
                    <div nz-col nzSpan="8" class="payed-button">
                        <button nz-button routerLink="{{ '/organization/' + organizationId + '/modify/billing' | localize }}" nzType="primary">
                            {{ 'PAYER' | translate }}
                        </button>
                    </div>
                </sp-list-item>
            </sp-list>

            <!-- Si pas de facture -->
            <div nz-col *ngIf="billsToPay.length === 0" class="p-3" nzSpan="24">
                <span class="px-3">
                    <a routerLink="{{ '/organization/' + organizationId + '/modify/billing' | localize }}">
                        {{ 'COTISATION-A-JOUR' | translate }}
                    </a>
                </span>
            </div>
        </div>
    </section>
</div>
