import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToursService } from '../../services/tours.service';
import { Tour } from '../../model/tour.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { flatMap, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { TourShow } from '../../model/tour-show.model';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { Globals } from 'src/app/_configs/globals';

@Component({
    selector: 'app-edit-tour-owner',
    templateUrl: 'edit-tour-owner.component.html',
    styles: [
        `
      [nzType='loading'] {
        font-size: 40px;
      }
      .footer {
        min-height: 120px;
        background: #ecebe7;
        padding-top: 40px;
      }
      .container {
        padding-bottom: 20px;
      }
      .h2 {
        margin-bottom: 20px;
      }
      .text-smaller {
        color: #bbb6b3;
        font-size: 13px !important;
        font-weight: 400;
      }
    `,
    ],
})
export class EditTourOwnerComponent implements OnInit {
    form: UntypedFormGroup;
    tour: Tour;
    tourShows: TourShow[];
    public tour$: Observable<Tour>;
    private tourSubject: BehaviorSubject<Tour> = new BehaviorSubject(null);

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly translateService: TranslateService,
        private readonly toursService: ToursService,
        private readonly breadcrumbService: BreadcrumbService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly accountService: AccountService,
        readonly global: Globals
    ) {
        this.tour$ = this.tourSubject.asObservable();
    }

    ngOnInit(): void {
        // inti load data with back end values
        this.loadData(true).subscribe(this.update.bind(this));
    }

    onSubmit() {
        const tour = new Tour(this.tourSubject.value);
        tour.setTranslatedProperty(
            this.translateService.currentLang,
            'name',
            this.form.value.name
        );
        tour.setTranslatedProperty(
            this.translateService.currentLang,
            'description',
            this.form.value.description
        );

        return this.toursService
            .updateTour(tour)
            .pipe(flatMap(() => this.loadData()))
            .subscribe(this.update.bind(this));
    }

    closeForDeposit() {
        return this.toursService
            .closeForDeposit(this.tourSubject.value)
            .pipe(
                tap(() => this.updateTourForStorage({ isDepositOpen: !this.tourSubject.value.isDepositOpen })),
                flatMap(() => this.loadData()))
            .subscribe(this.update.bind(this));
    }

    openForDeposit() {
        return this.toursService
            .openForDeposit(this.tourSubject.value)
            .pipe(
                tap(() => this.updateTourForStorage({ isDepositOpen: !this.tourSubject.value.isDepositOpen })),
                flatMap(() => this.loadData()))
            .subscribe(this.update.bind(this));
    }

    closeForVote() {
        return this.toursService
            .closeForVotes(this.tourSubject.value)
            .pipe(
                tap(() => this.updateTourForStorage({ isVoteOpen: !this.tourSubject.value.isVoteOpen })),
                flatMap(() => this.loadData()))
            .subscribe(this.update.bind(this));
    }

    openForVote() {
        return this.toursService
            .openForVotes(this.tourSubject.value)
            .pipe(
                tap(() => this.updateTourForStorage({ isVoteOpen: !this.tourSubject.value.isVoteOpen })),
                flatMap(() => this.loadData()))
            .subscribe(this.update.bind(this));
    }

    updateTourForStorage = ({ isDepositOpen, isVoteOpen }: { isDepositOpen?: boolean, isVoteOpen?: boolean }): void => {
        const updatedTourForDeposit = new Tour({
            ...this.tourSubject.value,
            isDepositOpen: isDepositOpen !== undefined ? isDepositOpen : this.tourSubject.value.isDepositOpen,
            isVoteOpen: isVoteOpen !== undefined ? isVoteOpen : this.tourSubject.value.isVoteOpen,
        });
        // save to session storage to be loaded from storage
        this.toursService.setTourInStorage(updatedTourForDeposit);
    }

    private loadData(init?: boolean) {
        const tourId = +this.activatedRoute.snapshot.params.tourId;
        return zip(
            init ? this.toursService.getTourById(tourId) : of(this.toursService.getTourByIdFromStorage(tourId)),
            this.toursService.getSubmittedTourShows([
                { field: 'tourId', value: +this.activatedRoute.snapshot.params.tourId },
                {
                    field: 'organizationId',
                    value: this.accountService.getCurrentCtxOrganizationId(),
                },
            ])
        );
    }

    private update([ tour, tourShows ]: [ Tour, TourShow[] ]): void {
        this.breadcrumbService.addBreadcrumbCascade(
            [
                new BreadcrumbItem({
                    title: tour.getTranslatedProperty(
                        this.translateService.currentLang,
                        'name'
                    ),
                    url: `/tour/${tour.id}/shows`,
                }),
                new BreadcrumbItem({
                    title: this.translateService.instant('MODIFIER'),
                }),
            ],
            true
        );
        this.tourSubject.next(tour);
        this.tourShows = tourShows;
        this.form = this.formBuilder.group({
            name: [
                tour.getTranslatedProperty(this.translateService.currentLang, 'name'),
                Validators.required,
            ],
            description: [
                tour.getTranslatedProperty(this.translateService.currentLang, 'description')
            ]
        });
    }
}
