import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'sp-button',
    template: `
    <button nz-button
    (click)="onClick()"
    [nzLoading]="isLoading"
    [disabled]="disabled"
    class="btn btn--default">
    {{ label }}
    </button>
`,
    styleUrls: [ './button.component.scss' ]
})
export class SPButtonComponent {
    @Output() public actionClick = new EventEmitter();
    @Input() public disabled: boolean = false;
    @Input() public isLoading: boolean = false;
    // ! TO FIX: THIS COMPONENT IS NEVER USED
    @Input() public label: string = 'Save';

    public onClick() {
        this.actionClick.emit();
    }
}
