import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ListItem } from '../../../shared/model/list-item.model';
import { Meeting } from '../../meeting/model/meeting.model';
import { Organization } from '../../organization/model/organization.model';
import { Product } from '../../product/model/product.model';
import { Show } from '../../show/model/show.model';
import { GetVitrineById } from '../enums/vitrine-type.enum';

export class Vitrine {
    id: number;
    showId: number;
    meetingId: number;
    productId: number;
    organizationId: number;
    activityId: number;
    statusId: number;
    isPaid: number;
    createdAt: Date;
    // RIDEAU Form infos
    isExpress: number;
    isYoungAudience: number;
    demoFile: string;
    mediaUrl: string;
    mediaPassword: string;
    additionalMediaPassword: string;
    additionalUrl: string;
    media: any[];

    private rideauInfosForm: UntypedFormGroup;

    // Concepts
    show: Show;
    product: Product;
    meeting: Meeting;
    organization: Organization;

    constructor(data: any) {
        this.id = data['id'];
        this.showId = data['showId'];
        this.meetingId = data['meetingId'];
        this.activityId = data['activityId'];
        this.productId = data['productId'];
        this.organizationId = data['organizationId'];
        this.statusId = data['statusId'];
        this.isPaid = data['isPaid'];
        this.createdAt = data['createdAt'] || new Date();

        this.isExpress = data['isExpress'];
        this.isYoungAudience = data['isYoungAudience'];
        this.demoFile = data['demoFile'];
        this.mediaUrl = data['mediaUrl'];
        this.mediaPassword = data['mediaPassword'];
        this.additionalUrl = data['additionalUrl'];
        this.additionalMediaPassword = data['additionalMediaPassword'];
        this.media = data['media'];

        this.show = data['show'] ? new Show(data['show']) : null;
        this.product = data['product'] ? new Product(data['product']) : null;
        this.meeting = data['meeting'] ? new Meeting(data['meeting']) : null;
        this.organization = (this.show && this.show.organization) || (data['organization'] && new Organization(data['organization'])) || null;
    }

    getRideauForm(fb: UntypedFormBuilder): UntypedFormGroup {
        if (this.rideauInfosForm) {
            return this.rideauInfosForm;
        }
        this.rideauInfosForm = fb.group({
            isExpress: new UntypedFormControl(!!this.isExpress),
            isYoungAudience: new UntypedFormControl(!!this.isYoungAudience),
            demoFile: new UntypedFormControl(this.demoFile),
            mediaUrl: new UntypedFormControl(this.mediaUrl),
            mediaPassword: new UntypedFormControl(this.mediaPassword),
            additionalUrl: new UntypedFormControl(this.additionalUrl),
            additionalMediaPassword: new UntypedFormControl(this.additionalMediaPassword)
        });

        return this.rideauInfosForm;
    }

    toListItem(lang?: string): ListItem {
        const item = new ListItem();
        item.itemId = this.id;
        item.itemImgSrc = this.show.image;
        item.itemTitle = this.show.getTranslatedProperty(lang, 'title');
        item.itemSubtitle = '';
        item.linkedShowExpired = this.show.statusId === 5 ? true : false;

        if (this.show && this.show.artists.length) {
            if (this.show.artists.length > 1) {
                item.itemSubtitle = this.show.artists[0].name + ` et ${this.show.artists.length - 1} autres`;
            } else {
                item.itemSubtitle = this.show.artists[0].name;
            }
        }
        item.itemInfo1 = this.organization ? this.organization.getTranslatedProperty(lang, 'name') : '';
        return item;
    }
}

export class ActivityVitrine {
    id?: number;
    activityId?: number;
    vitrineId?: number;
    vitrine?: Vitrine;
    vitrineTypeId?: number;
    duration?: number;

    constructor(data: any) {
        this.id = data['id'];
        this.activityId = data['activityId'];
        this.vitrineId = data['vitrineId'];
        this.vitrine = data['vitrine'] ? new Vitrine(data['vitrine']) : undefined;
        this.vitrineTypeId = data['vitrineTypeId'];
        this.duration = data['duration'];
    }

    toListItem(lang) {
        const item = this.vitrine.toListItem(lang);
        item.itemId = this.id;
        // item.itemInfo3 = item.itemInfo1;
        item.itemInfo3 = 'VITRINE_TYPE.' + GetVitrineById(this.vitrineTypeId);
        item.itemInfo4 = undefined;
        if (this.duration) {
            item.itemInfo4 = this.duration + ' minute' + (this.duration > 1 ? 's' : '');
        }
        item.itemCategory = item.itemInfo4;
        return item;
    }
}
