<div class="item-row {{ !showForm ? '' : 'opacity50' }}">
    <div class="item-row__col">
        <div class="item-row__text item-row__text--title d-inline-block">{{ contact.firstName }} {{ contact.lastName }}</div>
        <p class="item-row__text item-row__text--subtitle">{{ contact.title }}</p>
    </div>
    <div class="item-row__col">
        <div class="item-row__text item-row__text--title d-inline-block">{{ contact.phone }} {{ contact.phonePostNumber ? 'Poste ' + contact.phonePostNumber : '' }}</div>
        <p class="item-row__text item-row__text--subtitle">{{ contact.email }}</p>
    </div>
    <div class="item-row__col item-row__col--roles">
        <div class="d-flex justify-content-end u-gap(xxs)" *ngIf="!isDisabled">
            <button type="button" class="btn-rect d-flex align-items-center" (click)="showModifyForm()">
                {{ 'FORM.MODIFIER' | translate }}
                <i class="icon-edit" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn-square" (click)="removeContactFromShow(contact.id)"><i class="icon-trash" aria-hidden="true"></i></button>
        </div>
    </div>
</div>
<!-- MODIFY CONTACT FORM -->
<ng-container *ngIf="showForm">
    <form class="w-100" nz-form [formGroup]="modifyContactForm" (ngSubmit)="modifyContactFromShow()">
        <div class="form-filter">
            <div nz-row nzGutter="24" class="form-filter__container p-3">
                <div nz-col nzMd="8">
                    <!--
          <nz-form-item>
            <nz-form-control>
              <label for="lastName" class="repertory-filter__label d-block">{{'FORM.NOM' | translate}} *</label>
              <input nz-input id="lastName" formControlName="lastName" type="lastName" maxlength="75" required>
              <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('lastName').errors">
                {{ "CHAMP-REQUIS" | translate }}
              </span>
            </nz-form-control>
          </nz-form-item>
          -->
                    <app-nz-form-item inputId="'lastName'" [control]="modifyContactForm.get('lastName')" inputType="text">
                        <div class="input-custom-label">
                            <label for="lastName" class="repertory-filter__label d-block">{{ 'FORM.NOM' | translate }} *</label>
                        </div>
                        <div class="input-custom-error-msg">
                            <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('lastName').errors">
                                {{ 'CHAMP-REQUIS' | translate }}
                            </span>
                        </div>
                    </app-nz-form-item>
                </div>
                <div nz-col nzMd="8">
                    <!--
          <nz-form-item>
            <nz-form-control>
              <label for="firstName" class="repertory-filter__label d-block">{{'FORM.PRENOM' | translate}} *</label>
              <input nz-input id="firstName" formControlName="firstName" type="firstName" maxlength="75" required>
              <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('firstName').errors">
                {{ "CHAMP-REQUIS" | translate }}
              </span>
            </nz-form-control>
          </nz-form-item>
          -->
                    <app-nz-form-item inputId="'firstName'" [control]="modifyContactForm.get('firstName')" inputType="text">
                        <div class="input-custom-label">
                            <label for="firstName" class="repertory-filter__label d-block">{{ 'FORM.PRENOM' | translate }} *</label>
                        </div>
                        <div class="input-custom-error-msg">
                            <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('firstName').errors">
                                {{ 'CHAMP-REQUIS' | translate }}
                            </span>
                        </div>
                    </app-nz-form-item>
                </div>
                <div nz-col nzMd="8">
                    <!--
          <nz-form-item>
            <nz-form-control>
              <label for="title" class="repertory-filter__label d-block">{{'FORM.TITRE' | translate}} *</label>
              <input nz-input id="title" formControlName="title" type="title" maxlength="75" required>
              <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('title').errors">
                {{ "CHAMP-REQUIS" | translate }}
              </span>
            </nz-form-control>
          </nz-form-item>
          -->
                    <app-nz-form-item inputId="'title'" [control]="modifyContactForm.get('title')" inputType="text">
                        <div class="input-custom-label">
                            <label for="title" class="repertory-filter__label d-block">{{ 'FORM.TITRE' | translate }} *</label>
                        </div>
                        <div class="input-custom-error-msg">
                            <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('title').errors">
                                {{ 'CHAMP-REQUIS' | translate }}
                            </span>
                        </div>
                    </app-nz-form-item>
                </div>
                <div nz-col nzMd="12">
                    <!--
          <nz-form-item>
            <nz-form-control>
              <label for="email" class="repertory-filter__label d-block">{{'FORM.COURRIEL' | translate}} *</label>
              <input nz-input id="email" formControlName="email" type="email" required>
              <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('email').errors">
                <span *ngIf="modifyContactForm.get('email').errors['required']; else incorrectField">{{ "CHAMP-REQUIS" | translate }}</span>
                <ng-template #incorrectField>{{'ENTRER-VALEUR'|translate}}</ng-template>
              </span>
            </nz-form-control>
          </nz-form-item>
          -->
                    <app-nz-form-item inputId="'email'" [control]="modifyContactForm.get('email')" inputType="email">
                        <div class="input-custom-label">
                            <label for="email" class="repertory-filter__label d-block">{{ 'FORM.COURRIEL' | translate }} *</label>
                        </div>
                        <div class="input-custom-error-msg">
                            <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('email').errors">
                                <span *ngIf="modifyContactForm.get('email').errors['required']; else incorrectField">{{ 'CHAMP-REQUIS' | translate }}</span>
                                <ng-template #incorrectField>{{ 'ENTRER-VALEUR' | translate }}</ng-template>
                            </span>
                        </div>
                    </app-nz-form-item>
                </div>
                <div nz-col nzMd="8">
                    <!--
          <nz-form-item>
            <nz-form-control>
              <label for="phone" class="repertory-filter__label d-block">{{'FORM.TELEPHONE' | translate}}</label>
              <input nz-input id="phone" formControlName="phone" type="phone" required>
              <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('phone').errors">
                {{ "CHAMP-REQUIS" | translate }}
              </span>
            </nz-form-control>
          </nz-form-item>
          -->
                    <app-nz-form-item inputId="'phone'" [control]="modifyContactForm.get('phone')" inputType="text">
                        <div class="input-custom-label">
                            <label for="phone" class="repertory-filter__label d-block">{{ 'FORM.TELEPHONE' | translate }} *</label>
                        </div>
                        <div class="input-custom-error-msg">
                            <span class="has-error" *ngIf="modifyContactForm.invalid && modifyContactForm.get('phone').errors">
                                {{ 'CHAMP-REQUIS' | translate }}
                            </span>
                        </div>
                    </app-nz-form-item>
                </div>
                <div nz-col nzMd="4">
                    <!--
          <nz-form-item>
            <nz-form-control>
              <label for="phonePostNumber" class="repertory-filter__label d-block">{{'FORM.POSTE' | translate}}</label>
              <input nz-input id="phonePostNumber" formControlName="phonePostNumber" type="phonePostNumber">
            </nz-form-control>
          </nz-form-item>
          -->
                    <app-nz-form-item inputId="'phonePostNumber'" [control]="modifyContactForm.get('phonePostNumber')" inputType="text">
                        <div class="input-custom-label">
                            <label for="phonePostNumber" class="repertory-filter__label d-block">{{ 'FORM.POSTE' | translate }}</label>
                        </div>
                    </app-nz-form-item>
                </div>
            </div>
            <div class="form-filter__footer text-md-right p-3">
                <button class="btn-rect px-3 d-inline-block mr-md-3" (click)="closeForm()">{{ 'ANNULER' | translate }}</button>
                <button class="btn btn--small d-inline-block form-filter__footer__sauvegarder" nz-button [disabled]="modifyContactForm.invalid" nzSize="large" nzType="default">
                    {{ 'FORM.SAUVEGARDER' | translate }}
                </button>
            </div>
        </div>
    </form>
</ng-container>
