import { Component, ErrorHandler, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Show } from 'src/app/concepts/show/model/show.model';
import { ShowService } from 'src/app/concepts/show/services/show.service';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';
import { Tour } from 'src/app/concepts/tours/model/tour.model';
import { ToursService } from 'src/app/concepts/tours/services/tours.service';
import { BreadcrumbItem } from 'src/app/shared/model/breadcrumb.model';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.services';

@Component({
  selector: 'app-organization-tour-show',
  templateUrl: './organization-tour-show.component.html',
  styleUrls: ['./organization-tour-show.component.scss'],
  providers: [LocalizeRouterService]
})
export class OrganizationTourShowComponent implements OnInit {

  tourShowId: number;
  tourShow: TourShow;
  show: Show;
  tour: Tour;

  title: string;
  subtitle: string;

  constructor(
    private tourShowService: ToursService,
    private breadcrumbService: BreadcrumbService,
    private activeRoute: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private accountService: AccountService,
    @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
    private showService: ShowService,
    private tourService: ToursService,
    private erroHandler: ErrorHandler
  ) { }

  async ngOnInit() {

    this.tourShowId = +this.activeRoute.snapshot.paramMap.get("showId");

    await this.fetchAndSetTourShow();

    this.initBreadCrumb()
  }

  private initBreadCrumb(){

    this.breadcrumbService.addBreadcrumbCascade([ this.getOrganizationDepotBreadCrumItem(), this.getTourShowBreadCrumItem() ])

  }

  private async fetchAndSetTourShow(){
    await this.tourShowService.getTourShowById(this.tourShowId)
    .toPromise()
    .then( async tourShow => {

      this.tourShow = tourShow

      await this.tourService.getTourById(this.tourShow.tourId).toPromise()
      .then(tour => {
        this.tour = tour
      }).catch(err => {
        this.erroHandler.handleError(err)
      })

    }).catch(err => {
      this.erroHandler.handleError(err)
    })

    await this.showService.getShow(this.tourShow.showId).toPromise()
    .then(show => {
      this.show = show;

      this.title = this.show.getTranslatedProperty(this.translateService.currentLang, 'title');
      this.subtitle = this.show.artists? this.show.artists[0].name : '';

    })
  }


  private getTourShowBreadCrumItem(){
    const breadcrumb = new BreadcrumbItem();
    breadcrumb.set(this.title)
    return breadcrumb;
  }

  private getOrganizationDepotBreadCrumItem(){
    const breadcrumb = new BreadcrumbItem();

    breadcrumb.set(this.translateService.instant('modify'), this.router.createUrlTree(this.localizeRouter.translateRoute([
      'organization',
      this.accountService.getCurrentCtxOrganizationId(),
      'modify',
      'user-depot'
    ]) as any[]).toString())

    return breadcrumb;
  }

  navigateToShowOffer(){
    this.router.navigateByUrl(`/${this.translateService.currentLang}/${this.translateService.instant('ROUTES.shows-offers')}/${this.tourShow.organizationId}/${this.show.id}`);
  }

  navigateToDepot(){

    const url = this.router.createUrlTree(this.localizeRouter.translateRoute([
      'organization',
      this.accountService.getCurrentCtxOrganizationId(),
      'modify',
      'user-depot'
    ]) as any[]).toString();
    this.router.navigateByUrl('/' + this.translateService.currentLang + url);
  }
}
