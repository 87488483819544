import { NgModule } from '@angular/core';

import { SPDividerComponent } from './divider.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ CommonModule ],
    exports: [ SPDividerComponent ],
    declarations: [ SPDividerComponent ],
})
export class SPDividerModule { }
