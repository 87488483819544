import { AccountService } from 'src/app/concepts/account/services/account.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityCategories } from './../../../enums/activity-categories.enum';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Activity } from '../../../model/activity.model';
import { Globals } from '../../../../../_configs/globals';

@Component({
  selector: 'app-activity-general-form',
  templateUrl: './activity-general-form.component.html',
  styleUrls: ['./activity-general-form.component.scss'],
})
export class ActivityGeneralFormComponent implements OnInit {
  @Input()
  activity: Activity;

  @Output()
  public saveActivity: EventEmitter<any> = new EventEmitter<any>();

  public formGroup: UntypedFormGroup;
  public isLoading = false;

  public categoryList: any[] = [];

  descriptionModules;
  descriptionModel = '';

  public lang = this.translate.currentLang;

  constructor(
    private global: Globals,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.categoryList = Object.keys(ActivityCategories)
      .filter((elt) => isNaN(Number(elt)))
      .map((key) => {
        return {
          label: this.translate.instant('ACTIVITY-CATEGORIES.' + key),
          id: ActivityCategories[key],
        };
      });
  }

  public initForm(): void {
    this.formGroup = this.activity.getGeneralFormGroup(this.fb, this.lang);

    // Richtext is not part of the formgroup
    this.descriptionModel = this.activity.getTranslatedProperty(
      this.lang,
      'description'
    );
    this.descriptionModules = this.global.InputRichTextModules;
  }

  public doSubmit(): void {
    this.activity.setTranslatedProperty(
      this.lang,
      'title',
      this.formGroup.value.title
    );
    this.activity.categoryId = this.formGroup.value.categoryId;
    this.activity.setTranslatedProperty(
      this.lang,
      'description',
      this.descriptionModel
    );

    if (this.formGroup.value.date) {
      this.activity.date = this.formGroup.value.date;
    } else {
      this.activity.date = new Date();
    }

    const hourFrom = new Date(
      new Date(this.formGroup.value.hourFrom).setFullYear(
        this.activity.date.getFullYear(),
        this.activity.date.getMonth(),
        this.activity.date.getDate()
      )
    );
    const hourTo = new Date(
      new Date(this.formGroup.value.hourTo).setFullYear(
        this.activity.date.getFullYear(),
        this.activity.date.getMonth(),
        this.activity.date.getDate()
      )
    );

    this.activity.hourFrom = hourFrom;
    this.activity.hourTo = hourTo;
    this.activity.place = this.formGroup.value.place;
    this.activity.room = this.formGroup.value.room;
    this.activity.organizationId = this.accountService.getCurrentCtxOrganizationId();
    this.activity.isAvailable = this.formGroup.value.isAvailable ? 1 : 0;
    this.saveActivity.emit();
  }

  limitDescriptionLength($event): void {
    if ($event.editor.getLength() > this.global.MAXDESCRIPTIONLENGHT) {
      $event.editor.deleteText(
        this.global.MAXDESCRIPTIONLENGHT,
        $event.editor.getLength()
      );
    }
  }
}
