<div nz-row class="p-3 m-2 mt-3 table-head light-tablehead">
    <div nz-col nzMd="12" class="d-flex align-items-center table-head__col">
        <strong>
            {{ 'BABILLARD' | translate }}
        </strong>
    </div>
    <div nz-col nzMd="12" class="table-head__col d-flex justify-content-end" id="button-wrapper">
        <button class="btn-rect d-flex align-items-center justify-content-between" (click)="newPublication()">
            <i nz-icon class="pr-1" nzType="plus" nzTheme="outline" aria-hidden="true"></i>
            {{ 'NOUVELLE-PUBLICATION' | translate }}
        </button>
    </div>
</div>

<div *ngFor="let item of pubItemArray">
    <app-item-row-promo [item]="item" [showRenewButton]="false" (modifyItem)="modifier(item)" (removeItem)="supprimer(item)"></app-item-row-promo>
</div>
