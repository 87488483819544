import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgramService } from '../../services/program.services';
import { ShowService } from 'src/app/concepts/show/services/show.service';
import { ToursService } from 'src/app/concepts/tours/services/tours.service';
import { Show } from 'src/app/concepts/show/model/show.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Program } from '../../model/program.model';
import { Venue } from 'src/app/concepts/venue/model/venue.model';
import { VenueService } from 'src/app/concepts/venue/services/venue.service';
import { Subject, zip, of, Observable, BehaviorSubject } from 'rxjs';
import { take, takeUntil, flatMap, tap, share } from 'rxjs/operators';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Calendar } from '../../model/calendar.model';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { RowTypes } from 'src/app/shared/enums/row-types.enum';
import { Notification } from './notifications/notifications.component';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { OrganizationTypes } from '../../../organization/enums/organization-types.enum';
import { ProgramStatus } from '../../../program/model/program-status.model';
import { Organization } from '../../../organization/model/organization.model';
import { ShowStatus } from '../../../show/enums/show-status.enum';
import { sameDay, sameHour } from '../../date-utilities';
import { Tour } from '../../../tours/model/tour.model';
import { StorageKey } from '../../../account/services/auth/auth.service';
import { getTimestampFromDate } from '@app/shared/utils/time-utils';
import * as moment from 'moment';

@Component({
    selector: 'app-program-single',
    templateUrl: './program-single.component.html',
    styleUrls: ['./program-single.component.scss']
})
export class ProgramSingleComponent implements OnInit, OnDestroy {
    /**
     * The current status of the program.
     */
    public readonly programStatus = ProgramStatus;

    /**
     * The available row types.
     */
    public readonly rowTypes = RowTypes;

    /**
     * Whether data is currently being loaded.
     */
    loading: boolean;

    /**
     * An array of shows.
     */
    shows: Show[];

    /**
     * An array of venues.
     */
    venues: Venue[];

    /**
     * An array of calendars.
     */
    calendars: Calendar[];

    /**
     * An array of diffusers.
     */
    diffusers: Organization[];

    /**
     * The current program.
     */
    program: Program;

    /**
     * The current tour.
     */
    tour: Tour;

    /**
     * An observable of the current tour.
     */
    tour$: Observable<Tour>;

    /**
     * The program form group.
     */
    programForm: UntypedFormGroup;

    /**
     * The currently selected venue.
     */
    selectedVenue: Venue;

    /**
     * The current notification.
     */
    notification: BehaviorSubject<Notification> = new BehaviorSubject<Notification>(undefined);

    /**
     * An observable of the current notification.
     */
    public readonly notification$: Observable<Notification> = this.notification.asObservable();

    /**
     * Whether a date update is pending.
     */
    isDateUpdatePending = false;

    /**
     * Whether an hour update is pending.
     */
    isHourUpdatePending = false;

    /**
     * Whether a status update is pending.
     */
    isStatusUpdatePending = false;

    /**
     * Whether a venue ID update is pending.
     */
    isVenueIdUpdatePending = false;

    /**
     * Whether the current user is an admin on the tour.
     */
    isAdminTour = false;

    /**
     * Whether the current user is a diffuser.
     */
    isDiffuser: boolean;

    /**
     * Whether the current user is a producer.
     */
    isProducer: boolean;

    /**
     * Whether the program is currently disabled.
     */
    isDisabled = false;

    /**
     * Whether the submit button is currently disabled.
     */
    isButtonDisabled = false;

    /**
     * Whether custom venues are enabled.
     */
    isCustomVenueEnable = false;

    /**
     * Whether custom shows are enabled.
     */
    isCustomShowEnable = false;

    /**
     * @private
     * @readonly
     * @description The destroyed$ variable is an instance of the Subject class with a void type parameter.
     */
    private readonly destroyed$: Subject<void> = new Subject<void>();

    /**
     * @private
     * @readonly
     * @description The showStatusObject variable is an instance of the ShowStatus class.
     */

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private formGroup: UntypedFormBuilder,
        private showService: ShowService,
        private venueService: VenueService,
        private accountService: AccountService,
        public translate: TranslateService,
        private modalService: NzModalService,
        private programService: ProgramService,
        private notificationService: RideauNotificationService,
        private readonly organizationService: OrganizationService,
        private toursService: ToursService
    ) {}

    // the `match` object contains the remote matched program
    // update status of the program own by the other org. in a matching action
    get isMatchUpdatedRemote(): boolean {
        return this.program.match && this.program.match.isMatchUpdated;
    }

    // update status of the program own by the current org. in a matching action
    get isMatchUpdatedLocal(): boolean {
        return this.program.isMatchUpdated;
    }

    async ngOnInit(): Promise<any> {
        const response = await this.loadData().toPromise();
        this.updateForm(response as any);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    loadData(): Observable<any> {
        const organizationId = this.accountService.getCurrentCtxOrganizationId();
        return zip(
            of(this.route.snapshot.params.progId).pipe(
                flatMap((progId) => {
                    if (progId) {
                        return this.programService.getSingleProgram(progId);
                    } else {
                        return of(new Program({ organizationId }));
                    }
                })
            ),
            this.programService.getCalendars().map((calendars) => calendars.filter((calendar) => calendar.id !== 1)), // retrait calendrier General
            this.organizationService.getDiffusers(),
            this.organizationService.getOrganization(organizationId).pipe(
                flatMap((organization) => {
                    this.isProducer = organization.types.some((type) => type === OrganizationTypes.IS_PRODUCTEUR);
                    this.isDiffuser = organization.types.some((type) => type === OrganizationTypes.IS_DIFFUSEUR);
                    return this.showService
                        .getShows([
                            { field: 'statusId', value: ShowStatus.APPROUVE },
                            { field: 'isPaid', value: 1 },
                            ...(this.isProducer && !this.isDiffuser ? [{ field: 'organizationId', value: organizationId }] : [])
                        ])
                        .map<Show[], [Organization, Show[], boolean, boolean]>((shows) => [organization, shows, this.isProducer, this.isDiffuser]);
                })
            )
        ).pipe(
            flatMap(([program, calendars, diffusers, [organization, shows, isProducer, isDiffuser]]) => {
                let venues$: Observable<Venue[]>;
                if (isDiffuser) {
                    venues$ = this.venueService.getVenues(organizationId);
                } else if (program.match && program.match.organizationId) {
                    venues$ = this.venueService.getVenues(program.match.organizationId);
                } else {
                    venues$ = of([]);
                }
                if (program.tourId) {
                    this.tour$ = this.toursService.getTourById(program.tourId).pipe(takeUntil(this.destroyed$), share());
                    this.tour$.subscribe((tour) => (this.tour = tour));
                }

                return venues$.map((venues) => [program, calendars, venues, diffusers, [organization, shows, isProducer, isDiffuser]]);
            }),
            take(1)
        );
    }

    updateOrganizationType(isProducer: boolean, isDiffuser: boolean, showOrganizationId: number): void {
        if (isProducer && isDiffuser) {
            if (showOrganizationId) {
                if (showOrganizationId === this.program.organizationId) {
                    isDiffuser = false;
                } else {
                    isProducer = false;
                }
            } else if (this.program.showCustom) {
                isProducer = false;
            }
        }
        this.program.isProducer = +isProducer;
        this.program.isDiffuser = +isDiffuser;
    }

    updateShowRelated(showId: number): void {
        if (this.isProducer && this.isDiffuser) {
            const selectedShow = showId ? this.shows.find((show) => show.id === showId) : null;
            this.updateOrganizationType(this.isProducer, this.isDiffuser, selectedShow ? selectedShow.organizationId : null);
            this.onFormValueChange(this.programForm.value);
        }
    }

    updateForm([program, calendars, venues, diffusers, [organization, shows, isProducer, isDiffuser]]): void {
        this.program = new Program(program);
        if (program.show) {
            this.program.show = new Show({
                ...program.show,
                itemUrl: `${this.translate.currentLang}/${this.translate.instant('ROUTES.shows-offers')}/${program.show.organization.id}/${program.show.id}`,
                itemUrlExt: true
            });
        }
        this.updateOrganizationType(isProducer, isDiffuser, this.program.show && this.program.show.organization ? this.program.show.organization.id : 0);
        // tslint:disable-next-line:radix
        const currentUserId = parseInt(localStorage.getItem(StorageKey.USERID));
        let currentUserIsAdmin = false;
        if (this.tour) {
            this.tour.admins.forEach(function (element) {
                currentUserIsAdmin = Number(element.user.id) === currentUserId;
            });
        }

        this.venues = venues;
        this.calendars = calendars;
        this.shows = this.showService.toShows(shows);
        this.diffusers = diffusers;

        if (!this.programForm) {
            this.isAdminTour = this.program.isTourAdmin;
            this.initForm();

            // ? User NOT tour admin, shouldn't be able to edit the program IF the program status is Provisory OR Canceled OR Confirmed
            if (!this.isAdminTour && [ProgramStatus.Canceled, ProgramStatus.Confirmed, ProgramStatus.Provisory].includes(this.program.statusId)) {
                this.disableForm();
            }

            this.programForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
                this.onFormValueChange(value);
            });

            this.onFormValueChange(this.programForm.value);
        }
        if (this.program.match) {
            this.programForm.controls.showId && this.programForm.controls.showId.disable();
            this.programForm.controls.diffuserId && this.programForm.controls.diffuserId.disable();

            // remote match deleted
            if (this.program.isMatchDeleted) {
                this.notification.next({
                    status: 'danger',
                    message: 'PROGRAMME-NOTIFICATIONS.MESSAGE-MATCH-DELETED',
                    params: {
                        org: this.program.match.organization.getTranslatedProperty(this.translate.currentLang, 'name')
                    }
                });

                // match
            } else {
                this.programForm.addControl('statusId', new UntypedFormControl(this.program.statusId));

                if (this.program.isProducer) {
                    this.programForm.addControl(
                        'venueId',
                        new UntypedFormControl({
                            value: this.program.venueId || this.program.match.venueId,
                            disabled: false
                        })
                    );
                    this.programForm.addControl(
                        'hour',
                        new UntypedFormControl({
                            value: this.program.hour || this.program.match.hour,
                            disabled: false
                        })
                    );
                    // least verbose way to check the condition
                    if (this.program.match.venue) {
                        this.programForm.get('venueId').setValue(this.program.match.venue.id || null);
                    }

                    this.programForm.get('hour').setValue(this.program.hour || this.program.match.hour);
                }

                if (this.isMatchUpdatedRemote || this.isMatchUpdatedLocal) {
                    this.isDateUpdatePending = !sameDay(this.program.date, this.program.match.date);
                    this.isHourUpdatePending = !sameHour(this.program.hour, this.program.match.hour);
                    this.isStatusUpdatePending = !sameValue(this.program.statusId, this.program.match.statusId);
                    this.isVenueIdUpdatePending = !sameValue(this.program.venueId, this.program.match.venueId);
                }

                // local match update
                if (this.isMatchUpdatedLocal) {
                    this.programForm.controls.date && this.programForm.controls.date.setValue(this.program.match.date);
                    this.programForm.controls.hour && this.programForm.controls.hour.setValue(this.program.match.hour);
                    this.programForm.controls.statusId && this.programForm.controls.statusId.setValue(this.program.match.statusId);
                    this.programForm.controls.venueId && this.programForm.controls.venueId.setValue(this.program.match.venueId);

                    this.notification.next({
                        status: 'danger',
                        message: 'PROGRAMME-NOTIFICATIONS.MESSAGE-REMOTE-MATCH-CHANGE',
                        params: {
                            org: this.program.match.organization.getTranslatedProperty(this.translate.currentLang, 'name')
                        }
                    });

                    // remote match update
                } else if (this.isMatchUpdatedRemote) {
                    this.notification.next({
                        status: 'warning',
                        message: 'PROGRAMME-NOTIFICATIONS.MESSAGE-LOCAL-MATCH-CHANGE',
                        params: {
                            org: this.program.match.organization.getTranslatedProperty(this.translate.currentLang, 'name')
                        }
                    });

                    /* * ? this.isMatchUpdatedLocal & this.isMatchUpdatedRemote
                     * are reset to 0: all matched actions have been performed
                     * by both parts. A success message is displayed
                     * */
                } else {
                    this.displaySuccessNotification();
                }
            }
        } else {
            if (this.programForm.controls.showId) {
                this.programForm.controls.showId.enable();
            }
            if (this.programForm.controls.diffuserId) {
                this.programForm.controls.diffuserId.enable();
            }
        }
    }

    showShowManualInputToggle = (): boolean => this.program.isDiffuser && !this.program.match;

    showDiffuserManualInputToggle = (): boolean => !this.program.match;

    showPrivateFieldsSeparator = (): boolean =>
        (this.program.isProducer && this.programForm.contains('diffuserId')) || (this.program.isDiffuser && !!this.programForm.get('showId'));

    showInternalStatusOption = (): boolean => !this.program.match && !(this.programForm.contains('showCustom') || this.programForm.contains('venueCustom'));

    toggleShowManualInput(): void {
        this.isCustomShowEnable = !this.isCustomShowEnable;
        this.programForm.get('showId').setValue(null);
        this.programForm.get('showCustom').setValue(null);
        this.programForm.get('statusId').setValue(null);
    }

    toggleDiffuserManualInput(): void {
        this.isCustomVenueEnable = !this.isCustomVenueEnable;
        this.programForm.get('venueCustom').setValue(null);
        this.programForm.get('venueId').setValue(null);
        this.programForm.get('diffuserId').setValue(null);
    }

    deleteDateInscription(): void {
        const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
        const acceptDelete = this.translate.instant('OUI');
        const rejectDelete = this.translate.instant('NON');
        this.modalService.confirm({
            nzContent: deleteMessages,
            nzOkText: acceptDelete,
            nzCancelText: rejectDelete,
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.programService.deleteSingleProgram(this.program.id).subscribe(() => {
                    this.notificationService.success(this.translate.instant('INSCRIPTION-SUPPRIME'));
                    this.router.navigate([this.localizeRouter.translateRoute('/calendar')]);
                });
            }
        });
    }

    onVenueSelected(selectedId: number): void {
        this.selectedVenue = this.venues.find((venue) => venue.id === selectedId);
    }

    onVenueRemoved(): void {
        this.programForm.controls.venueId.setValue((this.selectedVenue = null));
    }

    public submitFormHandler = (isMatchUpdatedLocal: boolean): void => {
        /** Because the form has 2 buttons in a Program Matching context,
         * the form should be submitted only once. Once the validatePendingChanges
         * is executed, this function ends.
         * validatePendingChanges only when statusId equal to match statusId
         */
        if (isMatchUpdatedLocal && this.programForm.value.statusId === this.program.match.statusId) {
            this.validatePendingChanges();
            return;
        }
        if (!this.programForm.valid) {
            return;
        }
        of(this.program.id)
            .pipe(
                tap(() => (this.loading = true)),
                flatMap((programId) => this.updateProgramValues(programId)),
                take(1)
            )
            .subscribe(() => {
                this.notificationService.success(this.translate.instant('INSCRIPTION-MAJ'));
                this.loading = false;
                this.redirectToCalendar();
            });
    };

    public validatePendingChanges(): void {
        this.loading = true;
        this.programService
            .updateProgram(
                new Program({
                    ...this.program,
                    ...this.programForm.value,
                    match: undefined,
                    date: moment(this.program.date).format('YYYY-MM-DD').toString(),
                    isAccepted: true
                })
            )
            .pipe(
                flatMap(() => this.loadData()),
                tap(() => this.programForm.enable()),
                tap(this.updateForm.bind(this)),
                take(1)
            )
            .subscribe(() => {
                this.notificationService.success(this.translate.instant('INSCRIPTION-MAJ'));
                this.loading = false;
                this.displaySuccessNotification();
                this.redirectToCalendar();
            });
    }

    private updateProgramValues = (id: number) => {
        if (this.programForm.value.date) {
            this.programForm.patchValue({
                date: new Date(this.programForm.value.date.setHours(0, 0, 0, 0))
            });
        }
        const program: Program = new Program({
            ...this.program,
            ...this.programForm.value
        });
        if (this.programForm.value.diffuserId) {
            program.venueCustom = null;
        }
        if (this.programForm.value.venueCustom) {
            program.diffuserId = null;
        }
        if (this.programForm.value.showId) {
            program.showCustom = null;
        }
        if (this.programForm.value.showCustom) {
            program.showId = null;
        }

        if (program.hour) {
            this.setProgramHour(program);
        }
        if (id) {
            return this.programService.updateProgram(program);
        }
        return this.programService.createProgram(program);
    };

    private async onFormValueChange(program: Partial<Program>): Promise<void> {
        if (this.program.isProducer === 1 && this.program.isDiffuser === 1) {
            this.notification.next(null);
            return;
        }
        if (this.program.match) {
            return;
        }
        if (!program.diffuserId) {
            return;
        }
        const initialNotification: Notification = {
            status: 'info',
            message: 'PROGRAMME-NOTIFICATIONS.MESSAGE-INFO',
            params: {
                org: this.diffusers.find((diffuser) => diffuser.id === program.diffuserId).getTranslatedProperty(this.translate.currentLang, 'name')
            }
        };
        if (this.program.isDiffuser) {
            if (!program.showId) {
                this.notification.next(null);
                return;
            }
            const organizationId = this.shows.find((show) => show.id === program.showId).organizationId;
            this.organizationService
                .getOrganization(organizationId)
                .pipe(
                    tap(async (organization: Organization) => {
                        this.notification.next({ ...(await this.getNotificationForDiffuser(initialNotification, organization)) });
                    }),
                    takeUntil(this.destroyed$)
                )
                .subscribe();
        }

        if (this.program.isProducer) {
            this.notification.next({ ...(await this.getNotificationForProducer(initialNotification)) });
        }
    }
    /**
     *Check if the program has a tour date or if the user is an admin for the tour
     *@return {boolean} Returns a boolean indicating if there is a tour date or if the user is an admin for the tour
     */
    private get isTourDate(): boolean {
        return this.isAdminTour || !!this.program.tourId;
    }

    /**
     * Returns a notification for the producer based on whether or not there is a tour date
     * @param {Notification} notificationTemplate - The notification template to use
     * @return {Promise<Notification>} Returns a promise that resolves to the notification object
     */
    private getNotificationForProducer = async (notificationTemplate: Notification): Promise<Notification> => {
        if (!this.isTourDate) {
            return notificationTemplate;
        }
        return {
            ...(await this.getTourDateNotification(notificationTemplate))
        };
    };

    /**
     * Returns a notification for the diffuser based on whether or not there is a tour date
     * @param {Notification} notificationTemplate - The notification template to use
     * @param {Organization} organization - The organization object to use
     * @return {Promise<Notification>} Returns a promise that resolves to the notification object
     */
    private getNotificationForDiffuser = async (notificationTemplate: Notification, organization: Organization): Promise<Notification> => {
        if (!this.isTourDate) {
            return {
                ...notificationTemplate,
                params: {
                    tour: null,
                    org: organization.getTranslatedProperty(this.translate.currentLang, 'name')
                }
            };
        }
        return { ...(await this.getTourDateNotification(notificationTemplate)) };
    };

    /**
     * Returns a notification object with the tour date information
     * @param {Notification} notificationTemplate - The notification template to use
     * @return {Promise<Notification>} Returns a promise that resolves to the notification object
     */
    private getTourDateNotification = async (notificationTemplate: Notification): Promise<Notification> => {
        const tour = await this.tour$.toPromise();
        return {
            ...notificationTemplate,
            message: `PROGRAMME-NOTIFICATIONS.MESSAGE-SUCCESS-${this.isAdminTour ? 'ADMIN' : 'USER'}-TOUR`,
            status: 'success',
            params: {
                org: null,
                tour: tour.getTranslatedProperty(this.translate.currentLang, 'name')
            }
        };
    };

    private setProgramHour(program: Program): void {
        program.hour = moment(program.hour)
            .set({
                year: program.date.getFullYear(),
                month: program.date.getMonth(),
                date: program.date.getDate()
            })
            .toDate();
    }

    private initForm(): void {
        let diffuserId = null;
        if (this.program.diffuserId) {
            diffuserId = this.program.diffuserId;
        } else if (this.program.tourId) {
            diffuserId = this.program.organizationId;
        }
        this.programForm = this.formGroup.group({
            date: new UntypedFormControl({ value: this.program.date, disabled: this.program.statusId === ProgramStatus.Confirmed && !!this.program.tourId }, Validators.required),
            hour: new UntypedFormControl(this.program.hour || null),
            note: new UntypedFormControl(this.program.note),
            showId: new UntypedFormControl(this.program.showId),
            statusId: new UntypedFormControl(this.program.statusId ? this.program.statusId : null),
            venueId: new UntypedFormControl(this.program.venue ? this.program.venue.id : null),
            diffuserId: new UntypedFormControl(diffuserId),
            calendarId: new UntypedFormControl(this.program.calendarId ? this.program.calendarId : null),
            showCustom: new UntypedFormControl(this.program.showCustom ? this.program.showCustom : null),
            venueCustom: new UntypedFormControl(this.program.venueCustom ? this.program.venueCustom : null)
        });

        this.isCustomShowEnable = this.program.showCustom !== undefined;
        this.isCustomVenueEnable = this.program.venueCustom !== undefined;

        if (!this.program.id && this.isDiffuser) {
            this.programForm.get('diffuserId').setValue(this.accountService.getCurrentCtxOrganizationId());
        }
    }

    private disableForm(): void {
        if (this.program.tourId) {
            this.isDisabled = true;
            this.programForm.disable();
        }
    }

    private displaySuccessNotification(): void {
        this.notification.next({
            status: 'success',
            message: 'PROGRAMME-NOTIFICATIONS.MESSAGE-SUCCESS',
            params: {
                org: this.program.match.organization.getTranslatedProperty(this.translate.currentLang, 'name')
            }
        });
    }

    private redirectToCalendar = () => {
        // https://logient.atlassian.net/browse/RIDEAU-1819
        const programRouteTrans = `${this.translate.instant('ROUTES.program')}/`;
        const tourRouteTrans = this.program.tourId ? `${this.translate.instant('ROUTES.tour')}/${this.program.tourId}` : '';
        const tourProgramLink = `/${this.translate.currentLang}/${programRouteTrans}${tourRouteTrans}`;
        // /fr/programmations/tournée/68
        this.router.navigate([tourProgramLink], {
            queryParams: {
                date: getTimestampFromDate(this.programForm.get('date').value)
            },
            queryParamsHandling: 'merge'
        });
    };
}

function sameValue<T>(a: T, b: T): boolean {
    if (!a && !b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    return a === b;
}
