import {Component, Input, OnInit, Inject} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {AccountService} from "src/app/concepts/account/services/account.service";
import {Classification} from "../../../model/classification.model";
import {Show} from "../../../model/show.model";
import {ShowService} from "../../../services/show.service";
import {MarketingState, MarketingStateType} from "./maketing-state.class";

@Component({
  selector: "app-marketing-details",
  templateUrl: "./marketing-details.component.html",
  styleUrls: ["./marketing-details.component.scss"],
})
export class MarketingDetailsComponent implements OnInit {
  @Input() show: Show;

  marketingState: MarketingState;

  private organizationId: number;

  private defaultMarketingState: MarketingStateType[] = [
    {
      name: "enable",
      prop: {
        class: "mb-3 success",
        icon: "check",
        iconStyle: "color : #00D1AE",
        sentence: "MARKETING-NON-ACTIVE",
        buttonSentence: "DESACTIVATE-MARKETING",
        callback: this.changeShowMarketing.bind(this),
      },
    },
    {
      name: "desable",
      prop: {
        class: "mb-c danger",
        icon: "close",
        iconStyle: "color: #FE544D",
        sentence: "MARKETING-ACTIVE",
        buttonSentence: "ACTIVATE-MARKETING",
        callback: this.changeShowMarketing.bind(this),
      },
    },
  ];

  classifications: Classification[];

  constructor(
    private router: Router,
    @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
    private activatedRouter: ActivatedRoute,
    private showService: ShowService,
    private accountService: AccountService,
  ) {
    this.marketingState = new MarketingState(this.defaultMarketingState);
    this.organizationId = this.accountService.getCurrentCtxOrganizationId();
  }

  async ngOnInit() {
    this.classifications = await this.showService
      .getClassificationsList()
      .toPromise();
    if (!this.show.isMarketed) {
      this.marketingState.changeState("desable");
    }
  }

  navigateTo(path: string) {
    const transTab = this.localizeRouter.translateRoute(path);
    this.router.navigate([`../${transTab}`], {
      relativeTo: this.activatedRouter,
    });
  }

  changeShowMarketing() {
    return this.showService
      .updateShowIsMarketing(this.show.id, {
        isMarketed: +!this.show.isMarketed,
        organizationId: this.organizationId,
      })
      .subscribe((response) => {
        this.show.isMarketed = !this.show.isMarketed;
        this.show.isMarketed
          ? this.marketingState.changeState("enable")
          : this.marketingState.changeState("desable");
      });
  }

  createShowClassification() {
    let classificationRes = []
    if (this.classifications != undefined) {
      let clarification = this.classifications.find(classification => classification.id === this.show.classifications[0]);

      if (clarification) {
        classificationRes.push(clarification.desc)
        return this.parentClassification(classificationRes, clarification.parentId).reverse().join(' / ')
      }
    }
  }

  parentClassification(result, classificationId) {
    if (classificationId) {
      let classification = this.classifications.find(classification => classification.id === classificationId)
      result.push(classification.desc)

      // Récursive si le noeud actuel a un parent
      if (classification.parentId) {
        this.parentClassification(result, classification.parentId)
      }
    }
    return result
  }
}
