import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'sp-nz-checkbox',
    templateUrl: './nz-checkbox.component.html',
    styleUrls: ['./nz-checkbox.component.scss']
})
export class NzCheckboxComponent {
    @Input() controlName: UntypedFormControl;
    @Input() ngModelName: any;
    @Input() isDisabled: any;
    @Input() ngModelOptions: any;
    @Output() selectionChange = new EventEmitter<any>();
    constructor(@Optional() @Self() public ngControl: NgControl, private readonly translate: TranslateService) {}

    emitSelection(value: any) {
        this.selectionChange.emit(value);
    }

    getControl(): UntypedFormControl {
        if (this.controlName instanceof UntypedFormControl) {
            return this.controlName;
        } else if (typeof this.controlName === 'string' && this.ngControl && this.ngControl.control) {
            return this.ngControl.control.get(this.controlName) as unknown as UntypedFormControl;
        } else {
            throw new Error('Invalid control provided to CustomInputComponent');
        }
    }
}
