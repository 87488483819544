import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SpOptionComponent } from './sp-option/sp-option.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, NzSelectModule, NzFormModule],
    exports: [SelectComponent, SpOptionComponent],
    declarations: [SelectComponent, SpOptionComponent]
})
export class SPSelectModule { }
