<div
    class="page-template-head"
    [ngClass]="{ dashboardTheme: isDashboardPage, showPageTheme: isShowPage, 'with-image': isWithImagePage, mainPageTheme: isMainPage, withNoMargin: withNoMargin }"
>
    <div class="container">
        <div nz-row class="row">
            <!-- IMAGE -->
            <div class="col" nz-col [nzSpan]="image ? '4' : '0'" [nzMd]="image ? '4' : '0'" class="page-title--image col">
                <img src="{{ image }}" alt="" />
            </div>

            <div class="col"  class="page-title--title {{ image ? 'float-right' : 'w-100' }} col">
                <!-- TITLE BAR -->
                <div [class]="image ? 'ml-4 mt-2' : ''">
                    <h1 [class]="subtitle ? 'mb-0' : ''" [ngClass]="{ 'clr-white': isDashboardPage }">{{ title }}</h1>
                    <ng-container *ngIf="categories">
                        <p class="fiche-title-category">
                            <span *ngFor="let type of categories" class="mb-2">{{ 'ORGANIZATION-TYPE_' + type | translate }}</span>
                        </p>
                    </ng-container>
                    <ng-container *ngIf="subtitle">
                        <p class="font-size-med mr-3" [ngClass]="{ 'clr-white': isDashboardPage }">{{ subtitle }}</p>
                    </ng-container>
                </div>
                <!-- BUTONS -->
                <div class="page-title--button">
                    <!-- FAVORITES  -->
                    <ng-container *ngIf="useFavorites">
                        <button
                            nz-dropdown
                            nzTrigger="click"
                            class="btn-rect btn--larger btn--dropdown"
                            [ngClass]="{ _isBtnActive: isBtnActive }"
                            [nzDropdownMenu]="menu"
                            (nzVisibleChange)="updateFavorites()"
                            [nzClickHide]="nzClickHide"
                        >
                            <ng-container *ngIf="!isRotate && (userFavorite || organizationFavorite || networkFavorite); else defaultFavPicto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svgBookMark">
                                    <path d="M6 2c-.552 0-1 .448-1 1v14c0 .89 1.077 1.337 1.707.707L10 14.414l3.293 3.293c.63.63 1.707.184 1.707-.707V3c0-.552-.448-1-1-1H6z" />
                                </svg>
                            </ng-container>

                            <ng-template #defaultFavPicto>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svgBookMark">
                                    <path
                                        d="M13 4v10.586l-2.293-2.293c-.39-.39-1.024-.39-1.414 0L7 14.586V3c0-.552-.448-1-1-1s-1 .448-1 1v14c0 .89 1.077 1.337 1.707.707L10 14.414l3.293 3.293c.63.63 1.707.184 1.707-.707V3c0-.552-.448-1-1-1H9c-.552 0-1 .448-1 1s.448 1 1 1h4z"
                                    />
                                </svg>
                            </ng-template>

                            <span>{{ 'FAVORIS' | translate }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" [ngClass]="isRotate ? '_svgRotate' : ''">
                                <path
                                    fill-rule="nonzero"
                                    d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z"
                                />
                            </svg>
                        </button>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <nz-checkbox-wrapper>
                                <ul nz-menu>
                                    <li nz-menu-item>{{'SAVE-AS-FAVORITE' | translate}}:</li>
                                    <li nz-menu-item>
                                        <img class="avatar" [src]="user.avatar || './assets/images/placeholder-membre.svg'" alt="" />
                                        <label nz-checkbox [(ngModel)]="userFavorite">{{'MOI'| translate}}</label>
                                    </li>
                                    <li nz-menu-item *ngIf="organization">
                                        <img class="avatar" [src]="organization ? organization.logo : '../../../../../assets/images/empty.png'" alt="" />
                                        <label nz-checkbox [(ngModel)]="organizationFavorite">
                                            {{ organization ? (organization | translatable: 'name' | stringShortener: 15) + ' (' + ('MY-TEAM' | translate) + ')' : '' }}
                                        </label>
                                    </li>
                                    <li nz-menu-item *ngFor="let network of userNetworks; let index = index; trackBy: trackByIndex">
                                        <img class="avatar" [src]="network ? network.logo : '../../../../../assets/images/empty.png'" alt="" />
                                        <label nz-checkbox [(ngModel)]="networkFavorite[index]" placeholder="network">
                                            {{ network ? (network | translatable: 'name' | stringShortener: 15) + ' (' + ('MY-NETWORK' | translate) + ')' : '' }}
                                        </label>
                                    </li>
                                    <li nz-menu-item>
                                        <button class="btn btn--larger" nzType="default" nzTrigger="click" (click)="saveFavorite()">{{'SAUVEGARDER' | translate}}</button>
                                    </li>
                                </ul>
                            </nz-checkbox-wrapper>
                        </nz-dropdown-menu>
                    </ng-container>

                    <!-- EXPORT DROPDOWN -->
                    <ng-container>
                        <!-- Transclusion du composant d'export (si présent) -->
                        <ng-content></ng-content>
                    </ng-container>

                    <!-- ACTIONS BY AUTHORIZATION  -->
                    <ng-container *appPermission="permission; objectOrganizationId: organizationId; organizationType: orgaType; shouldBeApproved: shouldBeApproved">
                        <ng-container *ngIf="buttonText">
                            <ng-container *ngIf="buttonLink; else otherButtonType">
                                <!-- Using an <a> (previously, a button was used here) element with routerLink is preferred over a button for navigation in Angular due to semantic clarity, browser history management, accessibility benefits, bookmarking support, and default browser behavior. -->
                                <a class="btn btn--larger btn--edit" [routerLink]="buttonLink | localize" nzType="default" style="padding-top: 7px">
                                    <i nz-icon aria-hidden="true" class="pr-12">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <path
                                                fill="#ffffff"
                                                fill-rule="nonzero"
                                                d="M16.142 3.707a3 3 0 0 1 0 4.243l-7.07 7.07a1 1 0 0 1-.314.213l-4.95 2.121c-.83.356-1.669-.483-1.313-1.313l2.121-4.95a1 1 0 0 1 .212-.313l7.071-7.07a3 3 0 0 1 4.243 0zM11.26 7.174L6.38 12.056l-1.06 2.475 2.474-1.06 4.881-4.883-1.414-1.414zm2.054-2.053l-.64.64 1.414 1.413.64-.638a1 1 0 1 0-1.414-1.415z"
                                            />
                                        </svg>
                                    </i>
                                    {{ buttonText }}
                                </a>
                            </ng-container>
                            <ng-template #otherButtonType>
                                <button
                                    class="btn btn--small"
                                    *ngIf="buttonType; else otherButton"
                                    (click)="buttonClicked.emit()"
                                    nz-button
                                    [nzLoading]="buttonIsLoading"
                                    nzType="default"
                                    style="padding: 4px 22px 4px 14px; font-size: 14px !important"
                                >
                                    <i nz-icon aria-hidden="true" nzType="{{ buttonType }}" nzTheme="outline" style="padding-right: 4px"></i>
                                    {{ buttonText }}
                                </button>
                            </ng-template>
                            <ng-template #otherButton>
                                <button
                                    class="btn btn--small"
                                    (click)="buttonClicked.emit()"
                                    nz-button
                                    [nzLoading]="buttonIsLoading"
                                    nzType="default"
                                    style="padding: 4px 22px 4px 14px; font-size: 14px !important"
                                >
                                    <i nz-icon aria-hidden="true" nzType="plus" nzTheme="outline" style="padding-right: 4px"></i>
                                    {{ buttonText }}
                                </button>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <img class="headerImage" src="assets/images/visuel_3.png" *ngIf="isDashboardPage" alt="" />
</div>
