import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '../../../location/services/location.service';
import { SiteConcepts } from '../../../../shared/enums/site-concepts.enum';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../../account/services/account.service';
import { Organization } from '../../../organization/model/organization.model';
import { VenueStatus } from '../../enums/venue-status.enum';
import { VenueService } from '../../services/venue.service';
import { IVenue, Venue } from '../../model/venue.model';
import { Globals } from 'src/app/_configs/globals';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';

const SAVE_FORM_TKEY = 'FORM.SAUVEGARDEE';

@Component({
    selector: 'app-venue-modify',
    templateUrl: './venue-modify.component.html',
    styleUrls: ['./venue-modify.component.scss']
})
export class VenueModifyComponent implements OnInit {
    public venue: Venue;
    public venueId: number;
    public venueState: string;
    public organizationId: number;
    public organization: Organization;
    public isLoading: boolean;
    public isReady = false;

    // displaying errors
    public displayErrors = false;
    public addressInfoErrorCount = 0;
    public profileErrorCount = 0;
    public residencyErrorCount = 0;

    /*FORM STEPS*/
    public current = '';
    public tabs = [
        {
            id: 'COORDONNEES',
            varErrorCount: 0
        },
        {
            id: 'INFORMATION-TECHNIQUE',
            varErrorCount: 0
        },
        {
            id: 'RESIDENCY',
            varErrorCount: 0
        },
        {
            id: 'PROFIL',
            varErrorCount: 0
        }
    ];

    public lang: string;

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private globals: Globals,
        private route: ActivatedRoute,
        private venueService: VenueService,
        private translate: TranslateService,
        private accountService: AccountService,
        public locationService: LocationService,
        private breadcrumbService: BreadcrumbService,
        private localiseService: LocalizeRouterService,
        private notification: RideauNotificationService,
        private organizationService: OrganizationService
    ) {}

    ngOnInit(): void {
        // listen to route changes
        this.venueId = this.route.snapshot.params['venueId'];
        this.organizationId = this.route.snapshot.params['organizationId'];
        this.lang = this.translate.currentLang;

        // Get oorganization owning the venue
        this.organizationService.getOrganization(this.organizationId).subscribe((data) => {
            this.organization = data;
            this.handleRouteEvents();
        });
    }

    private handleRouteEvents() {
        this.route.params.subscribe((params) => {
            // par defaut l'onglet courrant est le premier;
            this.current = 'COORDONNEES';
            if (params.page) {
                // traduire tous les onglets jusqu'à trouver celui passé en URL
                for (const tab of this.tabs) {
                    const locTab = this.localiseService.translateRoute(tab['id']);
                    if (params.page === locTab) {
                        this.current = tab['id'];
                        this.getSingleVenue();
                        break;
                    }
                }
            } else {
                // redirect to identification si pas de parametre.
                const transTab = this.localiseService.translateRoute(this.current);
                this.router.navigate([`./${transTab}`], { relativeTo: this.route });
            }
        });
    }

    async getSingleVenue() {
        this.venueService.getVenue(this.venueId).subscribe(
            (data: Venue) => {
                this.venue = new Venue(data);
                // Set Breadcrumb
                const venueName = this.venue.getTranslatedProperty(this.lang, 'name');
                const venueUrl = '/venue/' + this.venue.organizationId + '/' + this.venue.id;

                const orgName = this.organization.getTranslatedProperty(this.lang, 'name');
                const orgUrl = '/organization/' + this.venue.organizationId + '/';

                const parentOrgItem = new BreadcrumbItem();
                parentOrgItem.set(orgName, orgUrl, false);

                const venueModifyItem = new BreadcrumbItem();
                venueModifyItem.set(venueName, venueUrl, true);

                const currentCtxOrg = this.accountService.getCurrentCtxOrganizationId();

                // If i'm a member of the organization except Scene-pro members
                if (currentCtxOrg != this.globals.SCENE_PRO_ORGID) {
                    this.breadcrumbService.setBreadcrumbConcept(SiteConcepts.ACCOUNT);
                }

                this.breadcrumbService.addBreadcrumbCascade([parentOrgItem, venueModifyItem], true);

                if (!this.venue.isApproved) {
                    this.updateErrorCounts();
                }
                this.isReady = true;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    saveCoord(event: any) {
        this.isLoading = true;
        forkJoin([this.locationService.modifyAdress(this.venue.address), this.venueService.modifyVenue(this.venue as IVenue)])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.notification.success(this.translate.instant(SAVE_FORM_TKEY));
                this.updateErrorCounts();

                if (!this.venue.isApproved && event === undefined) {
                    this.navigateTo('INFORMATION-TECHNIQUE');
                }
            });
    }

    saveProfile(event: any) {
        this.isLoading = true;

        this.venueService
            .modifyVenue(this.venue as Venue)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.notification.success(this.translate.instant(SAVE_FORM_TKEY));
                this.updateErrorCounts();

                if (!this.venue.isApproved && event === undefined) {
                    if (this.totalErrorCount() > 0) {
                        // afficher message d'erreur et toggle open le displayError
                        this.notification.error(this.translate.instant('MESSAGES.ERREUR-SOUMISSION'));
                        this.displayErrors = true;
                    } else {
                        this.submitForApproval();
                    }
                }
            });
    }

    saveTechnicalInfo(event: any) {
        this.isLoading = true;
        forkJoin([
            this.venueService.patchVenueConfigurations(this.organizationId, this.venue.id, this.venue.configurations),
            this.venueService.modifyVenue(this.venue as Venue)
        ])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(() => {
                this.notification.success(this.translate.instant(SAVE_FORM_TKEY));
                this.updateErrorCounts();
                if (!this.venue.isApproved && event === undefined) {
                    this.navigateTo('RESIDENCY');
                }
            });
    }

    saveResidency(event: any): void {
        const updatedVenue = { ...this.venue } as Venue;
        this.isLoading = true;
        this.updateErrorCounts();
        if (this.residencyErrorCount > 0) {
            this.isLoading = false;
            this.displayErrors = true;
            return;
        }
        this.venueService
            .modifyVenue(updatedVenue)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(() => {
                this.displayErrors = false;
                this.residencyErrorCount = 0;
                this.notification.success(this.translate.instant(SAVE_FORM_TKEY));
                if (!this.venue.isApproved && !event) {
                    this.navigateTo('PROFIL');
                }
            });
    }

    toggleDisplayErrors(): void {
        this.displayErrors = !this.displayErrors;
        if (this.displayErrors) {
            this.updateErrorCounts();
        }
    }

    private updateErrorCounts(): void {
        const coordForm = this.venue.getCoordFormGroup(this.fb, this.translate.currentLang);
        this.addressInfoErrorCount = this.countInvalidControls(coordForm);

        const profileForm = this.venue.getProfileFormGroup(this.fb, this.translate.currentLang);
        this.profileErrorCount = this.countInvalidControls(profileForm);
        const residencyForm = this.venue.getResidencyFormGroup();
        this.residencyErrorCount = residencyForm.get('isResidency').value ? this.countInvalidControls(residencyForm) : 0;
        this.tabs[0].varErrorCount = this.addressInfoErrorCount;
        this.tabs[2].varErrorCount = this.residencyErrorCount;
        this.tabs[3].varErrorCount = this.profileErrorCount;
    }

    private countInvalidControls(fg: UntypedFormGroup): number {
        const invalid = [];
        const controls = fg.controls;
        for (const name in controls) {
            if ('controls' in controls) {
                this.countInvalidControls(fg['controls'][name] as UntypedFormGroup);
            } else if (controls[name].invalid) {
                controls[name].setErrors({});
                invalid.push(name);
            }
        }
        return invalid.length;
    }

    totalErrorCount(): number {
        return this.addressInfoErrorCount + this.profileErrorCount + this.residencyErrorCount;
    }

    // Approvals
    submitForApproval() {
        this.changeStatus(VenueStatus.SOUMIS).subscribe(() => this.notification.success(this.translate.instant('MESSAGES.SOUMIS-POUR-APPROBATION')));
    }

    approve() {
        this.changeStatus(VenueStatus.APPROUVE).subscribe(() => this.notification.success(this.translate.instant('STATUS.APPROUVEE')));
    }

    reject() {
        this.changeStatus(VenueStatus.REJETE).subscribe(() => this.notification.success(this.translate.instant('STATUS.REJETEE')));
    }

    private changeStatus(status: VenueStatus): Observable<any> {
        const approvalState = {
            venueId: this.venueId,
            statusId: status,
            organizationId: this.organizationId
        };
        this.isLoading = true;
        return this.venueService.modifyVenueApproval(approvalState).do(() => {
            this.venue.statusId = status;
            this.isLoading = false;
        });
    }

    private get isSceneProAdmin(): boolean {
        return this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID;
    }

    private navigateTo = (route: string): void => {
        const translatedRouteName = this.localiseService.translateRoute(route);
        this.router.navigate([`../${translatedRouteName}`], { relativeTo: this.route });
    };
}
