import { Component } from '@angular/core';
interface HelpMenuItem {
    value: string;
    id: string;
    selected: boolean;
}
@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: [ './help.component.scss' ],
})
export class HelpComponent {
    helpMenuList: HelpMenuItem[] = [
        { value: "AIDE-MENU.GENERAL", id: "AIDE-MENU-ID.GENERAL", selected: true },
        { value: "AIDE-MENU.BOTTIN", id: "AIDE-MENU-ID.BOTTIN", selected: false },
        { value: "AIDE-MENU.OFFRES-SPECTACLES", id: "AIDE-MENU-ID.OFFRES-SPECTACLES", selected: false },
        { value: "AIDE-MENU.RENCONTRES-PRO", id: "AIDE-MENU-ID.RENCONTRES-PRO", selected: false },
        { value: "AIDE-MENU.TOURNER-QUEBEC", id: "AIDE-MENU-ID.TOURNER-QUEBEC", selected: false }
    ]

    onSelectMenu(index: number): void {
        this.helpMenuList = [ ...this.helpMenuList ].map((listMenuItem, listIndex): HelpMenuItem => ({
            ...listMenuItem,
            selected: listIndex === index
        }));
    }
}
