<form id="profile-form" nz-form [formGroup]="rideauForm">
    <ng-container *ngIf="!reloading">
        <div nz-row class="my-3">
            <div nz-col nzSpan="24">
                <app-item-upload-repeater
                    title="FORM.MATERIEL-APPUI-SUPP"
                    subtitle="FORM.MATERIEL-APPUI-EXPLICATIONS"
                    btnText="FORM.MATERIEL-AJOUTER"
                    [isVitrine]="true"
                    [name]="'vitrineFiles'"
                    [action]="presentationFileEndpoint"
                    [shouldDisplayImage]="false"
                    [acceptedFileTypes]="acceptedFileTypes"
                    [(items)]="materielSupList"
                    [maxFileSize]="maxFileSize"
                ></app-item-upload-repeater>
            </div>
        </div>
    </ng-container>

    <div nz-row>
        <div nz-col nzSpan="19">
            <strong>{{ 'FORM.LIENS-DEMO-TITLE' | translate }}</strong>
            <p>{{ 'FORM.LIENS-DEMO-DESCRIPTION' | translate }}</p>
            <!--  Main Link -->
            <div nz-row nzGutter="16">
                <div nz-col nzSpan="16">
                    <!-- <nz-form-item>
                        <nz-form-control>
                            <label class="mb-2" for="demoLink">{{ 'FORM.LIEN-DEMO-1' | translate}}</label>
                            <input type="url" nz-input id="demoLink" formControlName="mediaUrl">

                            <span class="has-error"
                                *ngIf="rideauForm.get('mediaUrl').touched && rideauForm.get('mediaUrl').errors">
                                {{'CHAMP-REQUIS-SIMPLE'|translate}}
                            </span>
                        </nz-form-control>
                    </nz-form-item> -->

                    <app-nz-form-item inputId="'demoLink'" [control]="rideauForm.get('mediaUrl')" inputType="url">
                        <div class="input-custom-label">
                            <label class="mb-2" for="demoLink">{{ 'FORM.LIEN-DEMO-1' | translate }}</label>
                        </div>
                        <div class="input-custom-error-msg">
                            <span class="has-error" *ngIf="rideauForm.get('mediaUrl').touched && rideauForm.get('mediaUrl').errors">
                                {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                            </span>
                        </div>
                    </app-nz-form-item>
                </div>

                <div nz-col nzSpan="8">
                    <!-- <nz-form-item>
                        <nz-form-control>
                            <label class="mb-2" for="mediaPassword">{{ 'FORM.MOT-DE-PASSE-IF-PRIVATE' |
                                translate}}</label>
                            <input type="url" nz-input id="mediaPassword" formControlName="mediaPassword">
                        </nz-form-control>
                    </nz-form-item> -->

                    <app-nz-form-item inputId="'mediaPassword'" [control]="rideauForm.get('mediaPassword')" inputType="url">
                        <div class="input-custom-label">
                            <label class="mb-2" for="mediaPassword">{{ 'FORM.MOT-DE-PASSE-IF-PRIVATE' | translate }}</label>
                        </div>
                    </app-nz-form-item>
                </div>
            </div>

            <!--  additional Link -->
            <div nz-row nzGutter="16">
                <div nz-col nzSpan="16">
                    <!-- <nz-form-item>
                        <nz-form-control>
                            <label class="mb-2" for="otherDemoLink">{{ 'FORM.LIEN-DEMO-2' | translate}}</label>
                            <input nz-input id="otherDemoLink" formControlName="additionalUrl">
                        </nz-form-control>
                    </nz-form-item> -->

                    <app-nz-form-item inputId="'otherDemoLink'" [control]="rideauForm.get('additionalUrl')" inputType="text">
                        <div class="input-custom-label">
                            <label class="mb-2" for="otherDemoLink">{{ 'FORM.LIEN-DEMO-2' | translate }}</label>
                        </div>
                    </app-nz-form-item>
                </div>

                <div nz-col nzSpan="8">
                    <!-- <nz-form-item>
                        <nz-form-control>
                            <label class="mb-2" for="additionalMediaPassword">{{ 'FORM.MOT-DE-PASSE-IF-PRIVATE' |
                                translate}}</label>
                            <input nz-input id="additionalMediaPassword" formControlName="additionalMediaPassword">
                        </nz-form-control>
                    </nz-form-item> -->

                    <app-nz-form-item inputId="'additionalMediaPassword'" [control]="rideauForm.get('additionalMediaPassword')" inputType="text">
                        <div class="input-custom-label">
                            <label class="mb-2" for="additionalMediaPassword">{{ 'FORM.MOT-DE-PASSE-IF-PRIVATE' | translate }}</label>
                        </div>
                    </app-nz-form-item>
                </div>
            </div>
        </div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="24">
            <sp-checkbox formControlName="isYoungAudience" [classList]="['pt-2', 'pb-3']">
                {{ 'VITRINE-JURY-JEUNE-PUBLIC' | translate }}
            </sp-checkbox>
            <sp-checkbox formControlName="isExpress">
                {{ 'VITRINE_TYPE.PRESENTATION_EXPRESS' | translate }}
            </sp-checkbox>
        </div>
    </div>
</form>
