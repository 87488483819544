import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Stand } from '../../model/stand.model';
import { StandFormComponent } from './stand-form/stand-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { StandRangeFormComponent } from './stand-range-form/stand-range-form.component';

@Component({
  selector: 'app-stand-create',
  templateUrl: './stand-create.component.html',
  styleUrls: ['./stand-create.component.scss'],
})
export class StandCreateComponent implements OnInit {
  @Input()
  meetingId: number;

  @Input()
  meetingOwnerOrgId: number;

  @Output()
  reloadStandList: EventEmitter<any> = new EventEmitter<any>();

  newStand: Stand;

  constructor(private modalService: NzModalService) {}

  ngOnInit(): void {
    this.newStand = new Stand({ meetingId: this.meetingId });
  }

  reloadList() {
    this.reloadStandList.emit();
  }

  showStandFormModal(): void {
    const showStandFormModal = this.modalService.create<StandFormComponent>({
      nzContent: StandFormComponent,
      nzWidth: '400px',
      // Le callBack OnOk renvoie l'instance du composant
      nzOnOk: (res: StandFormComponent) => {
        if (res.createStand()) {
          this.newStand = new Stand({ meetingId: this.meetingId });
          setTimeout(() => {
            this.reloadStandList.emit();
          }, 1000);
          return true;
        } else return false;
      },
    }as any);

    const showStandFormModalInstance = showStandFormModal.getContentComponent();
    showStandFormModalInstance.theStand = this.newStand;
    showStandFormModalInstance.meetingOwnerOrgId = this.meetingOwnerOrgId;
    showStandFormModalInstance.meetingId = this.meetingId; 
  }

  showMultiStandForm() {
    const showMultiStandFormModal = this.modalService.create<StandRangeFormComponent>({
      nzContent: StandRangeFormComponent,
      // Le callBack OnOk renvoie l'instance du composant
      nzOnOk: (res: StandRangeFormComponent) => {
        if (res.createStandRange()) {
          setTimeout(() => {
            this.reloadStandList.emit();
          }, 1000);
          return true;
        } else return false;
      },
    }as any);

    const showMultiStandFormModalInstance = showMultiStandFormModal.getContentComponent();
    showMultiStandFormModalInstance.meetingId = this.meetingId;
    showMultiStandFormModalInstance.meetingOwnerOrgId = this.meetingOwnerOrgId;

  }
}
