import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Globals } from '@app/_configs/globals';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { ErrorsHandler } from 'src/app/shared/error-handling/errors-handler';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [ErrorsHandler]
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: UntypedFormGroup;

  isLoading = false;

  constructor(
    private authService : AuthService,
    private formBuilder: UntypedFormBuilder,
    private rideauNotificationService: RideauNotificationService,
    private translate: TranslateService,
    private errorHandlerService: ErrorsHandler,
    private route: Router,
    private globals: Globals
  ) { }

  ngOnInit() {
    this.initForm();
  }

  async sendPasswordResetRequest(){
    if(this.forgotPasswordForm.valid){
      this.isLoading = true;

      await this.authService.resetPasswordRequest(this.forgotPasswordForm.get('email').value)
      .toPromise()
      .then(_ => {
         this.rideauNotificationService.success(this.translate.instant('EMAIL_SEND'));
         this.route.navigate([this.translate.currentLang,'login'])
      })
      .catch(err => {
        this.errorHandlerService.handleError(err)
      });

      this.isLoading = false;

    }
  }

  private initForm(){
    this.forgotPasswordForm = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.globals.emailRegex)])
    })
  }

}
