import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-infos',
  templateUrl: './user-infos.component.html',
  styleUrls: ['./user-infos.component.scss'],
})
export class UserInfosComponent {
  @Input() user: any;
  @Input() editUrl: string;
}
