import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SiteConcepts } from '../enums/site-concepts.enum';
import { BreadcrumbItem } from '../model/breadcrumb.model';

@Injectable()
export class BreadcrumbService implements OnDestroy {
    private breadcrumbItemsSubject: BehaviorSubject<BreadcrumbItem[]> = new BehaviorSubject<BreadcrumbItem[]>([]);
    public readonly breadcrumbItems$: Observable<BreadcrumbItem[]>;
    private destroyed: Subject<void> = new Subject<void>();
    // Listeners
    private _singleCascade = new Subject<any>();
    private _conceptListener = new Subject<any>();
    private _resetListener = new Subject<any>();

    constructor() {
        this.breadcrumbItems$ = this.breadcrumbItemsSubject.asObservable().pipe(
            filter((array) => !!array && !!array.length),
            takeUntil(this.destroyed)
        );
    }
    cascadeListener(): Observable<any> {
        return this._singleCascade.asObservable();
    }

    conceptChangedListener(): Observable<any> {
        return this._conceptListener.asObservable();
    }

    listResetListener(): Observable<any> {
        return this._resetListener.asObservable();
    }

    setBreadcrumbItems = (list: BreadcrumbItem[]) => {
        this.breadcrumbItemsSubject.next(list);
    };
    /**
     * Change the main
     * @param SiteConcepts - SiteConcepts
     */
    setBreadcrumbConcept(siteConcept: SiteConcepts): void {
        this._conceptListener.next(siteConcept);
    }

    /**
     * Reset Breadcrumb
     */
    resetBreadcrumb(): void {
        this._resetListener.next(null);
    }

    /**
     * Add items to breadcrumb (multi-level)
     * @param BreadcrumbItem[] - breadcrumb item list
     * @param stepLevel - depends if we are going down in levels
     */
    addBreadcrumbCascade(breadcrumbItems: BreadcrumbItem[], stepLevel?: boolean): void {
        const obj = {
            stepLevel: stepLevel,
            breadcrumbItems: breadcrumbItems
        };
        this._singleCascade.next(obj);
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
