<form id="profile-form" class="mb-4" nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div class="row">
        <div class="col-md-6">
            <sp-quill-editor
                label="{{ 'FORM.DESCRIPTION' | translate }} *"
                [value]="descriptionModel"
                [modules]="descriptionModules"
                placeholder="{{ 'FORM.DESCRIPTION-PLACEHOLDER' | translate }}"
                (contentChanged)="contentChanged($event)"
                [disabled]="formGroup.disabled"
            ></sp-quill-editor>
            <span class="has-error" *ngIf="displayErrors && isBlankDescValidator(descriptionModel)">
                {{ 'CHAMP-REQUIS' | translate }}
            </span>
        </div>
        <div class="col-md-6">
            <nz-form-control>
                <label>{{ 'FORM.LOGO' | translate }} *</label>
            </nz-form-control>

            <app-img-upload
                [currentImage]="organization.logo"
                [uploadAction]="uploadAction"
                [maxCropperWidth]="1000"
                [maxCropperHeight]="1000"
                uploadActionName="orgimage"
                [maxFileWeigth]="1000"
                [isDisabled]="formGroup.disabled"
                (onUploadFile)="onUploadFileCallback($event)"
            ></app-img-upload>

            <span class="has-error" *ngIf="displayErrors && formGroup.get('logo').errors">
                {{ 'CHAMP-REQUIS' | translate }}
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <nz-form-item>
                <nz-form-control>
                    <label for="" class="d-block">{{ 'FORM.DATE-FONDATION' | translate }} *</label>

                    <nz-date-picker nzPlaceHolder="yyyy/mm/dd" nzAllowClear="true" nzFormat="yyyy/MM/dd" formControlName="dateFoundation"></nz-date-picker>
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('dateFoundation').hasError('required')">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('dateFoundation').hasError('minDate')">
                        {{ 'FORM.MIN-DATE' | translate }}
                    </span>
                    <span class="has-error" *ngIf="displayErrors && formGroup.get('dateFoundation').hasError('maxDate')">
                        {{ 'FORM.MAX-DATE' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <app-nz-form-item inputId="'numero_entreprise_qc'" [control]="formGroup.get('neq')">
                <div class="input-custom-label">
                    <label for="numero_entreprise_qc">{{ 'FORM.NUMERO-ENTREPRISE-QC' | translate }}</label>
                </div>
            </app-nz-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="" class="d-block mb-2">{{ 'FORM.RESEAUX-SOCIAUX' | translate }}</label>
        </div>
    </div>
    <!-- input repeater work in progress -->
    <div formArrayName="socialMediaAccounts">
        <nz-form-item *ngFor="let social of controlSocialsArray.controls; let i = index">
            <div class="row" [formGroupName]="i">
                <div class="col-md-8">
                    <div class="row align-items-center">
                        <div class="col-md-10">
                            <input placeholder="URL" formControlName="url" />
                        </div>
                        <div class="col-md-2">
                            <i nz-icon type="minus-circle-o" class="dynamic-delete-button" (click)="removeField(i, $event)" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </nz-form-item>
    </div>
    <nz-form-item>
        <nz-form-control>
            <button class="btn-rect" [disabled]="formGroup.disabled" style="padding: 0px 11px; font-size: 14px" (click)="addField($event)">Ajouter lien</button>
        </nz-form-control>
    </nz-form-item>

    <!-- Boutons si orga non soumise ou rejetée  -->
    <ng-container *ngIf="organization.statusId === 1 || organization.statusId === 4">
        <button *appPermission="1; objectOrganizationId: organization.id; shouldBeApproved: false" nz-button [nzLoading]="isLoading" class="btn btn--default">
            {{ 'ENREGISTER-ET-SOUMETTRE' | translate }}
        </button>
        <p *appPermission="1; objectOrganizationId: organization.id; shouldBeApproved: false" class="d-inline-block ml-2">
            {{ 'OU' | translate }}
            <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
            <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
        </p>
    </ng-container>
    <!-- Bouton simple si orga approuvée -->
    <ng-container *ngIf="(organization.statusId === 3 || organization.statusId === 2) && !formGroup.disabled">
        <button
            *appPermission="1; objectOrganizationId: organization.id; shouldBeApproved: false"
            nz-button
            [disabled]="formGroup.invalid"
            [nzLoading]="isLoading"
            [disabled]="formGroup.invalid"
            class="btn btn--default"
        >
            {{ 'ENREGISTER' | translate }}
        </button>
    </ng-container>
</form>
