import { Component, HostBinding } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
    selector: 'sp-divider',
    template: ``
})
export class SPDividerComponent {
    constructor(private sanitizer: DomSanitizer) { }

    @HostBinding('style')
    get myStyle(): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(`
        display: block;
        padding: 0;
        height: 1px;
        margin: 4px 0;
        overflow: hidden;
        line-height: 0;
        background-color: #e8e8e8;
        `);
    }

}
