import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { PxUser } from './model/project-x-user';

@Injectable({ providedIn: 'root' })
export class UserResolver  {
  constructor(
    private readonly authService: AuthService
  ) {
  }

  resolve(): Observable<PxUser> {
    return from(this.authService.populateUser());
  }
}