import { AbstractControl, ValidationErrors } from '@angular/forms';

export function validateDate(
  control: AbstractControl
): ValidationErrors | null {
  const theDate = new Date(control.value);
  const minDate = new Date('1900-01-01');
  const maxDate = new Date();

  const condition1 = theDate.getTime() >= minDate.getTime();
  const condition2 = theDate.getTime() <= maxDate.getTime();

  if (condition1 && condition2) {
    return null;
  }
  if (!condition1) {
    return { minDate: true };
  }
  if (!condition2) {
    return { maxDate: true };
  }
}

export function isMonth(control: AbstractControl): ValidationErrors | null {
  let month = control.value;
  if (isNaN(month)) {
    return { monthInvalid: true };
  }
  month = Number(month);
  if (month < 1 || month > 12) {
    // check month range
    return { monthInvalid: true };
  }
  return null;
}

export function isYear(control: AbstractControl): ValidationErrors | null {
  let year = control.value;
  if (isNaN(year)) {
    return { yearInvalid: true };
  }
  year = Number(year);
  const currentyear = new Date().getFullYear();
  if (year < currentyear || year > currentyear + 20) {
    // check year range
    return { yearInvalid: true };
  }
  return null;
}
