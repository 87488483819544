<app-section-title title="{{ 'MES-ORGANISATIONS' | translate }}" buttonText=" {{'NOUVELLE-ORGANISATION' | translate }}"
  (buttonClicked)="createNewOrganization()" [shouldBeApproved]="false" [buttonIsLoading]="isDisabled">
  coco
</app-section-title>

<section>
  <div nz-row nzGutter="24" style="margin-bottom:20px">
    <!-- LOADING SPINNER -->
    <div *ngIf="isLoading" class="loader">
      <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
    </div>
    <!-- LIST -->
    <sp-list [nzDataSource]="userOrganizations" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
      <a [routerLink]="">
        <ng-template #item let-item>
          <sp-list-item>
            <div nz-col nzSpan="4">
              <div>
                <a (click)="navigateToOrganization(item.id)">
                  <div class="sidebar-image-container">
                    <img class="w-100" src="{{item.logo}}" alt="">
                  </div>
                </a>
              </div>
            </div>
            <div nz-col nzSpan="14">
              <div>
                <a (click)="navigateToOrganization(item.id)">
                  {{ item.getTranslatedProperty(lang,'name')}}</a>
              </div>
            </div>
          </sp-list-item>
        </ng-template>
      </a>
    </sp-list>
  </div>
</section>