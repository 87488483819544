import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { Transaction } from 'src/app/concepts/payment/models/transaction.model';
import { CreditCardService } from 'src/app/concepts/payment/services/credit-card.service';
import { Product } from 'src/app/concepts/product/model/product.model';
import { BillInfos } from '../model/bill-infos.model';
import { Organization } from './../../../../concepts/organization/model/organization.model';
import { AccountService } from '../../../../concepts/account/services/account.service';

@Component({
  selector: 'app-paid-bill',
  templateUrl: './paid-bill.component.html',
  styleUrls: ['./paid-bill.component.scss'],
})
export class PaidBillComponent implements OnInit {
  organization: Organization;
  transaction: Transaction;
  transactionId: number;
  user: PxUser;
  products: any[];
  billInfos: BillInfos;
  // flag for refund feature (Scene_Pro admin only)
  isRefund = false;

  lang: string = this.translate.currentLang;

  constructor(
    private organizationService: OrganizationService,
    private creditCardService: CreditCardService,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    const organizationId = this.route.snapshot.params['organizationId'];

    this.transactionId = this.route.snapshot.params['transactionId'];

    this.user = this.authService.User;

    const path = this.route.snapshot.routeConfig.path;
    const localizedRefundLabel = this.translate.instant('ROUTES.refund');
    this.isRefund = path.endsWith(localizedRefundLabel);

    // Si facture d'une organisation
    if (organizationId) {
      forkJoin(
        this.getOrgas(organizationId),
        this.getTransaction(organizationId),
        this.getProducts(organizationId)
      ).subscribe(this.setBillInfos.bind(this));
    } else {
      // Si facture d'user
      this.initUser();
    }
  }

  initUser(): void {
    const userId = this.route.snapshot.params['userId'];
    if (!userId) {
      this.user = this.authService.User;
      this.getBillInfos4User();
    } else {
      this.accountService.getUser(userId).subscribe((user: PxUser) => {
        this.user = user;
        this.getBillInfos4User();
      });
    }
  }

  getBillInfos4User(): void {
    forkJoin(this.getUserTransaction(), this.getProducts()).subscribe(
      this.setBillInfos.bind(this)
    );
  }

  getOrgas(organizationId: number): Observable<any> {
    return this.organizationService
      .getOrganization(organizationId)
      .do((res) => (this.organization = res));
  }

  getTransaction(organizationId: number): Observable<any> {
    return this.creditCardService
      .getHistory(organizationId)
      .do((bills) => this.assignTransaction(bills));
  }

  getUserTransaction(): Observable<any> {
    return this.creditCardService
      .getUserHistory(Number(this.user.id))
      .do((bills) => this.assignTransaction(bills));
  }

  getProducts(organizationId?: number): Observable<any> {
    return this.creditCardService
      .getProductsForTransaction(this.transactionId, organizationId)
      .do((res: Product[]) => {
        this.products = res;
      });
  }

  setBillInfos(): void {
    this.billInfos = new BillInfos();
    this.billInfos.subtotal = this.transaction.subtotal;
    this.billInfos.taxTps = this.transaction.taxTps;
    this.billInfos.taxTvq = this.transaction.taxTvq;
    this.billInfos.total = this.transaction.total;
    this.billInfos.receivedAmount = this.transaction.total;
    this.billInfos.amountToPay = 0;
    this.billInfos.date = this.transaction.createdAt;
    this.billInfos.numero = this.transaction.id;
    this.billInfos.originalNumero = this.transaction.originalTransactionId;
  }

  private assignTransaction(bills: Transaction[]) {
    this.transaction = bills.find(
      (bill) => Number(bill.id) === Number(this.transactionId)
    );
  }
}
