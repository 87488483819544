import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { Filter, FilterValue, ListItem, Pagination, SelectedFilter, Sort } from './../../model/list-item.model';
import { Globals } from '../../../_configs/globals';
import { RowTypes } from '../../enums/row-types.enum';

@Component({
    selector: 'app-item-list',
    templateUrl: './item-list.component.html',
    styleUrls: ['./item-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ItemListComponent implements OnInit {
    // List Types
    public activityList = RowTypes.ACTIVITY;
    /**
     *  Réference sur le composant de recherche avancée, si il existe
     *  Pour rajouter un composant de recherche avancée, le mettre à
     * l'interieur des balise avec la réference #advancedSearch. Ex :
     *  <app-item-list>
     *    <app-rech-avancee #advancedSearch advanced-search></app-rech-avancee>
     *  </app-item-list>
     * */
    @ContentChild('advancedSearch', { static: true })
    public advancedSearchComponent: any;
    public bottinList = RowTypes.BOTTIN;
    public checkedCollapse = false;
    //If it's Product List, different display only showing row display
    @Input()
    public elementsLabel?: string;
    /**
     * Flag pour l'utilisation du bouton Modifier
     */
    @Input()
    public enableEditing: boolean;
    /**
     * Flag pour l'utilisation du bouton Modifier
     */
    @Input()
    public enableRemoving: boolean;
    @Output()
    public exportFavorites? = new EventEmitter<any>();
    @Output()
    public filterChange = new EventEmitter<SelectedFilter>();
    /**
     * filtres
     */
    @Input()
    public filterList: Filter[];
    /**
     * Flag si on cache le header
     */
    @Input()
    public hideHeader: boolean;
    @Input() public isAdmin?: boolean;
    // Flag to download favorites OdS
    @Input()
    public isDownloadFavorites?: boolean;
    // Flag List / Cards
    public isListViewRow = false;
    /**
     * Flag si en cours de chargement
     */
    @Input()
    public isLoading: boolean;
    @Input()
    public isProductList: boolean;
    @Input()
    public isToRate?: boolean;
    /**
     * List d'elements à afficher
     */
    @Input()
    public itemList: ListItem[] = [];
    /**
     * Flag pour le type d'élément affichés
     */
    @Input()
    public listItemsType: RowTypes;
    @Output()
    public modifyItems = new EventEmitter<any>();
    @Output()
    public pageChange = new EventEmitter<Pagination>();
    /**
     * Config de la pagination
     */
    @Input()
    public pagination: Pagination;
    public participantList = RowTypes.CONTACT;
    public productList = RowTypes.PRODUCT;
    @Output()
    public rateItem = new EventEmitter<any>();
    @Output()
    public removeItems = new EventEmitter<any>();
    @Input()
    public searchBarPlaceHolder: string;
    @Output()
    public searchChange = new EventEmitter<string>();
    public searchFormControl = new UntypedFormControl();
    @Input() public searchSessionStorageSuffix?: string;
    public selectedFilter: string;
    public showsList = RowTypes.SHOW;
    @Output()
    public sortChange = new EventEmitter<any>();
    /**
     * Tris
     */
    @Input()
    public sortList: Sort[];
    // just need for ngModel & compiling purposes
    public sortModel: any = null;
    @Input()
    public updateItemRate = new EventEmitter<SelectedFilter>();
    @Output()
    public updateKeepedShow = new EventEmitter<any>();
    /**
     * Flag pour l'utilisation des filtres
     */
    @Input()
    public useFilters: boolean;
    /**
     * Flag pour l'utilisation de la pagination
     */
    @Input()
    public usePagination: boolean;
    /**
     * Flag pour l'utilisation de la recherche
     */
    @Input()
    public useSearchBar: boolean;
    /**
     * Flag pour l'utilisation du tri
     */
    @Input()
    public useSorting: boolean;
    /**
     * Flag pour l'utilisation du switch
     */
    @Input()
    public useViewSwitcher: boolean;
    public vitrineList = RowTypes.VITRINE;

    constructor(private translate: TranslateService, protected globals: Globals) {}

    public changeListView() {
        this.isListViewRow = !this.isListViewRow;
        sessionStorage.setItem(`recherche_liste_view_${this.searchSessionStorageSuffix}`, JSON.stringify(this.isListViewRow));
    }

    public modifyItemCallback($event) {
        this.modifyItems.emit($event);
    }

    public ngOnInit(): void {
        this.searchFormControl.valueChanges.pipe(debounceTime(300)).subscribe((searchVal) => {
            // On remet la pagination à zero
            this.pagination.offset = 0;
            this.searchChange.emit(searchVal);
            if (this.searchSessionStorageSuffix) {
                sessionStorage.setItem(`recherche_liste_${this.searchSessionStorageSuffix}`, JSON.stringify(searchVal));
            }
        });

        this.elementsLabel = this.elementsLabel || this.translate.instant('vitrines');

        if (this.useViewSwitcher) {
            this.listItemsType == RowTypes.PRODUCT ? (this.isListViewRow = true) : (this.isListViewRow = false); //Show Row view if it's Product List
        } else {
            this.isListViewRow = true;
        }

        if (this.searchSessionStorageSuffix && JSON.parse(sessionStorage.getItem(`recherche_liste_${this.searchSessionStorageSuffix}`))) {
            this.searchFormControl.setValue(JSON.parse(sessionStorage.getItem(`recherche_liste_${this.searchSessionStorageSuffix}`)));
        }

        this.checkedCollapse = !!(this.searchSessionStorageSuffix && JSON.parse(sessionStorage.getItem(`recherche_avancee_collapse_${this.searchSessionStorageSuffix}`)));

        if (this.searchSessionStorageSuffix && JSON.parse(sessionStorage.getItem(`recherche_liste_sort_${this.searchSessionStorageSuffix}`)) != null) {
            this.sortModel = JSON.parse(sessionStorage.getItem(`recherche_liste_sort_${this.searchSessionStorageSuffix}`));
            this.sortChange.emit(this.sortModel);
        }
        if (this.searchSessionStorageSuffix && JSON.parse(sessionStorage.getItem(`recherche_liste_view_${this.searchSessionStorageSuffix}`))) {
            this.isListViewRow = JSON.parse(sessionStorage.getItem(`recherche_liste_view_${this.searchSessionStorageSuffix}`));
        }
    }

    public onPageChange(page: number) {
        const paginate = new Pagination();
        paginate.limit = this.pagination.limit;
        paginate.offset = paginate.limit * (page - 1);
        this.pageChange.emit(paginate);
    }

    public onRateItemCallback($event) {
        this.rateItem.emit($event);
    }

    public onSelectFilter(filterField: string, { filterValue, filterValueName }: FilterValue, index?: number, listIndex?: number) {
        /* ? This code iterates over a list of filters and updates the 'selected' property of each filter value.
         *
         * For each filter in 'filterList':
         * - For each 'filterValue' in the current filter:
         *   - If the index of the 'filterValue' equals 'index' and 'index' is not undefined,
         *     and the filter index equals 'listIndex', then 'selected' is set to true.
         *   - Otherwise, 'selected' is set to false.
         *
         * This means that for a given filter (determined by 'listIndex'), only one 'filterValue'
         * (determined by 'index') can be selected at a time.
         */
        this.filterList.forEach((item: Filter, filterIndex: number) => {
            item.filterValues.forEach((value, valueIndex) => {
                value.selected = index === valueIndex && index !== undefined && listIndex === filterIndex;
            });
        });

        this.selectedFilter = filterValueName ? filterValueName : '';

        // On remet aussi la pagination à zero
        this.pagination.offset = 0;
        this.filterChange.emit({ field: filterField, value: filterValue });
    }

    public onSortChange($event: unknown) {
        this.sortChange.emit($event);
        if (this.searchSessionStorageSuffix) {
            sessionStorage.setItem(`recherche_liste_sort_${this.searchSessionStorageSuffix}`, JSON.stringify($event));
        }
    }

    public onUpdateKeepedShowCallback($event) {
        this.updateKeepedShow.emit($event);
    }

    public rechercheAvancee() {
        this.checkedCollapse = !this.checkedCollapse;
        if (this.searchSessionStorageSuffix) {
            sessionStorage.setItem(`recherche_avancee_collapse_${this.searchSessionStorageSuffix}`, JSON.stringify(this.checkedCollapse));
        }
    }

    public removeItemCallback($event) {
        this.removeItems.emit($event);
    }
}
