import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { Jury } from '../../model/jury.model';
import { MeetingService } from '../../services/meeting.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/_configs/globals';

@Component({
  selector: 'app-meeting-jury',
  templateUrl: './meeting-jury.component.html',
  styleUrls: ['./meeting-jury.component.scss'],
})
export class MeetingJuryComponent implements OnInit {
  @Input() meetingId: number;
  @Input() readonly: boolean;

  juryForm: UntypedFormGroup;
  collapseForm = false;

  juryList: Jury[] = [];

  pendingJuries: Jury[] = [];
  existingJuries: Jury[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NzModalService,
    private translate: TranslateService,
    private notificaton: RideauNotificationService,
    private meetingService: MeetingService,
    private global: Globals
  ) {}

  ngOnInit(): void {
    this.juryForm = this.fb.group({
      email: new UntypedFormControl(undefined, [
        Validators.pattern(this.global.emailRegex),
        Validators.required,
      ]),
    });
    this.getJuries();
  }

  getJuries(): void {
    this.meetingService.getMeetingJuries(this.meetingId).subscribe((data) => {
      this.juryList = data.map((jury) => new Jury(jury));
      this.pendingJuries = this.juryList.filter((jury) => !jury.userId);
      this.existingJuries = this.juryList.filter((jury) => !!jury.userId);
    });
  }

  deleteInvitation(userId: number): void {
    const modal = this.modalService.create({
      nzContent: this.translate.instant('SUPPRIMER-CONFIRMATION'),
      nzWidth: '400px',
      // Le callBack OnOk renvoie l'instance du composant
      nzOnOk: () => {
        this.meetingService
          .removeJuryMember(this.meetingId, userId)
          .subscribe((data) => {
            this.notificaton.success(
              this.translate.instant('FORM.JURY-SUPPRIME')
            );
            this.getJuries();
          });
      },
    });
  }

  addJuryToMeeting(): void {
    if (this.juryForm.valid) {
      const newJury = new Jury({
        meetingId: this.meetingId,
        email: this.juryForm.get('email').value,
      });
      this.meetingService
        .addJuryMember(this.meetingId, newJury)
        .subscribe((data) => {
          this.notificaton.success(this.translate.instant('FORM.SAUVEGARDE'));
          this.juryForm.patchValue({ email: '' });
          this.collapseForm = false;
          this.getJuries();
        });
    } else {
      console.log('Form non valid');
    }
  }
}
