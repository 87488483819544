import { NgModule } from '@angular/core';
import { OrganizationBillingComponent } from './organization-billing.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { PaginatedListModule } from '@app/shared/components/paginated-list/paginated-list.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, SharedModule, TranslateModule, RouterModule, NzRadioModule, NzDividerModule, PaginatedListModule, LocalizeRouterModule, FormsModule],
    exports: [OrganizationBillingComponent],
    declarations: [OrganizationBillingComponent]
})
export class OrganizationBillingModule {}
