import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { ProductTypes } from '../../../enums/product-types-enum';
import { ProductService } from '../../../services/product.service';
import { AbstractProductForm } from '../product-abstract-form/product-abstract-form.component';
import { Meeting } from './../../../../meeting/model/meeting.model';
import { MeetingService } from './../../../../meeting/services/meeting.service';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '@app/_configs/globals';
import { EnumUtils, IterableEnum } from '@app/shared/utils/enum.utils';

@Component({
    selector: 'app-product-general-form',
    templateUrl: './product-general-form.component.html',
    styleUrls: ['./product-general-form.component.scss']
})
export class ProductGeneralFormComponent extends AbstractProductForm implements OnInit {
    public readonly dateRangeFormat = this.globals.dateRangeFormat;
    public readonly dateRangeTimeFormat = this.globals.time_format_simple;

    public DEPOT_VITRINE_TYPE = ProductTypes.DEPOT_VITRINE;
    public INSCRIPTION_TYPE = ProductTypes.INSCRIPTION;
    public formGroup: UntypedFormGroup;
    public lang: string;
    public meetings: any[] = [];
    public organizations: Organization[] = [];
    public productTypes: IterableEnum<typeof ProductTypes>[];
    public radioDateValue: string;
    public radioQuantityValue: string;
    public selectedValue = null;

    private productRemainingQuantityHandler = (): void => {
        /*
         * 1. default value for remainingQuantity:
         * apply form field value to payload
         */
        // Retrieve the value of the quantity input field from the form
        const { queryParams } = this.route.snapshot;
        const updatedQuantityFormValue = +this.formGroup.get('quantity').value;
        this.productValue.remainingQuantity = +this.formGroup.value.remainingQuantity; //initial value
        if (!!Object.keys(queryParams).length) {
            // product is new,
            const { isNew } = queryParams;
            if (isNew) {
                // remaining quantity must be equal to quantity
                this.productValue.remainingQuantity = updatedQuantityFormValue;
            }
        } else {
            // product modification
            this.productValue.remainingQuantity = +this.formGroup.value.remainingQuantity; // default value
            // Check if the updated quantity is different from the original quantity of the product
            // and update remaining quantity
            if (updatedQuantityFormValue !== this.productValue.quantity) {
                this.productValue.remainingQuantity = updatedQuantityFormValue;
            }
        }
    };

    constructor(
        private globals: Globals,
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        public productService: ProductService,
        private meetingService: MeetingService,
        protected accountService: AccountService,
        public organizationService: OrganizationService,
        private route: ActivatedRoute
    ) {
        super(accountService);
    }

    public doSubmit(param?: any) {
        this.productValue.setTranslatedProperty(this.translate.currentLang, 'name', this.formGroup.value.name);
        this.productValue.organizationId = this.formGroup.value.organizationId;
        this.productValue.setTranslatedProperty(this.translate.currentLang, 'descInternal', this.formGroup.value.descInternal);
        this.productValue.setTranslatedProperty(this.translate.currentLang, 'descPublic', this.formGroup.value.descPublic);
        this.productValue.productTypeId = this.formGroup.value.productTypeId;

        this.radioDateValue == 'productRadioValidityDate' ? (this.productValue.isAlwaysValid = 0) : (this.productValue.isAlwaysValid = 1);
        if (this.radioDateValue == 'productRadioValidityDate') {
            if (this.formGroup.value.productDateRange != null) {
                this.productValue.validFrom = this.formGroup.value.productDateRange[0];
                this.productValue.validTo = this.formGroup.value.productDateRange[1];
            }
        } else {
            this.productValue.validFrom = null;
            this.productValue.validTo = null;
        }

        this.productRemainingQuantityHandler();
        this.productValue.quantity = +this.formGroup.get('quantity').value;
        this.radioQuantityValue == 'productRadioQuantity' ? (this.productValue.isQuantityUnlimited = 0) : (this.productValue.isQuantityUnlimited = 1);

        if (this.productValue.productTypeId === ProductTypes.INSCRIPTION || this.productValue.productTypeId === ProductTypes.DEPOT_VITRINE) {
            this.productValue.meetingId = this.formGroup.value.meetingId;
            const meeting = this.meetings.find((elt) => elt.id === this.productValue.meetingId);
            this.productValue.meetingName = meeting ? meeting.label : null;

            if (this.productValue.productTypeId === ProductTypes.INSCRIPTION) {
                this.productValue.isStandOptional = this.formGroup.value.isStandOptional ? 1 : 0;
            } else {
                this.productValue.isStandOptional = null;
            }
        } else {
            this.productValue.isStandOptional = null;
            this.productValue.meetingId = null;
            this.productValue.meetingName = null;
        }

        super.doSubmit(param);
    }

    public getMeetings(): void {
        this.meetingService.getAllMeetings().subscribe((res: Meeting[]) => {
            this.meetings = res.map((elt) => ({
                label: elt.getTranslatedProperty(this.lang, 'name'),
                id: elt.id
            }));
        });
    }

    public getOrganizations(): void {
        this.organizationService.getOrganizationsList().subscribe((data: any) => {
            this.organizations = data.organizations.map((org: any) => new Organization(org));
        });
    }

    public ngOnInit(): void {
        this.lang = this.translate.currentLang;
        registerLocaleData(fr);
        this.initForm();
        this.getOrganizations();
        this.getMeetings();
        const iterableProductTypes = EnumUtils.convertToIterable(ProductTypes);
        this.productTypes = EnumUtils.filterBy(
            <IterableEnum<typeof ProductTypes>[]>iterableProductTypes,
            ({ value }: IterableEnum<typeof ProductTypes>) => ((value as unknown) as ProductTypes) !== ProductTypes.COTISATION
        ) as IterableEnum<typeof ProductTypes>[];

        // Si le type de produit n'est pas Inscription, on vide les champs 'Rencontre' et 'Choix de stand'
        this.formGroup.get('productTypeId').valueChanges.subscribe((value) => {
            if (value !== ProductTypes.INSCRIPTION) {
                this.formGroup.get('meetingId').patchValue(null);
                this.formGroup.get('isStandOptional').patchValue(null);
            }
        });
    }

    protected initForm(): void {
        // init du formulaire
        this.formGroup = this.product.getGeneralFormGroup(this.fb, this.lang);
        this.formGroup.value.isAlwaysValid == 1 ? (this.radioDateValue = 'productRadioAllTime') : (this.radioDateValue = 'productRadioValidityDate');
        this.formGroup.value.isQuantityUnlimited == 1 ? (this.radioQuantityValue = 'productRadioUnlimited') : (this.radioQuantityValue = 'productRadioQuantity');
    }
    setRadioDateValueValue($event) {
        this.radioDateValue = $event;
    }
}
