
<div class="user-infobox px-2 py-3 mb-5 d-flex">
  <div class="col-sm-8">
    <div class="row user-infobox__row">
      <div class="col-sm-4">
        <label class="user-infobox__label">{{ 'FORM.NOM' | translate}}</label>
      </div>
      <div class="col-sm-8">
        <span class="user-infobox__desc">{{ user.firstName + ' ' + user.lastName }}</span>
      </div>
    </div>
    <div class="row user-infobox__row">
      <div class="col-sm-4">
        <label class="user-infobox__label">{{ 'FORM.COURRIEL' | translate}}</label>
      </div>
      <div class="col-sm-8">
        <span class="user-infobox__desc">{{ user.email }}</span>
      </div>
    </div>
    <div class="row user-infobox__row">
      <div class="col-sm-4">
        <label class="user-infobox__label">{{ 'MOT-DE-PASSE' | translate}}</label>
      </div>
      <div class="col-sm-8">
        <span class="user-infobox__desc user-infobox__desc--password">{{ 'MOT-DE-PASSE' | translate}}</span>
      </div>
    </div>
  </div>
  <div class="col-sm-4 text-right">
    <a class="btn btn--small" [href]="editUrl" style="padding-top:12px; padding-left:19px">{{'FORM.MODIFIER' | translate}}</a>
  </div>
</div>
