import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Permission } from 'src/app/shared/enums/roles.enum';
import { AccountService } from './../../concepts/account/services/account.service';
import { OrganizationTypes } from './../../concepts/organization/enums/organization-types.enum';

/**
 * Cette directive structurelle vérifie que l'utilisateur dispose de la permission
 * passée en paramètre avant d'afficher l'élement sur laquelle elle est appliquée.
 * La directive s'utilise comme suit :
 *
 *    <element *appPermission="1"></element>  Pour l'edition
 *    <element *appPermission="2"></element>  Pour suppression
 *    ... etc, voir Permission dans roles.enum.ts pour  l'ensemble des valeurs possibles
 *
 * Paramètre optionnel objectOrganizationId, par exemple l'organizationId d'un Show, pour vérifier
 * qu'il est le même que l' organizationId du contexte avant de checker les permissions.
 * Exemple d'utilisation :
 *
 *    <element *appPermission="3;objectOrganizationId : 55 "></element>
 *    <element *appPermission="1;objectOrganizationId :show.organizationId"></element>
 *
 * Paramètre optionnel organizationType pour vérifier que l'organisation courante a bien le type
 * nécéssaire (Producteur, Diffuseur, Gestionnaire de salle)
 * Utilisation :
 *
 *    <element *appPermission="1;organizationType:1"></element> Pour Producteur
 *    <element *appPermission="1;organizationType:2"></element> Pour Diffuseur
 *    <element *appPermission="1;organizationType:3"></element> Pour Gestionnaire de salle
 *
 * Paramètre optionnel shouldBeApproved pour vérifier que l'organisation courante a le status
 * APPROUVÉE. Paramètre vrai par défaut, c'est à dire que par défaut, on s'attent à ce que
 * l'organisation courante soit approuvée pour accorder la permission. Utilisation :
 *
 *      <element *appPermission="1;shouldBeApproved:false"></element>
 * */
@Directive({
    selector: '[appPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {
    private _appPermission: Permission;
    @Input()
    set appPermission(value: Permission) {
        this._appPermission = value;
    }

    private _objectOrganizationId: number;
    @Input()
    set appPermissionObjectOrganizationId(value: number) {
        this._objectOrganizationId = value;
    }

    private _organizationType: OrganizationTypes;
    @Input()
    set appPermissionOrganizationType(value: OrganizationTypes) {
        this._organizationType = value;
    }

    private _shouldBeApproved = true;
    @Input()
    set appPermissionShouldBeApproved(value: boolean) {
        this._shouldBeApproved = value;
    }

    private permissionSub$: Subscription;
    private ctxOrgaSub$: Subscription;
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private accountService: AccountService) {}

    ngOnInit(): void {
        this.ctxOrgaSub$ = this.accountService.currentOrganizationChange.subscribe(() => this.checkPermission());
        this.checkPermission();
    }

    private checkPermission(): void {
        this.accountService
            .checkAccess(this._appPermission, this._objectOrganizationId, this._organizationType, this._shouldBeApproved)
            .subscribe((hasAcces: boolean) => {
                if (hasAcces && !this.hasView) {
                    this.displayElement();
                }
                if (!hasAcces && this.hasView) {
                    this.hideElement();
                }
            });
    }

    private displayElement(): void {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
    }

    private hideElement(): void {
        this.viewContainer.clear();
        this.hasView = false;
    }

    ngOnDestroy(): void {
        if (this.permissionSub$) {
            this.permissionSub$.unsubscribe();
        }
        if (this.ctxOrgaSub$) {
            this.ctxOrgaSub$.unsubscribe();
        }
    }
}
