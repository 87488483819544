import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/_configs/globals';
import { TagsService } from '../../services/tags.service';
import { Tag } from '../../model/tags.model';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
})
export class TagsListComponent implements OnInit {
  isReady = false;
  currentLang = '';
  singleEtiquetteLink = '';

  etiquettes: [];

  constructor(
    private globals: Globals,
    private translate: TranslateService,
    private etiquettesService: TagsService,
    private notification: RideauNotificationService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.getAllEtiquettes();
  }

  createNewEtiquette() {
    this.etiquettesService.createEtiquetteAndRedirectToSingle(this.currentLang);
  }

  getAllEtiquettes() {
    return this.etiquettesService.getAllEtiquettes().subscribe((data) => {
      this.etiquettes = data.tags.map((tag) => new Tag(tag));
      this.isReady = true;
    });
  }

  deleteEtiquette($event) {
    this.etiquettesService.deleteTag($event).subscribe((data) => {
      // Notify
      this.notification.success(this.translate.instant('ETIQUETTE-SUPPRIME'));
      this.getAllEtiquettes();
    });
  }
}
