<ng-container *ngIf="user">
    <app-page-title title="{{ user.firstName + ' ' + user.lastName }}"></app-page-title>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="list-filters">
                    <div class="list-filters__box">
                        <sp-menu>
                            <sp-menu-item [label]="'PROFIL' | translate" [routerLink]="['../../info', userId]" routerLinkActive="active"></sp-menu-item>
                            <sp-menu-item [label]="'ORGANISATIONS' | translate" [routerLink]="['../../organizations', userId] | localize" routerLinkActive="active"></sp-menu-item>
                            <sp-menu-item [label]="'FACTURATION' | translate" [routerLink]="['../../billing', userId] | localize" routerLinkActive="active"></sp-menu-item>
                        </sp-menu>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <ng-container *ngIf="openedTab == 'profile'">
                    <form nz-form [nzLayout]="vertical" [formGroup]="validateForm" (ngSubmit)="submitForm()">
                        <div class="col-12">
                            <h2 class="mb-4">{{ 'PROFIL' | translate }}</h2>
                            <div class="row">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-form-label for="firstName">{{ 'FORM.FIRSTNAME' | translate }}</nz-form-label>
                                                    <input type="text" id="firstName" formControlName="firstName" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-12">
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-form-label for="lastName">{{ 'FORM.LASTNAME' | translate }}</nz-form-label>
                                                    <input type="text" id="lastName" formControlName="lastName" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-12">
                                            <nz-form-item>
                                                <nz-form-control>
                                                    <nz-form-label for="email">{{ 'FORM.ADRESSE-COURRIEL' | translate }}</nz-form-label>
                                                    <input [ngClass]="{ warning: newEmailRequested }" type="text" id="email" formControlName="email" />
                                                </nz-form-control>
                                            </nz-form-item>
                                            <p class="warning-text" *ngIf="newEmailRequested">{{ 'FORM.NEW-EMAIL-REQUEST-ADMIN' | translate }}</p>
                                        </div>

                                        <div class="col-12">
                                            <div class="row align-items-center">
                                                <div class="col-md-6">
                                                    <nz-form-item>
                                                        <nz-form-control>
                                                            <nz-form-label for="phone">{{ 'FORM.TELEPHONE' | translate }}</nz-form-label>
                                                            <input nz-input id="phone" formControlName="phone" value="{{ user.phone }}" />
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <app-input-phone-extension formControlName="phonePostNumber"></app-input-phone-extension>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="!showPasswordForm; else passwordFormTemplate" class="col-12 mb-4">
                                            <label class="d-block">{{ 'MOT-DE-PASSE' | translate }}</label>
                                            <button class="d-block account_password_btn mt-2" nz-button type="button" (click)="showPasswordForm = true">
                                                {{ 'CHANGE-PASSWORD' | translate }}
                                            </button>
                                        </div>

                                        <ng-template #passwordFormTemplate>
                                            <form [formGroup]="passwordForm">
                                                <div class="col-12">
                                                    <nz-form-item>
                                                        <app-password-input formControlName="newPassword" labelColor="#000" label="FORM.NOUVEAU-MOT-DE-PASSE"></app-password-input>
                                                    </nz-form-item>
                                                    <app-password-hint-validator
                                                        textColor="black"
                                                        [isValid]="passwordForm.controls.newPassword.valid"
                                                        sentence="{{ 'PASSWORD-REGEX' | translate }}"
                                                    ></app-password-hint-validator>
                                                </div>

                                                <div class="col-12">
                                                    <nz-form-item>
                                                        <app-password-input
                                                            formControlName="confirmPassword"
                                                            label="FORM.CONFIRMER-MOT-DE-PASSE"
                                                            labelColor="#000"
                                                        ></app-password-input>
                                                    </nz-form-item>
                                                </div>
                                            </form>
                                        </ng-template>

                                        <div class="col-md-12">
                                            <sp-checkbox formControlName="emailsOption" [(ngModel)]="emailsOption">
                                                <strong>{{ 'NOTIFICATIONS.OPTION' | translate }}</strong>
                                            </sp-checkbox>
                                        </div>

                                        <div class="col-12">
                                            <button class="btn btn--small mr-2 d-inline-block" nzSize="large" style="margin-top: 20px">{{ 'ENREGISTER' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 pt-2">
                                    <h4>{{ 'FORM.PHOTO-DE-PROFIL' | translate }}</h4>
                                    <app-img-upload
                                        [imageTitle]="'FORM.PHOTO-DE-PROFIL' | translate"
                                        [maxCropperWidth]="imageDimensions.maxWidth"
                                        [maxCropperHeight]="imageDimensions.maxHeight"
                                        [currentImage]="user.avatar"
                                        [uploadAction]="uploadAction"
                                        uploadActionName="avatar"
                                        [maxFileWeigth]="1000"
                                        (onUploadFile)="onUploadFileCallback($event)"
                                    ></app-img-upload>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>

                <ng-container *ngIf="openedTab == 'organizations'">
                    <form nz-form [nzLayout]="vertical" [formGroup]="validateForm" (ngSubmit)="submitForm()">
                        <div class="col-md-12">
                            <div class="row mb-4">
                                <div class="col-md-7">
                                    <h2 class="mb-0">{{ 'ORGANISATIONS' | translate }}</h2>
                                </div>
                            </div>
                            <div>
                                <nz-table class="table table--neutral" #orgaTable [nzData]="organizations">
                                    <tr class="table__row" *ngFor="let data of orgaTable.data">
                                        <td class="table__avatar-td">
                                            <span class="table__avatar-container">
                                                <img class="table__avatar" src="{{ data.logo }}" alt="" />
                                            </span>
                                        </td>
                                        <td>
                                            <p class="table__title">{{ data.getTranslatedProperty(currentLang, 'name') }}</p>
                                            <p class="table__type">{{ data.email }}</p>
                                        </td>
                                        <td>
                                            <span *ngIf="data.statusId === 1">
                                                <i nz-icon aria-hidden="true" [nzType]="'minus-circle'" style="color: blue"></i>
                                                {{ 'STATUS.NON_SOUMIS' | translate }}
                                            </span>
                                            <span *ngIf="data.statusId === 2">
                                                <i nz-icon aria-hidden="true" [nzType]="'question-circle'" style="color: orange"></i>
                                                {{ 'STATUS.SOUMIS' | translate }}
                                            </span>
                                            <span *ngIf="data.statusId === 3">
                                                <i nz-icon aria-hidden="true" [nzType]="'check-circle'" style="color: green"></i>
                                                {{ 'STATUS.APPROUVE' | translate }}
                                            </span>
                                            <span *ngIf="data.statusId === 4">
                                                <i nz-icon aria-hidden="true" [nzType]="'close-circle'" style="color: red"></i>
                                                {{ 'STATUS.REJETE' | translate }}
                                            </span>
                                        </td>
                                    </tr>
                                </nz-table>
                            </div>
                        </div>
                    </form>
                </ng-container>

                <ng-container *ngIf="openedTab == 'billing'">
                    <app-account-billing [(user)]="user"></app-account-billing>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
