import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ObjectStatus } from 'src/app/shared/enums/object-status.enum';
import { ListItem } from 'src/app/shared/model/list-item.model';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-item-row-promo',
  templateUrl: './item-row-promo.component.html',
  styleUrls: ['./item-row-promo.component.scss'],
})
export class ItemRowPromoComponent {
  objectStatus = ObjectStatus;

  @Input() item: ListItem;
  @Input() showRenewButton?: boolean;

  @Output() modifyItem = new EventEmitter<void>();
  @Output() removeItem = new EventEmitter<void>();
  @Output() renewItem = new EventEmitter<void>();

  constructor(
    private translate: TranslateService,
    private modalService: NzModalService
  ) {}

  delete(): void {
    const deleteMessages = this.translate.instant('SUPPRIMER-CONFIRMATION');
    const acceptDelete = this.translate.instant('OUI');
    const rejectDelete = this.translate.instant('NON');
    this.modalService.confirm({
      nzContent: deleteMessages,
      nzOkText: acceptDelete,
      nzCancelText: rejectDelete,
      nzClosable: true,
      nzMaskClosable: true,
      nzOnOk: () => {
        this.removeItem.emit();
      },
    });
  }
}
