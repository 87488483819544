import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Product } from '../../model/product.model';
import { ProductService } from '../../services/product.service';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { BreadcrumbService } from '../../../../shared/services/breadcrumb.services';
import { BreadcrumbItem } from '../../../../shared/model/breadcrumb.model';
// import { Product } from '../model/product.model';

@Component({
  selector: 'app-product-modify',
  templateUrl: './product-modify.component.html',
  styleUrls: ['./product-modify.component.scss'],
})
export class ProductModifyComponent implements OnInit {
  public product: Product;
  public productId: number;
  public isReady = false;
  public lang = this.translate.currentLang;
  public isLoading: boolean;

  //displaying errors
  public displayErrors = false;
  public generalErrorCount = 0;
  public compatibilityErrorCount = 0;
  public tarificationErrorCount = 0;

  /*FORM STEPS*/
  current = '';

  public tabs = ['RENSEIGNEMENTS-GENERAUX', 'COMPTABILITE', 'TARIFICATION'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private productService: ProductService,
    private breadcrumbService: BreadcrumbService,
    @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
    private notification: RideauNotificationService
  ) {}

  ngOnInit(): void {
    this.productId = this.route.snapshot.params['productId'];
    this.handleRouteEvents();
    this.getSingleProduct();
  }

  private handleRouteEvents(): void {
    this.route.params.subscribe((params) => {
      // par defaut l'onglet courrant est le premier;
      this.current = 'RENSEIGNEMENTS-GENERAUX';
      if (params.page) {
        // traduire tous les onglets jusqu'à trouver celui passé en URL
        for (const tab of this.tabs) {
          const locTab = this.localizeRouter.translateRoute(tab);
          if (params.page === locTab) {
            this.current = tab;
            break;
          }
        }
      } else {
        //redirect si pas de parametre.
        const transTab = this.localizeRouter.translateRoute(this.current);
        this.router.navigate([`./${transTab}`], { relativeTo: this.route });
      }
    });
  }

  getSingleProduct(): void {
    this.productService
      .getProduct(this.productId)
      .subscribe((data: Product) => {
        this.product = new Product(data);

        // Set Breadcrumb
        const productName = this.product.getTranslatedProperty(
          this.lang,
          'name'
        );

        const breadcrumbItem = new BreadcrumbItem();
        breadcrumbItem.set(productName, null, true);
        this.breadcrumbService.addBreadcrumbCascade([breadcrumbItem], true);

        this.isReady = true;
      });
  }

  saveGeneral(event: any) {
    this.isLoading = true;
    forkJoin(this.productService.modifyProduct(this.product as Product))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
        this.gotToNextTab();
        if (event === 'reload') {
          this.getSingleProduct();
        }
      });
  }

  private gotToNextTab(): void {
    const idx = this.tabs.findIndex((elt) => elt === this.current);
    if (idx !== this.tabs.length - 1) {
      //redirect to next tab.
      const transTab = this.localizeRouter.translateRoute(this.tabs[idx + 1]);
      this.router.navigate([`../${transTab}`], { relativeTo: this.route });
    }
  }
}
