import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { getDomainName } from 'src/app/shared/utils/other-utils';
import { AuthService } from '../../../account/services/auth/auth.service';
import { VitrineStatus } from '../../enums/vitrine-status.enum';
import { BreadcrumbItem } from './../../../../shared/model/breadcrumb.model';
import { BreadcrumbService } from './../../../../shared/services/breadcrumb.services';
import { Globals } from './../../../../_configs/globals';
import { AccountService } from './../../../account/services/account.service';
import { Meeting } from './../../../meeting/model/meeting.model';
import { MeetingService } from './../../../meeting/services/meeting.service';
import { Vitrine } from './../../model/vitrine.model';
import { VitrineService } from './../../services/vitrine.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ItemRowFile, UploadedItemFile } from 'src/app/shared/model/item-row-file.model';
import { getAdditionalMaterialTypeById, getVitrineAdditionalMaterialTypeBaseList } from '../../enums/vitrine-additional-material-type.enum';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ErrorsHandler } from 'src/app/shared/error-handling/errors-handler';
import { FormItemSelectService } from 'src/app/shared/services/form.row.select.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ItemUploadRepeaterService } from 'src/app/shared/components/item-upload-repeater/item-upload-repeater.service';
import { StringUtils } from 'src/app/shared/utils/string-utils';
@Component({
    selector: 'app-vitrine-approval',
    templateUrl: './vitrine-approval.component.html',
    styleUrls: ['./vitrine-approval.component.scss'],
    providers: [ErrorsHandler]
})
export class VitrineApprovalComponent implements OnInit, OnDestroy {
    vitrineId: number;
    meetingId: number;
    onlineUserId: number;
    vitrine: Vitrine;
    meeting: Meeting;
    rideauForm: UntypedFormGroup;
    reloading = false;
    materielSupList: ItemRowFile[] = [];
    isLoading = false;
    isJuryMember = false;
    isRideau = false;
    currentVitrineStatus = 1;
    isMeetingOwner = false;
    public vitrineMaterialEdition = false;
    isSending = false;
    readonly vitrineFilesName = 'vitrineFiles';
    readonly presentationFileEndpoint = this.globals.api.ENDPOINT + 'upload/vitrine/files';
    lang = null;
    private regExpFileExtract = /.+(\/.+)$/;
    public initialMediaFiles: ItemRowFile[] = [];
    private fullFileList: BehaviorSubject<ItemRowFile[]> = new BehaviorSubject<ItemRowFile[]>([]);
    public fullFileList$: Observable<ItemRowFile[]> = this.fullFileList.asObservable();
    public readonly defaultDateFormat: string = this.globals.date_format_default;
    private readonly vitrineAdditionalMaterialTypeLength: number = getVitrineAdditionalMaterialTypeBaseList().length;
    constructor(
        private globals: Globals,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private authService: AuthService,
        private vitrineService: VitrineService,
        private meetingService: MeetingService,
        private accountService: AccountService,
        private breadcrumbService: BreadcrumbService,
        private notification: RideauNotificationService,
        private modalService: NzModalService,
        private errorHandler: ErrorsHandler,
        private router: Router,
        private formItemSelectService: FormItemSelectService,
        private itemUploadRepeaterService: ItemUploadRepeaterService
    ) {}

    ngOnInit(): void {
        this.vitrineId = this.route.snapshot.params['showcaseId'];
        this.meetingId = this.route.snapshot.params['rencontreId'];
        this.onlineUserId = parseInt(this.authService.User.id);
        this.lang = this.translate.currentLang;
        this.isLoading = true;
        this.itemUploadRepeaterService.uploadedFiles$.subscribe((files: ItemRowFile[]) => {
            this.materielSupList = [...files];
            this.fullFileList.next([...this.initialMediaFiles, ...this.materielSupList]);
            const shouldDisableButton = this.materielSupList.length === this.vitrineAdditionalMaterialTypeLength;
            this.itemUploadRepeaterService.setDisableButtonFromUploadFiles(shouldDisableButton);
        });
        forkJoin([this.getVitrine(), this.getMeeting()]).subscribe(() => (this.isLoading = false));
    }

    getVitrine(): Observable<Vitrine> {
        return this.vitrineService.getVitrineById(this.vitrineId).do((res) => {
            this.vitrine = res;
            this.currentVitrineStatus = this.vitrine.statusId;
            this.rideauForm = this.vitrine.getRideauForm(this.fb);
            this.initItemList({ isCancelled: false });
        });
    }

    private initItemList = ({ isCancelled }: { isCancelled: boolean }): void => {
        const mediaFiles = this.vitrine.media.map(
            (media: UploadedItemFile): ItemRowFile => ({
                id: 'contentTypeId' in media ? media.contentTypeId.toString() : StringUtils.generateUUID(),
                desc: media.label,
                file: media.url,
                hasUpload: true
            })
        );
        if (isCancelled) {
            this.fullFileList.next([...mediaFiles]);
            this.materielSupList = mediaFiles.filter((item: ItemRowFile) => item.file.includes(this.vitrineFilesName));
            this.updateInitialFileTypeFromMaterialList();
            return;
        }
        this.initialMediaFiles = mediaFiles
            .filter((item: ItemRowFile) => !item.file.includes(this.vitrineFilesName))
            .map(
                (media: ItemRowFile): ItemRowFile => {
                    let fileName = media.desc;
                    if (!isNaN(Number(media.desc))) {
                        fileName = media.file
                            .split(this.regExpFileExtract)
                            .filter((x) => !!x.length)
                            .join('')
                            .split('-')[1]
                            .replace(/(%20)/gm, ' ');
                    }
                    return {
                        ...media,
                        desc: fileName
                    };
                }
            );
        this.materielSupList = mediaFiles.filter((item: ItemRowFile) => item.file.includes(this.vitrineFilesName));
        this.fullFileList.next([...this.initialMediaFiles, ...this.materielSupList]);
        this.updateInitialFileTypeFromMaterialList();
    };

    public openVitrineMaterialEdition = (): void => {
        this.vitrineMaterialEdition = true;
        this.initItemList({ isCancelled: false });
    };

    public get isSubmitted(): boolean {
        return this.vitrine.statusId === VitrineStatus.SOUMIS;
    }

    public get isRejected(): boolean {
        return this.vitrine.statusId === VitrineStatus.REJETE;
    }

    public get isApproved(): boolean {
        return this.vitrine.statusId === VitrineStatus.APPROUVE;
    }

    getMeeting(): Observable<Meeting> {
        return this.meetingService.getMeetingById(this.meetingId).do((res) => {
            this.meeting = res;

            // Détermine si le user connecté est membre du jury
            this.isJuryMember = this.meeting.juries.map((x) => x.userId).indexOf(this.onlineUserId) != -1;

            // Détermine si l'utilisateur appartien a l'organisation propriétaire
            this.isMeetingOwner = this.meeting.organizationId == this.accountService.getCurrentCtxOrganizationId();

            // Determine si membre de SCENE_PRO
            this.isRideau = this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID;
            const meetingName = this.meeting.getTranslatedProperty(this.lang, 'name');
            const meetingUrl = '/pro-meeting/' + this.meetingId;
            const breadcrumbItem = new BreadcrumbItem({
                title: meetingName,
                url: meetingUrl
            });
            this.breadcrumbService.addBreadcrumbCascade([breadcrumbItem]);
        });
    }

    refuse(): void {
        const body = {
            vitrineId: this.vitrine.id,
            statusId: VitrineStatus.REJETE,
            organizationId: this.meeting.organizationId
        };
        this.vitrineService.approveVitrine(body).subscribe(() => {
            this.notification.success(this.translate.instant('VITRINE-REJETEE'));
            this.currentVitrineStatus = 3;
            this.getVitrine();
        });
    }

    accept(): void {
        const body = {
            vitrineId: this.vitrine.id,
            statusId: VitrineStatus.APPROUVE,
            organizationId: this.meeting.organizationId
        };
        this.vitrineService.approveVitrine(body).subscribe(() => {
            this.notification.success(this.translate.instant('VITRINE-ACCEPTEE'));
            this.currentVitrineStatus = 2;
            this.getVitrine();
        });
    }

    isVideo(src: string): boolean {
        const domain = getDomainName(src);
        return domain === 'youtube' || domain === 'vimeo';
    }
    isAudio(src: string): boolean {
        const domain = getDomainName(src);
        return domain === 'soundcloud';
    }
    getVitrineType(): string {
        const res: string[] = [];
        if (this.vitrine.isExpress) {
            res.push(this.translate.instant('VITRINE_TYPE.PRESENTATION_EXPRESS'));
        }
        return res.join(' - ');
    }

    getAdditionalMaterialType(media: ItemRowFile): string {
        if (media.file.includes(this.vitrineFilesName) && !isNaN(Number(media.desc))) {
            return this.translate.instant('VITRINE_ADDITIONAL_MATERIAL_TYPE.' + getAdditionalMaterialTypeById(Number(media.desc)));
        }
        return media.desc;
    }

    public cancel = (): void => {
        // update list of vitrine materials
        // when the form is not visible anymore
        this.initItemList({ isCancelled: true });
        this.vitrineMaterialEdition = false;
    };

    submitVitrineMaterialEdition(): void {
        this.vitrine.isExpress = this.rideauForm.get('isExpress').value ? 1 : 0;
        this.vitrine.isYoungAudience = this.rideauForm.get('isYoungAudience').value ? 1 : 0;
        this.vitrine.demoFile = this.rideauForm.get('demoFile').value;
        this.vitrine.mediaUrl = this.rideauForm.get('mediaUrl').value;
        this.vitrine.mediaPassword = this.rideauForm.get('mediaPassword').value;
        this.vitrine.additionalUrl = this.rideauForm.get('additionalUrl').value;
        this.vitrine.additionalMediaPassword = this.rideauForm.get('additionalMediaPassword').value;
        this.materielSupList = this.materielSupList.filter((item: ItemRowFile) => !!item.file);
        // add the newly added files
        const materielSupList = this.materielSupList
            .map(
                (item: ItemRowFile): UploadedItemFile => ({
                    url: item.file,
                    label: item.desc,
                    contentTypeId: Number(item.id)
                })
            )
            .filter((item: UploadedItemFile) => item.url !== '');
        // re-add the initial uploaded files
        const initialUploadedFiles = this.initialMediaFiles
            .map(
                (item: ItemRowFile): UploadedItemFile => ({
                    url: item.file,
                    label: item.desc,
                    contentTypeId: +item.id
                })
            )
            .filter((item: UploadedItemFile) => item.url !== '');
        this.vitrine.media = [...initialUploadedFiles, ...materielSupList];
        const body = new Vitrine(this.vitrine);
        delete body.show;
        delete body.product;
        delete body.meeting;
        delete body.organization;
        this.isSending = true;
        this.vitrineService.modifyVitrine(body).subscribe(() => {
            this.isSending = false;
            this.vitrineMaterialEdition = false;
            this.formItemSelectService.reset();
            this.itemUploadRepeaterService.reset({ resetInitialTypes: true });
            this.updateInitialFileTypeFromMaterialList(); // update initialTypes array with updated material list data
        });
    }

    softDeleteVitrine(): void {
        this.modalService.confirm({
            nzContent: this.translate.instant('SUPPRIMER-CONFIRMATION'),
            nzOkText: this.translate.instant('OUI'),
            nzCancelText: this.translate.instant('NON'),
            nzClosable: true,
            nzMaskClosable: true,
            nzOnOk: () => {
                this.vitrineService
                    .softDeleteVitrine(this.vitrineId, this.vitrine.organizationId)
                    .toPromise()
                    .then(() => {
                        this.notification.success(this.translate.instant('VITRINE-DELETED'));
                        this.router.navigateByUrl(`/${this.translate.currentLang}/${this.translate.instant('ROUTES.pro-meeting')}/${this.meetingId}`);
                    })
                    .catch((error: Error | HttpErrorResponse) => {
                        this.errorHandler.handleError(error);
                    });
            }
        });
    }

    ngOnDestroy(): void {
        this.formItemSelectService.reset();
        this.itemUploadRepeaterService.reset({ resetInitialTypes: true });
    }

    private updateInitialFileTypeFromMaterialList = (): void => {
        const payload = {
            list: this.materielSupList,
            fileTypesLength: this.vitrineAdditionalMaterialTypeLength,
            options: {
                param: this.vitrineFilesName,
                action: this.presentationFileEndpoint
            }
        };
        this.itemUploadRepeaterService.setFileTypesFromMaterialList(payload);
    };
}
