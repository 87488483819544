import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmailConfirmationResolver {

  constructor(private authService: AuthService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    this.authService.clearUser();
    return true
  }


}
