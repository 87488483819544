import { noWhitespaceValidator } from 'src/app/shared/validators/not-blank.validator';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
  ValidatorFn,
} from '@angular/forms';
import { Translatable } from 'src/app/shared/model/translatable.model';
import { isMonth, isYear } from 'src/app/shared/validators/dates.validator';
import { Globals } from '@app/_configs/globals';

export interface ICreditCard {
  id?: number;
  memberId?: number;
  organizationId?: number;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  mobilePhone: string;
  streetAddress1: string;
  streetAddress2: string;
  streetAddress3: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  number: string;
  expMonth: string;
  expYear: string;
  holderName: string;
}

export class CreditCard extends Translatable implements ICreditCard {
  id?: number;
  memberId?: number;
  organizationId?: number;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  mobilePhone: string;
  streetAddress1: string;
  streetAddress2: string;
  streetAddress3: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  number: string;
  expMonth: string;
  expYear: string;
  holderName: string;

  private orgaCreditCardForm: UntypedFormGroup;
  private userCreditCardForm: UntypedFormGroup;

  constructor(datas: Record<string, any>) {
    super();
    this.id = datas['id'];
    this.memberId = datas['memberId'];
    this.organizationId = datas['organizationId'];
    this.firstName = datas['firstName'];
    this.lastName = datas['lastName'];
    this.company = datas['company'];
    this.email = datas['email'];
    this.mobilePhone = datas['mobilePhone'];
    this.streetAddress1 = datas['streetAddress1'];
    this.streetAddress2 = datas['streetAddress2'];
    this.streetAddress3 = datas['streetAddress3'];
    this.city = datas['city'];
    this.province = datas['province'];
    this.postalCode = datas['postalCode'];
    this.country = datas['country'];
    this.number = datas['number'];
    this.expMonth = datas['expMonth'];
    this.expYear = datas['expYear'];
    this.holderName = datas['holderName'];
  }

  /*FormGroups*/
  getOrgaCreditCardFormGroup(fb: UntypedFormBuilder, globals?: Globals): UntypedFormGroup {
    if (!this.orgaCreditCardForm) {
      this.orgaCreditCardForm = fb.group(
        {
          organizationId: [this.organizationId],
          firstName: [
            this.firstName,
            [Validators.required, noWhitespaceValidator],
          ],
          lastName: [
            this.lastName,
            [Validators.required, noWhitespaceValidator],
          ],
          company: [this.company],
          email: [
            this.email,
            [Validators.required, noWhitespaceValidator, globals ? Validators.pattern(globals.emailRegex) : Validators.email],
          ],
          mobilePhone: [
            this.mobilePhone,
            [Validators.required, noWhitespaceValidator],
          ],
          streetAddress1: [
            this.streetAddress1,
            [Validators.required, noWhitespaceValidator],
          ],
          streetAddress2: [this.streetAddress2],
          streetAddress3: [this.streetAddress3],
          city: [this.city, [Validators.required, noWhitespaceValidator]],
          province: [
            this.province,
            [Validators.required, noWhitespaceValidator],
          ],
          postalCode: [
            this.postalCode,
            [Validators.required, noWhitespaceValidator],
          ],
          country: [this.country, [Validators.required, noWhitespaceValidator]],
          number: [this.number, [Validators.required, noWhitespaceValidator]],
          expMonth: [
            this.expMonth,
            [Validators.required, noWhitespaceValidator, isMonth],
          ],
          expYear: [
            this.expYear,
            [Validators.required, noWhitespaceValidator, isYear],
          ],
          holderName: [
            this.holderName,
            [Validators.required, noWhitespaceValidator],
          ],
        },
        { validator: this.validateCardExpirationDate }
      );
    }
    return this.orgaCreditCardForm;
  }

  getUserCreditCardFormGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    if (!this.userCreditCardForm) {
      this.userCreditCardForm = fb.group({
        memberId: [this.memberId],
        firstName: [this.firstName],
        lastName: [this.lastName],
        company: [this.company],
        email: [this.email],
        mobilePhone: [this.mobilePhone],
        streetAddress1: [this.streetAddress1],
        streetAddress2: [this.streetAddress2],
        streetAddress3: [this.streetAddress3],
        city: [this.city],
        province: [this.province],
        postalCode: [this.postalCode],
        country: [this.country],
        number: [this.number],
        expMonth: [this.expMonth],
        expYear: [this.expYear],
        holderName: [this.holderName],
      });
    }
    return this.userCreditCardForm;
  }

  validateCardNumber(): { format: true } {
    return { format: true };
  }

  validateCardExpirationDate: ValidatorFn = (formGroup: UntypedFormGroup) => {
    const expMonth = formGroup.get('expMonth').value;
    const expYear = formGroup.get('expYear').value;
    const now = new Date();

    if (expMonth && expYear) {
      if (expYear < now.getFullYear()) {
        formGroup.controls['expYear'].setErrors({ expDateIsInvalid: true });
        return { expDateIsInvalid: true };
      } else if (
        expYear == now.getFullYear() &&
        expMonth < now.getMonth() + 1
      ) {
        formGroup.controls['expMonth'].setErrors({ expDateIsInvalid: true });
        return { expDateIsInvalid: true };
      } else return null;
    } else return null;
  };
}
