import { NgModule } from '@angular/core';
import { VenueSingleResidencyComponent } from './venue-single-residency.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
    imports: [CommonModule, TranslateModule, SharedModule],
    exports: [VenueSingleResidencyComponent],
    declarations: [VenueSingleResidencyComponent]
})
export class VenueSingleResidencyModule {}
