<app-main-navigation></app-main-navigation>
<div class="container pb-5">
    <div class="row pb-4">
        <div class="col">
            <!--eventually title component-->
            <h1 class="mb-4 mr-3 d-inline-block">{{ current == 0 ? ('NOUVEL-ARTISTE' | translate) : 'Nom de l˙artiste' }}</h1>
            <!--FORM STATE-->
            <p class="form-state d-inline-block">{{ 'STATE.INCOMPLET' | translate }}</p>
            <hr />
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <!--eventually form stepper component-->
            <nz-steps [nzCurrent]="current" nzDirection="vertical" nzSize="medium">
                <nz-step nzTitle="{{ current == 0 ? ('STATE.EN-COURS' | translate) : ('STATE.TERMINE' | translate) }}" nzDescription="{{ 'COORDONNEES' | translate }}"></nz-step>
                <nz-step
                    nzTitle="{{ current == 0 ? ('STATE.EN-ATTENTE' | translate) : current == 1 ? ('STATE.EN-COURS' | translate) : ('STATE.TERMINE' | translate) }}"
                    nzDescription="{{ 'PROFIL' | translate }}"
                ></nz-step>
                <nz-step
                    nzTitle="{{ current == 2 ? ('STATE.EN-COURS' | translate) : ('STATE.EN-ATTENTE' | translate) }}"
                    nzDescription="{{ 'DROITS-ADMINISTRATION' | translate }}"
                ></nz-step>
            </nz-steps>
        </div>
        <div class="col">
            <h2 class="mb-3">{{ current == 0 ? ('COORDONNEES' | translate) : current == 1 ? ('PROFIL' | translate) : ('DROITS-ADMINISTRATION' | translate) }}</h2>
            <!--STEP 1 CREATE artist-->
            <ng-container *ngIf="current == 0">
                <form id="create-artist-step-1" class="mb-4" nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
                    <div class="row">
                        <div class="col-md-6">
                            <app-nz-form-item inputId="artist" [control]="validateForm.get('artist')" inputType="text">
                                <div class="input-custom-label">
                                    <label for="artist">{{ 'FORM.NOM-ARTISTE-COMPAGNIE' | translate }}</label>
                                </div>
                                <div class="input-custom-error-msg">
                                    <span *ngIf="validateForm.get('artist').dirty && validateForm.get('artist').errors">
                                        {{ 'FORM.CHAMPS-REQUIS' | translate }}
                                    </span>
                                </div>
                            </app-nz-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <app-nz-form-item inputId="address" [control]="validateForm.get('address')" inputType="text">
                                <div class="input-custom-label">
                                    <label for="address">{{ 'FORM.ADRESSE' | translate }}</label>
                                </div>
                                <div class="input-custom-error-msg">
                                    <span *ngIf="validateForm.get('address').dirty && validateForm.get('address').errors">
                                        {{ 'FORM.CHAMPS-REQUIS' | translate }}
                                    </span>
                                </div>
                            </app-nz-form-item>
                        </div>
                        <div class="col-md-6">
                            <app-nz-form-item inputId="city" [control]="validateForm.get('city')" inputType="text">
                                <div class="input-custom-label">
                                    <label for="city">{{ 'FORM.VILLE' | translate }}</label>
                                </div>
                                <div class="input-custom-error-msg">
                                    <span *ngIf="validateForm.get('city').dirty && validateForm.get('city').errors">
                                        {{ 'FORM.CHAMPS-REQUIS' | translate }}
                                    </span>
                                </div>
                            </app-nz-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <app-nz-form-item inputId="zipcode" [control]="validateForm.get('zipcode')" inputType="text">
                                <div class="input-custom-label">
                                    <label for="zipcode">{{ 'FORM.CODE-POSTAL' | translate }}</label>
                                </div>
                                <div class="input-custom-error-msg">
                                    <span *ngIf="validateForm.get('zipcode').dirty && validateForm.get('zipcode').errors">
                                        {{ 'FORM.CHAMPS-REQUIS' | translate }}
                                    </span>
                                </div>
                            </app-nz-form-item>
                        </div>
                        <div class="col-md-3">
                            <nz-form-item>
                                <nz-form-control>
                                    <label for="country">{{ 'FORM.PAYS' | translate }}</label>
                                    <select class="" id="country" name="country" formControlName="country"></select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-md-3">
                            <nz-form-item>
                                <nz-form-control>
                                    <label for="province">{{ 'FORM.PROVINCE' | translate }}</label>
                                    <select class="" name="province" id="province" formControlName="province"></select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <app-nz-form-item inputId="phone" [control]="validateForm.get('phone')" inputType="text">
                                <div class="input-custom-label">
                                    <label for="phone">{{ 'FORM.TELEPHONE' | translate }}</label>
                                </div>
                            </app-nz-form-item>
                        </div>
                        <div class="col-md-3 d-flex align-items-center">
                            <app-nz-form-item inputId="phone_post" [control]="validateForm.get('phone_post')" inputType="text">
                                <div class="input-custom-label">
                                    <label class="mr-2 d-inline-block" for="phone_post">{{ 'FORM.POSTE' | translate }}</label>
                                </div>
                            </app-nz-form-item>
                        </div>
                        <div class="col-md-6">
                            <app-nz-form-item inputId="email" [control]="validateForm.get('email')" inputType="text">
                                <div class="input-custom-label">
                                    <label for="email">{{ 'FORM.COURRIEL' | translate }}</label>
                                </div>
                            </app-nz-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <app-nz-form-item inputId="website" [control]="validateForm.get('website')" inputType="text">
                                <div class="input-custom-label">
                                    <label for="website">{{ 'FORM.SITE-WEB' | translate }}</label>
                                </div>
                                <div class="input-custom-error-msg">
                                    <span *ngIf="validateForm.get('website').dirty && validateForm.get('website').errors">
                                        {{ 'FORM.CHAMPS-REQUIS' | translate }}
                                    </span>
                                </div>
                            </app-nz-form-item>
                        </div>
                    </div>
                    <nz-form-control>
                        <button class="mr-2 d-inline-block" nz-button nzSize="large" nzType="default">{{ 'ENREGISTER-CONTINUER' | translate }}</button>
                        <p class="d-inline-block">
                            {{ 'OU' | translate }}
                            <a (click)="next()" *ngIf="current < 2">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
                        </p>
                    </nz-form-control>
                </form>
            </ng-container>

            <!--STEP 2 ADD DETAILS TO NEW artist-->
            <ng-container *ngIf="current == 1">
                <form id="create-artist-step-2" class="mb-4" nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
                    <div class="row">
                        <div class="col-md-6">
                            <nz-form-item>
                                <nz-form-control>
                                    <label for="description">{{ 'FORM.DESCRIPTION' | translate }}</label>
                                    <textarea id="description" formControlName="description" nz-input rows="5"></textarea>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-md-6">
                            <nz-form-control>
                                <label>{{ 'PHOTO' | translate }}</label>
                            </nz-form-control>
                            <nz-upload
                                class="avatar-uploader"
                                nzAction="https://jsonplaceholder.typicode.com/posts/"
                                nzName="artist-logo"
                                nzListType="picture-card"
                                [nzShowUploadList]="false"
                                [nzBeforeUpload]="beforeUpload"
                                (nzChange)="handleChange($event)"
                            >
                                <ng-container *ngIf="!artistLogoUrl">
                                    <i class="icon-for-btn icon-plus"></i>
                                    <div class="ant-upload-text">{{ 'AJOUTER' | translate }}</div>
                                </ng-container>
                                <img *ngIf="artistLogoUrl" [src]="artistLogoUrl" class="artist-logo" alt="" />
                            </nz-upload>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <app-nz-form-item inputId="numero_entreprise_qc" [control]="validateForm.get('numero_entreprise_qc')" inputType="text">
                                <div class="input-custom-label">
                                    <label for="numero_entreprise_qc">{{ 'FORM.ISNI' | translate }}</label>
                                </div>
                            </app-nz-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label for="numero_entreprise_qc">{{ 'FORM.RESEAUX-SOCIAUX' | translate }}</label>
                        </div>
                    </div>
                    <div class="row pb-4">
                        <div class="col-md-6">
                            <app-nz-form-item inputId="reseaux_sociaux1" [control]="validateForm.get('reseaux_sociaux1')" inputType="text">
                                <div class="input-custom-label">
                                    <i nz-icon aria-hidden="true" type="facebook" theme="outline"></i>
                                </div>
                            </app-nz-form-item>
                        </div>
                        <div class="col-md-6">
                            <app-nz-form-item inputId="reseaux_sociaux2" [control]="validateForm.get('reseaux_sociaux2')" inputType="text">
                                <div class="input-custom-label">
                                    <i nz-icon aria-hidden="true" type="youtube" theme="outline"></i>
                                </div>
                            </app-nz-form-item>
                        </div>
                        <div class="col-md-6">
                            <app-nz-form-item inputId="reseaux_sociaux3" [control]="validateForm.get('reseaux_sociaux3')" inputType="text">
                                <div class="input-custom-label">
                                    <i nz-icon aria-hidden="true" type="twitter" theme="outline"></i>
                                </div>
                            </app-nz-form-item>
                        </div>
                        <div class="col-md-6">
                            <app-nz-form-item inputId="reseaux_sociaux4" [control]="validateForm.get('reseaux_sociaux4')" inputType="text">
                                <div class="input-custom-label">
                                    <i nz-icon aria-hidden="true" type="linkedin" theme="outline"></i>
                                </div>
                            </app-nz-form-item>
                        </div>
                    </div>
                    <nz-form-item>
                        <nz-form-control>
                            <button class="mr-2 d-inline-block" nz-button nzSize="large" nzType="default">{{ 'ENREGISTER-CONTINUER' | translate }}</button>
                            <p class="d-inline-block">
                                {{ 'OU' | translate }}
                                <a (click)="next()" *ngIf="current < 2">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
                            </p>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </ng-container>
            <!--STEP 3 DROITS D˙ADMINISTRATION-->
            <ng-container *ngIf="current == 2">
                <nz-form-item>
                    <nz-form-control>
                        <button class="mr-2 d-inline-block" nz-button nzSize="large" nzType="default">{{ 'FORM.SOUMETTRE-POUR-APPROBATION' | translate }}</button>
                        <p class="d-inline-block">
                            {{ 'OU' | translate }}
                            <a (click)="next()">{{ 'ENREGISTER-SANS-SOUMETTRE' | translate }}</a>
                        </p>
                    </nz-form-control>
                </nz-form-item>
            </ng-container>
            <!--TESTS-->
            <hr class="mt-3 mb-3" />
            <div class="steps-action mb-2">
                <!--STEPS NAVIGATION-->
                <button class="mr-2" nz-button nzType="default" (click)="pre()" *ngIf="current > 0">
                    <span>{{ 'PRECEDENT' | translate }}</span>
                </button>
                <button nz-button nzType="default" (click)="next()" *ngIf="current < 2">
                    <span>{{ 'SUIVANT' | translate }}</span>
                </button>
                <button nz-button nzType="primary" (click)="done()" *ngIf="current === 2">
                    <span>{{ 'STATE.TERMINE' | translate }}</span>
                </button>
            </div>
            <div class="mb-5 float-md-right">
                <label>
                    {{ 'ACCUEIL.SELECT' | translate }}
                    <select #langSelect (change)="translate.use(langSelect.value)">
                        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
                    </select>
                </label>
            </div>
        </div>
    </div>
</div>
