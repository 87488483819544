import { minutesToTime } from '../utils/time-utils';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Prends un nombre de minutes et le formate en heures et minutes
 * Usage :
 *   {{ 75 | hourMin }}
 * Affiche :
 *  1h15
 */
@Pipe({
  name: 'hourMin',
})
export class HourMinPipe implements PipeTransform {
  transform(value: number): string {
    const res = minutesToTime(value);
    const min = res.m === 0 ? '' : res.m < 10 ? `0${res.m}` : res.m;
    const hour = res.h === 0 ? '00' : res.h < 10 ? `0${res.h}` : res.h;
    return `${hour}h${min}`;
  }
}
