import { Injectable } from '@angular/core';

import { ToursService } from './services/tours.service';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Tour } from './model/tour.model';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';
import { StorageData } from './model/storage-data.model';
import { SessionStorageService } from '@app/shared/services/storage.service';
interface ArchivedPaidTourStorageData extends StorageData<Tour[]> {
    contextOrganizationId?: number;
}
@Injectable({ providedIn: 'root' })
export class ArchivedPaidToursResolver  {
    private readonly sessionStorageArchivedPaidToursKey = 'sp.archivedPaidTours';
    constructor(
        private sessionStorage: SessionStorageService,
        private readonly toursService: ToursService,
        private readonly userOrganizationIdResolver: UserOrganizationIdResolver
    ) {}

    resolve(): Observable<Tour[]> {
        return this.userOrganizationIdResolver.resolve().pipe(switchMap((contextOrganizationId) => this.getArchivedPaidTours(contextOrganizationId)));
    }

    private getArchivedPaidTours = (contextOrganizationId: number): Observable<Tour[] | null> => {
        if (contextOrganizationId === -1) {
            return of(null);
        }
        const storedData: ArchivedPaidTourStorageData = this.sessionStorage.getItem(this.sessionStorageArchivedPaidToursKey);
        // if (storedData && storedData.contextOrganizationId && storedData.contextOrganizationId === contextOrganizationId) {
        //     return of(storedData.list.map((x) => new Tour(x)));
        // }
        return this.toursService.archivedPaidTours(contextOrganizationId).pipe(
            tap((tours: Tour[]) => {
                this.sessionStorage.setItem(this.sessionStorageArchivedPaidToursKey, { ts: Date.now(), list: tours, contextOrganizationId });
                return tours.map((x) => new Tour(x));
            })
        );
    };
}
