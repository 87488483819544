import { Globals } from './../../../../../_configs/globals';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { OrganisationStatus } from './../../../enums/organization-status.enum';
import { UntypedFormGroup } from '@angular/forms';
import { Output, Input, EventEmitter, Directive } from '@angular/core';
import { Organization } from '../../../model/organization.model';
import { OrganizationTypes } from '../../../enums/organization-types.enum';
import { Permission } from 'src/app/shared/enums/roles.enum';

const ORGTYPEFIELDS = [
    'isProducteur',
    'isDiffuseur',
    'isSalle',
    'isGovernmental',
    'isProvider',
    'isMedia',
    'isOther',
    'tourAccess',
];

@Directive()
export abstract class AbstractOrganizationForm {
    @Input()
    displayErrors: boolean;

    @Input()
    isLoading: boolean;

    @Input()
    get organization(): Organization {
        return this.organizationValue;
    }

    set organization(value) {
        //Si le changement vient du composant parent (changement complet d'organisation)
        if (this.organizationValue && value.id !== this.organizationValue.id) {
            this.organizationValue = new Organization(value);
            this.initForm();
        } else {
            this.organizationValue = new Organization(value);
        }
        this.refillSavedOrgTypes();
        this.checkDisable();
        this.organizationChange.emit(this.organizationValue);
    }

    @Output()
    organizationChange = new EventEmitter<Organization>();

    @Output()
    submitCallback = new EventEmitter<any>();

    protected abstract initForm(): void;

    organizationValue: Organization;
    savedOrganizationTypes: number[];

    public formGroup: UntypedFormGroup;

    constructor(
        protected accountService: AccountService,
        protected globals: Globals
    ) { }

    protected checkDisable(): void {
        if (this.formGroup) {
            /**
             * if Scene_Pro ou non soumis ou rejeté:
             *  enable
             *
             * if orga member et CAN_EDIT:
             *  enable sauf types et nom
             *
             * else disable
             *  */

            if (
                this.organization.statusId === OrganisationStatus.NON_SOUMIS ||
                this.organization.statusId === OrganisationStatus.REJETE ||
                this.accountService.getCurrentCtxOrganizationId() ===
                this.globals.SCENE_PRO_ORGID
            ) {
                this.formGroup.enable();
            } else if (
                this.accountService.getCurrentCtxOrganizationId() ===
                this.organization.id
            ) {
                this.accountService
                    .hasPermission(Permission.CAN_SUBMIT)
                    .subscribe((res: boolean) => {
                        if (res) {
                            this.formGroup.enable();
                            this.disableFieldIfExists('name');
                            this.disableFieldIfExists('isProducteur');
                            this.disableFieldIfExists('isDiffuseur');
                            this.disableFieldIfExists('isSalle');
                            this.disableFieldIfExists('isGovernmental');
                            this.disableFieldIfExists('isProvider');
                            this.disableFieldIfExists('isMedia');
                            this.disableFieldIfExists('isOther');
                            this.disableFieldIfExists('tourAccess');
                        } else {
                            this.formGroup.disable();
                        }
                    });
            } else {
                this.formGroup.disable();
            }

            // user logged as RIDEAU can add new types but not remove already approved ones
            if (
                this.accountService.getCurrentCtxOrganizationId() ===
                this.globals.SCENE_PRO_ORGID &&
                this.organization.statusId === OrganisationStatus.APPROUVE
            ) {
                this.formGroup.enable();

                // tslint:disable-next-line: forin
                for (const typeField in OrganizationTypes) {
                    const field = this.formGroup.get(
                        ORGTYPEFIELDS[ parseInt(typeField) - 1 ]
                    );
                    if (field) {
                        this.savedOrganizationTypes.includes(parseInt(typeField))
                            ? field.disable()
                            : field.enable();
                    }
                }
            }
        }
    }

    public doSubmit(param?: unknown): void {
        this.organizationChange.emit(this.organizationValue);
        this.submitCallback.emit(param);
    }

    private refillSavedOrgTypes(): void {
        this.savedOrganizationTypes = [];
        this.organizationValue.types.forEach((type) =>
            this.savedOrganizationTypes.push(type)
        );
    }

    private disableFieldIfExists(name: string): void {
        const field = this.formGroup.get(name);
        if (field) field.disable();
    }

    private disableFieldIfExistsAndTrue(name: string): void {
        const field = this.formGroup.get(name);
        if (field && this.savedOrganizationTypes) field.disable();
    }
}
