import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SharedModule } from 'src/app/shared/shared.module';
import { LightboxModule } from 'ngx-lightbox';
import { AccountModule } from '../account/account.module';
/*Components*/
import { ProductGeneralFormComponent } from './components/product-modify/product-general-form/product-general-form.component';
import { ProductTarificationFormComponent } from './components/product-modify/product-tarification-form/product-tarification-form.component';
import { ProductCompatibilityFormComponent } from './components/product-modify/product-compatibility-form/product-compatibility-form.component';
import { ProductComponent } from './components/product.component';
import { ProductModifyComponent } from './components/product-modify/product-modify.component';
import { TarifRowComponent } from './components/tarif-row/tarif-row.component';
import { GratuitieRowComponent } from './components/gratuitie-row/gratuitie-row.component';

@NgModule({
    declarations: [
        ProductComponent,
        ProductModifyComponent,
        ProductGeneralFormComponent,
        ProductTarificationFormComponent,
        ProductCompatibilityFormComponent,
        TarifRowComponent,
        GratuitieRowComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        LocalizeRouterModule,
        AccountModule,
        SharedModule,
        LightboxModule,
        NzSelectModule
    ],
    exports: [ProductComponent, ProductModifyComponent, ProductGeneralFormComponent, ProductTarificationFormComponent, ProductCompatibilityFormComponent]
})
export class ProductModule {}
