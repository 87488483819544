import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

let unique = 0;

@Component({
    selector: 'app-rich-text',
    template: `
        <nz-form-item>
            <div class="row w-100">
                <div class="col-12 mb-1">
                    <label [attr.for]="id">
                        <ng-content></ng-content>
                    </label>
                </div>
                <div class="col-12">
                    <nz-form-control>
                        <quill-editor
                            [attr.id]="id"
                            [(ngModel)]="value"
                            (ngModelChange)="ngModelChange()"
                            [sanitize]="true"
                            [modules]="modules"
                            [placeholder]="'FORM.DESCRIPTION-PLACEHOLDER' | translate"
                            [class.error]="ngControl.touched && ngControl.errors"
                            [readOnly]="disable"
                        ></quill-editor>
                        <p *ngIf="countMaxLength" class="word-count" [class.error]="ngControl.touched && ngControl.errors && ngControl.errors.maxlength">
                            {{ 'RICH_TEXT_CHARACTERS' | translate : { actualLength: actualLength, requiredLength: countMaxLength } }}
                        </p>
                        <p *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.maxlength" class="error">
                            {{ maxLengthTranslateKey | translate : ngControl.errors.maxlength }}
                        </p>
                        <p *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.minlength" class="error">
                            {{ minLengthTranslateKey | translate : ngControl.errors.minlength }}
                        </p>
                        <p *ngIf="ngControl.touched && ngControl.errors && ngControl.errors.required" class="error">
                            {{ requiredTranslateKey | translate }}
                        </p>
                    </nz-form-control>
                </div>
            </div>
        </nz-form-item>
    `,
    styles: [
        `
            :host {
                display: block;
            }
            label {
                margin-bottom: 10px;
                display: block;
            }
            .word-count {
                display: flex;
                justify-content: flex-end;
            }
            p.error {
                color: rgb(226, 0, 108) !important;
                margin-top: 5px;
            }
            quill-editor {
                background: white;
                display: block;
            }
            quill-editor.error {
                border: 2px solid rgb(226, 0, 108);
                border-radius: 2px;
            }
            ql-disabled {
                pointer-events: none !important;
                cursor: not-allowed !important;
            }
        `
    ]
})
export class RichTextComponent implements ControlValueAccessor {
    private onChange: (value: string) => void;
    private onTouched: () => void;
    private div = document.createElement('div');
    @Input() disabled: boolean;
    @Input() disable = false;
    @Input() modules: any;
    @Input() requiredTranslateKey = 'REQUIRED_ERROR';
    @Input() maxLengthTranslateKey = 'MAX_LENGTH_ERROR';
    @Input() minLengthTranslateKey = 'MIN_LENGTH_ERROR';
    @Input() countMaxLength: number;
    id = `RichTextComponent-${unique++}`;
    value: string;
    actualLength: number;
    constructor(readonly translateService: TranslateService, readonly ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }
    ngModelChange(): void {
        this.onChange(this.value);
        this.onTouched();
        this.count();
    }
    writeValue(value: string): void {
        this.value = value;
        this.count();
    }
    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    count(): void {
        this.div.innerHTML = this.value || '';
        this.actualLength = this.div.innerText.trim().length;
    }
}
