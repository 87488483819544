<ng-container *ngIf="isReady">
    <div nz-row class="page-template-head activity-head mb-5">
        <!-- Carré avec la date -->
        <div class="container pb-5">
            <div nz-row="">
                <div nz-col nzSpan="2">
                    <div class="activity-head__date-container">
                        <span class="activity-head__date-container__day">{{ day }}</span>
                        <span class="activity-head__date-container__month text-uppercase">{{ month }}</span>
                    </div>
                </div>

                <div nz-col nzSpan="16">
                    <div class="d-sm-flex activity-head__category">
                        <!-- TITLE -->
                        <h1 class="mb-0">
                            {{ activity.getTranslatedProperty(currentLang, 'title') }}
                        </h1>
                        <!-- CATEGORY -->
                        <div *ngIf="activity.categoryId" class="mb-sm-2 mb-0">
                            <div class="activity-head__bubble">{{ 'ACTIVITY-CATEGORIES.' + activity.categoryId | translate }}</div>
                        </div>
                        <div *ngIf="!(hasRemainingCapacity$ | async)" class="mb-sm-2 mb-0">
                            <div class="activity-head__bubble_capacity">{{ 'COMPLE' | translate }}</div>
                        </div>
                    </div>
                    <p class="font-size-med mb-0">{{ activity.hourFrom | timeFromDate }} {{ 'A-CCENT' | translate }} {{ activity.hourTo | timeFromDate }}</p>
                </div>

                <div nz-col nzSpan="6" class="text-right pt-2 action-buttons">
                    <!-- Dropdown de choix d'activités -->
                    <button
                        *ngIf="isInscriptionOpen"
                        nz-dropdown
                        nzTrigger="click"
                        class="btn btn-default viewParticipants"
                        style="min-width: 228px; padding-top: 7px"
                        [nzDropdownMenu]="menu"
                        [nzPlacement]="'bottomRight'"
                        [nzClickHide]="nzClickHide"
                    >
                        {{ 'AJOUTER-CHOIX-ACTIVITE' | translate }}
                        <i style="padding-left: 5px" nz-icon nzType="caret-down" nzTheme="fill" aria-hidden="true"></i>
                    </button>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <sp-menu [classList]="['dropdownInscriptions']" *ngIf="isInscriptionReady">
                            <sp-menu-item *ngIf="!(hasRemainingCapacity$ | async)" [classList]="['mb-sm-2 mb-0']">
                                <span class="activity-head__bubble_capacity">{{ 'COMPLE' | translate }}</span>
                            </sp-menu-item>

                            <!-- Une checkbox par participant -->
                            <ng-container *ngFor="let participant of participants">
                                <sp-menu-item (clicked)="$event.stopPropagation()" [classList]="['mb-3']">
                                    <sp-checkbox
                                        [disabled]="participations[participant.id].disabled || (!participations[participant.id].value && !(hasRemainingCapacity$ | async))"
                                        [(ngModel)]="participations[participant.id].value"
                                        (ngModelChange)="updateCapacity()"
                                    >
                                        <img src="{{ participant.user.avatar || './assets/images/placeholder-membre.svg' }}" alt="" />
                                        <span style="color: black">{{ participant.user.firstName + ' ' + participant.user.lastName }}</span>
                                        <span *ngIf="participations[participant.id].fullSchedule" nz-tooltip [nzTooltipTitle]="'CONFLIT-HORAIRE' | translate">
                                            <i sp-icon spType="clock-circle" spTheme="outline" aria-hidden="true"></i>
                                        </span>
                                    </sp-checkbox>
                                </sp-menu-item>
                                <sp-divider></sp-divider>
                            </ng-container>
                            <!-- Bouton de validation des choix -->
                            <sp-menu-item [classList]="['my-3']">
                                <button class="btn btn-primary" style="width: 100%" (click)="saveActivityChoice()" nz-button>
                                    {{ 'ENREGISTER' | translate }}
                                </button>
                            </sp-menu-item>
                        </sp-menu>
                    </nz-dropdown-menu>
                    <!-- Modifier l'activité -->
                    <ng-container *ngIf="meeting">
                        <button
                            type="button"
                            *appPermission="1; objectOrganizationId: meeting?.organizationId"
                            class="btn btn--default btn--edit ml-1"
                            style="min-width: 142px; padding-top: 7px"
                            (click)="modifyActivity()"
                        >
                            <i nz-icon class="pr-12" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <path
                                        fill="#ffffff"
                                        fill-rule="nonzero"
                                        d="M16.142 3.707a3 3 0 0 1 0 4.243l-7.07 7.07a1 1 0 0 1-.314.213l-4.95 2.121c-.83.356-1.669-.483-1.313-1.313l2.121-4.95a1 1 0 0 1 .212-.313l7.071-7.07a3 3 0 0 1 4.243 0zM11.26 7.174L6.38 12.056l-1.06 2.475 2.474-1.06 4.881-4.883-1.414-1.414zm2.054-2.053l-.64.64 1.414 1.413.64-.638a1 1 0 1 0-1.414-1.415z"
                                    />
                                </svg>
                            </i>
                            {{ 'FORM.MODIFIER' | translate }}
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div nz-row class="mb-5">
            <div nz-col nzSpan="12">
                <quill-editor
                    [modules]="{ toolbar: false }"
                    [readOnly]="true"
                    class="fiche-content"
                    [ngModel]="
                        activity.getTranslatedProperty(currentLang, 'description') ? activity.getTranslatedProperty(currentLang, 'description') : ('AUCUNE-DESC' | translate)
                    "
                ></quill-editor>
            </div>

            <div nz-col nzSpan="8" nzOffset="4" class="pl-3">
                <!-- LIEU -->
                <p class="page-template-labels mb-1">{{ 'FORM.LIEU' | translate }}</p>
                <p class="d-block">{{ activity.place ? activity.place : ('AUCUNE-DONNEE' | translate) }}</p>
                <!-- SALLE -->
                <p class="page-template-labels mb-1">{{ 'FORM.SALLE' | translate }}</p>
                <p class="d-block">{{ activity.room ? activity.room : ('AUCUNE-DONNEE' | translate) }}</p>
            </div>
        </div>
    </div>

    <div class="container-fluid bg-neutral py-5">
        <div class="container">
            <h3 class="mb-4">{{ 'SHOWCASES' | translate }}</h3>
            <ng-container *ngIf="activityVitrineList.length; else nothingToShow">
                <div class="item-card-grid">
                    <ng-container *ngFor="let vitrine of activityVitrineList">
                        <div class="item-card-wrapper">
                            <app-item-card [item]="vitrine" [itemRowType]="itemRow"></app-item-card>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #nothingToShow>
                <p>{{ 'AUCUNE-VITRINE-ACTIVITE' | translate }}</p>
            </ng-template>
        </div>
    </div>
</ng-container>
