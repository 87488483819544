<form id="organisation-step-2" class="mb-4" [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row>
        <div nz-col nzMd="18" formArrayName="disciplines">
            <label for="">
                <span>{{ 'FORM.DISCIPLINES' | translate }} *</span>
                <div class="mt-3">
                    <nz-form-item *ngFor="let discipline of controlDisciplinesArray.controls; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="col-10">
                                <nz-cascader
                                    id="{{ 'disciplines-' + i }}"
                                    nzPlaceHolder="{{ 'FORM.DISCIPLINES-SELECT' | translate }}"
                                    nzChangeOnSelect
                                    [nzExpandTrigger]="'hover'"
                                    [formControlName]="'cascade'"
                                    [nzOptions]="classificationsListCascade"
                                ></nz-cascader>
                            </div>
                            <div class="col-2">
                                <button
                                    class="btn btn--small d-inline-block"
                                    nzSize="large"
                                    nz-button
                                    (click)="removeDiscipline(i, $event)"
                                    nzType=""
                                    style="padding: 5px 12px 1px 11px; margin-top: 5px"
                                >
                                    <i class="icon-trash" style="font-size: 17px !important"></i>
                                </button>
                            </div>
                        </div>
                    </nz-form-item>
                </div>
            </label>
        </div>

        <div nz-col nzMd="18">
            <div class="row mb-3">
                <div class="col-10">
                    <nz-form-item *ngIf="classificationsListCascade.length > 0">
                        <nz-cascader
                            id="addDisciplines"
                            nzPlaceHolder="{{ 'FORM.DISCIPLINES-SELECT' | translate }}"
                            nzChangeOnSelect
                            [nzExpandTrigger]="'hover'"
                            [formControlName]="'discipline'"
                            [nzOptions]="classificationsListCascade"
                            (nzSelectionChange)="onAddChanges($event)"
                            (nzVisibleChange)="addNewClassification($event)"
                        ></nz-cascader>
                        <span class="has-error" *ngIf="displayErrors && show.classifications.length === 0">
                            {{ 'CHOISIR-UNE-DISCIPLINE' | translate }}
                        </span>
                    </nz-form-item>
                </div>
            </div>
        </div>
        <div nz-col nzMd="18" class="mb-5">
            <div formGroupName="audiences">
                <label>{{ 'FORM.PUBLIC-CIBLE' | translate }} *</label>
                <p class="has-error" *ngIf="formGroup.get('audiences').errors && formGroup.get('audiences').errors.hasOneOrManyRequiredError">
                    {{ 'CHOISIR-UNE-AUDIENCE' | translate }}
                </p>
                <sp-checkbox [classList]="['d-block', 'ml-0', 'mb-2']" formControlName="isAllPublic">
                    <strong>{{ 'FORM.GRAND_PUBLIC' | translate }}</strong>
                </sp-checkbox>
                <sp-checkbox [classList]="['d-block', 'ml-0', 'mb-2']" formControlName="isBabies">
                    <strong>{{ 'FORM.BEBES' | translate }}</strong>
                </sp-checkbox>
                <sp-checkbox [classList]="['d-block', 'ml-0', 'mb-2']" formControlName="isInfants">
                    <strong>{{ 'FORM.TOUT_PETITS' | translate }}</strong>
                </sp-checkbox>
                <sp-checkbox [classList]="['d-block', 'ml-0', 'mb-2']" formControlName="isSmallKids">
                    <strong>{{ 'FORM.PETIT_ENFANTS' | translate }}</strong>
                </sp-checkbox>
                <sp-checkbox [classList]="['d-block', 'ml-0', 'mb-2']" formControlName="isKids">
                    <strong>{{ 'FORM.ENFANTS' | translate }}</strong>
                </sp-checkbox>
                <sp-checkbox [classList]="['d-block', 'ml-0', 'mb-2']" formControlName="isYoung">
                    <strong>{{ 'FORM.JEUNES' | translate }}</strong>
                </sp-checkbox>
                <sp-checkbox [classList]="['d-block', 'ml-0', 'mb-2']" formControlName="isAdults">
                    <strong>{{ 'FORM.ADULTES' | translate }}</strong>
                </sp-checkbox>
                <sp-checkbox [classList]="['d-block', 'ml-0']" formControlName="isElders">
                    <strong>{{ 'FORM.AINES' | translate }}</strong>
                </sp-checkbox>
                <sp-checkbox [classList]="['d-block', 'ml-0']" formControlName="isFamily">
                    <strong>{{ 'FORM.FAMILLES' | translate }}</strong>
                </sp-checkbox>
            </div>
        </div>

        <div nz-col nzMd="18">
            <label class="d-block">{{ 'FORM.CONTENU' | translate }}</label>
            <div class="row mb-2" style="width: fit-content;" class>
                <div class="col">
            <sp-checkbox [classList]="['d-block']" formControlName="hasViolence">
                <strong>{{ 'FORM.VIOLENCE' | translate }}</strong>
            </sp-checkbox>
        </div>
        <div class="col">
            <sp-checkbox [classList]="['d-block']" formControlName="hasNudity">
                <strong>{{ 'FORM.NUDITE' | translate }}</strong>
            </sp-checkbox>
        </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="displaySaveForLater()">
        <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || formGroup.invalid" class="btn btn--primary">
            {{ 'ENREGISTER-CONTINUER' | translate }}
        </button>
        <p *appPermission="1; objectOrganizationId: show.organizationId" class="d-inline-block ml-2">
            {{ 'OU' | translate }}
            <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
            <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
        </p>
    </ng-container>
    <ng-container *ngIf="displaySubmit()">
        <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || formGroup.invalid" class="btn btn--primary">
            {{ 'ENREGISTER' | translate }}
        </button>
    </ng-container>
</form>
