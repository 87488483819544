<nz-form-item>
    <div class="row w-100">
        <div class="col-12 mb-1">
            <label [attr.for]="id">
                <ng-content></ng-content>
            </label>
        </div>
        <div class="col-12">
            <nz-form-control>
                <ng-content select="[prefix]"></ng-content>
                <nz-select
                    [attr.id]="id"
                    [nzShowSearch]="withSearch"
                    [nzAllowClear]="withClear"
                    [nzDisabled]="disabled"
                    [(ngModel)]="selected"
                    (ngModelChange)="ngModelChange()"
                    [nzPlaceHolder]="placeholder"
                >
                    <ng-container *ngIf="!hasGroups(); else elseGroupedBlock">
                        <div *ngIf="!optionTemplate; else elseCustomOptionBlock">
                            <nz-option
                                *ngFor="let option of options"
                                [nzLabel]="
                                    customLabel
                                        ? customLabel(option)
                                        : option.getTranslatedProperty
                                        ? option.getTranslatedProperty(translateService.currentLang, labelProperty)
                                        : option[labelProperty]
                                "
                                [nzValue]="option[valueProperty]"
                            ></nz-option>
                        </div>

                        <ng-template #elseCustomOptionBlock>
                            <nz-option
                                *ngFor="let option of options"
                                [nzLabel]="
                                    customLabel
                                        ? customLabel(option)
                                        : option.getTranslatedProperty
                                        ? option.getTranslatedProperty(translateService.currentLang, labelProperty)
                                        : option[labelProperty]
                                "
                                nzCustomContent
                                [nzValue]="option[valueProperty]"
                            >
                                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
                            </nz-option>
                        </ng-template>
                    </ng-container>

                    <ng-template #elseGroupedBlock>
                        <nz-option-group *ngFor="let kv of options | keyvalue" [nzLabel]="kv.key">
                            <nz-option
                                *ngFor="let option of kv.value"
                                [nzLabel]="
                                    customLabel
                                        ? customLabel(option)
                                        : option.getTranslatedProperty
                                        ? option.getTranslatedProperty(translateService.currentLang, labelProperty)
                                        : option[labelProperty]
                                "
                                [nzValue]="option[valueProperty]"
                            ></nz-option>
                        </nz-option-group>
                    </ng-template>
                </nz-select>
                <ng-content select="[suffix]"></ng-content>
            </nz-form-control>
        </div>
    </div>
</nz-form-item>
