import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { IMember } from 'src/app/concepts/organization/model/organization.model';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import { AccountService } from '../../../../concepts/account/services/account.service';
import { Globals } from '../../../../_configs/globals';
import { Permission } from '../../../enums/roles.enum';
import { RideauError } from '../../../model/error.model';
import { RideauNotificationService } from '../../../services/rideau-notification.service';

const SAVE_FORM_TKEY = 'FORM.SAUVEGARDE';

@Component({
    selector: 'app-item-row-member',
    templateUrl: './item-row-member.component.html',
    styleUrls: ['./item-row-member.component.scss']
})
export class ItemRowMemberComponent implements OnInit {
    @Input() itemLoopOptions: any;
    @Input() member: any;
    @Output() memberDeleted: EventEmitter<any> = new EventEmitter<any>();
    @Output() memberChangedRole: EventEmitter<any> = new EventEmitter<any>();

    isVisible = false;
    editable = false;

    constructor(
        public translate: TranslateService,
        private organizationService: OrganizationService,
        private modalService: NzModalService,
        private notification: RideauNotificationService,
        private globals: Globals,
        private accountService: AccountService,
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.member.user.avatar == '') {
            // image prop fallback
            this.member.user.avatar = this.globals.imgFallback;
            // this.member.user.avatar = './assets/images/notfound-square.png';
        }
        this.accountService.hasPermission(Permission.CAN_MANAGE).subscribe((res) => (this.editable = res));
    }

    changeOrganizationMemberRole(): void {
        // PUT SERVICE CALL
        this.organizationService.modifyOrganizationMembers(this.member as IMember).subscribe(() => {
            this.notification.success(this.translate.instant(SAVE_FORM_TKEY));
            this.memberChangedRole.emit();
        });
    }

    /*Modal*/
    showModal(): void {
        this.isVisible = true;
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    notifyForbiddenMemberDeletion(error: HttpErrorResponse): void {
        try {
            const { name, statusCode, message, stack } = error.error;
            const serverError: RideauError = new RideauError(name, statusCode, message, stack);
            if (name === 'Forbidden') {
                this.notification.error(this.translate.instant('ERRORS.' + 'FORBIDDEN-MEMBER-DELETION-MEETING'), '');
                console.error('Error : ', serverError);
            } else {
                throw error;
            }
        } catch (e) {
            this.notification.error(this.translate.instant('ERRORS.SERVER-ERROR'), '');
            console.error('Error : ', error);
        }
    }

    ConfirmRemoveOrganizationMember(memberId: number): void {
        this.modalService.confirm({
            nzContent: this.translate.instant('SUPPRIMER-MEMBRE-CONFIRMATION'),
            nzOkText: this.translate.instant('OUI'),
            nzCancelText: this.translate.instant('NON'),
            nzClosable: true,
            nzMaskClosable: true,
            // DELETE SERVICE CALL
            nzOnOk: () => {
                this.organizationService.getOrganizationMember(memberId).subscribe((member: IMember) => {
                    this.removeOrganizationMember(member, memberId);
                });
            }
        });
    }

    private removeOrganizationMember(member: IMember, memberId: any) {
        const isOwnUser = member.userId === Number(this.authService.User.id);

        // Endpoint is different if deleting yourself or an other member
        if (isOwnUser) {
            this.organizationService.removeUserFromOrganization(this.member.user.id, memberId).subscribe(
                () => {
                    this.accountService.getUserOrganizations();
                    this.organizationService.organizationsChanged.emit();
                    this.router.navigate(['/dashboard']);
                    this.notification.success(this.translate.instant(SAVE_FORM_TKEY));
                },
                (error: HttpErrorResponse) => this.notifyForbiddenMemberDeletion(error)
            );
        } else {
            this.organizationService.removeOrganizationMember(this.accountService.getCurrentCtxOrganizationId(), memberId).subscribe(
                () => {
                    this.memberDeleted.emit(memberId);
                    this.notification.success(this.translate.instant(SAVE_FORM_TKEY));
                },
                (error: HttpErrorResponse) => this.notifyForbiddenMemberDeletion(error)
            );
        }
        return isOwnUser;
    }
}
