import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToursService } from './services/tours.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tour } from './model/tour.model';
import { TourShow } from './model/tour-show.model';

@Injectable({ providedIn: 'root' })
export class TourShowResolver  {
  constructor(
    private readonly toursService: ToursService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TourShow> {
    return this.toursService.getTourShowById(+route.paramMap.get('tourShowId'));
  }
}
