import { MenuItem } from "@app/shared/components/layout/main-navigation/main-navigation-menu";

export const routes: MenuItem[] = [
    {
        link: '../general',
        label: 'RENSEIGNEMENTS-GENERAUX',
        classList: [ 'errorCount' ]
    },
    {
        link: '../disponibility',
        label: 'DISPONIBILITE',
        classList: [ 'errorCount' ]
    },
    {
        link: '../participants',
        label: 'PARTICIPANTS',
    },
    {
        link: '../vitrines',
        label: 'SHOWCASES',
    },
];
