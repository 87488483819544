<div nz-row nzGutter="16" class="px-3" [formGroup]="formGroup">
    <nz-form-item>
        <nz-form-control>
            <label for="title" class="d-block">{{ 'FORM.NUMERO-STAND' | translate }} *</label>
            <input id="title" nzSize="default" class="w-50" placeholder="A-123" formControlName="label" required />
            <span class="has-error" *ngIf="displayErrors && formGroup.controls['label'].errors">
                <div *ngIf="formGroup.controls['label'].errors['required']; else requiredField">
                    {{ 'FORM.CHAMPS-REQUIS' | translate }}
                </div>
                <ng-template #requiredField>
                    {{ 'ENTRER-VALEUR' | translate }}
                </ng-template>
            </span>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-control>
            <label for="cible" class="d-block">{{ 'DISPONIBILITE' | translate }} *</label>
            <nz-radio-group id="cible" formControlName="organizationTypeId">
                <label nz-radio class="input-radioBtn__radio mb-2" [nzValue]="allOrganizationsTypeId">{{ 'STAND-OPEN-TO-DIFFUSERS-AND-PRODUCERS' | translate }}</label>
                <label nz-radio class="input-radioBtn__radio mb-2" [nzValue]="diffuserTypeId">{{ 'STAND-RESERVED-FOR-DIFFUSERS' | translate }}</label>
                <label nz-radio class="input-radioBtn__radio mb-2" [nzValue]="producerTypeId">{{ 'STAND-RESERVED-FOR-PRODUCERS' | translate }}</label>
            </nz-radio-group>
            <span class="has-error" *ngIf="displayErrors && formGroup.controls['organizationTypeId'].errors">
                {{ 'ENTRER-VALEUR' | translate }}
            </span>
        </nz-form-control>
    </nz-form-item>
    <ng-container *ngIf="organizationList$ | async as organizationList; else loadingTpl">
        <nz-form-item class="w-100">
            <nz-form-control>
                <label for="assignment" class="d-block">{{ 'ATTRIBUE-A' | translate }}</label>
                <nz-select name="assignmentSelect" id="assignment" nzShowSearch nzAllowClear style="width: 100%" formControlName="organizationId">
                    <nz-option *ngFor="let org of organizationList" [nzLabel]="org.getTranslatedProperty(currentLang, 'name')" [nzValue]="org.id"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </ng-container>
</div>
<ng-template #loadingTpl>
    <div class="d-flex justify-content-center">
        <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
    </div>
</ng-template>
