import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NotificationAction, NotificationContent, Notification, NotificationContentBuilder } from '../../model/notifications.model';
import { TranslateService } from '@ngx-translate/core';
import { TimeagoIntl } from 'ngx-timeago';
import { strings as englishStrings } from 'ngx-timeago/language-strings/en';
import { strings as frenchStrings } from 'ngx-timeago/language-strings/fr-short';
import { NotificationStatus, NotificationType } from '../../enums/notifications-status.enum';
import { Globals } from 'src/app/_configs/globals';
import { Router } from '@angular/router';
import { AccountService } from '../../../account/services/account.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Show } from 'src/app/concepts/show/model/show.model';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { Venue } from 'src/app/concepts/venue/model/venue.model';
import { Tour } from 'src/app/concepts/tours/model/tour.model';
import { ToursService } from 'src/app/concepts/tours/services/tours.service';
import { ProgramService } from 'src/app/concepts/program/services/program.services';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Program } from 'src/app/concepts/program/model/program.model';
import { getTimestampFromDate } from 'src/app/shared/utils/time-utils';
import { Promotion } from '@app/concepts/promotion/model/promotion.model';
@Component({
    selector: 'app-notifications-single',
    templateUrl: './notifications-single.component.html',
    styleUrls: [ './notifications-single.component.scss' ]
})
export class NotificationsSingleComponent implements OnInit, OnDestroy {
    @Input() notification: Notification;
    @Input() hideArchiveBtn: boolean;

    @Output() onArchiveNotification: EventEmitter<any> = new EventEmitter();

    live = false;
    currentLang: string;
    notificationContent: NotificationContent;
    currentOrganizationId: number;
    public readonly notificationStatusEnum = NotificationStatus;
    public readonly notificationTypeEnum = NotificationType;
    private readonly notificationAction = NotificationAction;
    private readonly destroyed: Subject<void> = new Subject<void>();
    private readonly initialNotificationContent: NotificationContent = { type: '', name: '', image: 'hello', org: '' };

    constructor(
        intl: TimeagoIntl,
        private globals: Globals,
        private router: Router,
        public translate: TranslateService,
        public tourService: ToursService,
        public programService: ProgramService,
        private accountService: AccountService,
        private readonly localizeRouterService: LocalizeRouterService
    ) {
        this.currentLang = translate.currentLang;
        intl.strings = this.currentLang === 'fr' ? frenchStrings : englishStrings;
        intl.changes.next();
    }

    get isActionId(): boolean {
        return this.notification.actionId !== null && this.notification.actionId !== undefined;
    }

    get actionLabel(): string {
        return this.notification.getActionLabel();
    }

    get statusClassName(): string {
        return this.notification.getStatusData() ? this.notification.getStatusData().className : 'is-info';
    }

    get statusMessage(): string {
        const statusMessage = this.notification.getStatusData();
        if (statusMessage && statusMessage.message && statusMessage.message.trans) {
            return `${this.translate.instant(statusMessage.message.trans)}${statusMessage.message.date ? ' ' + statusMessage.message.date : ''} `;
        }
        return '';
    }

    get notificationType(): NotificationType {
        return this.notification.type;
    }

    public get isNotificationStatusInfo(): boolean {
        return this.notification.statusId === this.notificationStatusEnum.INFO;
    }
    public get isNotificationStatusApproved(): boolean {
        return this.notification.statusId === this.notificationStatusEnum.APPROVED;
    }
    public get isNotificationStatusRefused(): boolean {
        return this.notification.statusId === this.notificationStatusEnum.REFUSED;
    }
    public get isNotificationStatusOutdated(): boolean {
        return this.notification.statusId === this.notificationStatusEnum.OUTDATED;
    }
    public get isNotificationStatusConfirmed(): boolean {
        return this.notification.statusId === this.notificationStatusEnum.CONFIRMED;
    }

    ngOnInit(): void {
        this.notificationContent = this.getNotificationContentByType();
        this.currentLang = this.translate.currentLang;
        this.currentOrganizationId = this.accountService.getCurrentCtxOrganizationId();
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    getNotificationContentByType(): NotificationContent {
        switch (this.notificationType) {
            case NotificationType.WELCOME:
                return this.getNotificationContentForWelcome();
            case NotificationType.ORGANIZATION:
                return this.getNotificationContentForOrganization();
            case NotificationType.VENUE:
                return this.getNotificationContentForVenue();
            case NotificationType.SHOW:
                return this.getNotificationContentForShow();
            case NotificationType.PROMOTION:
                return this.getNotificationContentForPromotion();
            case NotificationType.PROGRAM:
                return this.getNotificationContentForProgram();
            case NotificationType.TOUR:
                return this.getNotificationContentForTour();
            case NotificationType.DATE:
                return this.getNotificationContentForDate();
            default:
                return new NotificationContentBuilder(this.initialNotificationContent).build();
        }
    }

    public getNotificationContentByTypeProperty = (property: string): string => this.notificationContent[ property ];

    notificationActionHandler(): void {
        if (this.notification.type === NotificationType.TOUR) {
            this.router.navigate([ this.localizeRouterService.translateRoute(`/programmations/tour/${this.notification.tourId}`) ]);
        } else {
            switch (this.notification.actionId) {
                case this.notificationAction.CREATE:
                    {
                        // redirect to profil/create org
                        const profile = this.translate.instant('ROUTES.profile');
                        const organizations = this.translate.instant('ROUTES.organisations');
                        const createLink = `/${this.currentLang}/${profile}/${organizations}/`;
                        this.router.navigate([ createLink ]);
                    }
                    break;
                case this.notificationAction.RENEW:
                    if (this.notification.show) {
                        const showRoute = this.translate.instant('ROUTES.shows-offers');
                        const showLink = `/${this.currentLang}/${showRoute}/${this.currentOrganizationId}/${this.notification.show.id}`;
                        this.router.navigate([ showLink ]);
                    } else if (this.notification.venue) {
                        // venue id / edit link
                        const venueRoute = this.translate.instant('ROUTES.venue');
                        const modify = this.translate.instant('ROUTES.modify');
                        const venueLink = `/${this.currentLang}/${venueRoute}/${this.notification.venue.organizationId}/${this.notification.venue.id}`;
                        this.router.navigate([ venueLink ]);
                    } else if (this.notification.promotion) {
                        // venue id / edit link
                        const promotionRoute = this.translate.instant('ROUTES.shows-offers');
                        const promotionLink = `/${this.currentLang}/${promotionRoute}/${this.currentOrganizationId}/${this.notification.promotion.show.id}`;
                        this.router.navigate([ promotionLink ]);
                    }
                    break;
                case this.notificationAction.VALIDATE:
                    {
                        // lien ver programmation: example https://sit.app.scenepro.ca/fr/programmations/149 et ID : notification.programId
                        const programRoute = this.translate.instant('ROUTES.program');
                        const programLink = `/${this.currentLang}/${programRoute}/${this.notification.programId}`;
                        this.router.navigate([ programLink ]);
                    }
                    break;
                case this.notificationAction.ACCESS:
                    // lien vers programmation: example https://sit.app.scenepro.ca/fr/programmations/tournée/69/offre-de-spectacle/204/?noTourDate=1 et ID : notification.programId

                    this.programService
                        .getSingleProgram(this.notification.programId)
                        .pipe(
                            map((program: Program): Date => program.date),
                            takeUntil(this.destroyed)
                        )
                        .subscribe((programDate: Date) => {
                            const programRouteTrans = this.translate.instant('ROUTES.program');
                            const programLink = `/${this.currentLang}/${programRouteTrans}/`;
                            // * /fr/programmations?nonTourDates=1
                            this.router.navigate([ programLink ], {
                                queryParams: {
                                    date: getTimestampFromDate(programDate)
                                },
                                queryParamsHandling: 'merge'
                            });
                        });
                    break;
            }
        }
    }

    archiveNotification(): void {
        this.onArchiveNotification.emit(this.notification.id);
    }

    private getNotificationContentForOrganization() {
        const org = new Organization(this.notification.organization);
        const nameTranslation = `${this.translate.instant('NOTIFICATIONS.VOTRE')} ${this.translate.instant('NOTIFICATIONS.ORGANIZATION')} <b>${org.getTranslatedProperty(
            this.currentLang,
            'name'
        )}</b> ${this.translate.instant(this.notification.getStringFromStatusId(org.statusId))}`;
        return new NotificationContentBuilder(this.initialNotificationContent)
            .setType(this.translate.instant('NOTIFICATIONS.ORGANIZATION'))
            .setName(nameTranslation)
            .setImage(org.logo)
            .build();
    }

    private getNotificationContentForWelcome() {
        return new NotificationContentBuilder(this.initialNotificationContent)
            .setImage(this.globals.notifWelcome)
            .setName(this.translate.instant('NOTIFICATIONS.BIENVENUE'))
            .build();
    }

    private getNotificationContentForVenue() {
        const venue = new Venue(this.notification.venue);
        return new NotificationContentBuilder(this.initialNotificationContent)
            .setImage(venue.photo)
            .setName(
                `${this.translate.instant('NOTIFICATIONS.VOTRE')} ${this.translate.instant('NOTIFICATIONS.VENUE')} <b>${venue.getTranslatedProperty(
                    this.currentLang,
                    'name'
                )}</b> ${this.translate.instant(this.notification.getStringFromStatusId(venue.statusId))}${this.isNotificationStatusOutdated && this.notification.deadline ? ' ' + Globals.getLocaleString(this.notification.deadline) : ''
                }`
            )
            .setType(this.translate.instant('NOTIFICATIONS.VENUE'))
            .build();
    }

    private getNotificationContentForShow() {
        const show = new Show(this.notification.show);
        const nameTranslation = `${this.translate.instant('NOTIFICATIONS.VOTRE')} ${this.translate.instant('NOTIFICATIONS.SHOW-OFFER-MIN')} <b>${show.getTranslatedProperty(
            this.currentLang,
            'title'
        )}</b> ${this.translate.instant(this.notification.getStringFromStatusId(show.statusId))}`;
        const expiredDateTranslation = this.isNotificationStatusOutdated && this.notification.deadline ? ' ' + Globals.getLocaleString(this.notification.deadline) : '';
        return new NotificationContentBuilder(this.initialNotificationContent)
            .setType(this.translate.instant('NOTIFICATIONS.SHOW-OFFER-MIN'))
            .setName(`${nameTranslation} ${expiredDateTranslation}`)
            .setImage(show.image)
            .build();
    }

    private getNotificationContentForPromotion() {
        // Create a Promotion object using data from the notification.
        const promotion = new Promotion(this.notification.promotion);
        // Calculate the expiration date for the promotion.
        const expirationDate = this.notification.getExpirationDate('month');
        // Determine the notification deadline date based on the status and deadline.
        const date =
            !!this.isNotificationStatusOutdated && !!promotion.deadline
                ? ' ' + Globals.getLocaleString(this.notification.deadline)
                : Globals.getLocaleString(expirationDate.toISOString());
        // Construct the notification content by combining various translations and data.
        const translation = `${this.translate.instant('NOTIFICATIONS.VOTRE')} ${this.translate.instant('NOTIFICATIONS.PROMOTION')} <b>${promotion.show.getTranslatedProperty(
            this.currentLang,
            'title'
        )}</b> ${this.translate.instant(this.notification.getStringFromStatusId(promotion.statusId))}${date}`;
        // Create a new NotificationContentBuilder with an initial content and set properties.
        return new NotificationContentBuilder(this.initialNotificationContent)
            .setImage(promotion.show.image)
            .setName(translation)
            .setType(this.translate.instant('NOTIFICATIONS.PROMOTION'))
            .build();
    }

    private getNotificationContentForProgram() {
        const organization = new Organization(this.notification.organization);
        const show = new Show(this.notification.show);
        return new NotificationContentBuilder(this.initialNotificationContent)
            .setImage(show.image)
            .setName(show.getTranslatedProperty(this.currentLang, 'title'))
            .setType(this.translate.instant('NOTIFICATIONS.PROGRAM'))
            .setOrg(organization.getTranslatedProperty(this.currentLang, 'name'))
            .build();
    }

    private getNotificationContentForTour() {
        const organization = new Organization(this.notification.organization);
        const tour = new Tour(this.notification.tour);
        return new NotificationContentBuilder(this.initialNotificationContent)
            .setImage(organization.logo)
            .setType(
                this.translate.instant('NOTIFICATIONS.TOUR', {
                    tour: tour.getTranslatedProperty(this.currentLang, 'name'),
                    organization: organization.getTranslatedProperty(this.currentLang, 'name')
                })
            )
            .build();
    }

    private getNotificationContentForDate() {
        let notificationContent = new NotificationContentBuilder(this.initialNotificationContent);
        if (this.notification.tour) {
            const show = new Show(this.notification.show);
            const organization = new Organization(this.notification.tourAdmin);
            const tour = new Tour(this.notification.tour);
            return notificationContent
                .setImage(show.image)
                .setType(
                    this.translate.instant('NOTIFICATIONS.CONFIRMED-TOUR-DATE', {
                        tour: tour.getTranslatedProperty(this.currentLang, 'name'),
                        organization: organization.getTranslatedProperty(this.currentLang, 'name'),
                        show: show.getTranslatedProperty(this.currentLang, 'title')
                    })
                )
                .build();
        }
        return notificationContent;
    }
}
