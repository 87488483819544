<ng-container>
    <app-page-title [title]="promotion.getTitle(lang) || 'NOUVELLE-PROMOTION' | translate" [permission]="1"></app-page-title>
    <div nz-row class="mb-4">
        <div nz-col nzMd="12" nzOffset="6">
            <div class="approval-box" *ngIf="promotion.statusId === 2">
                <p>{{ 'PROMOTION-APPROVAL-STATE' | translate }}</p>
                <div>
                    <button *appPermission="4" (click)="reject()" nz-button class="btn btn--default rejected mr-md-3">
                        {{ 'REJETER' | translate }}
                    </button>

                    <button *appPermission="4" (click)="approve()" nz-button class="btn btn--default approved">
                        {{ 'APPROUVER' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div nz-row class="mb-4">
        <div nz-col nzMd="8" nzOffset="6">
            <!-- STATUS -->
            <span *ngIf="promotion.statusId === 1" class="rect">{{ 'STATUS.NON_SOUMISE' | translate }}</span>
            <span *ngIf="promotion.statusId === 2" class="rect">{{ 'EN-ATTENTE-APPROBATION' | translate }}</span>
            <span *ngIf="promotion.statusId === 3" class="rect">{{ 'STATUS.APPROUVEE' | translate }}</span>
            <span *ngIf="promotion.statusId === 4" class="rect">{{ 'STATUS.REJETEE' | translate }}</span>
            <span *ngIf="promotion.statusId === 5" class="rect">{{ 'STATUS.OUTDATED' | translate }}</span>
        </div>
    </div>

    <form nz-form *ngIf="isReady" [formGroup]="formGroup" (ngSubmit)="doSubmit()">
        <div nz-row>
            <div nz-col nzMd="10" nzOffset="6">
                <div nz-row class="selectors">
                    <div nz-col>
                        <nz-form-item>
                            <nz-form-control>
                                <label for="selected-show" class="mb-2">{{ 'FORM.SPECTACLE' | translate }} *</label>
                                <nz-select id="selected-show" nzPlaceHolder="{{ 'FORM.SELECTIONNER' | translate }}" style="width: 100%" required formControlName="selectedShow">
                                    <nz-option *ngFor="let show of shows" nzCustomContent [nzLabel]="show.getTranslatedProperty(lang, 'title')" [nzValue]="show">
                                        {{ show.getTranslatedProperty(lang, 'title') }}
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>

                        <span class="has-error" *ngIf="formGroup.get('selectedShow').invalid && (formGroup.get('selectedShow').dirty || formGroup.get('selectedShow').touched)">
                            {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzOffset="6" nzMd="8">
                <div nz-row nzGutter="16">
                    <div nz-col nzMd="8">
                        <nz-form-item>
                            <nz-form-control>
                                <label for="date-from">
                                    {{ 'FORM.DATE-DEBUT' | translate }} *
                                    <nz-date-picker
                                        id="date-from"
                                        class="d-block"
                                        nzAllowClear="true"
                                        nzFormat="yyyy/MM/dd"
                                        nzPlaceHolder="yyyy/MM/dd"
                                        formControlName="dateFrom"
                                    ></nz-date-picker>
                                </label>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzMd="8">
                        <nz-form-item>
                            <nz-form-control>
                                <label class="d-block">{{ 'FORM.DUREE' | translate }} *</label>
                                <nz-select nzShowSearch nzAllowClear [nzDisabled]="true" formControlName="monthsNumber">
                                    <nz-option nzLabel="1 mois" nzValue="1"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div *ngIf="isCreation" nz-col nzMd="8">
                        <nz-form-item>
                            <nz-form-control>
                                <label for="prix" class="d-block">{{ 'FORM.PRIX' | translate }}</label>
                                <div id="prix" class="prix">{{ showPrice() }}</div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzOffset="6" nzMd="10">
                <label for="item-card-grid" class="d-block mb-2">{{ 'FORM.APERCU' | translate }}</label>
                <ng-container>
                    <!-- CARDS VIEW -->
                    <div class="item-card-grid">
                        <ng-container id="item-card-grid" *ngFor="let item of itemList">
                            <div class="item-card-wrapper">
                                <app-item-card [item]="item" [itemRowType]="itemRow"></app-item-card>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <div nz-col nzSpan="20">
            <div *ngIf="isLoading" class="loader">
                <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
            </div>
        </div>

        <div class="container-fluid bg-gray py-5">
            <div class="container">
                <div nz-row class="mb-5" nzGutter="16">
                    <!-- Boutons si publication non soumise(1) ou rejetée(4)  -->
                    <ng-container>
                        <div class="d-flex justify-content-end" style="margin-right: 180px">
                            <button class="btn-rect px-3 d-inline-block mr-md-3" style="padding-top: 7px" (click)="dismiss()">
                                {{ 'ANNULER' | translate }}
                            </button>

                            <button *appPermission="1" nz-button [disabled]="!formGroup.valid" class="btn btn--default">
                                {{ (isCreation ? 'ENREGISTER-ET-PAYER' : 'ENREGISTER') | translate }}
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-container>
