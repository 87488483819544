import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'sp-simple-select',
    templateUrl: './sp-simple-select.component.html',
    styleUrls: []
})
export class SpSimpleSelectComponent {
    @Input() label: string;
    @Input() options: Array<{ id: any; label: string }>;
    @Input() control: UntypedFormControl;
}
