import { Globals } from 'src/app/_configs/globals';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MembershipService } from '../../services/membership.service';
import { MembershipConfig } from './../../model/membership-config.model';
import { TranslateService } from '@ngx-translate/core';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';

@Component({
  selector: 'app-membership-configuration',
  templateUrl: './membership-configuration.component.html',
  styleUrls: ['./membership-configuration.component.scss'],
})
export class MembershipConfigurationComponent implements OnInit {
  membershipConfig: MembershipConfig;

  membershipConfigForm: UntypedFormGroup;

  constructor(
    private membershipService: MembershipService,
    private translate: TranslateService,
    private accountService: AccountService,
    private notification: RideauNotificationService,
    private globals: Globals,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.membershipService
      .getMembershipConfiguration(this.globals.RIDEAU_ORGID)
      .subscribe((res) => {
        this.membershipConfig = res;
        this.membershipConfigForm = this.membershipConfig.getMembershipConfigForm(
          this.fb
        );
      });
  }

  saveFinance(): void {
    const body = new MembershipConfig(this.membershipConfigForm.value);
    body.organizationHeadId = this.globals.RIDEAU_ORGID;
    this.membershipService.updateMembershipConfiguration(body).subscribe(() => {
      this.notification.success(
        this.translate.instant('FORM.CONFIG.ENREGISTREE')
      );
    });
  }
}
