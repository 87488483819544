<ng-container>
    <app-page-title [title]="publication.getTranslatedProperty(lang, 'title')" [permission]="1"></app-page-title>
    <div nz-row class="mb-4">
        <div nz-col nzMd="12" nzOffset="6">
            <div class="approval-box" *ngIf="publication.statusId === 2">
                <p>{{ 'PUBLICATION-APPROVAL-STATE' | translate }}</p>
                <div>
                    <button *appPermission="4" (click)="reject()" nz-button class="btn btn--default rejected mr-md-3">
                        {{ 'REJETER' | translate }}
                    </button>

                    <button *appPermission="4" (click)="approve()" nz-button class="btn btn--default approved">
                        {{ 'APPROUVER' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div nz-row class="mb-4">
        <div nz-col nzMd="8" nzOffset="6">
            <!-- STATUS -->
            <span *ngIf="publication.statusId === 1" class="rect">{{ 'STATUS.NON_SOUMISE' | translate }}</span>
            <span *ngIf="publication.statusId === 2" class="rect">{{ 'EN-ATTENTE-APPROBATION' | translate }}</span>
            <span *ngIf="publication.statusId === 3" class="rect">{{ 'STATUS.APPROUVEE' | translate }}</span>
            <span *ngIf="publication.statusId === 4" class="rect">{{ 'STATUS.REJETEE' | translate }}</span>
            <span *ngIf="publication.statusId === 5" class="rect">{{ 'STATUS.OUTDATED' | translate }}</span>
        </div>
    </div>

    <form nz-form *ngIf="isReady" [formGroup]="formGroup" (ngSubmit)="doSubmit()">
        <div nz-row>
            <div nz-col nzMd="8" nzOffset="6">
                <nz-form-item *ngIf="isScenePro">
                    <nz-form-control>
                        <label for="organizationName">
                            {{ 'ORGANISATION-PROPRIETAIRE' | translate }}
                            <input nz-input id="organizationName" formControlName="organizationName" disabled />
                        </label>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control>
                        <label for="title">
                            {{ 'FORM.TITRE' | translate }} *
                            <input nz-input id="title" formControlName="title" />
                        </label>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-control>
                        <label for="urlVideo">
                            {{ 'FORM.LIEN' | translate }} *
                            <input nz-input formControlName="website" />
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-col nzMd="6" class="ml-4 d-flex flex-column">
                <label class="d-inline-block mb-1" for="publication-image">{{ 'FORM.IMAGE-VEDETTE' | translate }} *</label>
                <ng-container *ngIf="hasAvatar; else noAvatar">
                    <div class="avatar-upload avatar-upload--before ant-upload ant-upload-select ant-upload-select-picture-card">
                        <img *ngIf="publication.image" id="publication-image" [src]="publication.image" class="avatar w-100" alt="" />
                        <i class="avatar-upload__remove icon-trash" (click)="removeAvatar()" aria-hidden="true"></i>
                    </div>
                </ng-container>

                <ng-template #noAvatar>
                    <app-img-upload
                        [imageTitle]="'FORM.PHOTO-DE-PROFIL' | translate"
                        [maxCropperWidth]="imageConfig.maxWidth"
                        [maxCropperHeight]="imageConfig.maxHeight"
                        [currentImage]="publication.image"
                        [uploadAction]="uploadAction"
                        uploadActionName="publicationmain"
                        [maxFileWeigth]="1000"
                        (onUploadFile)="catchImageUrl($event)"
                    ></app-img-upload>
                </ng-template>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzOffset="6" nzMd="8">
                <nz-form-item>
                    <nz-form-control>
                        <label>
                            {{ 'FORM.DESCRIPTION' | translate }} *
                            <textarea row="8" nz-input style="height: 170px" formControlName="description" maxlength="300"></textarea>
                        </label>
                    </nz-form-control>
                </nz-form-item>

                <div nz-row nzGutter="16">
                    <div nz-col nzMd="8">
                        <nz-form-item>
                            <nz-form-control>
                                <label>
                                    {{ 'FORM.DATE-DEBUT' | translate }} *
                                    <nz-date-picker
                                        class="d-flex"
                                        nzAllowClear="true"
                                        nzFormat="yyyy/MM/dd"
                                        nzPlaceHolder="yyyy/MM/dd"
                                        formControlName="dateFrom"
                                        [nzDisabled]="isPublicationPaid()"
                                    ></nz-date-picker>
                                </label>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzMd="8">
                        <nz-form-item>
                            <nz-form-control>
                                <label class="d-block">{{ 'FORM.DUREE' | translate }} *</label>
                                <nz-select nzShowSearch nzAllowClear formControlName="weeksNumber" [nzDisabled]="isPublicationPaid()">
                                    <nz-option nzLabel="1 semaine" nzValue="1"></nz-option>
                                    <nz-option nzLabel="2 semaines" nzValue="2"></nz-option>
                                    <nz-option nzLabel="3 semaines" nzValue="3"></nz-option>
                                    <nz-option nzLabel="4 semaines" nzValue="4"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div *ngIf="isCreation" nz-col nzMd="8">
                        <nz-form-item>
                            <nz-form-control>
                                <label class="d-block">{{ 'FORM.PRIX' | translate }}</label>
                                <div class="prix">{{ showPrice() }}</div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <ng-container *ngIf="isDateLessThanTwoDays()">
                    <div nz-row>
                        <div class="d-flex align-items-center mb-4" style="width: auto">
                            <i nz-icon aria-hidden="true" nzType="warning" nzTheme="outline" style="font-size: x-large; color: #ef3778"></i>
                            <div class="warning">
                                <p class="warning__title">
                                    {{ 'FORM.WARNING-PUBLICATION' | translate }}
                                </p>
                                <p class="warning__subtitle">
                                    {{ 'FORM.WARNING-PUB-EXPLICATION' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div nz-row class="mb-4">
            <div nz-col nzOffset="6" nzMd="12">
                <label class="d-block mb-2">{{ 'FORM.APERCU' | translate }}</label>
                <div class="apercu-border d-flex apercu-rempli">
                    <img class="pub-image" src="{{ imageUrl ? imageUrl : defaultImage }}" alt="" />

                    <div class="pl-4 py-3 pr-3" style="overflow: hidden; max-height: 170px">
                        <p style="color: blue; margin-bottom: auto; font-weight: bold">
                            {{ formGroup.get('title').value }}
                        </p>
                        <p style="white-space: pre-line; margin-bottom: 0">{{ formGroup.get('description').value | shortenText: 300 }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid bg-gray py-5">
            <div class="container">
                <div nz-row class="mb-5 d-flex justify-content-end" nzGutter="16">
                    <!-- Boutons si publication non soumise(1) ou rejetée(4)  -->
                    <ng-container>
                        <div class="d-flex justify-content-end" style="margin-right: 180px">
                            <button class="btn-rect px-3 d-inline-block mr-md-3" style="padding-top: 7px" (click)="dismiss()">
                                {{ 'ANNULER' | translate }}
                            </button>

                            <button *appPermission="1" nz-button [disabled]="formGroup.invalid" class="btn btn--default">
                                {{ (isCreation ? 'ENREGISTER-ET-PAYER' : 'ENREGISTER') | translate }}
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</ng-container>
