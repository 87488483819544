import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicationFormComponent } from './components/publication-form/publication-form.component';
import { PublicationListComponent } from './components/publication-list/publication-list.component';
import { MyPublicationsComponent } from './components/my-publications/my-publications.component';
import { MyPublicationsLittleComponent } from './components/my-publications-little/my-publications-little.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [PublicationFormComponent, PublicationListComponent, MyPublicationsComponent, MyPublicationsLittleComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, LocalizeRouterModule, RouterModule, SharedModule],
    exports: [MyPublicationsComponent, MyPublicationsLittleComponent]
})
export class PublicationModule {}
