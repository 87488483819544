export interface MenuItem {
    link: string;
    label: string;
    classList?: string[];
}

export const menuItems: MenuItem[] = [
    {
        link: '/admin/etiquettes',
        label: 'ETIQUETTES'
    },
    {
        link: '/admin/product',
        label: 'PRODUITS'
    },
    {
        link: '/admin/publication',
        label: 'PUBLICATIONS'
    },
    {
        link: '/admin/promotion',
        label: 'PROMOTIONS'
    },
    {
        link: '/admin/membership/billing',
        label: 'COTISATIONS'
    },
    {
        link: '/admin/reports',
        label: 'RAPPORTS'
    },
    {
        link: '/admin/users',
        label: 'USERS'
    },
    {
        link: '/admin/tour',
        label: 'DEPOT-RESEAUX'
    }
];
