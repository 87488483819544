import { ProductModule } from './../product/product.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipConfigurationComponent } from './components/membership-configuration/membership-configuration.component';
import { MembershipBillingListComponent } from './components/membership-billing/membership-billing-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { AccountModule } from '../account/account.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { LightboxModule } from 'ngx-lightbox';
import { MembershipComponent } from './components/membership.component';
import { MembershipBillingItemComponent } from './components/membership-billing/membership-billing-item/membership-billing-item.component';

@NgModule({
    declarations: [MembershipComponent, MembershipConfigurationComponent, MembershipBillingListComponent, MembershipBillingItemComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        LocalizeRouterModule,
        AccountModule,
        SharedModule,
        LightboxModule,
        ProductModule,
        NzSelectModule
    ],
    exports: [MembershipComponent, MembershipConfigurationComponent, MembershipBillingListComponent, MembershipBillingItemComponent]
})
export class MembershipModule {}
