<app-page-title title="{{ 'NOTIFICATIONS.NOTIFICATIONS' | translate }}"></app-page-title>

<div class="notification-center container mb-5">
    <div class="row justify-content-center mb-5">
        <!-- FILTERS  -->
        <div class="col col-sm-3">
            <div *ngFor="let filter of notificationFilters">
                <div class="list-filters__box">
                    <span *ngIf="filter.filterName" class="list-filters__box__title">{{ filter.filterName }}</span>
                    <sp-menu>
                        <ng-container *ngFor="let filterVal of filter.filterValues; index as i">
                            <sp-menu-item
                            [selected]="filterVal.selected"
                            (clicked)="onSelectFilter(filter.filterField, filterVal, i)">
                                <span class="mr-3">{{ filterVal.filterValueName }}</span>
                            </sp-menu-item>
                        </ng-container>
                    </sp-menu>
                </div>
            </div>

            <p class="suppressMessage">{{ 'ARCHIVE-SUPPRESS-MESSAGE' | translate }}</p>
        </div>
        <!-- NOTIFICATIONS LIST -->

        <div class="col col-sm-9">
            <ng-container *ngIf="isReady; else loadingTpl">
                <app-notifications-list
                    [headerEnabled]="true"
                    [headerTotal]="pagination.getTotal()"
                    [notifications]="notifications"
                    [hideArchiveBtn]="hideArchiveBtn"
                    (archiveItem)="archiveNotifications($event)"
                    (archiveAll)="archiveNotifications()"
                ></app-notifications-list>

                <!-- PAGINATION -->
                <nz-pagination
                    [nzPageIndex]="pagination.getPage()"
                    [nzTotal]="pagination.getTotal()"
                    [nzPageSize]="pagination.limit"
                    [nzItemRender]="renderItemTemplate"
                    (nzPageIndexChange)="onPageChange($event)"
                ></nz-pagination>
                <ng-template #renderItemTemplate let-type let-page="page">
                    <a class="precedent_suivant {{ pagination.getPage() == 1 ? 'disabled' : '' }}" *ngIf="type === 'pre'">
                        <i style="padding-right: 5px" nz-icon aria-hidden="true" nzType="left" nzTheme="outline"></i>
                        {{ 'PRECEDENT' | translate }}
                    </a>
                    <a class="precedent_suivant" *ngIf="type === 'next'">
                        {{ 'SUIVANT' | translate }}
                        <i style="padding-left: 5px" nz-icon aria-hidden="true" nzType="right" nzTheme="outline"></i>
                    </a>
                    <a *ngIf="type === 'page'">{{ page }}</a>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #loadingTpl>
    <div class="loader">
        <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
    </div>
</ng-template>
