import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { MainService } from 'src/app/shared/services/main.services';
import { Jury } from '../model/jury.model';
import { ParticipantMeeting } from '../model/participant.model';
import { Pagination, SelectedFilter } from './../../../shared/model/list-item.model';
import { Globals } from './../../../_configs/globals';
import { AccountService } from './../../account/services/account.service';
import { Meeting } from './../model/meeting.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MeetingService extends MainService {
    constructor(
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private accountService: AccountService,
        private translate: TranslateService,
        protected httpClient: HttpClient,
        private authService: AuthService,
        protected globals: Globals,
        private router: Router,
    ) {
        super(httpClient, globals);
    }

    getAllMeetings(filters?: SelectedFilter[], pagination?: Pagination, searchText?: string, withOrg?: boolean): Observable<Meeting[]> {
        let endpoint = this.uri + this.globals.endpoints.meeting.all;

        // add context organization
        const contextOrgId = this.accountService.getCurrentCtxOrganizationId();
        endpoint += `?contextOrgId=${contextOrgId}`;

        if (searchText) {
            endpoint += `&autocomplete=1&name=${encodeURIComponent(searchText)}`;
        }
        if (filters && filters.length) {
            endpoint += filters[0].value == 1 ? '&' : '';
            endpoint += this.formatGetFilters(filters);
        }
        if (pagination) {
            endpoint += '&' + this.formatPagination(pagination);
        }
        if (withOrg) {
            endpoint += `&withOrg=${withOrg ? '1' : '0'}`;
        }

        return this.httpClient.get(endpoint).map((res) => res['meetings'].map((meeting) => new Meeting(meeting)));
    }

    getAllMyParticipatedMeetings(): Observable<Meeting[]> {
        let endpoint = this.uri + this.globals.endpoints.meeting.myparticipated;

        // add context organization
        const contextOrgId = this.accountService.getCurrentCtxOrganizationId();
        endpoint += `?contextOrgId=${contextOrgId}`;

        return this.httpClient.get(endpoint).map((res) => res['meetings'].map((meeting) => new Meeting(meeting)));
    }

    getMeetingById(id: number): Observable<Meeting> {
        const endpoint = this.uri + this.globals.endpoints.meeting.getMeetingEndpoint(id);
        return this.httpClient.get(endpoint).map((res) => new Meeting(res));
    }

    createMeetingAndRedirectToForm(): void {
        const endpoint = this.uri + this.globals.endpoints.meeting.main;

        const meeting = new Meeting({
            organizationId: this.accountService.getCurrentCtxOrganizationId()
        });
        meeting.setTranslatedProperty(this.translate.currentLang, 'name', this.translate.instant('NOUVELLE-RENCONTRE-PRO'));

        this.httpClient.post(endpoint, this.cleanUpNullValues(meeting)).subscribe((res) => {
            const url = this.localizeRouter.translateRoute(`/pro-meeting/${res['meeting']['id']}/modify`);
            this.router.navigate([url]);
        });
    }

    modifyMeeting(meeting: Meeting): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.meeting.main;
        return this.httpClient.put(endpoint, meeting);
    }

    deleteMeeting(id: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.meeting.getMeetingEndpoint(id);
        return this.httpClient.delete(endpoint);
    }

    addParticipant(participant: any): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.meeting.addParticipant;
        return this.httpClient.post(endpoint, participant);
    }

    removeParticipant(meetingId: number, participantId: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.meeting.getParticipantEndpoint(meetingId, participantId);
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        // si user avec organisation
        if (orgId !== this.globals.NO_ORGA) {
            endpoint += `?organizationId=${orgId}`;
        }

        return this.httpClient.delete(endpoint);
    }

    deletePaidParticipant(meetingId: number, participantId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.meeting.getSoftDeleteEndPoint(meetingId, participantId);

        return this.httpClient.delete(endpoint);
    }

    modifyParticipantType(meetingId: number, participantId: number, participantType: string): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.meeting.getParticipantTypeUpdateEndpoint(meetingId, orgId);
        const body = {
            id: participantId,
            extraType: participantType
        };
        return this.httpClient.put(endpoint, body);
    }

    getAllMeetingParticipants(meetingId: number, filters?: SelectedFilter[], pagination?: Pagination, search?: any): Observable<ParticipantMeeting[]> {
        let endpoint = this.uri + this.globals.endpoints.meeting.getFilteredParticipants(meetingId);

        if (filters || pagination) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }
        if (search) {
            endpoint += '&search=' + encodeURIComponent(search);
        }

        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) pagination.total = data['total'];
                return data['participants'].map((part) => new ParticipantMeeting(part));
            })
        );
    }

    getAllMeetingParticipantsForCurrentOrg(meetingId: number, isPaid?: boolean, withActivities?: boolean): Observable<ParticipantMeeting[]> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        let endpoint = this.uri + this.globals.endpoints.meeting.getAllParticipantsEndpoint(meetingId);

        // si user sans organisation
        if (orgId === this.globals.NO_ORGA) {
            endpoint += `?userId=${this.authService.User.id}`;
        }
        // sinon organisation courante
        else {
            endpoint += `?organizationId=${orgId}`;
        }

        if (isPaid !== undefined && isPaid !== null) {
            endpoint += `&isPaid=${isPaid ? 1 : 0}`;
        }

        if (withActivities) {
            endpoint += '&withActivities=1';
        }

        return this.httpClient.get(endpoint).map((res: any[]) => {
            return res.map((part) => new ParticipantMeeting(part));
        });
    }

    getParticipantById(meetingId: number, participantId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.meeting.getParticipantEndpoint(meetingId, participantId);
        return this.httpClient.get(endpoint);
    }

    getParticipantsOrganization(meetingId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.meeting.getParticipantOrgEndpoint(meetingId);
        return this.httpClient.get(endpoint);
    }

    addProductToParticipant(meetingId: number, participantId: number, productId: number, tarifId?: number, gratuityId?: number): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        let endpoint = this.uri + this.globals.endpoints.meeting.getParticipantProductEndpoint(meetingId, participantId, productId);

        if (orgId !== this.globals.NO_ORGA || tarifId) {
            endpoint += '?';
        }
        if (orgId !== this.globals.NO_ORGA) {
            endpoint += `organizationId=${orgId}`;
        }
        if (tarifId) {
            const addParams = endpoint.indexOf('?') != -1 ? `&productTariffId=${tarifId}` : `?productTariffId=${tarifId}`;
            endpoint += addParams;
        }
        if (gratuityId) {
            const addParams = endpoint.indexOf('?') != -1 ? `&productGratuityId=${gratuityId}` : `?productGratuityId=${gratuityId}`;
            endpoint += addParams;
        }

        return this.httpClient.post(endpoint, {});
    }

    removeProductFromParticipant(meetingId: number, participantId: number, productId: number): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        let enpoint = this.uri + this.globals.endpoints.meeting.getParticipantProductEndpoint(meetingId, participantId, productId);
        if (orgId !== this.globals.NO_ORGA) {
            enpoint += `?organizationId=${orgId}`;
        }
        return this.httpClient.delete(enpoint);
    }

    removeProductsFromParticipant(meetingId: number, participantId: number, productIds: number[]): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        let enpoint = this.uri + this.globals.endpoints.meeting.getParticipantProductsDeleteEndpoint(meetingId, participantId);
        if (orgId !== this.globals.NO_ORGA) {
            enpoint += `?organizationId=${orgId}`;
        }
        let params = new HttpParams();
        params = params.append('ids', productIds.join(', '));
        return this.httpClient.delete(enpoint, { params: params });
    }

    payForInscription(paiementInfos: any): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.meeting.pay;
        return this.httpClient.post(endpoint, paiementInfos);
    }

    cancelInscription(meetingId: number): Observable<any> {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.meeting.getCancelEndpoint(meetingId, orgId);
        return this.httpClient.put(endpoint, {});
    }

    getMeetingJuries(meetingId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.meeting.getAllJuryEndpoint(meetingId);
        return this.httpClient.get(endpoint);
    }

    addJuryMember(meetingId: number, jury: Jury): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.meeting.getJuryEndpoint(meetingId);
        endpoint += `?organizationId=${this.accountService.getCurrentCtxOrganizationId()}`;
        return this.httpClient.post(endpoint, jury);
    }

    removeJuryMember(meetingId: number, juryId: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.meeting.getJuryEndpoint(meetingId) + '/' + juryId;
        endpoint += `?organizationId=${this.accountService.getCurrentCtxOrganizationId()}`;
        return this.httpClient.delete(endpoint);
    }
}
