import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() badgeText: string;
  @Input() badgeIcon: string;
  @Input() badgeType: string;
  @Input() badgeSize: string;

  ngOnInit(): void {
    this.badgeSize ? (this.badgeSize = 'badge--' + this.badgeSize) : '';
    this.badgeType ? (this.badgeType = 'badge--' + this.badgeType) : '';
  }
}
