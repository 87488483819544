import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ToursService } from './services/tours.service';
import { Observable, of, combineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { TourShow } from 'src/app/concepts/tours/model/tour-show.model';
import { UserOrganizationIdResolver } from '../account/user-organization-id.resolver';
import { TourAdminResolver } from './tour-admin.resolver';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TourShowStatus } from 'src/app/concepts/tours/enums/tour-show-status.enum';
import { Store } from './store';
import { Program } from 'src/app/concepts/program/model/program.model';

@Injectable({ providedIn: 'root' })
export class PaidTourShowsResolver extends Store<TourShow[]>  {
  constructor(
    private readonly toursService: ToursService,
    private readonly userOrganizationIdResolver: UserOrganizationIdResolver,
    private readonly tourAdminResolver: TourAdminResolver,
    private readonly httpClient: HttpClient
  ) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TourShow[]> {
    return this.initialize(combineLatest([
      this.userOrganizationIdResolver.resolve(),
      this.toursService.getTourById(+route.paramMap.get('tourId')),
      this.tourAdminResolver.resolve(route)
    ]).pipe(
      switchMap(([contextOrganizationId, tour]) => {
        if (tour.sourceTourId) {
          const showStatusId = route.data.showStatusId
          return this.httpClient.get<TourShow[]>(`${environment.BACKEND}tours/${tour.id}/tour-shows`, {
            params: {
              organizationId: contextOrganizationId.toString(),
              ...(showStatusId ? { showStatusId } : {})
            }
          }).pipe(
            map(data => data.map(v => new TourShow(v)))
          );
        } else {
          return of([]);
        }
      })
    ));
  }

  incrementProgramCount(program: Program) {
    this.state.find(tourShow => tourShow.show.id === program.showId).programCount += 1;
    this.state = this.state;
  }

  decrementProgramCount(program: Program) {
    this.state.find(tourShow => tourShow.show.id === program.showId).programCount -= 1;
    this.state = this.state;
  }
}
