import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { ParticipantMeeting } from './../../../meeting/model/participant.model';
import { Activity } from './../../model/activity.model';
import { ActivityService } from './../../services/activity.service';
import { SelectedFilter } from 'src/app/shared/model/list-item.model';
import { Observable } from 'rxjs';
import { Globals } from 'src/app/_configs/globals';

@Component({
    selector: 'app-choose-activities',
    templateUrl: './choose-activities.component.html',
    styleUrls: [ './choose-activities.component.scss' ]
})
export class ChooseActivitiesComponent implements OnInit {
    @Input()
    participants: ParticipantMeeting[];

    @Input()
    meetingId: number;

    @Input()
    meetingTitle: string;

    @Input()
    organization: Organization;

    @Input()
    isActivityChoiceEnabled: boolean;

    public activityList: Activity[];

    isLoading = false;

    lang = this.translate.currentLang;

    filters: SelectedFilter[] = [
        {
            field: 'isAvailable',
            value: '1'
        }
    ];

    activityMatrix = {};

    // Nombre d'activités par participant
    activityTotals = {};
    public hasRemainingCapacity$: Observable<boolean> = this.activityService.hasRemainingCapacity$;

    constructor(private globals: Globals, private notificaton: RideauNotificationService, private activityService: ActivityService, private translate: TranslateService) { }

    ngOnInit(): void {
        // Avec les Activities, on récupère pour chacune un tableau de participantIds,
        // qui nous permet d'initialiser la matrice
        this.getActivities();
    }
    // https://logient.atlassian.net/browse/RIDEAUM-686
    // when created, the activity can have a "remaining Capacity" field left blank and the value is saved as null
    // the null value is the same as infinite capacity
    public hasRemainingCapacity = (activityId: number): boolean => {
        const index = this.activityList.findIndex(x => x.id === activityId);
        if (index > -1) {
            const activity = this.activityList[ index ];
            return 'remainingCapacity' in activity && (activity.remainingCapacity === null || activity.remainingCapacity > 0);
        }
        return false;
    };


    /**
     * Retourne la date formattée avec le jour de la semaine
     * (ex: 'vendredi 26 juillet 2019')
     * @param dateStr
     */
    getLocalizedDate(dateStr): string {
        const date = new Date(dateStr);
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        } as Intl.DateTimeFormatOptions;
        return date.toLocaleDateString('fr-CA', options);
    }

    /*
     * Indique si une des case (activity, participant) de la matrice
     * doit être disabled selon les paramètres de restriction de l'activité
     */
    private getEnabledState(participant: ParticipantMeeting, activity: Activity): boolean {
        // Si désactivé de manière générale
        if (!this.isActivityChoiceEnabled) {
            return false;
        } else {
            return activity.isActivityChoiceEnabled(participant, this.organization);
        }
    }

    /**
     * parcours la matrice pour mettre à jour les totaux
     */
    updateTotals(): void {
        this.activityTotals = this.participants.reduce((acc, participant) => {
            acc[ participant.id ] = this.activityList.reduce((acc2, activity) => {
                if (this.activityMatrix[ participant.id ][ activity.id ].value) {
                    acc2++;
                }
                return acc2;
            }, 0);
            return acc;
        }, {});
    }

    onCheckboxChange(participant: ParticipantMeeting, activityId: number) {
        const activity = this.activityList.find((act) => act.id === activityId);

        // si on ajoute une activité
        if (this.activityMatrix[ participant.id ][ activityId ].value) {
            participant.activities.push(activity);
            activity.participants.push(participant.id);
        }
        // si ion enlève une activité
        else {
            participant.activities = participant.activities.filter((act) => act.id !== activityId);
            activity.participants = activity.participants.filter((partId) => partId !== participant.id);
        }
        this.initMatrix();
        this.updateTotals();
    }

    /**
     * fonction appelée quand on clique sur OK dans la modale. Fais l'appel service.
     */
    onValidate(): Observable<any> {
        const body: any[] = Object.keys(this.activityMatrix).map((participantId) => {
            const choiceLine = {
                participantId: Number(participantId),
                activities: []
            };

            choiceLine.activities = Object.keys(this.activityMatrix[ participantId ])
                .filter((activityId) => this.activityMatrix[ participantId ][ activityId ].value)
                .map((activityId) => Number(activityId));

            return choiceLine;
        });

        return this.activityService.saveParticipantsActivity(this.meetingId, body);
    }

    printDocument(): boolean {
        const mywindow = window.open('', 'PRINT', 'height=600,width=1000');

        mywindow.document.write('<html><head><title>' + 'Printing activity choices' + '</title>');
        mywindow.document.write(
            '<link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@500;600&family=Open+Sans:wght@400;600&family=Roboto:wght@400;500&display=swap" rel="stylesheet">'
        );
        mywindow.document.write('<style>' + this.globals.printActivityChoicesCSS + '</style>');
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById('htmlData').innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        document.querySelectorAll('.ant-checkbox-checked input.ant-checkbox-input').forEach(function (item, index) {
            item[ 'checked' ] = true;
        });

        mywindow.print();
        return true;
    }

    private getActivities(): void {
        this.isLoading = true;
        this.activityService.getActivitiesForMeeting(this.meetingId, this.filters).subscribe((datas) => {
            this.activityList = datas;
            this.initMatrix();
            this.updateTotals();
            this.isLoading = false;
        });
    }

    /**
     * Initialisatoin de la matrice et des totaux. Comme on cherche à transformer des tableaux en
     * objet, on utilise des reduce()
     */
    private initMatrix(): void {
        // Matrice
        this.activityMatrix = this.participants.reduce((acc1, participant) => {
            acc1[ participant.id ] = this.activityList.reduce((acc2, activity) => {
                // Si le tableau des participants de l'activité comprends le participantId courant,
                // C,est qu'il est déjà inscrit -> On met la valeur à true.
                acc2[ activity.id ] = {
                    value: activity.participants.includes(participant.id),
                    disabled: !this.getEnabledState(participant, activity),
                    fullSchedule: !activity.isParticipantScheduleFree(participant)
                };

                return acc2;
            }, {});
            return acc1;
        }, {});

        // Totaux
        this.activityTotals = this.participants.reduce((acc, participant) => {
            acc[ participant.id ] = 0;
            return acc;
        }, {});
    }
}
