import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
/*Components*/
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { LightboxModule } from 'ngx-lightbox';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../shared/shared.module';
import { MyShowsLittleComponent } from './components/my-shows/my-shows-little/my-shows-little.component';
import { MyShowsComponent } from './components/my-shows/my-shows.component';
import { ShowAdvancedSearchComponent } from './components/show-list/show-advanced-search/show-advanced-search.component';
import { ShowListComponent } from './components/show-list/show-list.component';
import { ShowCachetFormComponent } from './components/show-modify/show-cachet-form/show-cachet-form.component';
import { ShowClassificationFormComponent } from './components/show-modify/show-classification-form/show-classification-form.component';
import { ShowContactsFormComponent } from './components/show-modify/show-contacts-form/show-contacts-form.component';
import { ShowIdentificationFormComponent } from './components/show-modify/show-identification-form/show-identification-form.component';
import { ShowMaterielFormComponent } from './components/show-modify/show-materiel-form/show-materiel-form.component';
import { ShowModifyComponent } from './components/show-modify/show-modify.component';
import { ShowSingleComponent } from './components/show-single/show-single.component';
import { ShowPromoteComponent } from './components/show-single/show-promote/show-promote.component';
import { MarketingDetailsComponent } from './components/show-modify/marketing-details/marketing-details.component';
import { ReadOnlyActionComponent } from './components/show-modify/marketing-details/read-only-action/read-only-action.component';
import { GetTranlatedLanguagesPipe } from './pipes/get-tranlated-languages.pipe';
import { GetTranslatedPublicsPipe } from './pipes/get-translated-publics.pipe';
import { MinuteToHourPipe } from './pipes/minute-to-hour.pipe';
import { ExtractSingleClassificationPipe } from './pipes/extract-single-classification.pipe';
import { ShowDepotSingleComponent } from './components/show-depot-single/show-depot-single.component';
import { ImageCropperComponent } from 'ngx-image-cropper';

@NgModule({
    declarations: [
        ShowSingleComponent,
        ShowModifyComponent,
        ShowListComponent,
        MyShowsComponent,
        MyShowsLittleComponent,
        ShowIdentificationFormComponent,
        ShowClassificationFormComponent,
        ShowCachetFormComponent,
        ShowContactsFormComponent,
        ShowMaterielFormComponent,
        ShowAdvancedSearchComponent,
        ShowPromoteComponent,
        MarketingDetailsComponent,
        ReadOnlyActionComponent,
        GetTranlatedLanguagesPipe,
        GetTranslatedPublicsPipe,
        MinuteToHourPipe,
        ExtractSingleClassificationPipe,
        ShowDepotSingleComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        LocalizeRouterModule,
        // AccountModule,
        SharedModule,
        LightboxModule,
        QuillModule.forRoot(),
        ImageCropperComponent
    ],
    exports: [ShowSingleComponent, ShowModifyComponent, ShowListComponent, MyShowsComponent, MyShowsLittleComponent, ShowPromoteComponent, ShowAdvancedSearchComponent]
})
export class ShowModule {}
