<app-page-title title="{{ 'DATE-SPECTACLE' | translate }}">
    <div class="show-info">
        <i class="icon-world" aria-hidden="true"></i>
        <h5 *ngIf="tour">{{ tour.organization | translatable: 'name':'' }} – {{ tour | translatable: 'name' }}</h5>
    </div>
</app-page-title>

<form nz-form [formGroup]="programForm" (ngSubmit)="doSubmit()">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col col-sm-8 col-lg-6 mb-5">
                <div class="statusNotification success">
                    <span class="statusNotification__message">
                        <svg class="success" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path
                                fill-rule="nonzero"
                                d="M2.138 11.771c.899 3.824 4.006 6.367 7.862 6.367 3.84 0 6.938-2.522 7.85-6.321a1 1 0 0 1 1.946.467C18.67 16.97 14.778 20.138 10 20.138c-4.798 0-8.7-3.194-9.809-7.909a1 1 0 0 1 1.947-.458zm11.426-6.097a1 1 0 0 1 .326 1.282l-.064.108-4.776 7a1 1 0 0 1-1.523.154l-.083-.092-2.224-2.77a1 1 0 0 1 1.475-1.344l.085.093 1.378 1.716 4.016-5.885a1 1 0 0 1 1.39-.262zM10 0c4.778 0 8.67 3.168 9.796 7.854a1 1 0 0 1-1.945.467C16.938 4.521 13.84 2 10 2 6.143 2 3.036 4.543 2.137 8.367a1 1 0 1 1-1.947-.458C1.3 3.194 5.202 0 10.001 0z"
                            />
                        </svg>
                        <div *ngIf="tour$ | async as tour">
                            {{ 'TOURNER-ADMIN-INFO' | translate }}
                            <span>{{ tour | translatable: 'name' }}</span>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col col-sm-8 col-lg-6 mb-5">
                <div nz-row nzGutter="16" class="ml-1 mb-1">
                    <!-- date-->
                    <app-date-picker formControlName="date" (ngModelChange)="onDateChange($event)">{{ 'FORM.DATE' | translate }} *</app-date-picker>

                    <!-- showId -->
                    <app-row-select
                        *ngIf="shows$ | async as shows"
                        [rowType]="RowTypes.SHOW"
                        [options]="shows"
                        labelProperty="title"
                        formControlName="showId"
                        (ngModelChange)="showsOnChange($event)"
                    >
                        {{ 'SPECTACLE' | translate }}
                    </app-row-select>

                    <!-- diffuserId -->
                    <app-row-select *ngIf="diffusers$ | async as diffusers" [rowType]="RowTypes.DIFFUSEUR" [options]="diffusers" labelProperty="name" formControlName="diffuserId">
                        {{ 'DIFFUSEUR' | translate }}
                    </app-row-select>

                    <section *ngIf="!isProducer || !isProducer || isCustomVenueEnable || program.match">
                        <div nz-row nzGutter="16">
                            <!-- venueId -->
                            <div nz-col nzMd="16">
                                <div>
                                    <app-select formControlName="venueId" labelProperty="name" [options]="venues$ | async" (ngModelChange)="venuesOnChange($event)">
                                        {{ 'FORM.SALLE' | translate }}
                                    </app-select>
                                </div>
                            </div>

                            <!-- hour -->
                            <div nz-col nzMd="8">
                                <nz-form-item>
                                    <label for="hour">{{ 'HEURE' | translate }}</label>
                                    <app-time-picker formControlName="hour" [validateStatus]="isHourUpdatePending ? 'error' : ''"></app-time-picker>
                                </nz-form-item>
                            </div>
                        </div>
                    </section>

                    <!-- statusId -->
                    <app-radio formControlName="statusId" [Options]="ProgramStatus" translateKeyPrefix="PROGRAMME-STATUS-" [filterOutKeys]="[0, 1]">
                        {{ 'FORM.STADE-CONFIRMATION' | translate }}
                    </app-radio>

                    <section>
                        <div nz-row nzGutter="16" class="ml-1 mb-3">
                            <div class="form-separator">
                                <span class="form-separator__line"></span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g fill="none" fill-rule="evenodd">
                                            <g fill="#1C1C1B" fill-rule="nonzero">
                                                <path
                                                    d="M10 3c2.689 0 4.882 2.122 4.995 4.783L15 9.267C15.597 9.613 16 10.259 16 11v4c0 1.105-.895 2-2 2H6c-1.105 0-2-.895-2-2v-4c0-.74.402-1.386.999-1.732L5 8c0-2.761 2.239-5 5-5zm0 2C8.402 5 7.096 6.249 7.005 7.824L7 9h5.999L13 8c0-1.657-1.343-3-3-3z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </i>
                                <span class="form-separator__line"></span>
                            </div>
                            <p class="form-text">{{ 'FORM.PRIVATE-INFOS' | translate }}</p>
                        </div>

                        <div nz-row nzGutter="16" class="ml-1 mb-1">
                            <!-- calendarId -->
                            <nz-form-item *ngIf="calendars$ | async as calendars">
                                <label for="calendarId">{{ 'CALENDAR' | translate }}</label>
                                <nz-form-control>
                                    <nz-select name="calendarId" nzAllowClear style="width: 100%" formControlName="calendarId">
                                        <nz-option
                                            *ngFor="let calendar of calendars"
                                            [nzLabel]="calendar.getTranslatedProperty(translate.currentLang, 'title')"
                                            [nzValue]="calendar.id"
                                        ></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>

                            <!-- ADDITIONNAL NOTE -->
                            <nz-form-item>
                                <label for="note">{{ 'FORM.NOTE' | translate }}</label>
                                <nz-form-control>
                                    <input id="note" name="note" formControlName="note" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    <!-- FORM SUBMIT -->
    <div class="bg-neutral py-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col col-sm-8 col-lg-6 mb-5">
                    <button nz-button [nzLoading]="loading" [disabled]="!programForm.valid || loading" class="btn btn--default">
                        {{ 'ENREGISTER' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
