import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'sp-checkbox-group',
    template: `<div class="checkbox-group">
        <ng-content></ng-content>
    </div>`,
    styleUrls: [ './checkbox-group.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SPCheckboxGroupComponent { }
