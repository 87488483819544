import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RowTypes } from '../../../../shared/enums/row-types.enum';
import { Filter, FilterValue, ListItem, Pagination, SelectedFilter } from '../../../../shared/model/list-item.model';
import { VitrineStatus } from '../../enums/vitrine-status.enum';
import { Vitrine } from '../../model/vitrine.model';
import { VitrineService } from '../../services/vitrine.service';

@Component({
    selector: 'app-vitrine-list',
    templateUrl: './vitrine-list.component.html',
    styleUrls: ['./vitrine-list.component.scss']
})
export class VitrineListComponent implements OnInit {
    @Input() meetingId: any;
    @Input() enableFilters: boolean;
    @Input() enableEditing: boolean;
    @Input() useViewSwitcher: boolean;
    @Input() isAdminUsage: boolean;
    @Output() vitrineListSize: EventEmitter<any> = new EventEmitter<any>();

    vitrinesList: Vitrine[];
    vitrinesListItems: ListItem[];

    // Configs
    currentLang: string;
    searchText: string;
    isLoading = false;
    listType: RowTypes = RowTypes.VITRINE;

    private selectedFilters: SelectedFilter[] = [];
    private advancedFilters: SelectedFilter[] = [];

    // On ne recupère que les vitrines payées
    private defaultFilters: SelectedFilter[] = [
        {
            field: 'isPaid',
            value: 1
        }
    ];
    filterList: Filter[] = [];
    pagination: Pagination = new Pagination();

    constructor(private translate: TranslateService, private vitrineService: VitrineService, private router: Router) {}

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
        this.initFilters();
        this.initPagination();
        this.getVitrines();
    }

    getVitrines() {
        this.isLoading = true;
        sessionStorage.setItem('vitrine_pagination', JSON.stringify(this.pagination));
        this.vitrineService
            .getVitrineForMeeting(this.meetingId, [...this.defaultFilters, ...this.selectedFilters, ...this.advancedFilters], this.pagination, this.searchText)
            .subscribe((data) => {
                this.vitrinesList = data;
                this.vitrinesListItems = this.vitrinesList.map((vitrine) => {
                    const item = vitrine.toListItem(this.currentLang);
                    item.itemUrl = `/pro-meeting/${vitrine.meetingId}/showcase/${vitrine.id}/show/${vitrine.showId}`;
                    return item;
                });
                this.vitrineListSize.emit(this.pagination.total);
                this.isLoading = false;
            });
    }

    initFilters() {
        this.selectedFilters = [];
        this.filterList = [];
        this.getVitrineFilters();
    }

    getVitrineFilters() {
        const VitrineFilter = new Filter();
        VitrineFilter.filterField = 'statusId';
        VitrineFilter.filterName = this.translate.instant('VITRINES-STATUT');
        VitrineFilter.filterValues = [];
        VitrineFilter.filterValues.push(
            {
                filterValueName: this.translate.instant('STATUS.SOUMIS-COURT'),
                filterValue: VitrineStatus.SOUMIS
            },
            {
                filterValueName: this.translate.instant('STATUS.ACCEPTE'),
                filterValue: VitrineStatus.APPROUVE
            },
            {
                filterValueName: this.translate.instant('STATUS.REFUSE'),
                filterValue: VitrineStatus.REJETE
            }
        );

        VitrineFilter.filterValues.unshift({
            filterValueName: this.translate.instant('TOUTES'),
            filterValue: 0
        } as FilterValue);

        this.filterList.push(VitrineFilter);

        if (!this.enableFilters && !this.isAdminUsage) {
            const filter: SelectedFilter = {
                field: 'statusId',
                value: VitrineStatus.APPROUVE
            };
            this.selectedFilters.push(filter);
        }
    }

    initPagination() {
        this.pagination = new Pagination(0, 12);
        const session = JSON.parse(sessionStorage.getItem('vitrine_pagination'));
        if (session) {
            Object.assign(this.pagination, session);
        }
    }

    onFilterChange(event: SelectedFilter) {
        this.selectedFilters = [event];
        this.getVitrines();
    }

    onAdvancedFilterChange(event: SelectedFilter[]) {
        // On remet à la page 1
        this.pagination.offset = 0;
        this.advancedFilters = event;
        this.getVitrines();
    }

    onSearchChange(event: string) {
        this.searchText = event;
        this.getVitrines();
    }

    onPageChange(event) {
        this.pagination.offset = event.offset;
        this.getVitrines();
    }

    modifyVitrine(vitrineId) {
        const activity = this.translate.instant('ROUTES.activity');
        const modify = this.translate.instant('ROUTES.modify');
        const editRoute = `/${this.currentLang}/${activity}/${this.meetingId}/${vitrineId}/${modify}`;
        this.router.navigate([editRoute]);
    }
}
