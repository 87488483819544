import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Component, Input, OnInit } from '@angular/core';
import { Meeting } from 'src/app/concepts/meeting/model/meeting.model';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { StandService } from '../../services/stand.service';
import { IStandRequest, Stand } from './../../model/stand.model';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../../../_configs/globals';
import { OrganizationTypes } from '@app/concepts/organization/enums/organization-types.enum';
import { IUpdatedResponse } from '@app/shared/model/response.model';

@Component({
    selector: 'app-choose-stand-modal',
    templateUrl: './choose-stand-modal.component.html',
    styleUrls: [ './choose-stand-modal.component.scss' ]
})
export class ChooseStandModalComponent implements OnInit {
    @Input()
    meeting: Meeting;

    @Input()
    organization: Organization;

    @Input()
    standList: Stand[];

    @Input()
    selectedStand: Stand;

    selectedStandCopy: Stand;

    defaultImg: string;

    savedForm: boolean = false;

    constructor(private globals: Globals, private standService: StandService, private translate: TranslateService, private notification: RideauNotificationService) { }

    ngOnInit(): void {
        // Set disable flag for each stand (if occupied or type of orga not allowed)
        // this.setupStandList();
        this.defaultImg = this.globals.imgFallback;
        this.selectedStandCopy = this.selectedStand;
    }

    public async onValidate(): Promise<boolean> {
        // Marquer le formulaire comme enregistré
        this.savedForm = true;
        if (this.selectedStand) {
            // Check if there's a stand attached to the organization
            const oldStandIndex = this.standList.findIndex((stand) => stand.organizationId === this.organization.id);
            if (oldStandIndex > -1 && this.standList[ oldStandIndex ].id === this.selectedStand.id) {
                // nothing to update
                return Promise.resolve(false);
            }
            const payload = new Stand({
                ...this.selectedStand,
                organization: this.organization,
                organizationId: this.organization.id,
                organizationTypeId: this.organizationTypeId
            });
            this.removeStandFromSelection(oldStandIndex);
            // Update the selected stand
            return this.updateStand(payload, oldStandIndex);
        } else {
            return this.removeStandFromSelection();
        }
    }

    private removeStandFromSelection = async (index?: number): Promise<boolean> => {
        // Si aucun stand n'est sélectionné
        // Rechercher l'ancien stand
        if (index) {
            const payload: IStandRequest = {
                ...this.standList[ index ],
                meetingId: this.meeting.id,
                label: this.standList[ index ].label,
                organizationTypeId: OrganizationTypes.VACANT,
                organizationId: null,
                id: this.standList[ index ].id,
                meetingOrganizationId: this.meeting.organizationId,
                contextOrganizationId: null
            };
            return Promise.resolve(false);
        }
        const oldStandIndex = this.standList.findIndex((stand) => stand.organizationId === this.organization.id);
        if (oldStandIndex > -1) {
            const payload = new Stand({
                ...this.standList[ oldStandIndex ],
                organization: null,
                contextOrganization: null,
                organizationId: null,
                organizationTypeId: OrganizationTypes.VACANT
            });
            // Libérer l'ancien emplacement
            return this.updateStand(payload, oldStandIndex, 'CHOIX-STAND-DESELECTIONNE');
        }
        // Retourner une promesse résolue si aucun stand n'est sélectionné
        return Promise.resolve(false);
    }
    private notify(oldStandIndex: number, message?: string) {
        if (oldStandIndex > -1) {
            this.standList[ oldStandIndex ].isDisabled = false;
        }
        this.notification.success(this.translate.instant(message || 'CHOIX-STAND-ENREGISTRE'));
    }

    private get organizationTypeId(): OrganizationTypes {
        // Recherche du type de l'organisation
        if (this.organization.types.includes(OrganizationTypes.IS_PRODUCTEUR)) {
            return OrganizationTypes.IS_PRODUCTEUR;
        } else if (this.organization.types.includes(OrganizationTypes.IS_DIFFUSEUR)) {
            return OrganizationTypes.IS_DIFFUSEUR;
        } else {
            return OrganizationTypes.IS_OTHER;
        }
    }

    private updateStand = async (payload: Stand, oldStandIndex: number, message?: string): Promise<boolean> => {
        return this.standService
            .updateStand(payload.toStandRequest())
            .toPromise()
            .then(({ isUpdated }: IUpdatedResponse) => {
                this.notify(oldStandIndex, message);
                return Promise.resolve(isUpdated);
            }).catch((err) => {
                if (err.error && err.error.others && err.error.others.name === 'Unavailable') {
                    // Display a notification if the choice cannot be made
                    this.notification.error(this.translate.instant('UNAVAILABLE-CHOICE'));
                }
                return Promise.reject(err);
            });
    }
}
