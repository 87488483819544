import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/_configs/globals';
import { Injectable } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.services';
import { formatDate } from 'src/app/shared/utils/time-utils';
import { Meeting } from '../meeting/model/meeting.model';
import { AccountService } from '../account/services/account.service';
import { Organization } from '../organization/model/organization.model';
import { TranslateService } from '@ngx-translate/core';
import { Tour } from '../tours/model/tour.model';
import { SelectedFilter } from './../../shared/model/list-item.model';

@Injectable({ providedIn: 'root' })
export class ReportsService extends MainService {
    constructor(protected globals: Globals, protected httpClient: HttpClient, private accountService: AccountService, private translate: TranslateService) {
        super(httpClient, globals);
    }

    public generateBillingReport(dateFrom: Date, dateTo: Date): void {
        const dateFromStr: string = formatDate(dateFrom);
        const dateToStr: string = formatDate(dateTo);
        const endpoint = this.uri + this.globals.endpoints.reports.getBillingEndpoint(dateFromStr, dateToStr);

        this.httpClient.get(endpoint).subscribe((res) => {
            const csvDatas = res['data'];
            const title = `transactions_${dateFromStr}_${dateToStr}.csv`;

            this.generateCsv(title, csvDatas);
        });
    }

    public generateProductsReport(dateFrom: Date, dateTo: Date): void {
        const dateFromStr: string = formatDate(dateFrom);
        const dateToStr: string = formatDate(dateTo);
        const endpoint = this.uri + this.globals.endpoints.reports.getProductsEndpoint(dateFromStr, dateToStr);

        this.httpClient.get(endpoint).subscribe((res) => {
            const csvDatas = res['data'];
            const title = `products_${dateFromStr}_${dateToStr}.csv`;

            this.generateCsv(title, csvDatas);
        });
    }

    generateVitrineReport(meeting: Meeting): void {
        const endpoint = this.uri + this.globals.endpoints.reports.getVitrinesReportEndpoint(meeting.id);

        const meetingName = meeting.getTranslatedProperty('fr', 'name');

        this.httpClient.get(endpoint).subscribe((res) => {
            this.generateXlsx(`Rapport_depots_de_vitrine_${meetingName}.xlsx`, res);
        });
    }

    generateParticipantReport(meeting: Meeting): void {
        let endpoint = this.uri + this.globals.endpoints.reports.getParticipantReportEndpoint(meeting.id);
        endpoint += `?organizationId=${this.accountService.getCurrentCtxOrganizationId()}`;

        const meetingName = meeting.getTranslatedProperty('fr', 'name');

        this.httpClient.get(endpoint).subscribe((res) => {
            this.generateXlsx(`Rapport_participants${meetingName}.xlsx`, res);
        });
    }

    generateSubmittedShowsReport(tour: Tour, network: Organization): void {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.reports.generateSubmittedShowsReport(tour.id, orgId);

        this.httpClient.get(endpoint).subscribe((res) => {
            this.generateXlsx(
                `Rapport_Tournée_${network.getTranslatedProperty(this.translate.currentLang, 'name')}_${tour.getTranslatedProperty(this.translate.currentLang, 'name')}.xlsx`,
                res
            );
        });
    }

    generateSubmittedShowsInterestsReport(tour: Tour, network: Organization): void {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        const endpoint = this.uri + this.globals.endpoints.reports.generateSubmittedShowsInterestsReport(tour.id, orgId);

        this.httpClient.get(endpoint).subscribe((res) => {
            this.generateXlsx(
                `Rapport_Interets_Tournée_${network.getTranslatedProperty(this.translate.currentLang, 'name')}_${tour.getTranslatedProperty(
                    this.translate.currentLang,
                    'name'
                )}.xlsx`,
                res
            );
        });
    }

    public generateMembersReport(): void {
        const endpoint = this.uri + this.globals.endpoints.reports.getMembersEndpoint(this.globals.RIDEAU_ORGID);
        this.httpClient.get(endpoint).subscribe((res) => {
            this.generateXlsx(`Rapport_service_aux_membres.xlsx`, res);
        });
    }

    public generateMemberFavoritesReport(filters?: SelectedFilter[]): void {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        let endpoint = this.uri + this.globals.endpoints.reports.getMemberFavoritesEndpoint(orgId);
        endpoint = this.getEndpointWithFilter(endpoint, filters);
        this.httpClient.get(endpoint).subscribe((res) => {
            this.generateXlsx(`Rapport_favoris.xlsx`, res);
        });
    }

    public generateCalendarXlsx(tour: Tour, filters?: SelectedFilter[]): void {
        const orgId = this.accountService.getCurrentCtxOrganizationId();
        let endpoint = this.uri + this.globals.endpoints.reports.getCalendarXlsxEndPoint(orgId);
        endpoint = this.getEndpointWithFilter(endpoint, filters);
        if (tour && tour.id) {
            endpoint += `&tourId=${tour.id}`;
        }
        const tourName = tour && tour.getTranslatedProperty(this.translate.currentLang, 'name') ? tour.getTranslatedProperty(this.translate.currentLang, 'name') : null;
        const reportFileName = tourName ? `Rapport_Calendrier_Tournee_${tourName}.xlsx` : 'Rapport_Calendrier.xlsx';
        this.httpClient.get(endpoint).subscribe((res) => {
            this.generateXlsx(reportFileName, res);
        });
    }

    public generateCalendar(tourId: number): void {
        const orgId = this.accountService.getCurrentCtxOrganizationId();

        let endpoint = this.uri + this.globals.endpoints.iCalendar.getCalendarICS();

        if (orgId) {
            endpoint += `?organizationId=${orgId}`;
        }

        if (tourId) {
            endpoint += `&tourId=${tourId}`;
        }

        this.httpClient.get(endpoint).subscribe((res: any) => {
            this.generateIcsFile(`calendarScenePro.ics`, res.calendar);
        });
    }

    generateUsersReport() {
        this.httpClient.get(this.uri + this.globals.endpoints.reports.users).subscribe((res) => {
            this.generateXlsx('Rapport_utilisateur.xlsx', res);
        });
    }

    getEndpointWithFilter = (endpoint: string, filters: SelectedFilter[]): string => {
        if (filters && filters.length) {
            endpoint += '?';
            endpoint += this.formatGetFilters(filters);
            endpoint += '&';
            endpoint = endpoint.slice(0, -1);
        }
        return endpoint;
    };

    generateIcsFile(title: string, icsData: any) {
        const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
        this.downloadBlob(title, blob);
    }

    private generateCsv(title: string, csvDatas: any) {
        const blob = new Blob([csvDatas], { type: 'text/csv' });

        this.downloadBlob(title, blob);
    }

    generateXlsx(title: string, data: any) {
        const byteCharacters = window.atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        this.downloadBlob(title, blob);
    }

    downloadBlob(title: string, blob: Blob) {
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE doesn't allow using a blob object directly as link href.
            // Workaround for "HTML7007: One or more blob URLs were
            // revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing
            // the URL has been freed."
            window.navigator.msSaveBlob(blob, title);
            return;
        }

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = title;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
            // a.remove();
        }, 100);
    }
}
