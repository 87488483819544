import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { greaterThanZeroValidator } from '../../../shared/validators/greaterThanZero.validator';

export class NetworkInfos {
    public annualAmountCALQ: number;
    public artistsFees: number;
    public broadcastEmployeesNumber: number;
    public createdAt: Date;
    public financialYearEnd: Date;
    public hasCALQSupport: number;
    public hasFederalSupport: number;
    public hasMunicipalSupport: number;
    public id: number;
    public legalStatusId: number;
    public organizationHeadId: number;
    public organizationId: number;
    public responsibleMember: string;
    public soldTicketsNumber: number;
    public ticketsRevenue: number;
    public updatedAt: Date;

    private networkInfoForm: UntypedFormGroup;

    constructor(datas: Record<string, any>) {
        this.id = datas[ 'id' ];
        this.organizationId = datas[ 'organizationId' ];
        this.organizationHeadId = datas[ 'organizationHeadId' ];
        this.responsibleMember = datas[ 'responsibleMember' ];
        this.legalStatusId = datas[ 'legalStatusId' ];
        this.hasCALQSupport = datas[ 'hasCALQSupport' ];
        this.annualAmountCALQ = datas[ 'annualAmountCALQ' ];
        this.hasFederalSupport = datas[ 'hasFederalSupport' ];
        this.hasMunicipalSupport = datas[ 'hasMunicipalSupport' ];
        this.broadcastEmployeesNumber = datas[ 'broadcastEmployeesNumber' ];
        this.financialYearEnd = datas[ 'financialYearEnd' ]
            ? new Date(datas[ 'financialYearEnd' ])
            : new Date(new Date().getFullYear(), 11, 31);
        this.ticketsRevenue = datas[ 'ticketsRevenue' ];
        this.artistsFees = datas[ 'artistsFees' ];
        this.soldTicketsNumber = datas[ 'soldTicketsNumber' ];
        this.createdAt = new Date(datas[ 'createdAt' ]);
        this.updatedAt = new Date(datas[ 'updatedAt' ]);
    }

    public getNetworkInfosForm(): UntypedFormGroup {
        const formatter = new Intl.NumberFormat('fr-CA', {
            minimumFractionDigits: 2,
        });
        if (!this.networkInfoForm) {
            this.networkInfoForm = new UntypedFormGroup({
                responsibleMember: new UntypedFormControl(this.responsibleMember),
                legalStatusId: new UntypedFormControl(this.legalStatusId, [ Validators.required ]),
                hasCALQSupport: new UntypedFormControl(this.hasCALQSupport === 1),
                annualAmountCALQ: new UntypedFormControl(this.annualAmountCALQ !== null ? formatter.format(this.annualAmountCALQ) : null),
                hasFederalSupport: new UntypedFormControl(this.hasFederalSupport === 1),
                hasMunicipalSupport: new UntypedFormControl(this.hasMunicipalSupport === 1),
                broadcastEmployeesNumber: new UntypedFormControl(
                    this.broadcastEmployeesNumber,
                    [ Validators.required ],
                ),
                financialYearEnd: new UntypedFormControl(this.financialYearEnd, [ Validators.required ]),
                ticketsRevenue: new UntypedFormControl(
                    this.ticketsRevenue !== null ? formatter.format(this.ticketsRevenue) : null,
                    [ Validators.required ]),
                artistsFees: new UntypedFormControl(this.artistsFees !== null ? formatter.format(this.artistsFees) : null,
                    [ Validators.required, greaterThanZeroValidator() ]
                ),
                soldTicketsNumber: new UntypedFormControl(
                    this.soldTicketsNumber !== null ? formatter.format(this.soldTicketsNumber) : null,
                    [ Validators.required, greaterThanZeroValidator() ],
                ),
            });
        }
        this.networkInfoForm.get('ticketsRevenue').valueChanges.subscribe(value => {
            if(value > 0) {
                this.networkInfoForm.get('artistsFees').setValidators([ Validators.required ]);
            } else {
                this.networkInfoForm.get('artistsFees').setValidators([ Validators.required, greaterThanZeroValidator() ]);
            }
            this.networkInfoForm.get('artistsFees').updateValueAndValidity();
        });
        
        return this.networkInfoForm;
    }
}

export enum OrganizationLegalStatus {
    LUCRATIF = 1,
    NON_LUCTRATIF = 2,
    COOP = 3,
    SCOLAIRE = 4,
    MUNICIPAL = 5,
    SOCIETE_ETAT = 6,
    AUTRE = 7,
}
